import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { GetPujaBookingInvoiceAPI } from "../ApiServices/BookPanditAPI/BookPanditAPI";
import { ServiceDiscounts } from "./enum";
import { GetDailyBookingInvoiceAPI } from "../ApiServices/DailyPanditAPI/DailyPanditApi";
import logo from '../../src/assets/images/logo.png'
import dayjs from "dayjs";
const Invoice = () => {
  const invoiceDetails = {
    invoiceNumber: "INV-12345",
    date: "March 16, 2025",
    customerName: "John Doe",
    customerAddress: "123 Main St, Springfield, IL",
    shippingAmount: 15.0, // Common shipping charge
    items: [
      { id: 1, description: "Product 1", quantity: 2, price: 29.99 },
      { id: 2, description: "Product 2", quantity: 1, price: 49.99 },
      { id: 3, description: "Product 3", quantity: 3, price: 19.99 },
    ],
  };

  const user_id = localStorage.getItem("userID");

  const [invoiceData, setInvoiceData] = useState({
    name: null,
    address: null,
    PanditName: null,
    PujaName: null,
    PujaPrice: null,
    DiscountPercentage: null,
    DiscountAmount: null,
    SamagriPrice: null,
    ConvenienceFee: null,
    PB_Total: null,
    PB_TaxPercentage: null,
    TaxAmount: null,
    PB_GrandTotal: null,
  });

  const location = useLocation();
  console.log(location.state);

  useEffect(() => {
    // debugger;
    if (location.state?.moduleName === "pujaBooking") {
      // Call puja booking invoice api
      GetPujaBookingInvoice();
    } else if (location.state?.moduleName === "dailyBooking") {
      GetDailyBookingInvoice();
    }
  }, [location.state]);

  // Get puja booking invoice api
  const GetPujaBookingInvoice = async () => {
    // debugger;

    try {
      const response = await GetPujaBookingInvoiceAPI(
        user_id,
        location.state.PB_ID
      );
      console.log(response);
      if (
        response.ResponseMessage === "list" &&
        response.ResponseCode === "0"
      ) {
        const modelData = response.DATA[0];
        setInvoiceData((prev) => ({
          ...prev,
          name: modelData?.FULL_NAME,
          address: modelData?.ADDRESS,
          PanditName: modelData?.PANDIT_NAME,
          PujaName: modelData?.PUJA_NAME,
          PujaPrice: modelData?.PB_PRICE,
          DiscountPercentage: modelData?.PB_DiscountPercentage,
          DiscountAmount: modelData?.PB_DiscountAmount,
          SamagriPrice: modelData?.PB_SAMAGRIPRICE,
          ConvenienceFee: modelData?.CONVENIENCE_FEE,
          PB_Total: modelData?.PB_Total,
          PB_TaxPercentage: modelData?.PB_TaxPercentage,
          TaxAmount: modelData?.PB_TaxAmount,
          PB_GrandTotal: modelData?.PB_GrandTotal,
          invoiceNumber: modelData?.InvoiceNumber,
          invoiceDate: dayjs(modelData?.InvDate).format('DD/MM/YYYY'),
          languageName: modelData?.LANG_NAME,
          pujaDate: dayjs(modelData?.PujaDate).format('DD/MM/YYYY'),
          pujaTime: modelData?.PB_TIME
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Get puja booking invoice api
  const GetDailyBookingInvoice = async () => {
    debugger;

    try {
      const response = await GetDailyBookingInvoiceAPI(
        user_id,
        location.state.DPB_ID
      );
      console.log(response);
      if (
        response.ResponseMessage === "list" &&
        response.ResponseCode === "0"
      ) {
        const modelData = response.DATA[0];
        setInvoiceData((prev) => ({
          ...prev,
          name: modelData?.FULL_NAME,
          address: modelData?.ADDRESS,
          PanditName: modelData?.PANDIT_NAME,
          PujaName: modelData?.DP_NAME,
          PujaPrice: modelData?.DPB_PRICE,
          DiscountPercentage: modelData?.DPB_DiscountPercentage,
          DiscountAmount: modelData?.DPB_DiscountAmount,
          SamagriPrice: modelData?.DPB_SAMAGRIPRICE,
          ConvenienceFee: modelData?.CONVENIENCE_FEE,
          PB_Total: modelData?.DPB_Total,
          PB_TaxPercentage: modelData?.DPB_TaxPercentage,
          TaxAmount: modelData?.DPB_TaxAmount,
          PB_GrandTotal: modelData?.DPB_GrandTotal,
          startDate: dayjs(modelData?.PoojaStartDate).format('DD/MM/YYYY'),
          endDate: dayjs(modelData?.PoojaEndDate).format('DD/MM/YYYY'),
          startTime: modelData?.PoojaStartTime,
          endTime: modelData?.PoojaEndTime,
          languageName: modelData?.LANG_NAME,
          invoiceNumber: modelData?.InvoiceNumber
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDownloadPDF = () => {
    const invoiceElement = document.getElementById("invoice-content");

    html2canvas(invoiceElement, { scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      const imgWidth = 210;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
      pdf.save(`Invoice_${invoiceDetails.invoiceNumber}.pdf`);
    });
  };;


  const calculateSubtotal = () => {
    return location.state.products.reduce(
      (total, item) => total + item.QTY * item.RATE,
      0
    );
  };

  const calculateTotal = () => {
    return (
      parseFloat(calculateSubtotal()) + Number(location.state.shippingCharges)
    );
  };

  function formatToIndianCurrency(amount) {
    if (!amount) return "";

    const [integerPart, decimalPart] = amount.toString().split(".");

    // Format the integer part using Indian numbering system
    const formattedIntegerPart = integerPart.replace(
      /(\d)(?=(\d\d)+\d$)/g,
      "$1,"
    );

    return decimalPart !== undefined
      ? `${formattedIntegerPart}.${decimalPart}`
      : formattedIntegerPart;
  }



  return (
    <div className="container d-flex flex-column justify-content-center align-items-center mt-2" style={{ minHeight: "100vh" }}>
      <div id="invoice-content" style={{ width: "70vw" }}>
        <div style={{ margin: '10px' }}>
          <div className="card shadow-sm" style={{
            border: "2px solid black",
            padding: "20px",
            borderRadius: "10px",
            backgroundColor: 'white',

          }}>
            <div className="d-flex justify-content-between align-items-center border-bottom p-3">

              <div >
                <h1 className="h3 fw-bold" style={{ color: "#A52444" }}>
                  INVOICE
                </h1>
              </div>
              <div>
                <img
                  src={logo}
                  alt="Buildous Logo"
                  className="img-fluid"
                  style={{ height: "50px", width: "120PX" }}
                />
              </div>
            </div>

            <div className="card-body" >
              <div className="row d-flex justify-content-between">
                <div className="col-md-6">
                  <h5 style={{ fontSize: '20px' }}>Invoice No.</h5>

                  <p >
                    {invoiceData?.invoiceNumber}

                  </p>
                </div>
                <div className="col-md-6 d-flex justify-content-end">
                  <div>
                    <h5 style={{ fontSize: '20px' }}>Invoice Date</h5>
                    {/* <p>{invoiceDetails.date}</p> */}
                    <p style={{ fontSize: '15px' }}>{invoiceData?.invoiceDate}</p>
                  </div>
                </div>
              </div>
              <div className="row d-flex justify-content-between">
                <div className="col-md-12">
                  <h5 style={{ fontSize: '20px' }}>Customer Info</h5>
                  <p style={{ margin: 'auto', fontSize: '15px' }}>
                    <strong>Name:</strong> {invoiceData.name}
                    {/* <strong>Name:</strong> Test customer */}
                  </p>
                  <p >
                    {/* <strong>Address:</strong> {location.state.Address} */}
                    <strong>Address:</strong> {invoiceData.address}
                  </p>
                </div>
              </div>
              {location.state?.moduleName === "pujaBooking" ? (
                <table className="table table-bordered">
                  <thead className="thead-light">
                    <tr>
                      <th style={{ fontSize: '15px' }}>Sr No.</th>
                      <th style={{ fontSize: '15px' }}>
                        {location.state.moduleName === "pujaBooking"
                          ? "Puja Name"
                          : location.state.moduleName === "dailyBooking"
                            ? "Puja Name"
                            : "Description"}
                      </th>
                      <th style={{ fontSize: '15px' }}>
                        {location.state.moduleName === "pujaBooking"
                          ? "Pandit Name"
                          : location.state.moduleName === "dailyBooking"
                            ? "Pandit Name"
                            : "header"}
                      </th>
                      <th style={{ fontSize: '15px' }}>Puja Date</th>
                      <th style={{ fontSize: '15px' }}>Puja Time</th>

                      <th style={{ fontSize: '15px' }}>Puja Language</th>
                    </tr>
                  </thead>
                  <tbody>

                    <tr style={{ fontSize: '15px' }}>
                      <td>1</td>
                      <td>{invoiceData.PujaName}</td>
                      <td>{invoiceData.PanditName}</td>
                      {/* <td>2</td> */}
                      {/* <td>{invoiceData.PB_PRICE - invoiceData.PB_DiscountAmount}</td> */}
                      <td>
                        {invoiceData?.pujaDate}
                      </td>
                      <td>
                        {invoiceData?.pujaTime}
                      </td>
                      <td>
                        {invoiceData?.languageName}
                      </td>

                    </tr>
                  </tbody>
                </table>
              ) : (
                <table className="table table-bordered">
                  <thead className="thead-light">
                    <tr>
                      <th style={{ fontSize: '15px' }}>Sr No.</th>
                      <th style={{ fontSize: '15px' }}>
                        Puja Name
                      </th>
                      <th style={{ fontSize: '15px' }}>
                        Pandit Name
                      </th>
                      <th style={{ fontSize: '15px' }}>Start  Date</th>
                      <th style={{ fontSize: '15px' }}>End  Date</th>
                      <th style={{ fontSize: '15px' }}> Start Time</th>
                      <th style={{ fontSize: '15px' }}> End Time</th>

                      <th style={{ fontSize: '15px' }}>Puja Language</th>
                    </tr>
                  </thead>
                  <tbody>

                    <tr style={{ fontSize: '15px' }}>
                      <td>1</td>
                      <td>{invoiceData.PujaName}</td>
                      <td>{invoiceData.PanditName}</td>
                      {/* <td>2</td> */}
                      {/* <td>{invoiceData.PB_PRICE - invoiceData.PB_DiscountAmount}</td> */}
                      <td>
                        {invoiceData?.startDate}
                      </td>
                      <td>
                        {invoiceData?.endDate}
                      </td>
                      <td>
                        {invoiceData?.startTime}
                      </td>
                      <td>
                        {invoiceData?.endTime}
                      </td>
                      <td>
                        {invoiceData?.languageName}
                      </td>

                    </tr>
                  </tbody>
                </table>
              )}


              <div className="row">
                <div className="col-md-8">
                  <h5 style={{ fontSize: '20px' }}>Notes</h5>
                  <p>Thank you for shopping with us!</p>
                </div>
                <div className="col-md-4">
                  <table className="table">
                    <tbody>
                      <tr>
                        <td style={{ fontSize: '14px' }}>
                          <strong>Brahman Dakshina:</strong>
                        </td>
                        <td className="text-right" style={{ fontSize: '14px' }}>
                          ₹{" "}
                          {Number(invoiceData.PB_Total)}
                        </td>
                      </tr>
                      {Number(invoiceData?.DiscountPercentage) !== 0 && (
                        <tr>
                          <td style={{ fontSize: '14px' }}>
                            <strong>Discount ({invoiceData?.DiscountPercentage}%):</strong>
                          </td>
                          <td className="text-right" style={{ fontSize: '14px' }}>
                            ₹{" "}
                            {Number(invoiceData.DiscountAmount)}
                          </td>
                        </tr>
                      )}

                      <tr>
                        <td style={{ fontSize: '14px' }}>
                          <strong>Samagri Price:</strong>
                        </td>
                        <td className="text-right" style={{ fontSize: '14px' }}>
                          ₹{" "}
                          {Number(invoiceData.SamagriPrice)}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ fontSize: '14px' }}>
                          <strong>Convenience Fee:</strong>
                        </td>
                        <td className="text-right" style={{ fontSize: '14px' }}>
                          ₹{" "}
                          {Number(invoiceData.ConvenienceFee)}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ fontSize: '14px' }}>
                          <strong>Subtotal:</strong>
                        </td>
                        <td className="text-right" style={{ fontSize: '14px' }}>
                          ₹{" "}
                          {Number(invoiceData.PujaPrice) -
                            Number(invoiceData.DiscountAmount) +
                            Number(invoiceData.SamagriPrice) +
                            Number(invoiceData.ConvenienceFee)}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ fontSize: '14px' }}>
                          <strong>Tax (18%):</strong>
                        </td>
                        <td className="text-right" style={{ fontSize: '14px' }}>
                          {/* ₹ {location.state.shippingCharges} */}₹{" "}
                          {invoiceData.TaxAmount}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ fontSize: '14px' }}>
                          <strong>Total Amount:</strong>
                        </td>
                        <td className="text-right" style={{ fontSize: '14px' }}>
                          ₹{" "}
                          {Number(invoiceData.PB_GrandTotal)}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            {/* Card Footer with Download Button */}


          </div>
        </div>

      </div>
      <div className="mt-3">
        <button className="btn" style={{
          backgroundColor: "#a52444",
          color: "white",
          padding: "10px 20px",
          fontSize: "16px",
          borderRadius: "5px"
        }} onClick={handleDownloadPDF}>
          Download Invoice
        </button>
      </div>
    </div>
  );
};

export default Invoice;
