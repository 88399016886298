import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Mylogo from "../../assets/images/logo.png";
import dayjs from "dayjs";
import axios from "axios";
import { BaseUrl } from "../../BaseUrl/BaseUrl";
const PujaAtTempleOrderDetails = () => {
  const userID = JSON.parse(localStorage.getItem("userID"));
  const [localData, setLocalData] = useState(null);
  const [orders, setOrders] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();

  const handleProceedRemainingPayment = async () => {
    if (location.state.TOTAL_PRICE - location.state.TRANSACTION_AMT === 0) {
      alert("All the amount already paid");
      return;
    }
    // let totalAmount = parseInt(modelRequestData.totalVal); // Assuming totalVal includes shipping
    // let minPayableAmount = Remaining_Amount; // 20% of the total amount

    let orderIDReturned = location.state.TRB_ID;
    const finalAmount = Number(location.state.TOTAL_PRICE);
    const remainingAmount =
      location?.state?.TOTAL_PRICE - location?.state?.TRANSACTION_AMT;
    const options = {
      key: "rzp_test_vv1FCZvuDRF6lQ",
      key_secret: "P4JAUwn4VdE6xDLJ6p2Zy8RQ",
      amount: remainingAmount * 100,
      currency: "INR",
      name: "My Om Namo- WowInfotech",
      description: "working",
      image: Mylogo,
      handler: function (response) {
        // console.log(response, "Payment Success");
        const paymentId = response.razorpay_payment_id;

        if (response) {
          axios
            .post(`${BaseUrl}/UpdatePaymentStatus?TYPE=PujaAtTemple`, {
              USER_ID: userID,
              ORDER_ID: orderIDReturned,
              // ORDER_ID: JSON.parse(localStorage.getItem("orderID")),
              TRANSACTION_ID: paymentId,
              PAYMENT_STATUS: "Success",
              TRANSACTION_STATUS: "Success",
              TRANSACTION_AMT: finalAmount,
              REMAING_AMOUNT: 0,
              PAYMENT_DATE: dayjs().format("YYYY-MM-DD"),
            })
            .then((res) => {
              if (res.data.ResponseMessage === "successfully.") {
                // localStorage.setItem("cartItemCount", "0");
                // window.dispatchEvent(new Event("cartUpdated"));
                // setShowCODModal(false);
                // nextStep();
                navigate("/my-order-darshan-Booking");
              } else {
                alert("Order placement failed, please try again.");
              }
            });
        }

        // const updatedPayload = {
        //   ...razorPayData,
        //   TRANSACTION_ID: paymentId,
        //   TRANSACTION_AMT: minPayableAmount,
        // };

        // axios.post(`${BaseUrl}/BookPooja`, updatedPayload).then((res) => {
        //   debugger;
        //   if (res.data.ResponseMessage === "Order Success.") {
        //     localStorage.setItem("cartItemCount", "0");
        //     window.dispatchEvent(new Event("cartUpdated"));
        //     navigate("/thank-you");
        //   } else {
        //     alert("Order placement failed, please try again.");
        //   }
        // });
      },
      theme: {
        color: "#ff416c",
      },
    };

    const rzp = new window.Razorpay(options);
    rzp.open();
  };
  return (
    <>
      <div className="m-5 p-5 my-4">
        <div className="row">
          {/* Left Section: Multiple Order Items */}
          <div className="col-md-8">

            <div className="card p-3 mb-3 shadow-sm">
              <div className="d-flex align-items-center">
                {/* Product Image on the Left */}
                <img
                  src={location?.state?.PAT_IMAGE}
                  alt="Product"
                  className="rounded"
                  style={{
                    width: "180px",
                    height: "150px",
                    objectFit: "contain",
                  }}
                />

                {/* Product Details in the Center */}
                <div className="flex-grow-1 px-3">
                  <h6 className="fw-bold">{location?.state?.PAT_NAME}</h6>
                  <p className="text-muted mb-1">Temple Address : {location?.state?.PATB_ADDRESS}</p>
                  <p className="text-muted mb-1">Puja Date :  {location?.state?.PATB_DATE}</p>
                  <p className="text-muted mb-1">Puja Time : {location?.state?.PATB_TIME}</p>
                  <p className="text-muted mb-1">Puja Language : {location?.state?.PATB_LANG}</p>
                  <p className="text-muted mb-1">Pandit Name :{location?.state?.PANDIT_NAME || '-'}</p>
                </div>

                {/* Price on the Right */}
                <div className="text-end">
                  <h5 className="fw-bold  mb-0">
                    ₹
                    {location?.state?.PAT_PRICE}
                  </h5>

                </div>
              </div>

              {/* Order Status */}
              <hr />
            </div>

            <div className="d-flex align-items-center">
              {/* <span className="text-success fw-bold me-2">✔</span> */}
              <p className="mb-0">Order Confirmed, {location?.state?.REG_DATE}</p>
            </div>

            {/* Cancellation Message */}
            <p className="text-muted mt-2">
              Your order was cancelled as the courier could not deliver it even
              after multiple attempts.
            </p>
          </div>

          {/* Right Section: Single Product's Shipping & Price Details */}
          <div className="col-md-4">
            {/* Shipping Details */}
            {/* <div className="card p-3 mb-3 shadow-sm">
              <h6 className="fw-bold">Other details</h6>

              <p className="text-muted small">
                {location?.state?.PAT_ADDRESS}
              </p>

              <p className="fw-bold mb-0">Phone number: {location?.state?.MOBILE_NO}</p>
            </div> */}

            {/* Price Details (Using First Product) */}

            <div className="card p-3 shadow-sm">
              <h6 className="fw-bold">Price Details</h6>
              <div className="d-flex justify-content-between">
                <p className=" fw-bold">Brahman Fee</p>
                <p className="fw-bold">{location?.state?.PAT_PRICE}</p>
              </div>
              {location?.state?.PAT_SAMAGRIPRICE !== null && location?.state?.PAT_SAMAGRIPRICE != 0 ? (
                <>
                  <div className="d-flex justify-content-between">
                    <p className="fw-bold">Samagri Price</p>
                    <p className=" fw-bold">
                      <span>{location?.state?.PAT_SAMAGRIPRICE}</span>
                    </p>
                  </div>
                  <div className="d-flex justify-content-between">
                    <p className="fw-bold">Samagri Tax({location?.state?.SamagriPriceTaxPercent}%)</p>
                    <p className=" fw-bold">
                      <span>{location?.state?.SamagriPriceTaxAmount}</span>
                    </p>
                  </div>
                </>

              ) : ''}
              <div className="d-flex justify-content-between">
                <p className="fw-bold"> Convenience Fee  </p>
                <p className=" fw-bold">
                  <span>  {location.state.CONVIENCE_FEE}</span>
                </p>
              </div>
              <div className="d-flex justify-content-between">
                <p className="fw-bold"> Convenience Tax ({location?.state?.ConvinienceFeeTaxPercent}%) </p>
                <p className=" fw-bold">
                  <span>  {Math.round(location?.state?.ConvinienceFeeTaxAmount)}</span>
                </p>
              </div>
              <hr></hr>


              <div className="d-flex justify-content-between">
                <p className="fw-bold">Grand Total</p>
                <p className=" fw-bold">
                  <span>
                    {Number(Math.round(location.state.PAT_PRICE)) + Number(Math.round(location?.state?.PAT_SAMAGRIPRICE)) + Number(Math.round(location.state.CONVIENCE_FEE)) + Number(Math.round(location.state?.ConvinienceFeeTaxAmount))}
                  </span>
                </p>
              </div>
            </div>

            {location?.state?.RemainingAmount == 0 && (
              <button
                className="careox-btn mt-2 mx-2"
                onClick={() =>
                  navigate("/puja-at-temple-invoice", {
                    state: {
                      Address: location?.state?.PATB_ADDRESS,
                      products: orders,
                      totalAmount: location?.state?.TOTAL_PRICE,
                      PATB_ID: location?.state?.PATB_ID
                    },
                  })
                }
              >
                View Invoice
              </button>
            )}


            <button
              className="careox-btn mt-2"
              onClick={() => {

                if (location?.state?.PAYMENT_TYPE == 'COD') {
                  navigate("/receipt-details", {
                    state: { moduleName: "PoojaAtTemple", orderID: location?.state?.PATB_ID },
                  })
                } else {

                  navigate("/view-receipt", {
                    state: { moduleName: "PoojaAtTemple", orderID: location?.state?.PATB_ID },
                  })
                }
              }
              }
            >
              View Receipt
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default PujaAtTempleOrderDetails;
