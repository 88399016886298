import { useContext, useEffect, useState } from "react";
import "./PanditBooking.css";
import { useLocation, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import DatePicker from "react-date-picker";
import Select from "react-select";
import { ERROR_MESSAGES } from "../../Components/GlobalErrorMessage";
import { SelectLanguageOptions } from "../../middlewares/Utils";
import { GetPanditListDataAPI } from "../../ApiServices/PanditList/PanditList";
import CODModal from "./CODModal";
import axios from "axios";
import { BaseUrl } from "../../BaseUrl/BaseUrl";
import Mylogo from "../../assets/images/logo.png";
import "../Stepper Form/stepper.css";
import placeholderImg from "../../assets/images/new/Batuk2-removebg-preview.png";
import { ServiceDiscounts } from "../../Components/enum";
import { LanguageList } from "../../ApiServices/DailyPanditAPI/DailyPanditApi";
import { LoaderContext } from "../../AuthContext/AuthContext";
import { CityLookupList, StateLookupList } from "../../ApiServices/Other/StateLookupListApi";

const steps = [
  "Booking Details",
  // "Select Pandit",
  "Booking Preview",
  "Payment Status",
];

const PanditBooking = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [showCODModal, setShowCODModal] = useState(null);
  const [findPandit, setFindPandit] = useState(null);
  const [selectedPandit, setSelectedPandit] = useState(null);
  const [orderID, setOrderID] = useState();
  const [panditSelectionError, setPanditSelectionError] = useState(null);
  const [error, setError] = useState(null);
  const [searchKeyword, setSearchKeyword] = useState(null);
  const [showBookingReviewModal, setShowBookingReviewModal] = useState(null);
  const [panditDataList, setPanditDataList] = useState([]);
  const navigate = useNavigate();
  const { setLoader } = useContext(LoaderContext)
  const [languageOption, setLanguageOption] = useState([]);
  const location = useLocation();
  const [productBookingData, setProductBookingData] = useState({
    date: null,
    time: null,
    languageID: null,
    landmark: null,
    address: null,
    pincode: null,
    city: null,
    state: null,
    needSamagri: "yes",
  });

  const [stateLookupList, setStateLookupList] = useState([])
  const [cityLookupList, setCityLookupList] = useState([])

  const pujaDetails = location.state?.pujaDetailsData;
  const SGST = 9;
  const CGST = 9;

  const fromDate = dayjs();
  const userID = localStorage.getItem("userID");
  const product = location.state?.product;

  console.log("pujaDetails", pujaDetails);
  // console.log("productBookingData", productBookingData);

  const [razorPayData, setRazorPayData] = useState({
    PB_DATE: null,
    PB_TIME: null,
    PB_LANGAUGE: null,
    PB_SAMAGRI: null,
    PB_PRICE: null,
    PB_SAMAGRIPRICE: null,
    PANDIT_ID: null,
    USER_ID: null,
    REFERENCES_NO: "",
    ADDRESS: null,
    PUJA_ID: null,
    PAYMENT_TYPE: null,
    CONVINIENCE_FEE: null,
    TAX: null,
    EXTRA: null,
    TRANSACTION_ID: "",
    TRANSACTION_AMT: "",
  });

  // console.log("razorPayData", location.state);


  const totalPrice =
    (parseFloat(product?.PUJA_PRICE) - parseFloat(product?.DiscountAmount)) +
    (productBookingData.needSamagri === "yes"
      ? parseFloat(product?.SAMAGRI_PRICE)
      : 0) +

    parseFloat(product?.CONVENIENCE_FEE) + parseFloat(product?.ConvinienceFeeTaxAmount) + parseFloat(product?.SamagriPriceTaxAmount);

  const Tax = Math.round(
    (ServiceDiscounts.panditBookingDiscount / 100) * Number(totalPrice)
  );

  const grandTotal = parseFloat(totalPrice);

  const [modelRequestData, setModelRequestData] = useState({
    totalPrice: null,
    amountEntered: null,
  });

  const nextStep = () => {
    if (currentStep < steps.length - 1) {
      if (currentStep === 0) {
        setCurrentStep(1); // Move to "Booking Preview"
      } else {
        setCurrentStep(currentStep + 1);
      }
    }
  };

  const prevStep = () => {
    if (currentStep > 0) {
      if (currentStep === 2) {
        setCurrentStep(1); // Move back to "Booking Preview"
      } else {
        setCurrentStep(currentStep - 1);
      }
    }
  };
  useEffect(() => {
    GetPanditListData();
  }, [productBookingData?.languageID]);

  useEffect(() => {
    // debugger;
    setRazorPayData((prev) => ({
      ...prev,
      PB_DATE: productBookingData.date,
      PB_TIME: productBookingData.time,
      PB_LANGAUGE: SelectLanguageOptions.find(
        (item) => item.value === productBookingData.languageID
      )?.label,
      PB_SAMAGRI: productBookingData.needSamagri,
      PB_PRICE: pujaDetails?.pujaPrice,
      PB_SAMAGRIPRICE: pujaDetails?.pujaSamagriPrice,
      PANDIT_ID: selectedPandit,
      USER_ID: userID,
      REFERENCES_NO: "",
      ADDRESS: productBookingData?.address,
      PUJA_ID: pujaDetails?.pujaId,
      PAYMENT_TYPE: "ONLINE",
      CONVINIENCE_FEE: pujaDetails?.convenienceFee,
      TAX: null,
      EXTRA: null,
      TRANSACTION_ID: "",
      TRANSACTION_AMT: "",
    }));
  }, []);

  useEffect(() => {
    GetPanditListData();
  }, [currentStep]);

  useEffect(() => {
    GetLanguageListLookupListData();
    GetStateLookupListData();
  }, []);

  useEffect(() => {

    if (productBookingData?.state !== null && productBookingData?.state !== undefined && productBookingData?.state !== '') {
      GetCityLookupListData(productBookingData?.state)
    }

  }, [productBookingData?.state])

  const GetStateLookupListData = async () => {
    try {
      const response = await StateLookupList(1); // Ensure it's correctly imported

      if (response?.ResponseCode === "0") {

        const languageLookupList = response?.DATA || [];

        const formattedLangList = languageLookupList.map((Lang) => ({
          value: Lang.StateID,
          label: Lang.StateName,
        }));

        setStateLookupList(formattedLangList);
      } else {
        console.error(
          "Failed to fetch sim Type lookup list:",
          response?.data?.statusMessage || "Unknown error"
        );
      }
    } catch (error) {
      console.error("Error fetching sim Type lookup list:", error);
    }
  };
  const GetCityLookupListData = async (ID) => {

    try {
      const response = await CityLookupList(ID); // Ensure it's correctly imported

      if (response?.ResponseCode === "0") {

        const languageLookupList = response?.DATA || [];

        const formattedLangList = languageLookupList.map((Lang) => ({
          value: Lang.DistrictID,
          label: Lang.DistrictName,
        }));

        setCityLookupList(formattedLangList);
      } else {
        console.error(
          "Failed to fetch sim Type lookup list:",
          response?.data?.statusMessage || "Unknown error"
        );
      }
    } catch (error) {
      console.error("Error fetching sim Type lookup list:", error);
    }
  };

  const GetLanguageListLookupListData = async () => {
    setLoader(true)
    try {
      const response = await LanguageList(); // Ensure it's correctly imported

      if (response?.ResponseCode === "0") {
        setLoader(false)

        const languageLookupList = response?.DATA || [];
        const formattedLangList = languageLookupList.map((Lang) => ({
          value: Lang.LANG_ID,
          label: Lang.LANG_NAME,
        }));

        setLanguageOption(formattedLangList);
      } else {
        setLoader(false)
        console.error(
          "Failed to fetch sim Type lookup list:",
          response?.data?.statusMessage || "Unknown error"
        );
      }
    } catch (error) {
      setLoader(false)
      console.error("Error fetching sim Type lookup list:", error);
    }
  };

  //   const GetPanditListData = () => {};
  const GetPanditListData = async () => {
    console.log(productBookingData.languageID, "fds43867rg34");
    const langID = productBookingData.languageID; // Adjust according to your data structure
    setLoader(true)

    // debugger;
    try {
      const response = await GetPanditListDataAPI(
        userID,
        location.state.product.PUJA_ID,
        langID,
        {
          START: 1,
          END: 10,
          WORD: "None",
          LATITUDE: "20.0086678",
          LONGITUDE: "73.7639264",
        }
      );

      if (response) {
        setLoader(false)
        setPanditDataList(response.DATA);
      }
    } catch (error) {
      setLoader(false)
      console.log(error);
    }
  };
  const HandleProductBookingBtnClicked = () => {
    // debugger;
    let isValid = false;

    if (
      productBookingData.date === null ||
      productBookingData.date === undefined ||
      productBookingData.date === "" ||
      productBookingData.time === null ||
      productBookingData.time === undefined ||
      productBookingData.time === "" ||
      productBookingData.landmark === null ||
      productBookingData.landmark === undefined ||
      productBookingData.landmark === "" ||
      productBookingData.languageID === null ||
      productBookingData.languageID === undefined ||
      productBookingData.languageID === "" ||
      productBookingData.address === null ||
      productBookingData.address === undefined ||
      productBookingData.address === "" ||
      productBookingData.state === null ||
      productBookingData.state === undefined ||
      productBookingData.state === "" ||
      productBookingData.city === null ||
      productBookingData.city === undefined ||
      productBookingData.city === "" ||
      productBookingData.pincode === null ||
      productBookingData.pincode === undefined ||
      productBookingData.pincode === "" ||
      productBookingData.needSamagri === null ||
      productBookingData.needSamagri === undefined ||
      productBookingData.needSamagri === ""
    ) {
      isValid = true;
      setError(true);
    }

    // const api_Params = {
    //   date: productBookingData.date,
    //   time: productBookingData.time,
    //   languageID: productBookingData.languageID,
    //   landmark: productBookingData.landmark,
    //   address: productBookingData.address,
    //   pincode: productBookingData.pincode,
    //   city: productBookingData.city,
    //   state: productBookingData.state,
    //   needSamagri: productBookingData.needSamagri,
    // };

    if (!isValid) {
      //   navigate("/services/book-puja/puja-details/pandit-list", {
      //     state: { productBookingData: productBookingData, product: product },
      //   });
      // setCurrentStep(currentStep + 1)
      handleFindPandit()
      // nextStep();
    }
  };

  const handleRazorpayPayment = () => {
    // debugger;
    // let totalAmount = parseInt(modelRequestData.totalVal); // Assuming totalVal includes shipping
    let minPayableAmount = modelRequestData.amountEntered
      ? modelRequestData.amountEntered
      : Number(grandTotal);

    let orderIDReturned = null;

    // axios
    //   .post(`${BaseUrl}/BookPooja`, {
    //     PB_DATE: productBookingData.date,
    //     PB_TIME: productBookingData.time,
    //     PB_LANGAUGE: SelectLanguageOptions.find(
    //       (item) => item.value === productBookingData.languageID
    //     )?.label,
    //     PB_SAMAGRI: productBookingData.needSamagri,
    //     PB_PRICE: pujaDetails?.pujaPrice,
    //     PB_SAMAGRIPRICE: pujaDetails?.pujaSamagriPrice,
    //     PANDIT_ID: findPandit === true ? null : selectedPandit,
    //     USER_ID: userID,
    //     REFERENCES_NO: "",
    //     ADDRESS: productBookingData?.address,
    //     PUJA_ID: pujaDetails?.pujaId,
    //     PAYMENT_TYPE: modelRequestData.amountEntered ? "COD" : "ONLINE",
    //     CONVINIENCE_FEE: pujaDetails?.convenienceFee,
    //     TAX: null,
    //     EXTRA: null,
    //     TRANSACTION_ID: "",
    //     TRANSACTION_AMT: "",
    //     // New params
    //     PB_DiscountPercentage: pujaDetails.pujaDiscount,
    //     PB_DiscountAmount: pujaDetails.DiscountAmount,
    //     PB_Total: pujaDetails.pujaPrice,
    //     PB_TaxPercentage: ServiceDiscounts.panditBookingDiscount,
    //     PB_TaxAmount: Tax,
    //     PB_GrandTotal: grandTotal,
    //     PAYMENT_STATUS:
    //       minPayableAmount === totalPrice ? "Completed" : "Pending",
    //     TRANSACTION_STATUS: "Success",
    //   })
    //   .then((res) => {
    //     if (res) {
    //       console.log(res);
    //       setOrderID(res.data.ID);
    //       // localStorage.setItem("orderID", JSON.stringify(res.data.ID));
    //       orderIDReturned = res.data.ID;
    //     }
    //   });

    const options = {
      key: "rzp_test_vv1FCZvuDRF6lQ",
      key_secret: "P4JAUwn4VdE6xDLJ6p2Zy8RQ",
      amount: minPayableAmount * 100,
      currency: "INR",
      name: "My Om Namo- WowInfotech",
      description: "working",
      image: Mylogo,
      handler: function (response) {
        // console.log(response, "Payment Success");
        const paymentId = response.razorpay_payment_id;

        console.log(response, "sfsdf");

        if (response) {
          // axios
          //   .post(`${BaseUrl}/UpdatePaymentStatus?TYPE=Pandit`, {
          //     USER_ID: userID,
          //     ORDER_ID: orderIDReturned,
          //     // ORDER_ID: JSON.parse(localStorage.getItem("orderID")),
          //     TRANSACTION_ID: paymentId,
          //     PAYMENT_STATUS:
          //       minPayableAmount === totalPrice ? "Completed" : "Pending",
          //     TRANSACTION_STATUS: "Success",
          //     TRANSACTION_AMT: minPayableAmount,
          //     REMAING_AMOUNT:
          //       Number(totalPrice) +
          //       Number(pujaDetails?.pujaSamagriPrice) +
          //       Number(pujaDetails?.convenienceFee) -
          //       minPayableAmount,
          //     PAYMENT_DATE: dayjs().format("YYYY-MM-DD"),
          //   })
          //   .then((res) => {
          //     // debugger;
          //     if (res.data.ResponseMessage === "successfully.") {
          //       // localStorage.setItem("cartItemCount", "0");
          //       // window.dispatchEvent(new Event("cartUpdated"));
          //       setShowCODModal(false);
          //       nextStep();
          //     } else {
          //       alert("Order placement failed, please try again.");
          //     }
          //   });
          axios
            .post(`${BaseUrl}/BookPooja`, {
              PB_DATE: productBookingData.date,
              PB_TIME: productBookingData.time,
              PB_LANGAUGE: languageOption.find(
                (item) => item.value === productBookingData.languageID
              )?.value,
              PB_SAMAGRI: productBookingData.needSamagri,
              PB_PRICE: pujaDetails?.pujaPrice,
              PB_SAMAGRIPRICE: pujaDetails?.pujaSamagriPrice,
              PANDIT_ID: findPandit === true ? null : selectedPandit,
              USER_ID: userID,
              REFERENCES_NO: "",
              ADDRESS: productBookingData?.address,
              PUJA_ID: pujaDetails?.pujaId,
              PAYMENT_TYPE: modelRequestData.amountEntered ? "COD" : "ONLINE",
              CONVINIENCE_FEE: pujaDetails?.convenienceFee,
              TAX: null,
              EXTRA: null,
              TRANSACTION_ID: paymentId,
              TRANSACTION_AMT: minPayableAmount,
              // New params
              PB_DiscountPercentage: pujaDetails?.pujaDiscount,
              PB_DiscountAmount: pujaDetails?.DiscountAmount,
              PB_Total: pujaDetails?.pujaPrice,
              PB_TaxPercentage: ServiceDiscounts.panditBookingDiscount,
              PB_TaxAmount: Tax,
              PB_GrandTotal: grandTotal,
              PAYMENT_STATUS:
                minPayableAmount === totalPrice ? "Completed" : "Pending",
              TRANSACTION_STATUS: "Success",
              SamagriPriceTaxAmount: product?.SamagriPriceTaxAmount || 0,
              SamagriPriceTaxPercent: product?.SamagriPriceTaxPercent || 0,
              ConvinienceFeeTaxPercent: product?.ConvinienceFeeTaxPercent || 0,
              ConvinienceFeeTaxAmount: product?.ConvinienceFeeTaxAmount || 0
            })
            .then((res) => {
              if (res) {
                console.log(res);
                setOrderID(res.data.ID);
                setShowCODModal(false);
                nextStep();
                // localStorage.setItem("orderID", JSON.stringify(res.data.ID));
                orderIDReturned = res.data.ID;
              }
            });
        }

        // const updatedPayload = {
        //   ...razorPayData,
        //   TRANSACTION_ID: paymentId,
        //   TRANSACTION_AMT: minPayableAmount,
        // };

        // axios.post(`${BaseUrl}/BookPooja`, updatedPayload).then((res) => {
        //   debugger;
        //   if (res.data.ResponseMessage === "Order Success.") {
        //     localStorage.setItem("cartItemCount", "0");
        //     window.dispatchEvent(new Event("cartUpdated"));
        //     navigate("/thank-you");
        //   } else {
        //     alert("Order placement failed, please try again.");
        //   }
        // });
      },
      theme: {
        color: "#ff416c",
      },
    };

    const rzp = new window.Razorpay(options);
    rzp.open();
  };

  // const BookPujaAPI = async () => {
  //   debugger;
  //   axios
  //     .post(`${BaseUrl}/BookPooja`, {
  //       PB_DATE: productBookingData.date,
  //       PB_TIME: productBookingData.time,
  //       PB_LANGAUGE: SelectLanguageOptions.find(
  //         (item) => item.value === productBookingData.languageID
  //       )?.label,
  //       PB_SAMAGRI: productBookingData.needSamagri,
  //       PB_PRICE: pujaDetails?.pujaPrice,
  //       PB_SAMAGRIPRICE: pujaDetails?.pujaSamagriPrice,
  //       PANDIT_ID: selectedPandit,
  //       USER_ID: userID,
  //       REFERENCES_NO: "",
  //       ADDRESS: productBookingData?.address,
  //       PUJA_ID: pujaDetails?.pujaId,
  //       PAYMENT_TYPE: "ONLINE",
  //       CONVINIENCE_FEE: pujaDetails?.convenienceFee,
  //       TAX: null,
  //       EXTRA: null,
  //       TRANSACTION_ID: "",
  //       TRANSACTION_AMT: "",
  //     })
  //     .then((res) => {
  //       if (res) {
  //         console.log(res);
  //         setOrderID(res.data.ID);
  //         localStorage.setItem("orderID", JSON.stringify(res.data.ID));
  //         orderIDReturned = res.data.ID;
  //       }
  //     });
  // };

  const handlePanditSelectionNext = () => {

    if (
      selectedPandit !== null &&
      selectedPandit !== undefined &&
      selectedPandit !== ""
    ) {
      // console.log("Panditselection");
      nextStep();
    } else {
      setPanditSelectionError(true);
    }
  };

  const handleToDateChange = (newValue) => {
    if (dayjs(newValue).isValid()) {
      const newToDate = dayjs(newValue).format("YYYY-MM-DD");
      setProductBookingData((prev) => ({ ...prev, date: newToDate }));
    } else {
      setProductBookingData((prev) => ({ ...prev, date: null }));
    }
  };

  const handleLanguageChange = (selectedValue) => {
    setProductBookingData((prev) => ({
      ...prev,
      languageID: selectedValue.value,
    }));
  };

  const handlePanditCardClicked = (pandit) => {
    setSelectedPandit(pandit.PANDIT_ID);
    // console.log(pandit);
  };

  const handleCODClicked = () => {
    setModelRequestData((prev) => ({
      ...prev,
      totalPrice: grandTotal,
      tax: Tax,
      convenienceFee: product.CONVENIENCE_FEE,
      convenienceFeeFax: product?.ConvinienceFeeTaxAmount,
      SamagriPriceTaxAmount: product.ConvinienceFeeTaxPercent != 0 ? product?.SamagriPriceTaxAmount : 0
    }));
    setShowCODModal(true);
  };

  function formatToIndianCurrency(amount) {
    if (!amount) return "";

    const [integerPart, decimalPart] = amount.toString().split(".");

    // Format the integer part using Indian numbering system
    const formattedIntegerPart = integerPart.replace(
      // Match groups of 3 digits initially, then groups of 2 digits
      /(\d)(?=(\d\d)+\d$)/g,
      "$1,"
    );

    // Return the formatted value with the decimal part (if present)
    return decimalPart !== undefined
      ? `${formattedIntegerPart}.${decimalPart}`
      : formattedIntegerPart;
  }

  const renderStarRating = (rating) => {
    const stars = [];
    for (let i = 0; i < 5; i++) {
      if (i < Math.floor(rating)) {
        stars.push(<i key={i} className="fa fa-star filled-star"></i>); // Full Star
      } else if (i < rating) {
        stars.push(<i key={i} className="fa fa-star-half-alt half-star"></i>); // Half Star
      } else {
        stars.push(<i key={i} className="fa fa-star empty-star"></i>); // Empty Star
      }
    }
    return stars;
  };

  console.log(
    SelectLanguageOptions.filter(
      (item) => item.value === productBookingData.languageID
    )[0]?.label
  );

  const handleFindPandit = () => {
    setFindPandit(true);
    nextStep();
  };

  return (
    <div style={{ background: "#F1F6F7", paddingTop: "30px" }}>
      {/* Step Indicator */}
      <div className="stepper-wrapper">
        {steps.map((step, index) => (
          <div
            key={index}
            className={`stepper-item ${index < currentStep
              ? "completed"
              : index === currentStep
                ? "active"
                : ""
              }`}
          >
            <div className="step-counter">{index + 1}</div>
            <div className="step-name" style={{ fontSize: "small" }}>
              {step}
            </div>
          </div>
        ))}
      </div>

      {/* Step Content */}
      <div
        className="step-content"
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "10px 30px",
          background: "#F1F6F7",
        }}
      >
        {currentStep === 0 && (
          <>
            <div className="booking-details-container ">
              <div
                className="booking-details-left"
              // style={{
              //   padding: "10px 20px",
              //   display: "flex",
              //   alignItems: "center",
              //   justifyContent: "center",
              // }}
              >
                <h5>Booking Details</h5>
                <hr />
                <div className="row">
                  <div className="col-md-6 col-sm-12 mb-2 d-flex flex-column">
                    <label htmlFor="bookingDate" style={{ textAlign: "left" }}>
                      Date<span style={{ color: "red" }}>*</span>
                    </label>
                    <DatePicker
                      id="bookingDate"
                      value={productBookingData?.date || ""}
                      minDate={new Date(new Date().setDate(new Date().getDate() + 3))}
                      clearIcon={null}
                      onChange={handleToDateChange}
                      format="dd/MM/yyyy"
                      className="custom-date-picker"
                    />

                    {error && !productBookingData.date && (
                      <span className="error-msg">{ERROR_MESSAGES}</span>
                    )}
                  </div>
                  <div className="col-md-6 col-sm-12 mb-2 d-flex flex-column">
                    <label htmlFor="bookingTime" style={{ textAlign: "left" }}>
                      Time<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      id="bookingTime"
                      type="time"
                      className="form-control"
                      value={productBookingData.time || ""}
                      onClick={(e) =>
                        e.target.showPicker && e.target.showPicker()
                      }
                      onChange={(e) =>
                        setProductBookingData((prev) => ({
                          ...prev,
                          time: e.target.value,
                        }))
                      }
                    />
                    {error && !productBookingData.time && (
                      <span className="error-msg">{ERROR_MESSAGES}</span>
                    )}
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6 col-sm-12 mb-2 d-flex flex-column">
                    <label htmlFor="language" style={{ textAlign: "left" }}>
                      Select Language<span style={{ color: "red" }}>*</span>
                    </label>
                    <Select
                      options={languageOption}
                      onChange={handleLanguageChange}
                      value={languageOption.find(
                        (item) => item.value === productBookingData.languageID
                      )}
                      styles={{
                        control: (base) => ({
                          ...base,
                          height: "45px",
                        }),
                      }}
                    />
                    {error && !productBookingData.languageID && (
                      <span className="error-msg">{ERROR_MESSAGES}</span>
                    )}
                  </div>{" "}
                  {/* State */}
                  <div className="col-md-6 col-sm-12 mb-2 d-flex flex-column">
                    <label htmlFor="state" style={{ textAlign: "left" }}>
                      State<span style={{ color: "red" }}>*</span>
                    </label>
                    <Select
                      options={stateLookupList}
                      value={stateLookupList.find(
                        (item) => item.value === productBookingData.state
                      )}
                      onChange={(selectedOption) => {
                        setProductBookingData((prev) => ({ ...prev, state: selectedOption?.value }));
                      }}
                    />
                    {error && !productBookingData.state && (
                      <span className="error-msg">{ERROR_MESSAGES}</span>
                    )}
                  </div>
                </div>

                <div className="row">
                  {" "}
                  {/* City */}
                  <div className="col-md-6 col-sm-12 mb-2 d-flex flex-column">
                    <label htmlFor="City" style={{ textAlign: "left" }}>
                      City<span style={{ color: "red" }}>*</span>
                    </label>
                    <Select
                      options={cityLookupList}

                      value={cityLookupList.find(
                        (item) => item.value === productBookingData.city
                      )}

                      onChange={(selectedOption) => {
                        setProductBookingData((prev) => ({ ...prev, city: selectedOption?.value }));
                      }}
                    />
                    {error && !productBookingData.city && (
                      <span className="error-msg">{ERROR_MESSAGES}</span>
                    )}
                  </div>{" "}
                  {/* Landmark */}
                  <div className="col-md-6 col-sm-12 mb-2 d-flex flex-column">
                    <label htmlFor="landmark" style={{ textAlign: "left" }}>
                      Landmark<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      id="landmark"
                      type="text"
                      className="form-control"
                      value={productBookingData.landmark || ""}
                      onChange={(e) => {
                        let inputVal = e.target.value.trimStart();
                        setProductBookingData((prev) => ({
                          ...prev,
                          landmark: inputVal,
                        }));
                      }}
                      placeholder="Enter Landmark"
                    />
                    {error && !productBookingData.landmark && (
                      <span className="error-msg">{ERROR_MESSAGES}</span>
                    )}
                  </div>
                </div>

                <div className="row">
                  {/* Address */}
                  <div className="col-md-6 col-sm-12 mb-2 d-flex flex-column">
                    <label htmlFor="address" style={{ textAlign: "left" }}>
                      Address<span style={{ color: "red" }}>*</span>
                    </label>
                    <textarea
                      id="address"
                      className="form-control"
                      value={productBookingData.address || ""}
                      onChange={(e) => {
                        let inputVal = e.target.value.trimStart();
                        setProductBookingData((prev) => ({
                          ...prev,
                          address: inputVal,
                        }));
                      }}
                      placeholder="Enter Address"
                    />
                    {error && !productBookingData.address && (
                      <span className="error-msg">{ERROR_MESSAGES}</span>
                    )}
                  </div>
                  {/* Pincode */}
                  <div className="col-md-6 col-sm-12 mb-2 d-flex flex-column">
                    <label htmlFor="pincode" style={{ textAlign: "left" }}>
                      Pincode<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      id="pincode"
                      type="text"
                      maxLength={6}
                      className="form-control"
                      value={productBookingData.pincode || ""}
                      onChange={(e) => {
                        let inputVal = e.target.value.replace(/\D/g, "");
                        setProductBookingData((prev) => ({
                          ...prev,
                          pincode: inputVal,
                        }));
                      }}
                      placeholder="Enter Pincode"
                    />
                    {error && !productBookingData.pincode && (
                      <span className="error-msg">{ERROR_MESSAGES}</span>
                    )}
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6 col-sm-12 mb-2 d-flex flex-column">
                    <label
                      htmlFor="needSamagri"
                      style={{ marginRight: "5px " }}
                    >
                      Do you want Samagri for this Puja?
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <div>
                      <label className="me-3">
                        <input
                          type="radio"
                          name="samagri"
                          value="yes"
                          checked={productBookingData.needSamagri === "yes"}
                          onChange={(e) =>
                            setProductBookingData((prev) => ({
                              ...prev,
                              needSamagri: e.target.value,
                            }))
                          }
                          className="me-1"
                        />
                        Yes
                      </label>
                      <label>
                        <input
                          type="radio"
                          name="samagri"
                          value="no"
                          checked={productBookingData.needSamagri === "no"}
                          onChange={(e) =>
                            setProductBookingData((prev) => ({
                              ...prev,
                              needSamagri: e.target.value,
                            }))
                          }
                          className="me-1"
                        />
                        No
                      </label>
                    </div>
                    {error && !productBookingData.needSamagri && (
                      <span className="error-msg">{ERROR_MESSAGES}</span>
                    )}
                  </div>
                  <div className="col-md-6 col-sm-12 mb-2 d-flex flex-column">
                    {productBookingData.needSamagri === "yes" ? (
                      <span>
                        Your puja samagri amount is: ₹{" "}
                        {formatToIndianCurrency(pujaDetails?.pujaSamagriPrice)}
                      </span>
                    ) : (
                      <span>Samagri will be arranged by you.</span>
                    )}
                  </div>
                </div>
              </div>
              <div className=" booking-details-right">
                <div className="price-summary">
                  <h5>{product?.PUJA_NAME}</h5>
                  <hr />
                  <ul>
                    <li>
                      <span>Collected on behalf of pandit & <br></br> paid to pandit:</span>
                      <span>
                        <br></br>
                        Rs {formatToIndianCurrency(product?.PUJA_PRICE - product?.DiscountAmount) || 0}
                      </span>
                    </li>
                    <hr></hr>
                    {/* <li>
                      <span>Discount ({pujaDetails?.pujaDiscount}%):</span>
                      <span>
                        - Rs{" "}
                        {formatToIndianCurrency(product?.DiscountAmount) || 0}
                      </span>
                    </li> */}
                    {productBookingData.needSamagri === "yes" && (
                      <>
                        <li>
                          <span>Samagri Price:</span>
                          <span>
                            + Rs{" "}
                            {productBookingData?.needSamagri === "yes"
                              ? formatToIndianCurrency(product?.SAMAGRI_PRICE)
                              : 0}
                          </span>
                        </li>
                        <li>
                          <span>Samagri Tax ({product?.SamagriPriceTaxPercent}%):</span>
                          <span>
                            + Rs{" "}
                            {productBookingData?.needSamagri === "yes"
                              ? formatToIndianCurrency(Math.round(product?.SamagriPriceTaxAmount))
                              : 0}
                          </span>
                        </li>
                        <hr></hr>
                      </>
                    )}

                    <li>
                      <span>Convenience Fee:</span>
                      <span>
                        + Rs{" "}
                        {formatToIndianCurrency(product?.CONVENIENCE_FEE) || 0}
                      </span>
                    </li>
                    <li>
                      <span> Convenience  Tax ({product?.ConvinienceFeeTaxPercent}%):</span>
                      <span>Rs {Math.round(product?.ConvinienceFeeTaxAmount)}</span>
                    </li>
                    <hr />

                    <li>
                      <span>Grand Total:</span>
                      <span> Rs {formatToIndianCurrency(Math.round(grandTotal))}</span>
                    </li>
                  </ul>
                  {/* <p>Payment Type: COD</p>
                  <button className="place-order-btn">Place Order</button> */}
                </div>
              </div>
            </div>
          </>
        )}

        {/* {currentStep === 1 && (
          <div className="panditList" style={{ width: "100%" }}>
            <div className="container">
              <div className="row mb-4">
                <div className="col-md-12 px-lg-5">
                  <div className="searching" style={{ marginTop: "50px" }}>
                    <div className="search-name">
                      <div className="input-group mb-3">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search Pandit"
                          onChange={(e) => setSearchKeyword(e.target.value)}
                          aria-label="Search Pandit"
                          aria-describedby="basic-addon1"
                          style={{
                            border: "1px solid #ced4da",
                            padding: "10px",
                            fontSize: "16px",
                          }}
                        />
                        <span
                          className="input-group-text"
                          id="basic-addon1"
                          style={{
                            backgroundColor: "#f8f9fa",
                            border: "1px solid #ced4da",
                            cursor: "pointer",
                          }}
                        >
                          <a href="/pandit-list">
                            <i
                              className="fa fa-search search-btn"
                              style={{
                                fontSize: "18px",
                                color: "#6c757d",
                              }}
                            ></i>
                          </a>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="padit-list-products mt-3">
                    <div className="row">
                      {panditDataList?.map((pandit) => (
                        <div
                          className="col-lg-3 col-md-4 col-sm-6 col-12 mb-4"
                          key={pandit.PANDIT_ID}
                        >
                          <div
                            className={`card pandit-card ${selectedPandit === pandit.PANDIT_ID
                              ? "selected"
                              : ""
                              }`}
                            onClick={() => handlePanditCardClicked(pandit)}
                          >

                            <div
                              className="card-img-container"
                              style={{ padding: "10px", objectFit: "cover" }}
                            >
                              <img
                                src={pandit.PHOTO}
                                onError={(e) => {
                                  e.target.onerror = null;
                                  e.target.src = placeholderImg;
                                }}
                                alt={pandit.PANDIT_NAME}
                                className="card-img-top"
                              />
                            </div>


                            <div className="card-body text-center">
                              <h5 className="card-title">
                                {pandit.PANDIT_NAME}
                              </h5>
                              <p className="card-text">
                                <strong>Mobile:</strong> {pandit.MOBILE_NO}
                              </p>
                              <p className="card-text">
                                <strong>Experience:</strong> {pandit.EXPERIENCE}{" "}
                                years
                              </p>
                              <p className="card-text">
                                <strong>DOB:</strong>{" "}
                                {new Date(pandit.DOB).toLocaleDateString()}
                              </p>


                              <div className="rating">
                                {renderStarRating(
                                  parseFloat(pandit.PANDIT_RATING)
                                )}
                                <span className="rating-text">
                                  ({pandit.PANDIT_RATING})
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {" "}
                    {panditSelectionError &&
                      (selectedPandit === null ||
                        selectedPandit === undefined ||
                        selectedPandit === "") ? (
                      <span style={{ color: "red" }}>Please Select Pandit</span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <span className="text-center">

                  Didn't find a pandit you like?
                  <span
                    onClick={handleFindPandit}
                    style={{ color: "#a42642", cursor: "pointer" }}
                  >

                    Click here, and we will find one for you.
                  </span>
                </span>
              </div>
            </div>
          </div>
        )} */}

        {currentStep === 1 && (
          <div className="py-4" style={{ width: "100%" }}>
            <div className="p-3">
              <div className="row g-4 d-flex">
                <div
                  className="booking-preview col-md-5 text-center"
                  style={{
                    flex: "2",
                  }}
                >
                  <div className="booking-preview-details-puja">
                    <div className="booking-preview-details-puja-top">
                      <div className="booking-preview-details-puja-top-left">
                        <img
                          src={product?.PUJA_IMAGE}
                          onError={(e) => {
                            e.target.onerror = null; // Prevent infinite loop
                            e.target.src = placeholderImg; // Set fallback image
                          }}
                          alt=""
                        />
                      </div>
                      <div className="booking-preview-details-puja-top-right">
                        <span className="puja-name">{product?.PUJA_NAME}</span>
                        <span>
                          Puja Language:{" "}
                          {
                            languageOption.filter(
                              (item) =>
                                item.value === productBookingData.languageID
                            )[0]?.label
                          }
                        </span>
                        <span>Puja Date: {productBookingData.date}</span>
                        <span>
                          Puja Time:{" "}
                          {new Date(
                            `1970-01-01T${productBookingData.time}`
                          ).toLocaleTimeString("en-US", {
                            hour: "2-digit",
                            minute: "2-digit",
                            hour12: true,
                          })}
                        </span>

                        {/* <span>
                          Pandit Name:{" "}
                          {
                            panditDataList.filter(
                              (item) => item.PANDIT_ID === selectedPandit
                            )[0]?.PANDIT_NAME
                          }
                        </span> */}
                      </div>

                    </div>
                    <p className="fw-bold">Note : You will receive pandit details within 24 hours</p>
                  </div>
                </div>
                <div className=" booking-details-right">
                  <div className="price-summary">
                    <h5>{product?.PUJA_NAME}</h5>
                    <hr />
                    <ul>
                      <li>
                        <span>Collected on behalf of pandit & <br></br> paid to pandit:</span>
                        <span>
                          <br></br>
                          Rs {formatToIndianCurrency(product?.PUJA_PRICE - product?.DiscountAmount) || 0}
                        </span>
                      </li>
                      <hr></hr>
                      {/* <li>
                      <span>Discount ({pujaDetails?.pujaDiscount}%):</span>
                      <span>
                        - Rs{" "}
                        {formatToIndianCurrency(product?.DiscountAmount) || 0}
                      </span>
                    </li> */}
                      {productBookingData.needSamagri === "yes" && (
                        <>
                          <li>
                            <span>Samagri Price:</span>
                            <span>
                              + Rs{" "}
                              {productBookingData?.needSamagri === "yes"
                                ? formatToIndianCurrency(product?.SAMAGRI_PRICE)
                                : 0}
                            </span>
                          </li>
                          <li>
                            <span>Samagri Tax ({product?.SamagriPriceTaxPercent}%):</span>
                            <span>
                              + Rs{" "}
                              {productBookingData?.needSamagri === "yes"
                                ? formatToIndianCurrency(product?.SamagriPriceTaxAmount)
                                : 0}
                            </span>
                          </li>
                          <hr></hr>
                        </>
                      )}

                      <li>
                        <span>Convenience Fee:</span>
                        <span>
                          + Rs{" "}
                          {formatToIndianCurrency(product?.CONVENIENCE_FEE) || 0}
                        </span>
                      </li>
                      <li>
                        <span> Convenience  Tax ({product?.ConvinienceFeeTaxPercent}%):</span>
                        <span>Rs {product?.ConvinienceFeeTaxAmount}</span>
                      </li>
                      <hr />

                      <li>
                        <span>Grand Total:</span>
                        <span> Rs {formatToIndianCurrency(Math.round(grandTotal))}</span>

                      </li>
                    </ul>
                    <div className="mt-3 d-flex justify-content-end gap-2">
                      <button
                        style={{ backgroundColor: "#a53444", color: "white" }}
                        className="btn px-4"
                        onClick={handleCODClicked}
                      >
                        Cash On Delivery
                      </button>
                      <button
                        style={{ backgroundColor: "#a53444", color: "white" }}
                        className="btn px-4"
                        onClick={handleRazorpayPayment}
                      >
                        Pay
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {currentStep === 2 && (
          <div>
            <div className="carousel-inner" role="listbox">
              <div className="item carousel-item-four active">
                <div className="">
                  <div className="row">
                    <div className="carousel slide carousel-slider">
                      <div className="carousel-position-four">
                        <div className="carousel-inner">
                          <div
                            className="banner-breadcrumb"
                            style={{
                              // backgroundImage: `url(${StupaImg})`,
                              width: "100%",
                              height: "100px",
                            }}

                          // style={{ backgroundImage: `url(${AboutImg})` }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row margin-bottom-40">
              <div className="col-md-12 col-sm-12">
                <div className="thankyou-box text-center bg-white p-5">
                  <h2
                    style={{
                      fontFamily: "Ubuntu",
                      textTransform: "capitalize",
                      color: "#504e4e",
                      fontSize: "32px",
                      marginBottom: "20px",
                    }}
                  >
                    Thank You for Ordering !
                  </h2>
                  <h4
                    className="text-success"
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "18px",
                      fontWeight: "450",
                    }}
                  >
                    Your Order Is Successful
                  </h4>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* Navigation Buttons */}
      {currentStep === 0 ? (
        <div
          className="navigation-buttons"
          style={{ marginRight: "50px", paddingBottom: "30px" }}
        >
          {/* <button
            onClick={prevStep}
            disabled={currentStep === 0}
            className="btn btn-secondary"
            style={{ backgroundColor: "#d7d7d7", color: "black" }}
          >
            Previous
          </button> */}
          <button
            onClick={
              currentStep === 0
                ? HandleProductBookingBtnClicked
                : currentStep === 1
                  ? handlePanditSelectionNext
                  : ""
            }
            disabled={currentStep === steps.length - 1}
            className="next-btn"
          >
            Next
          </button>
        </div>
      ) : currentStep === 1 ? (
        <div
          className="navigation-buttons"
          style={{ marginRight: "50px", paddingBottom: "30px" }}
        >
          <button
            className="btn btn-secondary"
            style={{ backgroundColor: "#d7d7d7", color: "black" }}
            onClick={prevStep}
          >
            Previous
          </button>
        </div>
      ) : currentStep === 2 ? (
        <div
          className="navigation-buttons"
          style={{ marginRight: "50px", paddingBottom: "30px" }}
        >
          <button className=" prev-btn" onClick={() => navigate("/")}>
            Home
          </button>
          <button
            className=" prev-btn"
            onClick={() => navigate("/my-order-pandit-Booking")}
          >
            My Orders
          </button>
        </div>
      ) : (
        <div
          className="navigation-buttons"
          style={{ marginRight: "50px", paddingBottom: "30px" }}
        >
          <button
            onClick={prevStep}
            disabled={currentStep === 0}
            className="btn btn-secondary"
            style={{ backgroundColor: "#d7d7d7", color: "black" }}
          >
            Previous
          </button>
          <button
            onClick={
              currentStep === 0
                ? HandleProductBookingBtnClicked
                : currentStep === 1
                  ? handlePanditSelectionNext
                  : ""
            }
            disabled={currentStep === steps.length - 1}
            className="next-btn"
          >
            Next
          </button>
        </div>
      )}
      <CODModal
        show={showCODModal}
        onHide={() => setShowCODModal(false)}
        onConfirm={handleRazorpayPayment}
        modelRequestData={modelRequestData}
        setModelRequestData={setModelRequestData}
      />
    </div>
  );
};

export default PanditBooking;
