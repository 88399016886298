import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AuthContextProvider } from "../../AuthContext/AuthContext";
import ConfirmationModal from "../ConfirmationModal";
import shoppingCart from "../../assets/images/shopping-cart.png";
import LoginPopUp from "../LoginPopup";
const Navbar = () => {
  const { isAuthenticated } = useContext(AuthContextProvider);
  const [confirmationModal, setConfirmationModal] = useState(null);
  const [onGetOTP, setOnGetOTP] = useState(null);
  const [showLoginPopUp, setShowLoginPopUp] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [itemCount, setItemCount] = useState(0);
  const [login, setLogin] = useState(localStorage.getItem("userID"));
  const name = localStorage.getItem("name");
  const isActive = (path) => location.pathname === path;
  const handleLogOut = () => {
    localStorage.clear();
    // navigate("/");
    window.location.reload();
  };

  useEffect(() => {
    setShowLoginPopUp(false);
  }, [onGetOTP]);

  useEffect(() => {
    const updateItemCount = () => {
      const count = localStorage.getItem("cartItemCount");
      setItemCount(count ? parseInt(count) : 0);
    };

    // Set the initial count
    updateItemCount();

    // Listen for storage changes from other tabs
    window.addEventListener("storage", updateItemCount);

    // Listen for custom cart updated event within the same tab
    window.addEventListener("cartUpdated", updateItemCount);

    // Cleanup the event listeners on unmount
    return () => {
      window.removeEventListener("storage", updateItemCount);
      window.removeEventListener("cartUpdated", updateItemCount);
    };
  }, []);

  return (
    <>
      <div className="topbar-two">
        <div className="container">
          <div
            className="topbar-two__inner"
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <ul className="topbar-two__info">
              {name}
              <li
                style={{ color: "white" }}
                onMouseEnter={(e) => (e.target.style.color = "white")}
                onMouseLeave={(e) => (e.target.style.color = "white")}
              >
                <span className="topbar-two__info__icon">
                  <i className="icofont-envelope"></i>
                </span>
                <a
                  style={{ color: "white" }}
                  onMouseEnter={(e) => (e.target.style.color = "white")}
                  onMouseLeave={(e) => (e.target.style.color = "white")}
                  href="mailto:demo@example.com"
                >
                  info@myomnamo.com
                </a>
              </li>
              {/* <li
                style={{ color: "white" }}
                onMouseEnter={(e) => (e.target.style.color = "white")}
                onMouseLeave={(e) => (e.target.style.color = "white")}
              >
                <span className="topbar-two__info__icon">
                  <i className="icofont-location-pin"></i>
                </span>
                <Link
                  style={{ color: "white" }}
                  onMouseEnter={(e) => (e.target.style.color = "white")}
                  onMouseLeave={(e) => (e.target.style.color = "white")}
                >
                  Wagle Estate, Thane west, 400604
                </Link>
              </li> */}
            </ul>
            {isAuthenticated ? (
              <ul className="list-unstyled topbar-two__nav">
                <li
                  className="nav-list"
                  style={{ color: "white" }}
                  onMouseEnter={(e) => (e.target.style.color = "white")}
                  onMouseLeave={(e) => (e.target.style.color = "white")}
                >
                  <Link
                    style={{ color: "white" }}
                    onMouseEnter={(e) => (e.target.style.color = "white")}
                    onMouseLeave={(e) => (e.target.style.color = "white")}
                    to="/my-order-list"
                  >
                    <i
                      class="fa-solid fa-bag-shopping"
                      style={{ marginRight: "2px" }}
                    ></i>{" "}
                    My Order
                  </Link>
                </li>

                <li
                  className="nav-list"
                  style={{ color: "white" }}
                  onMouseEnter={(e) => (e.target.style.color = "white")}
                  onMouseLeave={(e) => (e.target.style.color = "white")}
                >
                  <i
                    class="fa fa-user"
                    aria-hidden="true"
                    style={{ marginRight: "5px" }}
                  ></i>
                  <Link
                    style={{ color: "white" }}
                    onMouseEnter={(e) => (e.target.style.color = "white")}
                    onMouseLeave={(e) => (e.target.style.color = "white")}
                    to="/my-profile"
                  >
                    Profile
                  </Link>
                </li>
                <li
                  className="nav-list"
                  style={{ color: "white" }}
                  onMouseEnter={(e) => (e.target.style.color = "white")}
                  onMouseLeave={(e) => (e.target.style.color = "white")}
                >
                  <i class="fa-solid fa-right-from-bracket"></i>{" "}
                  <Link
                    style={{ color: "white" }}
                    onMouseEnter={(e) => (e.target.style.color = "white")}
                    onMouseLeave={(e) => (e.target.style.color = "white")}
                    onClick={() => setConfirmationModal(true)}
                  >
                    Logout
                  </Link>
                </li>
              </ul>
            ) : (
              <ul className="list-unstyled topbar-two__nav">
                {/* <li
                  className="nav-list"
                  style={{ color: "white" }}
                  onMouseEnter={(e) => (e.target.style.color = "white")}
                  onMouseLeave={(e) => (e.target.style.color = "white")}
                >
                  <Link
                    style={{ color: "white" }}
                    onMouseEnter={(e) => (e.target.style.color = "white")}
                    onMouseLeave={(e) => (e.target.style.color = "white")}
                    to="/login"
                  >
                    Login / Sign Up
                  </Link>
                </li> */}
                {/* <li
                  style={{ color: "white" }}
                  onMouseEnter={(e) => (e.target.style.color = "white")}
                  onMouseLeave={(e) => (e.target.style.color = "white")}
                >
                  <Link
                    style={{ color: "white" }}
                    onMouseEnter={(e) => (e.target.style.color = "white")}
                    onMouseLeave={(e) => (e.target.style.color = "white")}
                    to="/register"
                  >
                    Register
                  </Link>
                </li> */}
              </ul>
            )}
          </div>
        </div>
      </div>

      <header
        className="main-header main-header--four sticky-header sticky-header--normal"
        style={{
          position: "sticky",
          top: 0,
          boxShadow: "0px 10px 5px -5px rgba(0,0,0,0.2)",
          WebkitBoxShadow: "0px 10px 5px -5px rgba(0,0,0,0.2)",
          MozBoxShadow: "0px 10px 5px -5px rgba(0,0,0,0.2)",
        }}
      >
        <div className="container-fluid">
          <div className="main-header__inner">
            <div className="main-header__logo">
              <Link to="/">
                <img src="/assets/images/new/logo.png" width="223" alt="Logo" />
              </Link>
            </div>

            <nav className="main-header__nav main-menu">
              <ul className="main-menu__list">
                <li>
                  <Link
                    to="/"
                    style={{ color: isActive("/") ? "#d09d18" : "black" }}
                  >
                    Home
                  </Link>
                </li>
                {/* <li>
                  <Link
                    to="/about-us"
                    style={{
                      color: isActive("/about-us") ? "#d09d18" : "black",
                    }}
                  >
                    About Us
                  </Link>
                </li> */}
                <li>
                  <Link
                    to="#"
                    className="how-we-help-hover"
                    style={{
                      color: isActive("#") ? "#d09d18" : "black",
                      gap: "5px",
                    }}
                  >
                    How we help you
                    <i
                      class="fas fa-chevron-down"
                      style={{ fontSize: "12px" }}
                    ></i>
                  </Link>
                  <ul>
                    <li>
                      {/* <Link to="/services/book-puja">Pandit Booking</Link> */}
                      <Link to="/services/puja-booking">Puja Booking</Link>
                    </li>
                    <li>
                      {/* <Link to="/services/daily-booking">
                        Daily Pandit Booking
                      </Link> */}
                      <Link
                        to="/services/daily-puja-booking"
                        // state={{ key: 1 }}
                      >
                        Daily Puja Booking
                      </Link>
                    </li>
                    <li>
                      {/* <Link to="/my-store">E-Store</Link> */}
                      <Link to="/services/e-store">E-Store</Link>
                    </li>
                    <li>
                      {/* <Link to="/darshan-booking">Darshan Booking</Link> */}
                      <Link to="/services/darshan-booking">
                        Darshan Booking
                      </Link>
                    </li>
                    <li>
                      {/* <Link to="/pooja-at-temple">Puja At Temple</Link> */}
                      <Link to="/services/temple-puja">Puja At Temple</Link>
                    </li>
                    <li>
                      {/* <Link to="/horoscope">Horoscope</Link> */}
                      <Link to="/services/horoscope">Horoscope</Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link
                    to="/spiritual-gyan"
                    style={{
                      color: isActive("/spiritual-gyan") ? "#d09d18" : "black",
                    }}
                  >
                    Spiritual Gyan
                  </Link>
                </li>
                <li>
                  <Link
                    to="/contact-us"
                    style={{
                      color: isActive("/contact-us") ? "#d09d18" : "black",
                    }}
                  >
                    Contact Us
                  </Link>
                </li>
              </ul>
            </nav>

            <div className="main-header__right">
              <div className="mobile-nav__btn mobile-nav__toggler">
                <span></span>
                <span></span>
                <span></span>
              </div>

              <Link
                to="/view-cart"
                className="main-header__cart position-relative"
              >
                {isAuthenticated ? (
                  <div
                    style={{ position: "relative", display: "inline-block" }}
                  >
                    <img src={shoppingCart} width={30} height={30} alt="Cart" />
                    {itemCount > 0 && (
                      <span
                        style={{
                          position: "absolute",
                          top: "-5px",
                          right: "-5px",
                          background: "#8f3246",
                          borderRadius: "50%", // Ensure full round shape
                          width: "20px", // Fixed width
                          height: "20px", // Fixed height
                          display: "flex", // Center the text
                          alignItems: "center",
                          justifyContent: "center",
                          fontSize: "12px",
                          fontWeight: "bold",
                          color: "white",
                        }}
                        className="cart-badge"
                      >
                        {itemCount}
                      </span>
                    )}
                  </div>
                ) : (
                  <Link onClick={() => setShowLoginPopUp(true)}>
                    <span className="login-signup-nav">Login/signup</span>
                  </Link>
                  // <Link onClick={() => navigate("/login")}>
                  //   <span className="login-signup-nav">Login/signup</span>
                  // </Link>
                )}
              </Link>

              {/* <Link target="__blank" className="careox-btn"> */}
              <a
                className="download-app-btn"
                href="https://play.google.com/store/apps/details?id=spiritualapp.myomnamo"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span>
                  Download App &nbsp;
                  <i className="icofont-download"></i>
                </span>
              </a>

              {/* </Link> */}
            </div>
          </div>
        </div>
      </header>
      <ConfirmationModal
        show={confirmationModal}
        onHide={() => setConfirmationModal(false)}
        onConfirm={handleLogOut}
      />
      <LoginPopUp
        show={showLoginPopUp}
        onHide={() => setShowLoginPopUp(false)}
        setOnGetOTP={setOnGetOTP}
      />
    </>
  );
};

export default Navbar;
