import React, { useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import placeholderImg from "../../assets/images/new/Batuk2-removebg-preview.png";
import { AuthContextProvider } from "../../AuthContext/AuthContext";
import LoginPopUp from "../../Components/LoginPopup";

const DailyBookingDetails = () => {
  const location = useLocation();
  const [loginPopUp, setLoginPopUp] = useState(null);
  const navigate = useNavigate();
  const { isAuthenticated } = useContext(AuthContextProvider);

  const puja = location.state.puja;

  const handleBookPuja = () => {
    if (isAuthenticated) {
      navigate("/services/daily-puja/add-service", { state: { puja: puja } });
    } else {
      setLoginPopUp(true);
    }
  };

  console.log(location.state.puja);
  const createMarkup = (html) => {
    return { __html: html };
  };
  return (
    <div style={{ padding: "20px" }}>
      <div
        className="pujaDetails"
        style={{
          display: "flex",
          gap: "20px",
          maxWidth: "1200px",
          margin: "auto",
          padding: "20px",
          height: "calc(100vh - 80px)", // Adjust height minus header
          overflow: "hidden",
        }}
      >
        <div
          className="left-daily-booking"
          style={{
            position: "sticky",
            top: "80px",
            width: "400px",
            margin: "auto",
          }}
        >
          <img
            src={puja.DP_IMAGE}
            alt="Puja-Img"
            onError={(e) => {
              e.target.onerror = null; // Prevent infinite loop
              e.target.src = placeholderImg; // Set fallback image
            }}
            className="img-fluid rounded" // Bootstrap classes for responsiveness and rounded corners
            style={{ maxWidth: "80%", height: "auto" }}
          />
        </div>
        <div
          className="right"
          style={{
            flex: 1,
            overflowY: "scroll",
            height: "100%",
            paddingRight: "10px",
            scrollbarWidth: "none", // Hides scrollbar in Firefox
            msOverflowStyle: "none", // Hides scrollbar in IE/Edge
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center", // Ensures vertical alignment
              margin: "20px 0",
            }}
          >
            <h2>{puja.DP_NAME}</h2>
            <div className="button-container">
              <button
                className="careox-btn"
                style={{
                  backgroundColor: "#A52444",
                  color: "white",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                onClick={handleBookPuja}
              >
                Book Puja
              </button>
            </div>
          </div>

          <h4 style={{ color: "#a52444" }}>
            ₹ {puja.DP_PRICE - puja.DiscountAmount}/ Day
            <span
              style={{
                textDecoration: "line-through",
                color: "#777",
                marginLeft: "10px",
              }}
            >
              {puja.DP_PRICE}
            </span>
            <span style={{ color: "black", marginLeft: "10px" }}>
              ({Math.round((puja.DiscountAmount / puja.DP_PRICE) * 100)}% OFF)
            </span>
          </h4>

          {/* <h5>
            <span>
              <s>Rs {puja.DP_PRICE}</s>
            </span>
            <span> ({puja.DP_DISC}% Off)</span>
          </h5> */}

          <div className="mb-3 ">
            {/* <h5>Puja Details:</h5> */}
            <p
              dangerouslySetInnerHTML={{
                __html: puja.DP_DESC,
              }}
            />
          </div>

          <div className="mb-3">
            <h5>Puja Samagri:</h5>
            {puja.DP_SAMAGRI ? (
              <div dangerouslySetInnerHTML={createMarkup(puja.DP_SAMAGRI)} />
            ) : (
              <p>No samagri details available.</p>
            )}
          </div>

          {/* <h5>Samagri Price: Rs {puja.DP_SAMAGRI_PRICE}</h5> */}

          {/* <div className="button-container">
              <button
                className="btn btn-primary"
                style={{ backgroundColor: "#A52444", color: "white" }}
                onClick={handleOpenProductBookingModal}
              >
                Proceed to Book
              </button>
            </div> */}
        </div>
      </div>
      {loginPopUp && (
        <LoginPopUp show={loginPopUp} onHide={() => setLoginPopUp(false)} />
      )}
    </div>
  );
};

export default DailyBookingDetails;
