import { BaseUrl } from "../../BaseUrl/BaseUrl";
import { getApiWithoutAuthorization, postApiWithoutAuthorization } from "../ApiMethod/ApiMethod";

export const GetUserModel = async (id) => {
    const url = `${BaseUrl}/GetUserModel?USER_ID=${id}`;
    const res = await getApiWithoutAuthorization(url);
    return res;
};
export const UserRegNewWithMob = async (params, id) => {
    const url = `${BaseUrl}/UserRegNewWithMob?USER_ID=${id}`;
    const res = await postApiWithoutAuthorization(url, params);
    return res;
};


