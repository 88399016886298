import React from "react";
import supportImg1 from "../../assets/images/new/2.png";
import supportImg2 from "../../assets/images/shapes/arrow-1.png";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link } from "react-router-dom";
import batuk from "../../assets/images/new/fav-removebg-preview.png";

const Support = () => {
  const handleClick = () => {
    window.scrollTo({ top: 0, behavior: "instant" });
  };
  return (
    <section
      className="cta-two position-relative text-white"
      style={{
        // backgroundImage: `url("${supportImg1}")`,
        // backgroundSize: "cover",
        backgroundPosition: "center",
        width: "100%",
        minHeight: "100px",
        display: "flex",
        alignItems: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className="container">
        <div
          className="row align-items-center"
          style={{
            width: "100%",
            display: "felx",
            justifyContent: "space-between",
            margin: "0px 20px",
          }}
        >
          <div className="col-md-7">
            <div className="sec-title text-left">
              <h6 className="sec-title__tagline bw-split-in-right">
                <img className=" tagline-img" src={batuk} alt="" />
                Become a Partner With Us
                {/* <span className="sec-title__tagline__border"></span>Get in Touch */}
              </h6>
              <h3
                className="sec-title__title bw-split-in-left"
                style={{ marginLeft: "7px" }}
              >
                If you are a Pandit, sign up with us today. 
              </h3>
            </div>
          </div>
          <div className="col-md-5 text-md-end text-center">
            <div className="cta-two__button d-inline-block">
              <img
                src={supportImg2}
                alt="MyOm Namo"
                className="img-fluid me-2"
                style={{ marginRight: "10px" }}
              />
              <Link
               onClick={handleClick}
                to="/become-pandit"
                className="btn btn-light text-dark px-4 py-2 rounded-pill fw-bold"
              >
                <span>Become A Partner</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Support;
