import { Modal } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import DatePicker from "react-date-picker";
import dayjs from "dayjs";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css"; // Required for the calendar popup styling
import Select from "react-select";
import { SelectLanguageOptions } from "../../middlewares/Utils";
import { ERROR_MESSAGES } from "../GlobalErrorMessage";
import { useNavigate } from "react-router-dom";
import { LanguageList } from "../../ApiServices/DailyPanditAPI/DailyPanditApi";

const ProductBookingModal = ({ show, onHide, product }) => {
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [languageOption, setLanguageOption] = useState([]);
  const [productBookingData, setProductBookingData] = useState({
    date: null,
    time: null,
    languageID: null,
    landmark: null,
    address: null,
    pincode: null,
    city: null,
    state: null,
    needSamagri: null,
  });
  const fromDate = dayjs();

  const setInitialData = () => {
    setError(false);
    setProductBookingData((prev) => ({
      ...prev,
      date: null,
      time: null,
      languageID: null,
      landmark: null,
      address: null,
      pincode: null,
      city: null,
      state: null,
      needSamagri: null,
    }));
  };

  const HandleProductBookingBtnClicked = () => {
    let isValid = false;

    if (
      productBookingData.date === null ||
      productBookingData.date === undefined ||
      productBookingData.date === "" ||
      productBookingData.time === null ||
      productBookingData.time === undefined ||
      productBookingData.time === "" ||
      productBookingData.landmark === null ||
      productBookingData.landmark === undefined ||
      productBookingData.landmark === "" ||
      productBookingData.languageID === null ||
      productBookingData.languageID === undefined ||
      productBookingData.languageID === "" ||
      productBookingData.address === null ||
      productBookingData.address === undefined ||
      productBookingData.address === "" ||
      productBookingData.state === null ||
      productBookingData.state === undefined ||
      productBookingData.state === "" ||
      productBookingData.city === null ||
      productBookingData.city === undefined ||
      productBookingData.city === "" ||
      productBookingData.pincode === null ||
      productBookingData.pincode === undefined ||
      productBookingData.pincode === "" ||
      productBookingData.needSamagri === null ||
      productBookingData.needSamagri === undefined ||
      productBookingData.needSamagri === ""
    ) {
      isValid = true;
      setError(true);
    }

    // const api_Params = {
    //   date: productBookingData.date,
    //   time: productBookingData.time,
    //   languageID: productBookingData.languageID,
    //   landmark: productBookingData.landmark,
    //   address: productBookingData.address,
    //   pincode: productBookingData.pincode,
    //   city: productBookingData.city,
    //   state: productBookingData.state,
    //   needSamagri: productBookingData.needSamagri,
    // };

    if (!isValid) {
      navigate("/services/book-puja/puja-details/pandit-list", {
        state: { productBookingData: productBookingData, product: product },
      });
    }
  };

  useEffect(() => {
    GetLanguageListLookupListData();
  });

  const GetLanguageListLookupListData = async () => {
    try {
      const response = await LanguageList(); // Ensure this function is imported correctly

      if (response?.data?.statusCode === 200) {
        const languageLookupList = response?.data?.responseData?.data || [];

        const formattedSimTypeList = languageLookupList.map((Lang) => ({
          value: Lang.LANG_ID,
          label: Lang.LANG_NAME,
        }));

        setLanguageOption(formattedSimTypeList);
      } else {
        console.error(
          "Failed to fetch sim Type lookup list:",
          response?.data?.statusMessage || "Unknown error"
        );
      }
    } catch (error) {
      console.error("Error fetching sim Type lookup list:", error);
    }
  };

  const handleToDateChange = (newValue) => {
    if (dayjs(newValue).isValid()) {
      const newToDate = dayjs(newValue).format("YYYY-MM-DD");
      setProductBookingData((prev) => ({ ...prev, date: newToDate }));
    } else {
      setProductBookingData((prev) => ({ ...prev, date: null }));
    }
  };

  const handleLanguageChange = (selectedValue) => {
    setProductBookingData((prev) => ({
      ...prev,
      languageID: selectedValue.value,
    }));
  };
  return (
    <Modal
      show={show}
      //   onHide={onHide}
      onHide={() => {
        onHide();
        setInitialData();
      }}
      backdrop="static"
      keyboard={false}
      centered
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title style={{ fontSize: "25px", fontWeight: "bold" }}>
          Book Product
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-md-6 col-sm-12 mb-2 d-flex flex-column">
            <label htmlFor="bookingDate">
              Date
              <span style={{ color: "red" }}>*</span>
            </label>

            <DatePicker
              id="bookingDate"
              value={productBookingData?.date || ""}
              minDate={fromDate.toDate()}
              clearIcon={null}
              onChange={handleToDateChange}
              format="dd/MM/yyyy"
            />
            {error &&
            (productBookingData.date === null ||
              productBookingData.date === undefined ||
              productBookingData.date === "") ? (
              <span style={{ color: "red" }}>{ERROR_MESSAGES}</span>
            ) : (
              ""
            )}
          </div>
          <div className="col-md-6 col-sm-12 mb-2 d-flex flex-column">
            <label htmlFor="bookingTime">
              Time
              <span style={{ color: "red" }}>*</span>
            </label>
            <input
              id="bookingTime"
              type="time"
              className="form-control"
              value={productBookingData.time || ""}
              onChange={(e) =>
                setProductBookingData((prev) => ({
                  ...prev,
                  time: e.target.value,
                }))
              }
            />
            {error &&
            (productBookingData.time === null ||
              productBookingData.time === undefined ||
              productBookingData.time === "") ? (
              <span style={{ color: "red" }}>{ERROR_MESSAGES}</span>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-sm-12 mb-2 d-flex flex-column">
            <label htmlFor="bookingDate" style={{ marginLeft: "3px" }}>
              Select Language
              <span style={{ color: "red" }}>*</span>
            </label>
            <Select
              options={languageOption}
              onChange={handleLanguageChange}
              value={languageOption.find(
                (item) => item.value === productBookingData.languageID
              )}
            />
            {error &&
            (productBookingData.languageID === null ||
              productBookingData.languageID === undefined ||
              productBookingData.languageID === "") ? (
              <span style={{ color: "red" }}>{ERROR_MESSAGES}</span>
            ) : (
              ""
            )}
          </div>
          <div className="col-md-6 col-sm-12 mb-2 d-flex flex-column">
            <label htmlFor="landmark">
              Landmark
              <span style={{ color: "red" }}>*</span>
            </label>
            <input
              id="landmark"
              type="text"
              className="form-control"
              value={productBookingData.landmark || ""}
              onChange={(e) => {
                let inputVal = e.target.value;
                if (inputVal.startsWith(" ")) {
                  inputVal = inputVal.trimStart();
                }
                setProductBookingData((prev) => ({
                  ...prev,
                  landmark: inputVal,
                }));
              }}
              placeholder="Enter Landmark"
              //   style={{ padding: "0px" }}
            />
            {error &&
            (productBookingData.landmark === null ||
              productBookingData.landmark === undefined ||
              productBookingData.landmark === "") ? (
              <span style={{ color: "red" }}>{ERROR_MESSAGES}</span>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-sm-12 mb-2 d-flex flex-column">
            <label htmlFor="address">
              Address
              <span style={{ color: "red" }}>*</span>
            </label>
            <textarea
              id="address"
              type="text"
              className="form-control"
              value={productBookingData.address || ""}
              onChange={(e) => {
                let inputVal = e.target.value;
                if (inputVal.startsWith(" ")) {
                  inputVal = inputVal.trimStart();
                }
                setProductBookingData((prev) => ({
                  ...prev,
                  address: inputVal,
                }));
              }}
              placeholder="Enter Address"
              //   style={{ padding: "0px" }}
            />
            {error &&
            (productBookingData.address === null ||
              productBookingData.address === undefined ||
              productBookingData.address === "") ? (
              <span style={{ color: "red" }}>{ERROR_MESSAGES}</span>
            ) : (
              ""
            )}
          </div>
          <div className="col-md-6 col-sm-12 mb-2 d-flex flex-column">
            <label htmlFor="state">
              State
              <span style={{ color: "red" }}>*</span>
            </label>
            <input
              id="state"
              type="text"
              className="form-control"
              value={productBookingData.state || ""}
              onChange={(e) => {
                let inputVal = e.target.value;
                if (inputVal.startsWith(" ")) {
                  inputVal = inputVal.trimStart();
                }
                setProductBookingData((prev) => ({
                  ...prev,
                  state: inputVal,
                }));
              }}
              placeholder="Enter State"
              //   style={{ padding: "0px" }}
            />
            {error &&
            (productBookingData.state === null ||
              productBookingData.state === undefined ||
              productBookingData.state === "") ? (
              <span style={{ color: "red" }}>{ERROR_MESSAGES}</span>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-sm-12 mb-2 d-flex flex-column">
            <label htmlFor="City">
              City
              <span style={{ color: "red" }}>*</span>
            </label>
            <input
              id="City"
              type="text"
              className="form-control"
              value={productBookingData.city || ""}
              onChange={(e) => {
                let inputVal = e.target.value;
                if (inputVal.startsWith(" ")) {
                  inputVal = inputVal.trimStart();
                }
                setProductBookingData((prev) => ({
                  ...prev,
                  city: inputVal,
                }));
              }}
              placeholder="Enter City"
              //   style={{ padding: "0px" }}
            />
            {error &&
            (productBookingData.city === null ||
              productBookingData.city === undefined ||
              productBookingData.city === "") ? (
              <span style={{ color: "red" }}>{ERROR_MESSAGES}</span>
            ) : (
              ""
            )}
          </div>
          <div className="col-md-6 col-sm-12 mb-2 d-flex flex-column">
            <label htmlFor="pincode">
              Pincode
              <span style={{ color: "red" }}>*</span>
            </label>
            <input
              id="pincode"
              type="text"
              maxLength={6}
              className="form-control"
              value={productBookingData.pincode || ""}
              onChange={(e) => {
                let inputVal = e.target.value;

                // Remove any non-numeric characters
                inputVal = inputVal.replace(/\D/g, "");

                setProductBookingData((prev) => ({
                  ...prev,
                  pincode: inputVal,
                }));
              }}
              placeholder="Enter Pincode"
              //   style={{ padding: "0px" }}
            />
            {error &&
            (productBookingData.pincode === null ||
              productBookingData.pincode === undefined ||
              productBookingData.pincode === "") ? (
              <span style={{ color: "red" }}>{ERROR_MESSAGES}</span>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="row">
          <div
            className="col-md-6 col-sm-12 mb-2 d-flex align-center justify-center"
            style={{
              marginTop: "5px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <label htmlFor="needSamagri" style={{ marginRight: "5px " }}>
              Do you want Samagri for this Puja?
              <span style={{ color: "red" }}>*</span>
            </label>
            <div>
              <label className="me-3">
                <input
                  type="radio"
                  name="samagri"
                  value="yes"
                  checked={productBookingData.needSamagri === "yes"}
                  onChange={(e) =>
                    setProductBookingData((prev) => ({
                      ...prev,
                      needSamagri: e.target.value,
                    }))
                  }
                  className="me-1"
                />
                Yes
              </label>
              <label>
                <input
                  type="radio"
                  name="samagri"
                  value="no"
                  checked={productBookingData.needSamagri === "no"}
                  onChange={(e) =>
                    setProductBookingData((prev) => ({
                      ...prev,
                      needSamagri: e.target.value,
                    }))
                  }
                  className="me-1"
                />
                No
              </label>
            </div>
            {error &&
            (productBookingData.needSamagri === null ||
              productBookingData.needSamagri === undefined ||
              productBookingData.needSamagri === "") ? (
              <span style={{ color: "red" }}>{ERROR_MESSAGES}</span>
            ) : (
              ""
            )}
          </div>
          <div className="col-md-6 col-sm-12 mb-2" style={{ marginTop: "5px" }}>
            <span>
              Your puja samagri amount is: ₹ {product.pujaSamagriPrice}
            </span>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => {
            onHide();
            setInitialData();
          }}
        >
          NO
        </Button>
        <Button variant="primary" onClick={HandleProductBookingBtnClicked}>
          Yes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ProductBookingModal;
