import React, { useEffect, useState } from "react";
import {
  astro_details,
  birth_details,
} from "../../../ApiServices/Horoscope/BirthDetailsApi";
import { GetUserModel } from "../../../ApiServices/Profile/ProfileApi";

const BasicDetails = () => {
  const [animate, setAnimate] = useState(false);
  const [basicDetails, setBasicDetailsObj] = useState([]);
  const [astroDetails, setAstroDetailsObj] = useState([]);
  const basicDetails1 = [
    {
      birthDate: "23/10/1999",
      birthTime: "05:20",
      latitude: 20,
      longitude: 95.656,
      timezone: "ind-21",
      sunrise: "09:30 am",
      sunset: "05.30 pm",
      ayanamasha: "20:3",
    },
    // { birthDate: '23/10/1999', birthTime: '05:20', latitude: 20, longitude: 95.656, timezone: 'ind-21', sunrise: '09:30 am', sunset: '05.30 pm', ayanamasha: '20:3' },
    // { birthDate: '23/10/1999', birthTime: '05:20', latitude: 20, longitude: 95.656, timezone: 'ind-21', sunrise: '09:30 am', sunset: '05.30 pm', ayanamasha: '20:3' }
  ];
  const astroDetails1 = [
    { key: "Ascendant", label: "Data of birth" },
    { key: "Varna", label: "Birth time" },
    { key: "Vashya", label: "Latitude" },
    { key: "Yoni", label: "Latitude" },
    { key: "Gan", label: "Latitude" },
    { key: "Nadi", label: "Latitude" },
    { key: "Sign", label: "Latitude" },
  ];
  const fieldMappings = [
    { key: "birthDate", label: "Date of Birth" },
    { key: "birthTime", label: "Birth Time" },
    { key: "latitude", label: "Latitude" },
    { key: "longitude", label: "Longitude" },
    { key: "timezone", label: "Timezone" },
    { key: "sunrise", label: "Sunrise" },
    { key: "sunset", label: "Sunset" },
    { key: "ayanamasha", label: "Ayanamasha" },
  ];
  useEffect(() => {
    GetUserModelData();
    setTimeout(() => {
      setAnimate(true);
    }, 300); // Delay animation to make it smoother
  }, []);

  const GetUserModelData = async () => {
    // setLoader(true);

    const userID = JSON.parse(localStorage.getItem("userID"));
    try {
      const response = await GetUserModel(userID);
      if (response?.ResponseCode == 0) {
        // setLoader(false);

        const ModelData = response.DATA || []; // Assuming the data is an array and we need the first item
        // debugger
        const dob = new Date(ModelData[0]?.DOB);

        const year = dob.getFullYear();
        const month = dob.getMonth() + 1; // Months are zero-based (0 = January)
        const day = dob.getDate();

        const [hours, minutes] = ModelData[0]?.BIRTH_TIME.split(":");
        const apiParam = {
          day: day,
          month: month,
          year: year,
          hour: hours,
          min: minutes,
          lat: 25.7464,
          lon: 25.7464,
          tzone: 5.5,
        };
        await GetBasicDetails(apiParam);
        await GetAstroDetails(apiParam);
      } else {
        // setLoader(false);

        console.error(
          "Error fetching data: ",
          response?.data?.data?.statusCode
        );
      }
    } catch (error) {
      // setLoader(false);

      console.error("Error", error);
    }
  };
  const GetBasicDetails = async (apiParam) => {
    try {
      const response = await birth_details(apiParam);
      if (response !== null && response !== undefined) {
        // setLoader(false);

        setBasicDetailsObj((prev) => ({
          ...prev,
          year: response?.year,
          month: response?.month,
          day: response?.day,
          hour: response?.hour,
          minute: response?.minute,
          latitude: response?.latitude,
          longitude: response?.longitude,
          timezone: response?.timezone,
          seconds: response?.seconds,
          ayanamsha: response?.ayanamsha,
          sunrise: response?.sunrise,
          sunset: response?.sunset,
        }));
      } else {
        // setLoader(false);

        console.error("Error fetching data: ", response);
      }
    } catch (error) {
      // setLoader(false);

      console.error("Error", error);
    }
  };
  const GetAstroDetails = async (apiParam) => {
    try {
      const response = await astro_details(apiParam);
      if (response !== null && response !== undefined) {
        // setLoader(false);

        setAstroDetailsObj((prev) => ({
          ...prev,
          Ascendant: response?.ascendant,
          AscendantLord: response?.ascendant,
          Varna: response?.Varna,
          Vashya: response?.Vashya,
          Yoni: response?.Yoni,
          Gan: response?.Gan,
          Nadi: response?.Nadi,
          SignLord: response?.SignLord,
          sign: response?.sign,
          Naksahtra: response?.Naksahtra,
          NaksahtraLord: response?.NaksahtraLord,
          Charan: response?.Charan,
          Yog: response?.Yog,
          Karan: response?.Karan,
          Tithi: response?.Tithi,
          Yunja: response?.yunja,
          Tatva: response?.tatva,
          Name_alphabet: response?.name_alphabet,
          Paya: response?.paya,
        }));
      } else {
        // setLoader(false);

        console.error("Error fetching data: ", response);
      }
    } catch (error) {
      // setLoader(false);

      console.error("Error", error);
    }
  };
  return (
    <>
      <div className="container">
        <div className="row justify-content-around mt-3">
          {/* Left Table (Slides in from Left) */}
          <div
            className={`col-md-5 col-12 border p-3 bg-white ${
              animate ? "slide-in-left" : "hidden"
            }`}
          >
            <h5 className="text-center fw-bold">BASIC DETAILS</h5>
            <table className="table">
              <tbody>
                {Object?.entries(basicDetails || {}).map(
                  ([key, value], index) => (
                    <tr key={index}>
                      <th className="text-left fw-bold">{key.toUpperCase()}</th>
                      <th className="text-center">:</th>
                      <td className="text-left">{value}</td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </div>

          {/* Right Table (Slides in from Right) */}
          <div
            className={`col-md-5 col-12 border p-3 bg-white ${
              animate ? "slide-in-right" : "hidden"
            }`}
          >
            <h5 className="text-center fw-bold">ASTRO DETAILS</h5>
            <table className="table">
              <tbody>
                {Object.entries(astroDetails || {}).map(
                  ([key, value], index) => (
                    <tr key={index}>
                      <th className="text-left fw-bold">{key.toUpperCase()}</th>
                      <th className="text-center">:</th>
                      <td className="text-left">{value}</td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default BasicDetails;
