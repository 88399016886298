import { BaseUrl } from "../../BaseUrl/BaseUrl";
import { getApiWithAuthorization, postApiWithoutAuthorization } from "../ApiMethod/ApiMethod";


export const Estore_order = async (params) => {
    const url = `${BaseUrl}/Estore_order`;
    const res = await postApiWithoutAuthorization(url, params);
    return res;
};


export const GetDeliveryCharges = async (stateID,districtID) => {
    const url = `${BaseUrl}/GetDeliveryCharges?StateID=${stateID}&DistrictID=${districtID}`;
    const res = await getApiWithAuthorization(url);
  
    return res;
  };

export const EstoreInvoice = async (userID,order_id) => {
    const url = `${BaseUrl}/EstoreInvoice?USER_ID=${userID}&OrderID=${order_id}`;
    const res = await getApiWithAuthorization(url);
  
    return res;
  };