import axios from "axios";
import React, { useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
// import Cart from "./Cart";
import { BaseUrl } from "../../BaseUrl/BaseUrl";
import CheckedLoggedInModal from "./CheckLoggedInModal";
import toast from "react-hot-toast";
import about1 from "../../assets/images/about1.png";
import "./cart.css";
import { AuthContextProvider } from "../../AuthContext/AuthContext";
import LoginPopUp from "../LoginPopup";
const Cart = () => {
  const data = localStorage.getItem("userID");
  console.log(data, "vvvv34");
  const [loginPopUp, setLoginPopUp] = useState(null);
  const { isAuthenticated } = useContext(AuthContextProvider);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const closeModal = () => setIsModalOpen(false);

  const [inputValue, setInputValue] = useState(1);
  const location = useLocation();
  const navigate = useNavigate();
  const ItemAddedToast = () => toast.success("Item added successfully");

  // const displayValue = (e) => {
  //     setInputValue(e.target.value);
  // };

  const handleIncrement = () => {
    if (inputValue < 20) {
      const newValue = inputValue + 1;
      setInputValue(newValue);
      updateProductQuantity(newValue);
    }
  };

  const handleDecrement = () => {
    if (inputValue > 1) {
      const newValue = inputValue - 1;
      setInputValue(newValue);
      updateProductQuantity(newValue);
    }
  };


  // Input change handler
  // const handleInputChange = (e) => {
  //   const filteredValue = e.target.value.replace(/[^0-9]/g, "");

  //   // Handle empty input case
  //   if (filteredValue === "") {
  //     setInputValue(1);
  //     return;
  //   }

  //   const numericValue = parseInt(filteredValue, 10);

  //   // Clamp value between 1-20
  //   const clampedValue = Math.min(Math.max(numericValue, 1), 20);

  //   setInputValue(clampedValue);
  // };

  const handleInputChange = (e) => {
    const filteredValue = e.target.value.replace(/[^0-9]/g, "");
    if (filteredValue === "") {
      setInputValue(1);
      return;
    }
    const numericValue = parseInt(filteredValue, 10);
    const clampedValue = Math.min(Math.max(numericValue, 1), 20);
    setInputValue(clampedValue);
    updateProductQuantity(clampedValue);
  };

  // const addProduct = (product) => {
   

  //   if (data) {
  //     axios
  //       .post(`${BaseUrl}/Estore_Cart`, {
  //         USER_ID: data,
  //         CART_ID: product?.CAT_ID,
  //         PRODUCT_ID: product?.PRODUCT_ID,
  //         QUANTITY: inputValue,
  //         TASK: "NEW",
  //       })
  //       .then((res) => {
  //         if (res.data.ResponseCode === "0") {
  //           toast.success(res.data.ResponseMessage);
  //           navigate("/view-cart");
  //         } else {
  //           ItemAddedToast();
  //           setTimeout(() => navigate("/view-cart"), 100);

  //           const currentCount = parseInt(localStorage.getItem("cartItemCount")) || 0;
  //           localStorage.setItem("cartItemCount", currentCount + parseInt(inputValue));
    
  //           // ✅ Dispatch event to update header
  //           window.dispatchEvent(new Event("cartUpdated")); // Notify other components
  //         }
  //       })
  //       .catch((error) => {
  //         console.error("Error adding product to cart:", error);
  //         alert("An error occurred while adding the item to the cart.");
  //       });
  //   } else {
  //     navigate("/login");
  //     setIsModalOpen(true);
  //   }
  // };

  const addProduct = (product) => {

    if(!isAuthenticated){
      setLoginPopUp(true)
      return
    }
    if (data) {
      axios
        .post(`${BaseUrl}/Estore_Cart`, {
          USER_ID: data,
          CART_ID: product?.CAT_ID,
          PRODUCT_ID: product?.PRODUCT_ID,
          QUANTITY: inputValue, // Quantity is only for API, not cart count
          TASK: "NEW",
        })
        .then((res) => {
          if (res.data.ResponseCode === "0") {
            toast.success(res.data.ResponseMessage);
            navigate("/view-cart");
          } else {
            ItemAddedToast();
            setTimeout(() => navigate("/view-cart"), 100);
          }
  
          // ✅ Get existing cart data (array of products)
          let cartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
  
          // ✅ Check if product is already in the cart
          const existingItem = cartItems.find(
            (item) => item.PRODUCT_ID === product.PRODUCT_ID
          );
  
          if (!existingItem) {
            // ✅ If new, add to cart and update count
            cartItems.push({ PRODUCT_ID: product.PRODUCT_ID });
  
            // ✅ Save updated cart array
            localStorage.setItem("cartItems", JSON.stringify(cartItems));
  
            // ✅ Update count in localStorage
            localStorage.setItem("cartItemCount", cartItems.length);
  
            // ✅ Notify header to update count
            window.dispatchEvent(new Event("cartUpdated"));
          }
        })
        .catch((error) => {
          console.error("Error adding product to cart:", error);
          alert("An error occurred while adding the item to the cart.");
        });
    } else {
      navigate("/login");
      setIsModalOpen(true);
    }
  };


  const updateProductQuantity = (quantity) => {
    axios
      .post(`${BaseUrl}/Estore_Cart`, {
        USER_ID: data,
        CART_ID: location.state.CAT_ID,
        PRODUCT_ID: location.state.PRODUCT_ID,
        QUANTITY: quantity,
        TASK: "EDIT",
      })
      .then((res) => {
        console.log(res.data);
        window.dispatchEvent(new Event("cartUpdated"));
      })
      .catch((err) => console.error("Error updating quantity:", err));
  };

  

  return (
    <>
      <div className="container mt-4">
        <div className="row">
          {/* Left Side - Sticky Image with Full Utilization */}
          <div className="col-md-5">
            <div
              className="position-sticky d-flex align-items-center justify-content-center"
              style={{ top: "20px", height: "80vh" }}
            >
              <img
                src={location?.state?.PRODUCT_IMG}
                alt={location?.state?.PRODUCT_NAME}
                className="img-fluid shadow rounded"
                style={{
                  width: "100%",
                  height: "90%",
                  objectFit: "contain",
                  maxHeight: "90%",
                }}
              />
            </div>
          </div>

          {/* Right Side - Scrollable Product Details */}
          <div
            className="col-md-7 d-flex flex-column"
            style={{ maxHeight: "80vh", overflowY: "auto" }}
          >
            <h3>{location?.state?.PRODUCT_NAME}</h3>
            <h4 style={{ color: "#A42642" }}>
              ₹{" "}
              {location?.state?.PRODUCT_PRICE -
                Math.round(
                  (location?.state?.PRODUCT_PRICE * location?.state?.DISCOUNT) /
                    100
                )}
              {location?.state?.DISCOUNT !== 0 && (
                <span
                  className="text-muted ms-2"
                  style={{
                    fontWeight: "normal",
                    textDecoration: "line-through",
                    fontSize: "16px",
                  }}
                >
                  ₹ {location?.state?.PRODUCT_PRICE}
                </span>
              )}
            </h4>

            <p>
              <span style={{ background: "#8f3246" }} className="badge ">
                Discount {location?.state?.DISCOUNT}%
              </span>
            </p>

            {/* Size Selection */}
            <div
              className="mt-3 d-flex"
              style={{ justifyContent: "flex-start" }}
            >
              {/* <div className="d-flex align-items-center">
                <button
                  style={{ background: "#8f3246" }}
                  className="btn text-white fw-bold px-3"
                  onClick={handleDecrement}
                  disabled={inputValue === 1}
                >
                  -
                </button>
                <input
                  className="form-control text-center mx-2"
                  style={{ width: "60px" }}
                  value={inputValue}
                  onInput={handleInputChange}
                  readOnly
                />
                <button
                  style={{ background: "#8f3246" }}
                  className="btn text-white fw-bold px-3"
                  onClick={handleIncrement}
                  disabled={inputValue === 20}
                >
                  +
                </button>
              </div> */}
              <button
                className="btn text-white w-10 mx-3"
                style={{ background: "#8f3246" }}
                onClick={() => addProduct(location.state)}
              >
                Add to Cart
              </button>
            </div>

            {/* Product Details */}
            <div className="mt-3">
              <h6>Product Details</h6>
              <ul>
                <li
                  dangerouslySetInnerHTML={{
                    __html: location?.state?.PRODUCT_DESC,
                  }}
                />
                <li
                  dangerouslySetInnerHTML={{
                    __html: location?.state?.PRODUCT_SPECI,
                  }}
                />
              </ul>
            </div>
          </div>
        </div>
        {loginPopUp && (
        <LoginPopUp show={loginPopUp} onHide={() => setLoginPopUp(false)} />
      )}
      </div>
    </>
  );
};

export default Cart;
