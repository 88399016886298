import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const ExitIntentModal = ({ show, onHide, slug, onSelectCity }) => {
  return (
    <Modal show={show} onHide={onHide} centered className="exit-modal">
      <Modal.Header closeButton className="border-0">
        <Modal.Title className="w-100 text-center text-dark fw-bold">
          🎉 Get 10% To 30% Off On All Spiritual Services!

        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        <p className="lead text-muted">
          Download App & Get <strong>30% Off</strong> On Puja Samagri <strong>10% Off </strong> On Puja.
        </p>

        {/* App Download Buttons */}
        <div className="d-flex justify-content-center gap-3 my-3">
          <Button
            variant="dark"
            className="d-flex align-items-center gap-2"
            href="https://play.google.com/store/apps/details?id=spiritualapp.myomnamo&pli=1"
            target="_blank"
            rel="noopener noreferrer"
          >

            <i class="fa-brands fa-google-play"></i>
            <span>Download on Play Store</span>
          </Button>

        </div>

        {/* Showing Slug (Optional) */}
        {/* {slug && (
        <p className="small text-muted">
          You are viewing: <strong>{slug}</strong>
        </p>
      )} */}
      </Modal.Body>

      <Modal.Footer className="border-0 d-flex justify-content-center">
        <Button
          variant="danger"
          className="fw-bold px-4 py-2"
          onClick={onHide}
        >
          Continue Browsing
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ExitIntentModal;
