import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

function ConfirmationModal({ show, onHide, onConfirm }) {
  return (
    <Modal
      show={show}
      onHide={onHide}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header
        closeButton
        style={{
          backgroundColor: "#A52444",
          color: "white",
        }}
      >
        <Modal.Title style={{ fontSize: "25px", fontWeight: "bold" }}>
          Please Confirm
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{
          backgroundColor: "#f2eeee",
          color: "Black",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          minHeight: "80px", // Ensures proper vertical centering
        }}
      >
        <span style={{ fontSize: "20px" }}>
          Are you sure you want to logout?
        </span>
      </Modal.Body>
      <Modal.Footer style={{ backgroundColor: "#f2eeee" }}>
        <Button
          variant="secondary"
          onClick={onHide}
          style={{ backgroundColor: "#A52444", border: "none" }}
        >
          NO
        </Button>
        <Button
          variant="primary"
          onClick={onConfirm}
          style={{
            backgroundColor: "#EFB423",
            color: "#2B1B10",
            border: "none",
          }}
        >
          Yes
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ConfirmationModal;
