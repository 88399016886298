import React from "react";
import { Helmet } from "react-helmet-async";

const TermsAndConditionPage = () => {
  return (
    <>
      <Helmet>
        <title>Terms and Conditions - Myomnamo - Spiritual Services</title>
        <meta
          name="description"
          content="Read our Terms & Conditions for using MyOmNamo services. Understand policies for pandit bookings, pujas, and other spiritual services."
        />

        {/* Canonical Tag */}
        <link
          rel="canonical"
          href="https://www.myomnamo.com/terms-and-condition"
        />

        {/* Open Graph Meta Tags */}
        <meta
          property="og:title"
          content="Terms and Conditions - MyOmNamo - Spiritual Services"
        />
        <meta
          property="og:description"
          content="Read our Terms & Conditions for using MyOmNamo services. Understand policies for pandit bookings, pujas, and other spiritual services."
        />
        <meta
          property="og:image"
          content="https://www.myomnamo.com/assets/images/new/logo.png"
        />
        <meta
          property="og:url"
          content="https://www.myomnamo.com/terms-and-condition"
        />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="MyOmNamo" />
        <meta property="og:locale" content="en_IN" />

        {/* Twitter Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Terms and Conditions - MyOmNamo - Spiritual Services"
        />
        <meta
          name="twitter:description"
          content="Read our Terms & Conditions for using MyOmNamo services. Understand policies for pandit bookings, pujas, and other spiritual services."
        />
        <meta
          name="twitter:image"
          content="https://www.myomnamo.com/assets/images/new/logo.png"
        />

        {/* Schema Markup - Breadcrumb List */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org/",
            "@type": "BreadcrumbList",
            itemListElement: [
              {
                "@type": "ListItem",
                position: 1,
                name: "Home",
                item: "https://www.myomnamo.com/",
              },
              {
                "@type": "ListItem",
                position: 2,
                name: "Terms and Conditions",
                item: "https://www.myomnamo.com/terms-and-condition",
              },
            ],
          })}
        </script>

        {/* Schema Markup - WebPage */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "http://schema.org",
            "@type": "WebPage",
            name: "Terms and Conditions - MyOmNamo - Spiritual Services",
            url: "https://www.myomnamo.com/terms-and-condition",
            description:
              "Read our Terms & Conditions for using MyOmNamo services. Understand policies for pandit bookings, pujas, and other spiritual services.",
            publisher: {
              "@type": "Organization",
              name: "MyOmNamo",
            },
          })}
        </script>
      </Helmet>
      <section>
        <div
          className="container mx-auto p-6 mt-3"
          style={{ display: "flex", flexDirection: "column" }}
        >
          <h3 className="text-2xl font-bold mb-4">
            Terms and Conditions for MyOmNamo
          </h3>
          <p>
            Welcome to MyOmNamo! These Terms and Conditions ("Terms") govern
            your use of our website (
            <a href="https://www.myomnamo.com" className="text-blue-500">
              www.myomnamo.com
            </a>
            ) and the spiritual services we provide, including puja bookings,
            horoscope services, temple visits, and e-store purchases. By
            accessing or using our services, you agree to comply with these
            Terms. Please read them carefully.
          </p>

          <h4 className="text-xl font-semibold mt-6">1. Acceptance of Terms</h4>
          <p>
            By using MyOmNamo, you confirm that you have read, understood, and
            agreed to these Terms. If you do not agree, please refrain from
            using our services. We reserve the right to modify these Terms at
            any time, and your continued use will constitute acceptance of the
            updated terms.
          </p>

          <h4 className="text-xl font-semibold mt-6">2. Services Offered</h4>
          <p>MyOmNamo provides online spiritual services, including:</p>
          <ul className="list-disc pl-6">
            <li>Booking pandits for pujas and rituals</li>
            <li>Daily puja services</li>
            <li>Horoscope and astrology consultations</li>
            <li>Temple visit and darshan bookings</li>
            <li>
              Purchase of puja samagri and spiritual products from our e-store
            </li>
          </ul>
          <p>
            All services are subject to availability, and we reserve the right
            to modify or discontinue any service without prior notice.
          </p>

          <h4 className="text-xl font-semibold mt-6">
            3. User Responsibilities
          </h4>
          <ul className="list-disc pl-6">
            <li>
              You must provide accurate and complete information when booking
              services or making purchases.
            </li>
            <li>Payments must be made as per the specified rates.</li>
            <li>
              Any misuse, fraudulent activity, or violation of these Terms may
              result in service cancellation or legal action.
            </li>
            <li>
              You agree not to use our services for any unlawful or unethical
              purposes.
            </li>
          </ul>

          <h4 className="text-xl font-semibold mt-6">
            4. Booking and Cancellation Policy
          </h4>

          <h5 className="font-medium mt-4">Puja and Ritual Bookings:</h5>
          <ul className="list-disc pl-6">
            <li>Bookings are confirmed only after full payment.</li>
            <li>
              Cancellations must be made at least 48 hours in advance for a
              refund, if applicable.
            </li>
            <li>
              No refunds will be provided for cancellations made after the
              specified time or for no-shows.
            </li>
          </ul>

          <h5 className="font-medium mt-4">
            Horoscope and Astrology Services:
          </h5>
          <ul className="list-disc pl-6">
            <li>Consultations are based on the details provided by you.</li>
            <li>
              Refunds, if applicable, are subject to the discretion of MyOmNamo.
            </li>
          </ul>

          <h5 className="font-medium mt-4">E-Store Purchases:</h5>
          <ul className="list-disc pl-6">
            <li>Products are shipped as per availability.</li>
            <li>
              Refunds or replacements are subject to our{" "}
              <a href="#" className="text-blue-500">
                Return and Refund Policy
              </a>
              .
            </li>
          </ul>

          <h4 className="text-xl font-semibold mt-6">
            5. Payments and Pricing
          </h4>
          <ul className="list-disc pl-6">
            <li>
              All transactions are processed securely through trusted payment
              gateways.
            </li>
            <li>Prices are subject to change without notice.</li>
            <li>
              Additional charges (e.g., travel fees for pandits) may apply and
              will be communicated beforehand.
            </li>
          </ul>

          <h4 className="text-xl font-semibold mt-6">6. Privacy Policy</h4>
          <p>
            Your privacy is important to us. Our
            <a href="/privacy-policy" className="text-blue-500">
              {" "}
              Privacy Policy{" "}
            </a>
            explains how we collect, use, and protect your personal information.
            By using our services, you consent to our data practices.
          </p>

          <h4 className="text-xl font-semibold mt-6">
            7. Intellectual Property
          </h4>
          <p>
            All content on MyOmNamo, including text, images, and logos, is our
            property and protected by copyright laws. Unauthorized use, copying,
            or distribution is prohibited.
          </p>

          <h4 className="text-xl font-semibold mt-6">
            8. Limitation of Liability
          </h4>
          <ul className="list-disc pl-6">
            <li>
              MyOmNamo acts as a facilitator between users and service providers
              (pandits, temples, etc.).
            </li>
            <li>
              We do not guarantee the accuracy or outcomes of horoscope or puja
              services.
            </li>
            <li>
              We are not liable for any direct, indirect, or incidental damages
              arising from service use.
            </li>
          </ul>

          <h4 className="text-xl font-semibold mt-6">9. Third-Party Links</h4>
          <p>
            Our website may contain links to external sites. We are not
            responsible for their content or practices.
          </p>

          <h4 className="text-xl font-semibold mt-6">
            10. Governing Law and Dispute Resolution
          </h4>
          <p>
            These Terms are governed by Indian law. Any disputes will be subject
            to the jurisdiction of courts in <strong>Your City, State</strong>.
          </p>

          <h4 className="text-xl font-semibold mt-6">11. Contact Us</h4>
          <p>If you have any questions, please contact us:</p>
          <p>
            <strong>Email:</strong>{" "}
            <a href="mailto:info@myomnamo.com" className="text-blue-500">
              info@myomnamo.com
            </a>
          </p>
          <p>
            <strong>Phone:</strong> +91 8879222277
          </p>

          <p className="mt-6">
            By using MyOmNamo, you acknowledge that you have read, understood,
            and agreed to these Terms and Conditions.
          </p>

          <p className="font-semibold mt-6">
            Thank you for choosing MyOmNamo for your spiritual journey.
          </p>
        </div>
      </section>
    </>
  );
};

export default TermsAndConditionPage;
