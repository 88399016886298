import { BaseUrl } from "../../BaseUrl/BaseUrl";
import {
    getApiWithAuthorization,
    postApiWithoutAuthorization,
} from "../ApiMethod/ApiMethod";

export const StateLookupList = async (countryID) => {
    const url = `${BaseUrl}/State?CountryID=${countryID}`;
    const res = await getApiWithAuthorization(url);
    return res;
};
export const CityLookupList = async (cityID) => {
    const url = `${BaseUrl}/City?StateID=${cityID}`;
    const res = await getApiWithAuthorization(url);
    return res;
};
