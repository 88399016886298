import { useEffect, useState } from "react";
import React from "react";
import axios from "axios";
import { BaseUrl } from "../../BaseUrl/BaseUrl";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Mylogo from "../../assets/images/logo.png";
import dayjs from "dayjs";
const OrderDetails = () => {
  const userID = JSON.parse(localStorage.getItem("userID"));
  const [localData, setLocalData] = useState(null);
  const [totalAmount, setTotalAmount] = useState(0);
  const [orders, setOrders] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();



  useEffect(() => {
    axios
      .get(`${BaseUrl}/Estore_order?ORDER_ID=${location?.state?.ORDER_ID} `)
      .then((response) => {
        console.log("Response...", response.data.DATA);
        setOrders(response.data.DATA);
      });
    const storedData = JSON.parse(localStorage.getItem("paymentData"));
    setLocalData(storedData);
  }, []);

  useEffect(() => {
    const calculatedTotal = orders?.reduce(
      (total, order) =>
        total +
        order.RATE * order.QTY -
        Math.round((order.RATE * (order.DISCOUNT * order.QTY)) / 100),
      0
    );
    setTotalAmount(calculatedTotal);
  }, [orders]);

  const handleProceedRemainingPayment = async () => {
    if (localData?.remainingAmount === 0) {
      alert("All the amount already paid");
      return;
    }
    // let totalAmount = parseInt(modelRequestData.totalVal); // Assuming totalVal includes shipping
    // let minPayableAmount = Remaining_Amount; // 20% of the total amount

    let orderIDReturned = location?.state?.ORDER_ID;
    const finalAmount =
      Number(location?.state?.TOTAL_PRICE) +
      Number(location?.state?.SHIPING_CHARGES);
    console.log("finalAmount", finalAmount);
    const options = {
      key: "rzp_test_vv1FCZvuDRF6lQ",
      key_secret: "P4JAUwn4VdE6xDLJ6p2Zy8RQ",
      amount:
        (Number(location?.state?.TOTAL_PRICE) + // Total before discount
          Number(location?.state?.SHIPING_CHARGES) -
          location?.state?.TRANSACTION_AMT) *
        100,
      currency: "INR",
      name: "My Om Namo- WowInfotech",
      description: "working",
      image: Mylogo,
      handler: function (response) {
        // console.log(response, "Payment Success");
        const paymentId = response.razorpay_payment_id;

        if (response) {
          axios
            .post(`${BaseUrl}/UpdatePaymentStatus?TYPE=Estore`, {
              USER_ID: userID,
              ORDER_ID: orderIDReturned,
              // ORDER_ID: JSON.parse(localStorage.getItem("orderID")),
              TRANSACTION_ID: paymentId,
              PAYMENT_STATUS: "Success",
              TRANSACTION_STATUS: "Success",
              TRANSACTION_AMT: finalAmount,
              REMAING_AMOUNT: 0,
              PAYMENT_DATE: dayjs().format("YYYY-MM-DD"),
            })
            .then((res) => {
              // debugger;
              if (res.data.ResponseMessage === "successfully.") {
                // localStorage.setItem("cartItemCount", "0");
                // window.dispatchEvent(new Event("cartUpdated"));
                // setShowCODModal(false);
                // nextStep();
                navigate("/my-order");
              } else {
                alert("Order placement failed, please try again.");
              }
            });
        }

        // const updatedPayload = {
        //   ...razorPayData,
        //   TRANSACTION_ID: paymentId,
        //   TRANSACTION_AMT: minPayableAmount,
        // };

        // axios.post(`${BaseUrl}/BookPooja`, updatedPayload).then((res) => {
        //   debugger;
        //   if (res.data.ResponseMessage === "Order Success.") {
        //     localStorage.setItem("cartItemCount", "0");
        //     window.dispatchEvent(new Event("cartUpdated"));
        //     navigate("/thank-you");
        //   } else {
        //     alert("Order placement failed, please try again.");
        //   }
        // });
      },
      theme: {
        color: "#ff416c",
      },
    };

    const rzp = new window.Razorpay(options);
    rzp.open();
  };

  return (
    <>
      <div className="m-5 p-5 my-4">
        <div className="row">
          {/* Left Section: Multiple Order Items */}
          <div className="col-md-8">
            {orders.map((order, index) => (
              <div key={index} className="card p-3 mb-3 shadow-sm">
                <div className="d-flex align-items-center">
                  {/* Product Image on the Left */}
                  <img
                    src={order?.PRODUCT_IMG}
                    alt="Product"
                    className="rounded"
                    style={{
                      width: "180px",
                      height: "150px",
                      objectFit: "contain",
                    }}
                  />

                  {/* Product Details in the Center */}
                  <div className="flex-grow-1 px-3">
                    <h6 className="fw-bold">{order?.PRODUCT_NAME}</h6>
                    <p className="text-muted mb-1">Seller: Myomnamo Spiritual Services Pvt Ltd.</p>
                  </div>

                  {/* Price on the Right */}
                  <div className="text-end">
                    <h5 className="fw-bold  mb-0">
                      ₹
                      {order.RATE * order.QTY -
                        Math.round(
                          (order.RATE * (order.DISCOUNT * order.QTY)) / 100
                        )}
                    </h5>
                    {order.DISCOUNT > 0 && (
                      <span className="text-muted small text-decoration-line-through">
                        ₹{order.RATE * order.QTY}
                      </span>
                    )}
                  </div>
                </div>

                {/* Order Status */}
                <hr />
              </div>
            ))}
            <div className="d-flex align-items-center">
              {/* <span className="text-success fw-bold me-2">✔</span> */}
              <p className="mb-0">Order Confirmed, {location.state.ORDER_DATE}</p>
            </div>

            {/* Cancellation Message */}
            <p className="text-muted mt-2">
              Your order was cancelled as the courier could not deliver it even
              after multiple attempts.
            </p>
          </div>

          {/* Right Section: Single Product's Shipping & Price Details */}
          <div className="col-md-4">
            {/* Shipping Details */}
            <div className="card p-3 mb-3 shadow-sm">
              <h6 className="fw-bold">Shipping details</h6>
              {/* <p className="mb-1 fw-bold">Aniket Kahandal</p> */}
              <p className="text-muted small">
                {location?.state?.ORDER_ADDRESS}
              </p>
              {/* <p className="fw-bold mb-0">Phone number: 9623576236</p> */}
            </div>

            {/* Price Details (Using First Product) */}
            {orders.length > 0 && (
              <div className="card p-3 shadow-sm">
                <h6 className="fw-bold">Price Details</h6>
                <div className="d-flex justify-content-between">
                  <p className=" fw-bold">Product price</p>
                  <p className="fw-bold">{location?.state?.TOTAL_PRICE}</p>
                </div>
                <div className="d-flex justify-content-between">
                  <p className=" fw-bold">Discount Amount </p>
                  <p className="fw-bold">{location?.state?.TOTAL_DISC}</p>
                </div>
                <hr></hr>
                <div className="d-flex justify-content-between">
                  <p className=" fw-bold">Total Amount </p>
                  <p className="fw-bold">{Number(location?.state?.TOTAL_PRICE) - Number(location?.state?.TOTAL_DISC)}</p>
                </div>
                <div className="d-flex justify-content-between">
                  <p className="fw-bold">Tax ({location?.state?.TaxPercentage})</p>
                  <p className=" fw-bold">
                    <span>{location?.state?.TaxAmount}</span>
                  </p>
                </div>
                <div className="d-flex justify-content-between">
                  <p className="fw-bold">Delivery Charge</p>
                  <p className=" fw-bold">
                    <span>{location?.state?.DeliveryCharges}</span>
                  </p>
                </div>
                <hr></hr>
                <div className="d-flex justify-content-between">
                  <p className="fw-bold">Grand Total</p>
                  <p className=" fw-bold">
                    <span> {Number(location?.state?.TRANSACTION_AMT)}</span>
                  </p>
                </div>

              </div>
            )}
            {location?.state?.RemainingAmount == 0 && (
              <button
                className="careox-btn mt-2"
                onClick={() =>
                  navigate("/e-store-invoice", {
                    state: {
                      Address: location?.state?.ORDER_ADDRESS,
                      OrderID: location?.state?.ORDER_ID,

                      totalAmount: totalAmount,
                      shippingCharges: location?.state?.SHIPING_CHARGES,
                      moduleName: 'EstoreModule'
                    },
                  })
                }
              >
                View Invoice
              </button>
            )}
            <button
              className="careox-btn mt-2 mx-2"
              onClick={() => {
                navigate("/view-receipt", {
                  state: { moduleName: "E-store", orderID: location?.state?.ORDER_ID },
                })


                if (location?.state?.PAYMENT_TYPE == 'COD') {
                  navigate("/receipt-details", {
                    state: { moduleName: "Estore", orderID: location?.state?.ORDER_ID },
                  })
                } else {

                  navigate("/view-receipt", {
                    state: { moduleName: "Estore", orderID: location?.state?.ORDER_ID },
                  })
                }
              }
              }

            >
              View Receipt
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderDetails;
