import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { BaseUrl } from "../../BaseUrl/BaseUrl";
import axios from "axios";

// import Mylogo from "../Images/logo.png";
import { useNavigate } from "react-router-dom";
import { ERROR_MESSAGES } from "../GlobalErrorMessage";
function CODPaymentModal({
  show,
  onHide,
  onConfirm,
  modelRequestData,
  btnClick,
  setModelRequestData,
}) {
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const twentyPercentAmount = Math.ceil(modelRequestData.totalVal * 0.2);
  console.log(modelRequestData, "222222sss");

  const handleRazorpayPayment = () => {
    let totalAmount = parseInt(modelRequestData.totalVal); // Assuming totalVal includes shipping
    let minPayableAmount = totalAmount * 0.2; // 20% of the total amount
    axios
      .post(`${BaseUrl}/Estore_order`, modelRequestData?.amountEntered)
      .then((res) => {
        if (res.data.ResponseMessage === "Order Success.") {
          localStorage.setItem("cartItemCount", "0");
          window.dispatchEvent(new Event("cartUpdated"));
          navigate("/thank-you");
        } else {
          alert("Order placement failed, please try again.");
        }
      });
  };
  function formatToIndianCurrency(amount) {
    if (!amount) return "";

    const [integerPart, decimalPart] = amount.toString().split(".");

    // Format the integer part using Indian numbering system
    const formattedIntegerPart = integerPart.replace(
      // Match groups of 3 digits initially, then groups of 2 digits
      /(\d)(?=(\d\d)+\d$)/g,
      "$1,"
    );

    // Return the formatted value with the decimal part (if present)
    return decimalPart !== undefined
      ? `${formattedIntegerPart}.${decimalPart}`
      : formattedIntegerPart;
  }

  const acceptDecimal = (inputValue) => {
    // debugger;
    const sanitizedInput = inputValue
      .replace(/[^0-9.]/g, "") // Remove all non-numeric and non-dot characters
      .replace(/(\..*?)\./g, "$1"); // Allow only a single dot

    // Split into integer and decimal parts
    const [integerPart, decimalPart] = sanitizedInput.split(".");

    // Format the integer and decimal parts
    const formattedIntegerPart = integerPart?.slice(0, 8) || ""; // Limit integer to 8 digits
    const formattedDecimalPart = decimalPart?.slice(0, 2) || ""; // Limit decimal to 2 digits

    // Combine formatted parts
    const formattedInput =
      decimalPart !== undefined
        ? `${formattedIntegerPart}.${formattedDecimalPart}`
        : formattedIntegerPart;

    return formattedInput;
  };
  const handleCODSubmit = () => {
    let isValid = false;
    if (
      modelRequestData.amountEntered === null ||
      modelRequestData.amountEntered === "" ||
      modelRequestData.amountEntered === undefined
    ) {
      isValid = true;
      setError(true);
    } else if (
      modelRequestData.amountEntered < twentyPercentAmount ||
      modelRequestData.amountEntered > modelRequestData.totalPrice
    ) {
      isValid = true;
      setError(true);
    }

    if (!isValid) {
      btnClick();
    }
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header
        closeButton
        style={{
          backgroundColor: "#A52444",
          color: "white",
        }}
      >
        <Modal.Title style={{ fontSize: "25px", fontWeight: "bold" }}>
          E - Store Orders
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{
          backgroundColor: "#f2eeee",
          color: "Black",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          minHeight: "80px", // Ensures proper vertical centering
          flexDirection: "column",
        }}
      >
        <p style={{ fontSize: "15px" }}>
          You need to pay at least 20% of the total amount to book the Order .
          You can also pay the full amount.
        </p>

        <div className="amounts d-flex align-center flex-column">
          <span>
            <strong>Total Amount: </strong>
            {modelRequestData.totalVal
              ? Math.round(modelRequestData.totalVal)
              : "0.00"}
          </span>
          <span>
            <strong>Payment Amount (20%): </strong>
            {modelRequestData.totalVal
              ? Math.round(twentyPercentAmount)
              : "0.00"}
          </span>
        </div>

        <div
          className="d-flex flex-column"
          style={{ width: "100%", marginTop: "10px" }}
        >
          <label
            htmlFor=""
            style={{ display: "flex", justifyContent: "flex-start" }}
          >
            Enter Amount Here
            <span style={{ color: "red" }}>*</span>
          </label>
          <input
            type="text"
            value={formatToIndianCurrency(modelRequestData?.amountEntered)}
            onChange={(e) => {
              let inputVal = acceptDecimal(e.target.value);

              if (inputVal.startsWith(" ")) {
                inputVal = inputVal.trimStart();
              }

              // inputVal = inputVal.replace(/[^0-9.]/g, "");
              // let formattedValue = parseInt(inputVal, 10) || null;

              setModelRequestData((prev) => ({
                ...prev,
                amountEntered: inputVal,
              }));
            }}
            placeholder="Enter Amount"
            maxLength={9}
            className="form-control"
          />
          {error &&
          (modelRequestData.amountEntered === null ||
            modelRequestData.amountEntered === undefined ||
            modelRequestData.amountEntered === "") ? (
            <span style={{ color: "red" }}>{ERROR_MESSAGES}</span>
          ) : error && modelRequestData.amountEntered < twentyPercentAmount ? (
            <span style={{ color: "red" }}>
              Amount should not be less than 20%
            </span>
          ) : error &&
            modelRequestData.amountEntered > modelRequestData.totalPrice ? (
            <span style={{ color: "red" }}>
              Amount should not be greater than total price
            </span>
          ) : (
            ""
          )}
        </div>
      </Modal.Body>
      <Modal.Footer style={{ backgroundColor: "#f2eeee" }}>
        <Button
          variant="secondary"
          onClick={onHide}
          style={{ backgroundColor: "#A52444", border: "none" }}
        >
          CLOSE{" "}
        </Button>
        <Button
          variant="primary"
          onClick={handleCODSubmit}
          style={{
            backgroundColor: "#EFB423",
            color: "#2B1B10",
            border: "none",
          }}
        >
          PAY{" "}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default CODPaymentModal;
