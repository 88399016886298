import React, { useContext, useEffect, useState } from "react";
import OTPInput from "otp-input-react";
import Logo from "../../../assets/images/new/logo.png";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthContextProvider } from "../../../AuthContext/AuthContext";
import { RegisterUserVerifyOTP } from "../../../ApiServices/Register/RegisterAPI";

const RegisterOTP = () => {
  const [registerOTPData, setRegisterOTPData] = useState({
    email: null,
    otp: null,
  });
  const [error, setError] = useState(null);
  const { setLoader, setIsAuthenticated } = useContext(AuthContextProvider);
  const [errorMessage, setErrorMessage] = useState(null);

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    setRegisterOTPData((prev) => ({
      ...prev,
      email: location.state.email,
    }));
  }, []);

  const handleVerifyOTP = async () => {
    let isValid = false;
    // debugger;
    if (!registerOTPData.email) {
      setErrorMessage("Email not found. Please try signing up again.");
      return;
    }

    if (
      registerOTPData.otp === "" ||
      registerOTPData.otp === null ||
      registerOTPData.otp === undefined
    ) {
      isValid = true;
      setError(true);
    }

    if (!isValid) {
      try {
        const response = await RegisterUserVerifyOTP(
          registerOTPData.email,
          registerOTPData.otp
        );

        if (
          response.ResponseCode === "0" &&
          response.ResponseMessage === "Login successful!"
        ) {
          localStorage.setItem("userID", response.DATA[0].UserID);
          // alert("Registeration Successful!");
          setIsAuthenticated(true);
          navigate("/");
          // window.location.reload(); // Optional: Refresh page to reset state
        } else {
          // Display error message if OTP verification failed
          setError(true);
          setErrorMessage(
            response.ResponseMessage || "Invalid OTP. Please try again."
          );
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  return (
    <div>
      <div className="e-store main">
        <div className="signinn mb-5">
          <div className="container" id="container" style={{ padding: "0px" }}>
            <div className="form-container sign-in-container">
              <div className="otpp Otp">
                <div className="my-img">
                  <img
                    src={Logo}
                    alt="img"
                    className="p-5 pt-0 img-responsive"
                  />
                </div>

                {/* {errorMessage && (
                  <span
                    style={{
                      color: "red",
                      fontFamily: "Ubuntu",
                      fontSize: "12px",
                    }}
                  >
                    {errorMessage}
                  </span>
                )} */}
                <h1 className="mb-4">Enter OTP</h1>
                <OTPInput
                  value={registerOTPData.otp}
                  onChange={(otp) => {
                    setError(false);
                    setRegisterOTPData((prev) => ({
                      ...prev,
                      otp,
                    }));
                  }}
                  autoFocus
                  OTPLength={6}
                  otpType="number"
                  disabled={false}
                  // secure
                />

                {error ? (
                  <span
                    style={{
                      color: "red",
                      fontFamily: "Ubuntu",
                      fontSize: "16px",
                    }}
                  >
                    {errorMessage}
                  </span>
                ) : (
                  ""
                )}
                <button
                  className="mt-5 mb-4 signin-btn"
                  type="submit"
                  onClick={handleVerifyOTP}
                >
                  Verify OTP
                </button>

                {/* Optionally add a "Resend OTP" button here if needed */}
              </div>
            </div>
            <div className="overlay-container">
              <div className="overlay">
                <div className="overlay-panel overlay-right">
                  <h1>Hello, Friend!</h1>
                  <p>Sign in to your Account and Explore Get Your Products</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterOTP;
