import { BaseUrl } from "../../BaseUrl/BaseUrl";
import {
  getApiWithAuthorization,
  postApiWithoutAuthorization,
} from "../ApiMethod/ApiMethod";

export const LoginUser = async (params) => {
  const url = `${BaseUrl}/User_Login`;
  const res = await postApiWithoutAuthorization(url, params);
  return res;
};
export const SendOTP = async (params) => {
  const url = `${BaseUrl}/SendOTP`;
  const res = await postApiWithoutAuthorization(url, params);
  return res;
};

export const LoginUserVerifyOTP = async (email, OTP) => {
  const url = `${BaseUrl}/User_Login?OTP=${OTP}&EMAIL=${email}`;
  const res = await getApiWithAuthorization(url);
  return res;
};
export const VerifyOTP = async (OTP, mobile) => {
  const url = `${BaseUrl}/VerifyOTP?OTP=${OTP}&MOBILE_NUMBER=${mobile}`;
  const res = await getApiWithAuthorization(url);
  return res;
};
