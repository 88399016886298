import { BaseUrl } from "../../BaseUrl/BaseUrl";
import {
  getApiWithAuthorization,
  postApiWithoutAuthorization,
} from "../ApiMethod/ApiMethod";

export const RegisterUserAPI = async (params) => {
  const url = `${BaseUrl}/User_Login`;
  const res = await postApiWithoutAuthorization(url, params);
  return res;
};

export const RegisterUserVerifyOTP = async (email, OTP) => {
  const url = `${BaseUrl}/User_Login?OTP=${OTP}&EMAIL=${email}`;
  const res = await getApiWithAuthorization(url);
  return res;
};
