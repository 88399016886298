import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { BaseUrl } from "../../BaseUrl/BaseUrl";
import { Tooltip } from "@mui/material";
import { ServiceDiscounts } from "../../Components/enum";
// import { Base_Url } from "../baseurl/Base_Url";

// import OrderDetails from "./OrderDetails";
const MyPanditBookingOrders = () => {
  const userID = JSON.parse(localStorage.getItem("userID"));
  const [orders, setOrders] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    axios
      .post(`${BaseUrl}/Get_AllMyOrder?USER_ID=${userID}&TYPE=Pandit `, {
        START: 1,
        END: 10,
        WORD: "None",
      })
      .then((response) => {
        console.log("Response...", response.data.DATA);
        setOrders(response.data.DATA);
      });
  }, []);

  const SGST = 9;
  const CGST = 9;
  // const Tax = Math.round(((SGST + CGST) / 100) * Number(order.CONVENIENCE_FEE));

  const orderDetails = (myorders) => {
    navigate("/pandit-Booking-order-details", { state: { order: myorders } });
  };

  function formatToIndianCurrency(amount) {
    if (!amount) return "";

    const [integerPart, decimalPart] = amount.toString().split(".");

    // Format the integer part using Indian numbering system
    const formattedIntegerPart = integerPart.replace(
      // Match groups of 3 digits initially, then groups of 2 digits
      /(\d)(?=(\d\d)+\d$)/g,
      "$1,"
    );

    // Return the formatted value with the decimal part (if present)
    return decimalPart !== undefined
      ? `${formattedIntegerPart}.${decimalPart}`
      : formattedIntegerPart;
  }

  console.log("orders", orders);

  const CalculateGrandTotal = (
    price,
    convenienceFee,
    needSamagri,
    samagriPrice,
    discount
  ) => {
    return (
      totalPrice(price, convenienceFee, needSamagri, samagriPrice, discount) +
      Math.round(
        (ServiceDiscounts.panditBookingDiscount / 100) *
          Number(
            totalPrice(
              price,
              convenienceFee,
              needSamagri,
              samagriPrice,
              discount
            )
          )
      )
    );
  };

  const totalPrice = (
    price,
    convenienceFee,
    needSamagri,
    samagriPrice,
    discount
  ) => {
    return (
      Number(price) +
      Number(convenienceFee) +
      (needSamagri === "yes" ? Number(samagriPrice) : 0) -
      discount
    );
  };

  return (
    <div className="p-3 m-4 my-4">
      {orders.map((order) => (
        <div
          key={order.id}
          className="card mb-3  border-0 shadow-lg p-3 hover-zoom-out"
          onClick={() => orderDetails(order)}
        >
          <div className="d-flex align-items-center justify-content-between">
            {/* Order ID */}
            <p className="fw-bold mb-0 me-3">#{order.PB_ID}</p>

            {/* Product Name (Truncated if too long) */}
            <h6
              className=" mb-0 text-truncate flex-grow-1"
              style={{
                maxWidth: "250px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                fontWeight: "500",
              }}
              title={order.PUJA_NAME} // Shows full name on hover
            >
              {order.PUJA_NAME}
            </h6>

            {/* Product Price */}
            <p className=" mb-0 me-3" style={{ fontWeight: "500" }}>
              ₹{" "}
              {formatToIndianCurrency(
                CalculateGrandTotal(
                  order.PB_PRICE,
                  order.CONVENIENCE_FEE,
                  order.PB_SAMAGRI,
                  order.PB_SAMAGRIPRICE,
                  order.DiscountAmount
                )
              )}
            </p>
            <p className=" mb-0 me-3" style={{ fontWeight: "500" }}>
              {order?.PAYMENT_STATUS}
            </p>
            {/* Order Date */}
            <p
              className=" mb-0"
              // style={{ color: order.STATUS === "Pending" ? "red" : "green", minWidth: "100px", textAlign: "right", fontWeight: '500' }}
            >
              {order.REG_DATE}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default MyPanditBookingOrders;
