// TokenAuthorization.js
import axios from "axios";
const USERNAME = "638977"; // Replace with actual username
const PASSWORD = "8229775790bf977258b531f0e7d92b5d746b6aee"; // Replace with actual password
// POST API with Authorization
export const postApiWithAuthorization = async (url, data) => {
    try {
        const token = localStorage.getItem("token"); // Get token from Redux state
        const authString = `Basic ${btoa(`${USERNAME}:${PASSWORD}`)}`;
        const response = await axios.post(url, data, {
            headers: {
                Authorization: authString,
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        });
        return response.data; // Return response data directly
    } catch (error) {
        // handleAuthorizationError(error);
    }
};

// POST API without Authorization
export const postApiWithoutAuthorization = async (url, data) => {
    try {
        const response = await axios.post(url, data);
        return response.data; // Return response data directly
    } catch (error) {
        throw error; // Handle error in calling function
    }
};

// GET API with Authorization
export const getApiWithAuthorization = async (url) => {
    try {
        const token = localStorage.getItem("token"); // Get token from Redux state
        const response = await axios.get(url, {
            headers: {
                Authorization: `Bearer ${token}`, // Attach token dynamically
            },
        });
        return response.data; // Return response data directly
    } catch (error) {
        // handleAuthorizationError(error);
    }
};

// GET API without Authorization
export const getApiWithoutAuthorization = async (url) => {
    try {
        const response = await axios.get(url);
        return response.data; // Return response data directly
    } catch (error) {
        throw error; // Handle error in calling function
    }
};