import React from "react";
import OwlCarousel from "react-owl-carousel3";
import banner1 from "../../assets/images/new/banner1 (1).jpg";
import SliderImage1 from "../../assets/images/new/2.png";
import SliderImage2 from "../../assets/images/new/2.png";
import banner2 from "../../assets/images/new/banner1 (2).jpg";
import { Link } from "react-router-dom";

const MainSlider = () => {
  const options = {
    loop: true,
    animateOut: "fadeOut",
    animateIn: "fadeIn",
    items: 1,
    autoplay: true,
    autoplayTimeout: 7000,
    smartSpeed: 1000,
    nav: false,
    dots: true,
    margin: 0,
  };

  console.log("Banner Image Path:", banner1);

  return (
    <section className="main-slider-two">
      <OwlCarousel className="main-slider-two__carousel" {...options}>
        <div className="item">
          <div className="main-slider-two__item">
            <div
              className="main-slider-two__bg"
              style={{
                backgroundImage: `url("${banner1}")`,
              }}
            ></div>

            <div className="main-slider-two__shape-two">
              <img src={SliderImage1} alt="MyOm Namo" />
            </div>
            <div className="main-slider-two__overlay"></div>
            <div className="container">
              <div className="main-slider-two__content">
                <h2 className="main-slider-two__title">
                  <span className="main-slider-two__title__text">
                    Complete <span>Spiritual</span> <br /> Services All in One
                    Place
                  </span>
                </h2>
                <p className="main-slider-two__text">
                  Book pandit's, puja's, horoscope's, and temple visits online.
                  <br /> Explore our spiritual services and e-store today.
                </p>
                <div
                  className="main-slider-two__btn"
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    marginTop: "15px",
                  }}
                >
                  <Link
                    to="/services/puja-booking"
                    state={{ key: 0 }}
                    className="careox-btn"
                    style={{
                      display: "inline-block",
                      width: "auto",
                      padding: "8px 16px",
                    }}
                  >
                    <span>Book a Puja</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="item">
          <div className="main-slider-two__item">
            <div
              className="main-slider-two__bg"
              style={{
                backgroundImage: `url("${banner2}")`,
              }}
            ></div>
            <div className="main-slider-two__shape-one">
              <img src={SliderImage2} alt="MyOm Namo" />
            </div>
            <div className="main-slider-two__overlay"></div>
            <div className="container">
              <div className="main-slider-two__content">
                <h2 className="main-slider-two__title">
                  <span className="main-slider-two__title__text">
                    Improve Your <span>Spiritual</span>
                    <br /> Journey
                  </span>
                </h2>
                <p className="main-slider-two__text">
                  Reduce your spiritual journey with easy online bookings and a
                  wide range of services.
                  <br /> Visit our e-store for spiritual essentials.
                </p>
                <div
                  className="main-slider-two__btn"
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    marginTop: "15px",
                  }}
                >
                  <Link
                    to="/services/e-store"
                    state={{ key: 2 }}
                    className="careox-btn"
                    style={{
                      display: "inline-block",
                      width: "auto",
                      padding: "8px 16px",
                    }}
                  >
                    <span>Our services</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </OwlCarousel>
    </section>
  );
};

export default MainSlider;
