import React, { useEffect, useState } from "react";
import { DarshanBook_TempleList } from "../../ApiServices/Darshan Booking/DarshanBookingApi";
import { Link } from "react-router-dom";
import Batuk from "../../assets/images/new/Batuk2-removebg-preview.png";

const DarshanBookingList = () => {
  const [darshanBookingList, setDarshanBookingList] = useState([]);
  const [searchKeyWord, setSearchKeyWord] = useState("");
  useEffect(() => {
    DarshanBookingTempleListData();
  }, []);

  // Filter temples based on search input
  const filteredTemples = darshanBookingList?.filter((temple) =>
    searchKeyWord
      ? temple?.TR_NAME?.toLowerCase().includes(searchKeyWord.toLowerCase())
      : true
  );
  const DarshanBookingTempleListData = async () => {
    const userID = localStorage.getItem("userID") || "";

    const params = {
      START: 0,
      END: 10,
      WORD: "None",
      LATITUDE: "0", // Set to 0 or remove if API allows it
      LONGITUDE: "0", // Set to 0 or remove if API allows it
    };

    const response = await DarshanBook_TempleList(params, userID);

    if (response.ResponseCode === "0" && response.ResponseMessage === "list") {
      const blogs = response?.DATA;
      console.log(blogs, "Fetched temple list without location");

      setDarshanBookingList(blogs);
    }
  };

  return (
    <div
      style={{ display: "flex", flexDirection: "column" }}
      className="container mt-4"
    >
      {/* Search Bar */}
      <div className="row mb-4 ">
        <div className="col-md-6 mx-auto">
          <div className="input-group">
            <input
              type="text"
              className="form-control border-primary"
              placeholder="Search Temple Darshan"
              value={searchKeyWord}
              onChange={(e) => setSearchKeyWord(e.target.value)}
            />
            <span
              className="input-group-text text-white"
              style={{ backgroundColor: "#a52444" }}
            >
              <i className="fa fa-search"></i>
            </span>
          </div>
        </div>
      </div>

      {/* Puja List Heading */}
      <h4
        className="text-center mb-4 fw-bold"
        style={{ fontFamily: "Poppins" }}
      >
        Available Darshan Booking
      </h4>

      {/* Puja Services List */}
      <div className="row">
        {filteredTemples.length > 0 ? (
          filteredTemples.map((temple) => (
            <div
              key={temple.TR_ID}
              className="col-lg-4 col-md-6 col-sm-6 mb-4 card"
            >
              <Link to={`/temple/${temple.TR_ID}`}>
                <div className="card shadow-sm border-0">
                  <img
                    src={temple.TR_IMAGE}
                    alt={temple.TR_NAME}
                    className="card-img-top"
                    style={{ height: "200px", objectFit: "contain" }}
                  />
                  <div className="card-body">
                    <h5 className="card-title" style={{ color: "#efb423" }}>
                      {temple.TR_NAME}
                    </h5>
                    <p className="card-text">
                      <strong className="text-success">📍</strong>
                      <strong> Location:</strong> {temple.TR_ADDRESS}
                    </p>
                    {/* <p className="card-text mb-3">
                  <span role="img" aria-label="price">💰</span>
                  <strong> Darshan Price:</strong> ₹{temple.DARSHAN_PRICE}
                </p> */}
                    <p className="card-text">
                      Darshan Price:{" "}
                      <strong className="text-success">
                        ₹ {temple.DARSHAN_PRICE}
                      </strong>
                      {/* <span className="text-muted ms-2">
                      <s>Rs {puja.DP_PRICE}</s>
                    </span> */}
                    </p>
                    <div className="text-end">
                      <Link
                        to={`/temple/${temple.TR_ID}`}
                        className="btn btn-sm mt-1"
                        style={{
                          color: "#a52444",
                          fontWeight: "500",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        View Details
                        <i className="icon-right-arrow"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          ))
        ) : (
          <div className="d-flex flex-column align-items-center justify-content-center vh-100 text-center">
            <img
              src={Batuk}
              alt="No Services"
              className="img-fluid "
              style={{ maxWidth: "200px" }}
            />
            <h5 className="fw-bold">No Temple available for Darshan</h5>
            <p className="text-muted">Please explore our other categories.</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default DarshanBookingList;
