import React from "react";
import { Link } from "react-router-dom";
import PanditBookingImg from "../../assets/images/new/Pandit.png";
import eStoreImg from "../../assets/images/new/E-store.png";
import DailyPandit from "../../assets/images/new/Pooja.png";
import darshanImg from "../../assets/images/new/Group 1000004477.png";
import worshipImg from "../../assets/images/new/Temple.png";

const ServiceList = () => {
  const services = [
    {
      title: "Puja Booking",
      route: "/my-order-pandit-Booking",
      img: PanditBookingImg,
    },
    { title: "E-Store", route: "/my-order", img: eStoreImg },
    {
      title: "Daily Puja",
      route: "/my-order-daily-Booking",
      img: DailyPandit,
    },
    {
      title: "Darshan Booking",
      route: "/my-order-darshan-Booking",
      img: darshanImg,
    },
    {
      title: "Puja at Temple",
      route: "/my-order-puja-At-Temple",
      img: worshipImg,
    },
  ];

  return (
    <div className="container mt-4">
      <div className="row">
        {services.map((service, index) => (
          <div key={index} className="col-md-4 mb-4">
            <div className="card text-center shadow-sm">
              <div className="card-body">
                <img
                  src={service.img}
                  alt={service.title}
                  width={80}
                  height={80}
                />
                {/* <i className={`${service.icon} display-4 text-primary`}></i> */}
                <h5 className="card-title mt-3">{service.title}</h5>
                <Link
                  to={service.route}
                  style={{ background: "#8f3246" }}
                  className="btn text-white mt-2"
                >
                  My Orders
                </Link>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ServiceList;
