import { BaseUrl } from "../../BaseUrl/BaseUrl";
import { postApiWithAuthorization } from "../ApiMethod/ApiMethod";

export const GetPanditListDataAPI = async (user_id, puja_id,langID, params) => {
  const url = `${BaseUrl}/GetPanditList?TYPE=Pandit&USER_ID=${user_id}&PUJA_ID=${puja_id}&LANG_ID=${langID}&Pincode=`;


  const res = await postApiWithAuthorization(url, params);
  return res;
};
