import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BaseUrl } from "../../BaseUrl/BaseUrl";
import axios from "axios";

const DarshanBookingOrderList = () => {
  const userID = JSON.parse(localStorage.getItem("userID"));
  const [orders, setOrders] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .post(`${BaseUrl}/Get_AllMyOrder?USER_ID=${userID}&TYPE=Darshan `, {
        START: 0,
        END: 100,
        WORD: "None",
      })
      .then((response) => {
        console.log("Response...", response.data.DATA);
        setOrders(response.data.DATA);
      });
  }, []);

  const orderDetails = (myorders) => {
    navigate("/darshan-order-details", { state: myorders });
  };
  return (
    <div className="p-3 m-4 my-4">
      {orders.map((order) => (
        <div
          key={order.id}
          className="card mb-3  border-0 shadow-lg p-3 hover-zoom-out"
          onClick={() => orderDetails(order)}
        >
          <div className="d-flex align-items-center justify-content-between">
            {/* Order ID */}
            <p className="fw-bold mb-0 me-3"> # {order.TRB_ID}</p>

            {/* Product Name (Truncated if too long) */}
            <h6
              className=" mb-0 text-truncate flex-grow-1"
              style={{
                maxWidth: "250px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                fontWeight: "500",
              }}
              title={order.TR_NAME} // Shows full name on hover
            >
              {order.TR_NAME}
            </h6>

            {/* Product Price */}
            <p className=" mb-0 me-3" style={{ fontWeight: "500" }}>
              ₹ {order?.TRB_TOTALPRICE}
            </p>
            <p className=" mb-0 me-3" style={{ fontWeight: "500" }}>
              {" "}
              Payment - {order?.PAYMENT_STATUS}
            </p>
            <p className=" mb-0 me-3" style={{ fontWeight: "500" }}>
              {" "}
              Darshan - {order?.PAYMENT_STATUS}
            </p>

            {/* Order Date */}
            <p
              className=" mb-0"
              // style={{ color: order.STATUS === "Pending" ? "red" : "green", minWidth: "100px", textAlign: "right", fontWeight: '500' }}
            >
              {order.TRB_DATE}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default DarshanBookingOrderList;
