import React from 'react'
import { Link } from 'react-router-dom';

import PanditBookingImg from '../../../assets/images/myOrder/panditbooking.png'
import eStoreImg from '../../../assets/images/myOrder/estore.png'
import DailyPandit from '../../../assets/images/myOrder/dailypandit.png'
import darshanImg from '../../../assets/images/myOrder/temple.png'

const AstrologyList = () => {

  const HoroscopeServices = [
    { title: "Basic Detail", route: "/basic-details", img: PanditBookingImg },
    { title: "Biorhythm", route: null, img: eStoreImg },
    { title: "Daily Prediction", route: "/daily-prediction", img: DailyPandit },
    { title: "Horoscope Chart", route: null, img: darshanImg },
  ];
  return (
    <div className="container mt-4">
      <div className="row">
        {HoroscopeServices.map((service, index) => (
          <div key={index} className="col-md-4 mb-4">
            <div className="card text-center shadow-sm">
              <div className="card-body">
                <img src={service.img} alt={service.title} width={80} height={80} />
                {/* <i className={`${service.icon} display-4 text-primary`}></i> */}
                <h5 className="card-title mt-3">{service.title}</h5>
                {service.route !== null ? (
                  <Link to={service.route} style={{ background: '#8f3246' }} className="btn text-white mt-2">
                    Check
                  </Link>
                ) : <button disabled style={{ background: '#8f3246' }} className="btn text-white mt-2">Coming Soon</button>}

              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default AstrologyList