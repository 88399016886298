import React from "react";
import { Helmet } from "react-helmet-async";

const PrivacyPolicyPage = () => {
  return (
    <>
      <Helmet>
        <title>Privacy Policy - MyOmNamo - Spiritual Services Platform</title>
        <meta
          name="description"
          content="Read our Privacy Policy to understand how we protect your data. Learn about information collection, usage, and your rights as a user."
        />

        {/* Canonical Tag */}
        <link rel="canonical" href="https://www.myomnamo.com/privacy-policy" />

        {/* Open Graph Meta Tags */}
        <meta
          property="og:title"
          content="Privacy Policy - MyOmNamo - Spiritual Services Platform"
        />
        <meta
          property="og:description"
          content="Read our Privacy Policy to understand how we protect your data. Learn about information collection, usage, and your rights as a user."
        />
        <meta
          property="og:image"
          content="https://www.myomnamo.com/assets/images/new/logo.png"
        />
        <meta
          property="og:url"
          content="https://www.myomnamo.com/privacy-policy"
        />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="MyOmNamo" />
        <meta property="og:locale" content="en_IN" />

        {/* Twitter Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Privacy Policy - MyOmNamo - Spiritual Services Platform"
        />
        <meta
          name="twitter:description"
          content="Read our Privacy Policy to understand how we protect your data. Learn about information collection, usage, and your rights as a user."
        />
        <meta
          name="twitter:image"
          content="https://www.myomnamo.com/assets/images/new/logo.png"
        />

        {/* Schema Markup - Breadcrumb List */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org/",
            "@type": "BreadcrumbList",
            itemListElement: [
              {
                "@type": "ListItem",
                position: 1,
                name: "Home",
                item: "https://www.myomnamo.com/",
              },
              {
                "@type": "ListItem",
                position: 2,
                name: "Privacy Policy",
                item: "https://www.myomnamo.com/privacy-policy",
              },
            ],
          })}
        </script>

        {/* Schema Markup - WebPage */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "http://schema.org",
            "@type": "WebPage",
            name: "Privacy Policy - MyOmNamo - Spiritual Services Platform",
            url: "https://www.myomnamo.com/privacy-policy",
            description:
              "Read our Privacy Policy to understand how we protect your data. Learn about information collection, usage, and your rights as a user.",
            publisher: {
              "@type": "Organization",
              name: "MyOmNamo",
            },
          })}
        </script>
      </Helmet>
      <section>
        <div
          className="container mx-auto p-6 mt-3"
          style={{ display: "flex", flexDirection: "column" }}
        >
          <h3 className="text-2xl font-bold mb-4">
            Privacy Policy for MyOmNamo
          </h3>
          <p>
            Welcome to MyOmNamo. Your privacy is important to us. This Privacy
            Policy explains how we collect, use, and protect your personal
            information when you use our website (
            <a href="https://www.myomnamo.com" className="text-blue-500">
              www.myomnamo.com
            </a>
            ) and our spiritual services.
          </p>

          <h4 className="text-xl font-semibold mt-6">
            1. Information We Collect
          </h4>
          <p>
            We collect personal and non-personal information when you use our
            website and services.
          </p>

          <h5 className="font-medium mt-4">Personal Information:</h5>
          <ul className="list-disc pl-6">
            <li>Name</li>
            <li>Contact details (email, phone number, address)</li>
            <li>Payment details (processed through secure gateways)</li>
            <li>Date of birth (for horoscope services)</li>
            <li>Preferences and requirements for bookings</li>
          </ul>

          <h5 className="font-medium mt-4">Non-Personal Information:</h5>
          <ul className="list-disc pl-6">
            <li>Browser type and version</li>
            <li>Device and operating system</li>
            <li>IP address</li>
            <li>Pages visited and time spent on the website</li>
            <li>Cookies and tracking data</li>
          </ul>

          <h4 className="text-xl font-semibold mt-6">
            2. How We Use Your Information
          </h4>
          <ul className="list-disc pl-6">
            <li>Processing puja, darshan, and horoscope bookings</li>
            <li>Delivering e-store purchases</li>
            <li>Personalizing recommendations based on your preferences</li>
            <li>Sending booking confirmations, reminders, and updates</li>
            <li>Responding to inquiries and providing customer support</li>
            <li>Improving website functionality and user experience</li>
            <li>Complying with legal and regulatory requirements</li>
          </ul>

          <h4 className="text-xl font-semibold mt-6">
            3. How We Share Your Information
          </h4>
          <ul className="list-disc pl-6">
            <li>
              <strong>Service Providers:</strong> We share necessary details
              with pandits, astrologers, and logistics providers to fulfill your
              bookings and orders.
            </li>
            <li>
              <strong>Payment Processors:</strong> Your payment information is
              securely processed by third-party payment gateways.
            </li>
            <li>
              <strong>Legal Compliance:</strong> If required by law, we may
              disclose your information.
            </li>
            <li>
              <strong>Business Transfers:</strong> In case of a merger or sale,
              your data may be shared under the same privacy standards.
            </li>
          </ul>

          <h4 className="text-xl font-semibold mt-6">4. Data Security</h4>
          <p>
            We take appropriate security measures to protect your personal
            information:
          </p>
          <ul className="list-disc pl-6">
            <li>Secure payment gateways with encryption</li>
            <li>Access controls and authentication for data protection</li>
            <li>Regular monitoring of our systems for vulnerabilities</li>
          </ul>
          <p>
            However, no method of transmission over the internet is 100% secure.
          </p>

          <h4 className="text-xl font-semibold mt-6">
            5. Cookies and Tracking Technologies
          </h4>
          <p>
            We use cookies and tracking technologies to improve user experience:
          </p>
          <ul className="list-disc pl-6">
            <li>Recognizing returning users</li>
            <li>Storing user preferences</li>
            <li>Analyzing website traffic</li>
          </ul>
          <p>
            You can disable cookies through your browser settings, but this may
            affect functionality.
          </p>

          <h4 className="text-xl font-semibold mt-6">
            6. Your Rights and Choices
          </h4>
          <p>You have the right to:</p>
          <ul className="list-disc pl-6">
            <li>
              <strong>Access Your Data:</strong> Request a copy of your personal
              information.
            </li>
            <li>
              <strong>Update or Correct Data:</strong> Modify incorrect or
              outdated information.
            </li>
            <li>
              <strong>Opt-Out of Marketing:</strong> Unsubscribe from
              promotional emails.
            </li>
            <li>
              <strong>Request Data Deletion:</strong> Ask for your data to be
              removed (subject to legal constraints).
            </li>
          </ul>
          <p>
            To exercise these rights, contact us at{" "}
            <a href="mailto:info@myomnamo.com" className="text-blue-500">
              info@myomnamo.com
            </a>
            .
          </p>

          <h4 className="text-xl font-semibold mt-6">7. Third-Party Links</h4>
          <p>
            Our website may contain links to third-party websites. We are not
            responsible for their privacy policies.
          </p>

          <h4 className="text-xl font-semibold mt-6">8. Children’s Privacy</h4>
          <p>
            Our services are for users above 18. If a child provides
            information, contact us for immediate removal.
          </p>

          <h4 className="text-xl font-semibold mt-6">
            9. Changes to This Privacy Policy
          </h4>
          <p>
            We may update this Privacy Policy. Changes will be posted on this
            page.
          </p>

          <h4 className="text-xl font-semibold mt-6">10. Contact Us</h4>
          <p>If you have any questions, contact us:</p>
          <p>
            <strong>Email:</strong>{" "}
            <a href="mailto:info@myomnamo.com" className="text-blue-500">
              info@myomnamo.com
            </a>
          </p>
          <p>
            <strong>Phone:</strong> +91 8879222277
          </p>

          <p className="mt-6">
            By using MyOmNamo, you acknowledge that you have read and understood
            this Privacy Policy.
          </p>

          <p className="font-semibold mt-6">
            Thank you for trusting MyOmNamo for your spiritual journey.
          </p>
        </div>
      </section>
    </>
  );
};

export default PrivacyPolicyPage;
