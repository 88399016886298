import React, { useEffect, useState } from "react";
import { DailyPanditBookingAPI } from "../../ApiServices/DailyPanditAPI/DailyPanditApi";
import { Link, useNavigate } from "react-router-dom";
import placeholderImg from "../../assets/images/new/Batuk2-removebg-preview.png";
import Batuk from "../../assets/images/new/Batuk2.png";

const DailyBooking = () => {
  const user_id = localStorage.getItem("userID");
  const [searchKeyWord, setSearchKeyWord] = useState("");
  const [dailyPanditPujaListData, setDailyPanditPujaListData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    GetMyBookPanditPujaListData();
  }, []);

  const GetMyBookPanditPujaListData = async () => {
    try {
      const response = await DailyPanditBookingAPI(user_id, {
        START: 1,
        END: 10,
        WORD: "None",
        LATITUDE: "20.008581",
        LONGITUDE: "73.76413099999999",
      });

      if (
        response.ResponseCode === "0" &&
        response.ResponseMessage === "list"
      ) {
        setDailyPanditPujaListData(response.DATA);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const viewDetail = (puja) => {
    console.log("Navigating to details with puja:", puja);
    navigate("/services/daily-booking/puja-details", {
      state: { puja: puja },
    });
  };

  return (
    <div
      className="container mt-4"
      style={{ display: "flex", flexDirection: "column" }}
    >
      {/* Search Bar */}
      <div className="row mb-4">
        <div className="col-md-6 mx-auto">
          <div className="input-group">
            <input
              type="text"
              className="form-control border-primary"
              placeholder="Search Puja"
              value={searchKeyWord}
              onChange={(e) => setSearchKeyWord(e.target.value)}
            />
            <span
              className="input-group-text text-white"
              style={{ backgroundColor: "#a52444" }}
            >
              <i className="fa fa-search"></i>
            </span>
          </div>
        </div>
      </div>

      {/* Puja List Heading */}
      {/* <h4 className="text-center mb-4" style={{ fontFamily: "Poppins" }}>
        Daily Puja Services
      </h4> */}

      {/* Puja Cards */}
      <div className="row">
        {dailyPanditPujaListData?.length !== 0 ? (
          dailyPanditPujaListData
            .filter(
              (puja) =>
                !searchKeyWord ||
                puja?.DP_NAME?.toLowerCase().includes(
                  searchKeyWord.toLowerCase()
                )
            )
            .map((puja, index) => (
              <div
                className="col-lg-4 col-md-6 col-sm-6 mb-4"
                key={index}
                onClick={() => viewDetail(puja)}
              >
                <div className="card shadow-sm border-0">
                  <img
                    src={puja.DP_IMAGE}
                    className="card-img-top"
                    alt={puja.DP_NAME}
                    onError={(e) => {
                      e.target.onerror = null; // Prevent infinite loop
                      e.target.src = placeholderImg; // Set fallback image
                    }}
                    style={{ height: "200px", objectFit: "fill" }}
                  />
                  <div className="card-body">
                    <h5 className="card-title" style={{ color: "#a52444" }}>
                      {puja.DP_NAME}
                    </h5>
                    <p className="card-text">
                      <strong
                        style={{
                          color: "black",
                          fontSize: "16px",
                          fontWeight: "500",
                        }}
                      >
                        ₹ {puja.DP_PRICE - puja.DiscountAmount}/ Day
                      </strong>
                      {/* <span className="text-muted ms-2">
                      <s>Rs {puja.DP_PRICE}</s>
                    </span> */}
                    </p>
                    {/* <span className="badge bg-danger">{puja.DP_DISC}% Off</span> */}
                    <div className="mt-3 text-end">
                      <Link
                        to="/puja-details"
                        // className="btn btn-sm"
                        style={{
                          // backgroundColor: "#a52444",
                          color: "#a52444",
                          fontWeight: "500",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: "5px",
                        }}
                      >
                        View Details <i className="icon-right-arrow"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            ))
        ) : (
          <div className="d-flex flex-column align-items-center justify-content-center vh-90 text-center mt-4">
            <img
              src={Batuk}
              alt="Empty Cart"
              className="img-fluid mb-3"
              style={{ maxWidth: "200px" }}
            />
            <h5 className="fw-bold">No services available for this catagory</h5>
            <p className="text-muted">Please explore our other categories.</p>
            {/* <Link to="/my-store">
                  <button
                    style={{ background: "#a52444" }}
                    className="text-white btn me-2"
                  >
                    Add Products
                  </button>
                </Link> */}
          </div>
        )}
      </div>
    </div>
  );
};

export default DailyBooking;
