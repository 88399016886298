import React, { useState } from 'react'
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

const PredictionModal = ({ show, onHide, dailyPredictionList, predictionTitle }) => {
    const [headings, setHeadings] = useState(['Emotions', 'Health', 'Profession', 'Luck', 'Personal_Life', 'Travel'])
    return (
        <>
            <Modal
                show={show}
                onHide={onHide}
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Header
                    closeButton
                    style={{
                        backgroundColor: "#A52444",
                        color: "white",
                    }}
                >
                    <Modal.Title style={{ fontSize: "25px", fontWeight: "bold" }}>
                        Daily Prediction - {predictionTitle}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body
                    style={{
                        backgroundColor: "#f2eeee",
                        color: "Black",
                        display: "flex",
                        // justifyContent: "center",
                        alignItems: "center",
                        textAlign: "center",
                        maxHeight: "20em", // Ensures proper vertical centering
                        flexDirection: 'column',
                        overflow: 'auto'
                    }}
                >
                    {Object.entries(dailyPredictionList || {}).map(([key, value], index) => (
                        <>
                            <div>
                                <h4>{headings[index]}</h4>
                                <p style={{ textAlign: 'justify' }}>{value}</p>
                            </div>
                            <hr></hr>
                        </>

                    ))}

                </Modal.Body>
                <Modal.Footer style={{ backgroundColor: "#f2eeee" }}>
                    <Button
                        variant="secondary"
                        onClick={onHide}
                        style={{ backgroundColor: "#A52444", border: "none" }}
                    >
                        Close
                    </Button>

                </Modal.Footer>
            </Modal>
        </>
    )
}

export default PredictionModal
