import { BaseUrl } from "../../BaseUrl/BaseUrl";
import {
    getApiWithAuthorization,
    postApiWithoutAuthorization,
} from "../ApiMethod/ApiMethod";

export const GetDurationDays = async (DurationID) => {
    const url = `${BaseUrl}/GetDurationDays?DurationID=${DurationID}`;
    const res = await getApiWithAuthorization(url);
    return res;
};

