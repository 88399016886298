import React, { useContext, useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel3";
import blogImg1 from "../../assets/images/new/3.jpg";
import blogImg2 from "../../assets/images/new/7.jpg";
import blogImg3 from "../../assets/images/new/2.jpg";
import { AuthContextProvider } from "../../AuthContext/AuthContext";
import { CurrentBlogData } from "../../ApiServices/BlogApi/BlogAPI";
import { Link, useNavigate } from "react-router-dom";
import batuk from "../../assets/images/new/fav-removebg-preview.png";

const BlogCarousel = () => {
  const [blogList, setblogList] = useState([]);
  const { setLoader } = useContext(AuthContextProvider);
  const navigate = useNavigate();

  const stripHtml = (html) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;
    return tempDiv.innerText || tempDiv.textContent || "";
  };

  const options = {
    loop: blogList.length > 3,
    rewind: true, // Enable rewind instead of looping
    margin: 30,
    nav: true,
    dots: false,
    autoplay: true,
    autoplayTimeout: 3000,
    smartSpeed: 1000,
    navText: [
      "<i class='fas fa-arrow-left'></i>",
      "<i class='fas fa-arrow-right'></i>",
    ],
    responsive: {
      0: {
        items: 1, // 1 item on small screens
      },
      600: {
        items: 2, // 2 items on medium screens
      },
      1000: {
        items: 3, // 3 items on large screens
      },
    },
  };

  useEffect(() => {
    GetCurrentBlog({
      START: 0,
      END: 100,
      WORD: "",
      EXTRA1: "",
      EXTRA2: "",
      EXTRA3: "",
      LANG_ID: "1",
      USER_ID: localStorage.getItem("userID"),
    });
  }, []);

  const GetCurrentBlog = async (params) => {
    // debugger;
    setLoader(true);

    const response = await CurrentBlogData(params);

    if (response.ResponseCode === "0" && response.ResponseMessage === "list") {
      const blogs = response?.DATA.filter((item) => item && item.BLOG_IMAGE); // Ensure no empty items
      setblogList(blogs);
    }
  };

  const blogPosts = [
    {
      id: 1,
      title: "What's new in 2022 Tech",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Sequi perferendis molestiae non nemo doloribus.",
      image: blogImg1,
      category: "Technology",
      author: "Jane Doe",
      avatar: "https://i.pravatar.cc/40?img=1",
      time: "2h ago",
    },
    {
      id: 2,
      title: "AI and the Future",
      description:
        "Artificial Intelligence is evolving rapidly. How will it shape our future?",
      image: blogImg2,
      category: "Artificial Intelligence",
      author: "John Smith",
      avatar: "https://i.pravatar.cc/40?img=2",
      time: "5h ago",
    },
    {
      id: 2,
      title: "AI and the Future",
      description:
        "Artificial Intelligence is evolving rapidly. How will it shape our future?",
      image: blogImg2,
      category: "Artificial Intelligence",
      author: "John Smith",
      avatar: "https://i.pravatar.cc/40?img=2",
      time: "5h ago",
    },
    {
      id: 2,
      title: "AI and the Future",
      description:
        "Artificial Intelligence is evolving rapidly. How will it shape our future?",
      image: blogImg2,
      category: "Artificial Intelligence",
      author: "John Smith",
      avatar: "https://i.pravatar.cc/40?img=2",
      time: "5h ago",
    },
    {
      id: 2,
      title: "AI and the Future",
      description:
        "Artificial Intelligence is evolving rapidly. How will it shape our future?",
      image: blogImg2,
      category: "Artificial Intelligence",
      author: "John Smith",
      avatar: "https://i.pravatar.cc/40?img=2",
      time: "5h ago",
    },
    {
      id: 2,
      title: "AI and the Future",
      description:
        "Artificial Intelligence is evolving rapidly. How will it shape our future?",
      image: blogImg2,
      category: "Artificial Intelligence",
      author: "John Smith",
      avatar: "https://i.pravatar.cc/40?img=2",
      time: "5h ago",
    },
    {
      id: 2,
      title: "AI and the Future",
      description:
        "Artificial Intelligence is evolving rapidly. How will it shape our future?",
      image: blogImg2,
      category: "Artificial Intelligence",
      author: "John Smith",
      avatar: "https://i.pravatar.cc/40?img=2",
      time: "5h ago",
    },
    {
      id: 2,
      title: "AI and the Future",
      description:
        "Artificial Intelligence is evolving rapidly. How will it shape our future?",
      image: blogImg2,
      category: "Artificial Intelligence",
      author: "John Smith",
      avatar: "https://i.pravatar.cc/40?img=2",
      time: "5h ago",
    },
    {
      id: 2,
      title: "AI and the Future",
      description:
        "Artificial Intelligence is evolving rapidly. How will it shape our future?",
      image: blogImg2,
      category: "Artificial Intelligence",
      author: "John Smith",
      avatar: "https://i.pravatar.cc/40?img=2",
      time: "5h ago",
    },
  ];

  console.log("Filtered blogList:", blogList);
  const handleNavigation = (slug) => {
    navigate(`/spiritual-gyan/${slug}`, { replace: true });
  };
  return (
    <section
      className="blog-section p-5"
      style={{ marginBottom: "50px", backgroundColor: "#f1f6f7" }}
    >
      <div
        className="container"
        style={{ display: "flex", flexDirection: "column" }}
      >
        <div className="row">
          <div className="col-12">
            <div className="section-title text-center">
              {/* <h2>
            {" "}
            <span className="sec-title__tagline__border"></span>Trending Posts
          </h2> */}
              <h6
                className="sec-title__tagline bw-split-in-right"
                style={{ perspective: "400px" }}
              >
                <img className=" tagline-img" src={batuk} alt="" />
                Trending Posts
              </h6>
              <h3
                className="sec-title__title bw-split-in-left"
                style={{ perspective: "400px" }}
              >
                Spiritual Insights & Wisdom
              </h3>
              {/* <p>Stay updated with our latest news and insights</p> */}
            </div>

            <OwlCarousel className="owl-theme" {...options}>
              {/* <div className="blog-container d-flex"> */}
              {/* {blogPosts.map((post) => (
                <div className="card-custom" key={post.id}>
                  <div className="card-custom__header">
                    <img
                      src={post.image}
                      alt={post.title}
                      className="card-custom__image"
                    />
                  </div>
                  <div className="card-custom__body">
                    <h4 className="service-two__item__title">{post.title}</h4>
                    <p>{post.description}</p>
                  </div>
                </div>
              ))} */}
              {/* </div> */}
              {blogList && blogList.length > 0 ? (
                blogList.map((item, index) =>
                  item && item.BLOG_IMAGE ? (
                    <div onClick={() => handleNavigation(item.Blog_Slug)}>
                      <div
                        className="card-custom"
                        key={item.ROW || index}
                        style={{ height: "400px" }}
                      >
                        <div className="card-custom__header">
                          <img
                            src={item.BLOG_IMAGE}
                            alt={item.BLOG_TITLE}
                            style={{ objectFit: "fill" }}
                            className="card-custom__image"
                          />
                        </div>
                        <div className="card-custom__body">
                          <h4 className="service-two__item__title">
                            {item.BLOG_TITLE}
                          </h4>
                          <p className="card-text text-muted small">
                            {stripHtml(item.BLOG).length > 80
                              ? `${stripHtml(item.BLOG).substring(0, 80)}...`
                              : stripHtml(item.BLOG)}
                          </p>
                        </div>
                        <div className="blog-footer text-start">
                          <span
                          // onClick={() =>
                          //   navigate("/spiritual-gyan", {
                          //     state: { blog: item },
                          //   })
                          // }
                          >
                            Read more
                          </span>
                        </div>
                      </div>
                    </div>
                  ) : null
                )
              ) : (
                <p>No blogs available</p>
              )}
            </OwlCarousel>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BlogCarousel;

{
  /* <div class="container">
<div class="card">
  <div class="card__header">
    <img
      src={item.BLOG_IMAGE}
      alt={item.BLOG_TITLE}
      class="card__image"
      width="600"
    />
  </div>
  <div class="card__body">
    <h4>{item.BLOG_TITLE}</h4>
    <p>{item.description}</p>
  </div>
</div>
</div> */
}
