import React from "react";
import AboutImg1 from "../../assets/images/new/banner1 (1).jpg";
import contactImg1 from "../../assets/images/new/whyc.jpg";
import MissionImg from "../../assets/images/new/targeting.png";
import VisionImg from "../../assets/images/new/vision.png";
import contactImg2 from "../../assets/images/new/4.jpg";
import contactImg3 from "../../assets/images/new/2.jpg";
import contactImg4 from "../../assets/images/new/conatus us.png";
import contactImg5 from "../../assets/images/shapes/about-1-shape-2.png";
import contactImg6 from "../../assets/images/shapes/about-1-shape-3.png";
import { ERROR_MESSAGES } from "../../Components/GlobalErrorMessage";
import "./AboutUsPage.css";

const AboutUsPage = () => {
  return (
    <>
      <section className="page-header">
        <div className="page-header__bg"></div>
        <div className="container">
          <h2 className="page-header__title bw-split-in-left">About Us</h2>
        </div>
      </section>
      <section className="about-us py-5">
        <div
          className="container"
          style={{ display: "flex", flexDirection: "column", gap: "50px" }}
        >
          <div className="d-flex" style={{ gap: "20px" }}>
            <div className="col-lg-5">
              <div
                className="about-one__image wow fadeInLeft"
                data-wow-delay="100ms"
              >
                <div className="about-one__image__one">
                  <img
                    src={contactImg1}
                    style={{
                      height: "565px",
                      width: "467px",
                      objectFit: "cover",
                    }}
                    alt="contact"
                  />
                </div>
                <div className="about-one__image__two">
                  <img
                    src={contactImg3}
                    style={{
                      height: "304px",
                      width: "262px",
                      objectFit: "cover",
                    }}
                    alt="contact"
                  />
                </div>
                <div className="about-one__image__shape-one">
                  <img src={contactImg4} alt="shape" />
                </div>
                <div className="about-one__image__shape-two">
                  <img src={contactImg5} alt="shape" />
                </div>
                <div className="about-one__image__shape-three">
                  <img src={contactImg6} alt="shape" />
                </div>
              </div>
            </div>

            {/* Content Section */}
            <div className="col-lg-7">
              <article className="about-content content">
                <h2 className="section-title">Who We Are</h2>
                <p className="text-dark">
                  In today's fast-paced world, where traditions often take a
                  backseat, we at <strong>Om Namo</strong> aim to bring
                  spirituality closer to you. Our mission is to help individuals
                  and businesses seamlessly organize traditional rituals without
                  stress.
                </p>
                <p className="text-dark">
                  <strong>Om Namo</strong> is more than just a service—it’s a
                  bridge between devotion and modern convenience. We connect you
                  with experienced
                  <strong> pandits</strong> who perform rituals with
                  authenticity, ensuring that every ceremony is conducted with
                  the utmost respect and precision.
                </p>

                <h3 className="section-subtitle">Our Mission</h3>
                <p className="text-dark">
                  We strive to make traditional worship **accessible** and
                  **effortless**. Whether you seek personal guidance or
                  corporate pujas, our platform ensures a smooth and meaningful
                  experience.
                </p>

                <h3 className="section-subtitle">
                  Lorem ipsum dolor sit amet.
                </h3>
                <ul className="about-list">
                  <li>
                    <strong>Experienced Pandits:</strong> Handpicked
                    professionals with years of expertise.
                  </li>
                  <li>
                    <strong>Customized Rituals:</strong> Tailored puja packages
                    for every occasion.
                  </li>
                  <li>
                    <strong>Hassle-Free Booking:</strong> Effortless online
                    scheduling and consultation.
                  </li>
                  <li>
                    <strong>Authenticity & Trust:</strong> Rituals performed
                    with devotion and correctness.
                  </li>
                </ul>
              </article>
            </div>

            {/* Image Section */}
            {/* <div className="col-lg-5 text-center">
              <img
                src={AboutImg1}
                alt="About Us"
                className="about-img img-fluid"
              />
            </div> */}
          </div>

          <div className="vm-container">
            <h3
              className=" text-center"
              style={{
                marginBottom: "20px",
                fontSize: "30px",
                fontWeight: "bold",
                color: " #2c3e50",
                marginBottom: "20px",
                textTransform: "uppercase",
                position: "relative",
              }}
            >
              Our Vision and Mission
            </h3>
            <div className="row">
              <div className="col-lg-6">
                <div className="card shadow-sm h-100 d-flex flex-column">
                  <div className="card-body vision-content">
                    <img src={VisionImg} alt="" />
                    <h5 className="card-title text-center">Our Vision</h5>
                    <p className="card-text ">
                      To be a globally recognized leader in our industry,
                      driving innovation, sustainability, and excellence. We
                      aspire to create a future where our solutions enhance
                      lives, empower communities, and foster long-term growth.
                      By embracing cutting-edge technology and ethical business
                      practices, we aim to set new benchmarks in quality and
                      customer satisfaction. Our vision is to inspire change,
                      promote inclusivity, and leave a positive impact on
                      society and the environment.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="card shadow-sm h-100 d-flex flex-column">
                  <div className="card-body vision-content">
                    <img src={MissionImg} alt="" />
                    <h5 className="card-title text-center">Our Mission</h5>
                    <p className="card-text">
                      Our mission is to deliver innovative and high-quality
                      solutions that exceed customer expectations while
                      fostering trust, transparency, and long-term
                      relationships. We are committed to empowering businesses
                      and individuals through continuous improvement,
                      sustainability, and customer-centric strategies. By
                      investing in research, embracing diversity, and promoting
                      ethical practices, we strive to make a meaningful
                      difference. Our goal is to create value, inspire growth,
                      and contribute to a better world.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutUsPage;
