import { useEffect, useState } from "react";
import React from "react";
import axios from "axios";
import { BaseUrl } from "../../BaseUrl/BaseUrl";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Mylogo from "../../assets/images/logo.png";
import dayjs from "dayjs";
import { ServiceDiscounts } from "../../Components/enum";
import placeholderImg from "../../assets/images/new/Batuk2-removebg-preview.png";

const PBOrderDetails = () => {
  const userID = JSON.parse(localStorage.getItem("userID"));
  //   useEffect(() => {
  //     axios
  //       .get(`${BaseUrl}/Estore_order?ORDER_ID=${location.state.ORDER_ID} `)
  //       .then((response) => {
  //         console.log("Response...", response.data.DATA);
  //         setOrders(response.data.DATA);
  //       });
  //   }, []);

  const location = useLocation();
  const navigate = useNavigate();
  const order = location.state.order;

  // const SGST = 9;
  // const CGST = 9;
  // const Tax = Math.round(((SGST + CGST) / 100) * Number(order.CONVENIENCE_FEE));

  const CalculateGrandTotal = (
    price,
    convenienceFee,
    needSamagri,
    samagriPrice,
    discount
  ) => {
    return (
      totalPrice(price, convenienceFee, needSamagri, samagriPrice, discount) +
      Math.round(
        (ServiceDiscounts.panditBookingDiscount / 100) *
        Number(
          totalPrice(
            price,
            convenienceFee,
            needSamagri,
            samagriPrice,
            discount
          )
        )
      )
    );
  };

  const totalPrice = (
    price,
    convenienceFee,
    needSamagri,
    samagriPrice,
    discount
  ) => {
    return (
      Number(price) +
      Number(convenienceFee) +
      (needSamagri === "yes" ? Number(samagriPrice) : 0) -
      discount
    );
  };

  const Remaining_Amount = Math.round(order.RemainingAmount)


  const handleProceedRemainingPayment = async () => {
    // debugger;
    if (Remaining_Amount === 0) {
      alert("All the amount already paid");
      return;
    }
    // let totalAmount = parseInt(modelRequestData.totalVal); // Assuming totalVal includes shipping
    // let minPayableAmount = Remaining_Amount; // 20% of the total amount

    let orderIDReturned = order.PB_ID;
    // axios
    //   .post(`${BaseUrl}/BookPooja`, {
    //     PB_DATE: productBookingData.date,
    //     PB_TIME: productBookingData.time,
    //     PB_LANGAUGE: SelectLanguageOptions.find(
    //       (item) => item.value === productBookingData.languageID
    //     )?.label,
    //     PB_SAMAGRI: productBookingData.needSamagri,
    //     PB_PRICE: pujaDetails?.pujaPrice,
    //     PB_SAMAGRIPRICE: pujaDetails?.pujaSamagriPrice,
    //     PANDIT_ID: selectedPandit,
    //     USER_ID: userID,
    //     REFERENCES_NO: "",
    //     ADDRESS: productBookingData?.address,
    //     PUJA_ID: pujaDetails?.pujaId,
    //     PAYMENT_TYPE: "ONLINE",
    //     CONVINIENCE_FEE: pujaDetails?.convenienceFee,
    //     TAX: null,
    //     EXTRA: null,
    //     TRANSACTION_ID: "",
    //     TRANSACTION_AMT: "",
    //   })
    //   .then((res) => {
    //     if (res) {
    //       console.log(res);
    //       setOrderID(res.data.ID);
    //       // localStorage.setItem("orderID", JSON.stringify(res.data.ID));
    //       orderIDReturned = res.data.ID;
    //     }
    //   });

    const options = {
      key: "rzp_test_vv1FCZvuDRF6lQ",
      key_secret: "P4JAUwn4VdE6xDLJ6p2Zy8RQ",
      amount: Remaining_Amount * 100,
      currency: "INR",
      name: "My Om Namo- WowInfotech",
      description: "working",
      image: Mylogo,
      handler: function (response) {
        // console.log(response, "Payment Success");
        const paymentId = response.razorpay_payment_id;

        if (response) {
          axios
            .post(`${BaseUrl}/UpdatePaymentStatus?TYPE=Pandit`, {
              USER_ID: userID,
              ORDER_ID: orderIDReturned,
              // ORDER_ID: JSON.parse(localStorage.getItem("orderID")),
              TRANSACTION_ID: paymentId,
              PAYMENT_STATUS: "Completed",
              TRANSACTION_STATUS: "Success",
              TRANSACTION_AMT: order.RemainingAmount,
              REMAING_AMOUNT: 0,
              PAYMENT_DATE: dayjs().format("YYYY-MM-DD"),
            })
            .then((res) => {
              // debugger;
              if (res.data.ResponseMessage === "successfully.") {
                // localStorage.setItem("cartItemCount", "0");
                // window.dispatchEvent(new Event("cartUpdated"));
                // setShowCODModal(false);
                // nextStep();
                navigate("/my-order-pandit-Booking");
              } else {
                alert("Order placement failed, please try again.");
              }
            });
        }

        // const updatedPayload = {
        //   ...razorPayData,
        //   TRANSACTION_ID: paymentId,
        //   TRANSACTION_AMT: minPayableAmount,
        // };

        // axios.post(`${BaseUrl}/BookPooja`, updatedPayload).then((res) => {
        //   debugger;
        //   if (res.data.ResponseMessage === "Order Success.") {
        //     localStorage.setItem("cartItemCount", "0");
        //     window.dispatchEvent(new Event("cartUpdated"));
        //     navigate("/thank-you");
        //   } else {
        //     alert("Order placement failed, please try again.");
        //   }
        // });
      },
      theme: {
        color: "#ff416c",
      },
    };

    const rzp = new window.Razorpay(options);
    rzp.open();
  };

  console.log(order);

  const paymentBtnClick = () => { };

  const createMarkup = (html) => {
    return { __html: html };
  };

  function formatToIndianCurrency(amount) {
    if (!amount) return "";

    const [integerPart, decimalPart] = amount.toString().split(".");

    // Format the integer part using Indian numbering system
    const formattedIntegerPart = integerPart.replace(
      // Match groups of 3 digits initially, then groups of 2 digits
      /(\d)(?=(\d\d)+\d$)/g,
      "$1,"
    );

    // Return the formatted value with the decimal part (if present)
    return decimalPart !== undefined
      ? `${formattedIntegerPart}.${decimalPart}`
      : formattedIntegerPart;
  }

  return (
    <>
      <div>
        <div className="top">
          <div
            className="pujaDetails"
            style={{ display: "flex", padding: "20px" }}
          >
            <div className="left">
              <img
                src={order.PUJA_IMAGE}
                width={100}
                height={300}
                alt="Puja-Img"
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = placeholderImg;
                }}
              />
            </div>
            <div className="right">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center", // Ensures vertical alignment
                  marginBottom: "10px",
                }}
              >
                {/* <h6
                  className="list-group-item custom-list-item clearfix"
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "20px",
                    textTransform: "none",
                    margin: 0, // Remove default margin for better alignment
                  }}
                >
                  Puja Details
                </h6> */}
                <h2
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "20px",
                    textTransform: "none",
                    margin: 0, // Remove default margin for better alignment
                  }}
                >
                  {order.PUJA_NAME}
                </h2>
                {order.RemainingAmount > 0 && (
                  <div className="button-container">
                    <button
                      className="careox-btn"
                      style={{
                        backgroundColor: "#A52444",
                        color: "white",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      onClick={handleProceedRemainingPayment}
                    >
                      Proceed To Payment
                    </button>
                  </div>
                )}
              </div>

              <h4>
                Rs{" "}
                {/* {order.PB_PRICE -
                  Math.round((order.PB_PRICE * order.pujaDiscount) / 100)} */}
                {formatToIndianCurrency(order.PB_PRICE - order.DiscountAmount)}
              </h4>
              {/* <h5>
                <span>
                  <s>Rs {order.PB_PRICE}</s>
                </span>
              </h5> */}

              <div className="mb-3 ">
                <h5>Puja Details:</h5>
                <p
                  dangerouslySetInnerHTML={{
                    __html: order.PUJA_DETAILS,
                  }}
                />
              </div>

              <div className="mb-3">
                <h5>Samagri:</h5>
                {order.PB_SAMAGRI ? (
                  <div
                    dangerouslySetInnerHTML={createMarkup(order.PUJA_SAMAGRI)}
                  />
                ) : (
                  <p>No samagri details available.</p>
                )}
              </div>

              <h5>
                Samagri Price: Rs{" "}
                {formatToIndianCurrency(order.PB_SAMAGRIPRICE)}
              </h5>

              {/* <div className="button-container">
              <button
                className="btn btn-primary"
                style={{ backgroundColor: "#A52444", color: "white" }}
                onClick={handleOpenProductBookingModal}
              >
                Proceed to Book
              </button>
            </div> */}
            </div>
          </div>
        </div>
        <div className="container my-4">
          <div className="row g-4">
            {/* Puja Details */}
            <div className="col-md-4">
              <div className="card shadow-sm">
                <div className="card-body">
                  <h5 className="card-title text-center fw-bold">
                    Puja Details
                  </h5>
                  <table className="table table-bordered table-striped">
                    <tbody>
                      <tr>
                        <td>Puja Name</td>
                        <td className=" fw-bold">
                          {order.PUJA_NAME}
                        </td>
                      </tr>
                      <tr>
                        <td>Booking Date</td>
                        <td>{order.REG_DATE}</td>
                      </tr>
                      <tr>
                        <td>Language</td>
                        <td>{order.PB_LANGAUGE}</td>
                      </tr>
                      <tr>
                        <td>Puja Date</td>
                        <td>{order.PB_DATE}</td>
                      </tr>
                      <tr>
                        <td>Time</td>
                        <td>
                          {new Date(
                            `1970-01-01T${order.PB_TIME}`
                          ).toLocaleTimeString("en-US", {
                            hour: "2-digit",
                            minute: "2-digit",
                            hour12: true,
                          })}
                        </td>
                      </tr>
                      <tr>
                        <td>Samagri</td>
                        <td>{order.PB_SAMAGRI === "Yes" ? "Yes" : "No"}</td>
                      </tr>
                      <tr>
                        <td>Payment Mode</td>
                        <td>
                          <i className="bi bi-credit-card"></i>{" "}
                          {order.PAYMENT_TYPE}
                        </td>
                      </tr>
                      <tr>
                        <td>Payment Status</td>
                        <td className="fw-bold ">
                          {order.PAYMENT_STATUS === 'Complete' ? 'Completed' : order.PAYMENT_STATUS}
                        </td>
                      </tr>
                      <tr>
                        <td>Transaction Amount (₹)</td>
                        <td>{formatToIndianCurrency(Math.round(order.TRANSACTION_AMT))}</td>
                      </tr>
                      <tr>
                        <td>Remaining Amount (₹)  </td>
                        <td>
                          {formatToIndianCurrency(Math.round(order.RemainingAmount)) || 0}
                          {/* {formatToIndianCurrency(
                            CalculateGrandTotal(
                              order.PB_PRICE,
                              order.CONVENIENCE_FEE,
                              order.PB_SAMAGRI,
                              order.PB_SAMAGRIPRICE,
                              order.DiscountAmount
                            ) - order.TRANSACTION_AMT
                          ) || 0} */}
                          {/* {formatToIndianCurrency(
                            Number(order.PB_PRICE) +
                              Number(
                                order.PB_SAMAGRI === "yes"
                                  ? order.PB_SAMAGRIPRICE
                                  : 0
                              ) +
                              Number(order.CONVENIENCE_FEE) +
                              Tax -
                              order.TRANSACTION_AMT
                          ) || 0} */}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            {/* Pandit Details */}
            <div className="col-md-4">
              <div className="card shadow-sm">
                <div className="card-body">
                  <h5 className="card-title text-center fw-bold">
                    Pandit Details
                  </h5>
                  <table className="table table-bordered table-striped">
                    <tbody>
                      <tr>
                        <td>Pandit Name</td>
                        <td className=" fw-bold">
                          {order.PANDIT_NAME}
                        </td>
                      </tr>
                      <tr>
                        <td>Contact</td>
                        <td>{order.MOBILE_NO}</td>
                      </tr>
                      <tr>
                        <td>Status</td>
                        <td
                          className="fw-bold"
                        >
                          {order.PANDIT_STATUS === 'Complete' ? 'Completed' : order.PANDIT_STATUS}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            {/* Price Details */}
            <div className="col-md-4">
              <div className="card shadow-sm">
                <div className="card-body">
                  <h5 className="card-title text-center fw-bold">
                    Price Details
                  </h5>
                  <table className="table table-bordered table-striped">
                    <tbody>
                      <tr>
                        <td>Brahmin Dakshina (₹)</td>
                        <td className="text-end">
                          {formatToIndianCurrency(order.PB_PRICE)}
                        </td>
                      </tr>
                      <tr>
                        <td>Discount ({order.PUJA_DISC}%)</td>
                        <td className="text-end">
                          -{formatToIndianCurrency(order.DiscountAmount)}
                        </td>
                      </tr>
                      <tr>
                        <td>Samagri Price (₹)</td>
                        <td className="text-end">
                          {order.PB_SAMAGRI === "Yes"
                            ? formatToIndianCurrency(order.PB_SAMAGRIPRICE)
                            : "0"}
                        </td>
                      </tr>
                      <tr>
                        <td>Samagri Tax ({order?.SamagriPriceTaxPercent}%)</td>
                        <td className="text-end">
                          {order.PB_SAMAGRI === "Yes"
                            ? formatToIndianCurrency(order.SamagriPriceTaxAmount)
                            : "0"}
                        </td>
                      </tr>
                      <tr>
                        <td>Convenience Fee (₹)</td>
                        <td className="text-end">
                          {formatToIndianCurrency(order.CONVENIENCE_FEE)}
                        </td>
                      </tr>
                      <tr>
                        <td>Convenience Tax  ({order?.ConvinienceFeeTaxPercent}%)</td>
                        <td className="text-end">
                          {formatToIndianCurrency(Math.round(order.ConvinienceFeeTaxAmount))}
                        </td>
                      </tr>

                      <tr className="fw-bold">
                        <td>Total Amount (₹)</td>
                        <td className="text-end">
                          {formatToIndianCurrency(Math.round(order.PB_GrandTotal))}
                          {/* {formatToIndianCurrency(
                            CalculateGrandTotal(
                              order.PB_PRICE,
                              order.CONVENIENCE_FEE,
                              order.PB_SAMAGRI,
                              order.PB_SAMAGRIPRICE,
                              order.DiscountAmount
                            )
                          )} */}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              {order.PAYMENT_STATUS === 'Complete' && order.PANDIT_STATUS === 'Complete' ? (
                <button
                  className="careox-btn mt-2"
                  onClick={() =>
                    navigate("/invoice", {
                      state: { moduleName: "pujaBooking", PB_ID: order.PB_ID },
                    })
                  }
                >
                  View Invoice
                </button>
              ) : ''}
              <button
                className="careox-btn mt-2 mx-2"
                onClick={() => {

                  if (order.PAYMENT_TYPE == 'COD') {
                    navigate("/receipt-details", {
                      state: { moduleName: "PanditBooking", orderID: order.PB_ID },
                    })
                  } else {

                    navigate("/view-receipt", {
                      state: { moduleName: "PanditBooking", orderID: order.PB_ID },
                    })
                  }
                }
                }

              >
                View Receipt
              </button>






            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PBOrderDetails;
