import shadows from '@mui/material/styles/shadows';
import React, { useEffect, useState } from 'react'
import { GetUserModel } from '../../../ApiServices/Profile/ProfileApi';
import { daily_nakshatra_prediction } from '../../../ApiServices/Horoscope/BirthDetailsApi';
import dayjs from 'dayjs';

const DailyPrediction = () => {
    const [animate, setAnimate] = useState(false);
    const [dailyPredictionList, setDailyPredictionObj] = useState([])
    const cardDetails = [
        { key: 'Health', label: 'health' },
        { key: 'Emotions', label: 'Emotions' },
        { key: 'Profession', label: 'Profession' },
        { key: 'Luck', label: 'Luck' },
        { key: 'PersonalLife', label: 'Personal Life' },
        { key: 'Travel', label: 'Travel' },
    ]
    const pastelGradients = [
        "linear-gradient(135deg, #E0F8E0, #C1E1C1)", // Health - Soft Green
        "linear-gradient(135deg, #FDE2E4, #FAB1A0)", // Emotions - Soft Red/Pink
        "linear-gradient(135deg, #D6E6FF, #A6C8FF)", // Profession - Soft Blue
        "linear-gradient(135deg, #FFF5D1, #FFEB99)", // Luck - Soft Yellow
        "linear-gradient(135deg, #EAEAEA, #D6D6D6)", // Personal Life - Soft Grey
        "linear-gradient(135deg, #D1F2F2, #A0E7E5)" // Travel - Soft Cyan
    ];

    useEffect(() => {
        GetUserModelData()
        setTimeout(() => {
            setAnimate(true);
        }, 300); // Delay animation to make it smoother
    }, []);

    const GetUserModelData = async () => {
        // setLoader(true);

        const userID = JSON.parse(localStorage.getItem("userID"));
        try {
            const response = await GetUserModel(userID);
            if (response?.ResponseCode == 0) {
                // setLoader(false);
                const ModelData = response.DATA || []; // Assuming the data is an array and we need the first item

                const dob = new Date(ModelData[0]?.DOB);

                const year = dob.getFullYear();
                const month = dob.getMonth() + 1; // Months are zero-based (0 = January)
                const day = dob.getDate();

                const [hours, minutes] = ModelData[0]?.BIRTH_TIME.split(":");
                const apiParam = {
                    "day": day,
                    "month": month,
                    "year": year,
                    "hour": hours,
                    "min": minutes,
                    "lat": 25.7464,
                    "lon": 25.7464,
                    "tzone": 5.5
                }
                await GetDailyPredictionData(apiParam, dayjs(ModelData[0]?.DOB).format('DD/MM/YYYY'))

            } else {
                // setLoader(false);

                console.error('Error fetching data: ', response?.data?.data?.statusCode);
            }
        } catch (error) {
            // setLoader(false);

            console.error('Error', error);
        }
    };
    const GetDailyPredictionData = async (apiParam, birthDate) => {
        try {
            const response = await daily_nakshatra_prediction(apiParam);
            if (response !== null && response !== undefined) {
                // setLoader(false);
                setDailyPredictionObj((prev) => ({
                    ...prev,
                    Health: response?.prediction?.health,
                    Emotion: response?.prediction?.emotions,
                    Profession: response?.prediction?.profession,
                    Luck: response?.prediction?.luck,
                    Personal_life: response?.prediction?.personal_life,
                    Travel: response?.prediction?.travel,

                }));

            } else {
                // setLoader(false);

                console.error('Error fetching data: ', response);
            }
        } catch (error) {
            // setLoader(false);

            console.error('Error', error);
        }
    };

    return (
        <>
            <h3 className='text-center m-3'>Daily Prediction</h3>
            <div className='container'>
                <div className="row">
                    {Object?.entries(dailyPredictionList || {}).map(([key, value], index) => (
                        <div key={index} className="col-md-4 col-sm-6 col-12 mb-4 ">
                            <div
                                className={`card text-center shadow-md ${animate ? 'slide-in-left' : 'hidden'} `}
                                style={{
                                    height: '13em',
                                    background: pastelGradients[index % pastelGradients.length], // Cycles through gradients
                                    color: "#333", // Darker text for contrast
                                    textDecoration: "none",
                                    border: "none", // Remove default border
                                    borderRadius: "12px", // Softer corners

                                }}
                            >
                                <div className="card-body">
                                    <h5 className="card-title mt-3">{key}</h5>
                                    <p className='p-1' style={{ textAlign: 'justify' }}>{value}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    )
}

export default DailyPrediction
