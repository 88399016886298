import React from "react";

const GoogleMap = () => {
  return (
    <section className="contact-map">
      <div className="container-fluid">
        <div className="google-map google-map__contact">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7536.004420587187!2d72.9479525383258!3d19.195105810536976!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7b9084a5a12cb%3A0x2b25e557b030e5b1!2sLodha%20Supremus%202%20Tower!5e0!3m2!1sen!2sin!4v1740218577353!5m2!1sen!2sin"
            width="100%"
            height="450"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            title="Google Map"
          ></iframe>
        </div>
      </div>
    </section>
  );
};

export default GoogleMap;
