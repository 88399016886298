import React, { useContext, useEffect, useState } from "react";
import { AuthContextProvider } from "../../AuthContext/AuthContext";
import { CurrentBlogData } from "../../ApiServices/BlogApi/BlogAPI";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { FaSearch } from "react-icons/fa";
import MobileAppAd from "../../Components/MoblieAddComponent";
import { Helmet } from "react-helmet-async";

const Blogs = () => {
  const userID = localStorage.getItem("userID") || "";
  const { slug } = useParams();
  const [currentBlog, setCurrentBlog] = useState(null);
  const [blogList, setblogList] = useState([]);
  const [searchKeyWord, setSearchKeyword] = useState("");
  const location = useLocation();

  const { setLoader } = useContext(AuthContextProvider);
  const navigate = useNavigate();

  // console.log(id);

  // useEffect(() => {
  //   debugger;
  //   if (location.state.currentBlog) {
  //     setCurrentBlog(location.state.currentBlog);
  //   }
  // }, [location?.state]);

  useEffect(() => {
    GetCurrentBlog({
      START: 0,
      END: 100,
      WORD: "",
      EXTRA1: "",
      EXTRA2: "",
      EXTRA3: "",
      LANG_ID: "1",
      USER_ID: localStorage.getItem("userID"),
    });
  }, []);

  const GetCurrentBlog = async (params) => {
    // debugger;
    setLoader(true);

    const response = await CurrentBlogData(params);

    if (response.ResponseCode === "0" && response.ResponseMessage === "list") {
      const blogs = response?.DATA;
      setblogList(blogs);
      setCurrentBlog(blogs.find((item) => item.Blog_Slug === slug));
      //   if (blogs.length > 0) {
      //     if (location.state) {
      //       setCurrentBlog(location.state.blog);
      //     } else {
      //       setCurrentBlog(blogs[0]); // Set the first blog as the current blog
      //     }
      //   }
    }
  };

  const filteredBlogs = blogList.filter(
    (data) =>
      data.BLOG_TITLE.toLowerCase().includes(searchKeyWord.toLowerCase()) &&
      data.BLOG_TITLE !== currentBlog?.BLOG_TITLE
  );

  const stripHtml = (html) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;
    return tempDiv.innerText || tempDiv.textContent || "";
  };

  return (
    <>
      <section className="page-header" style={{ marginBottom: "2rem" }}>
        <div className="page-header__bg"></div>
        <div className="container">
          <h2 className="page-header__title bw-split-in-left">
            Spiritual Gyan
          </h2>
        </div>
      </section>
      <Helmet>
        {/* Meta Title & Description */}
        <title>{currentBlog?.META_Title}</title>

        {/* Meta description */}
        <meta name="description" content={currentBlog?.META_DESCRIPTION} />

        {/* Canonical URL */}

        {/* <link rel="canonical" href="https://www.myomnamo.com/spiritual-gyan" /> */}

        {/* Open Graph Meta Tags */}

        {/* <meta
          property="og:title"
          content="Spiritual Gyan - Insights, Rituals & Divine Knowledge"
        />
        <meta
          property="og:description"
          content="Explore spiritual wisdom, rituals, and divine insights on our blog. Enhance your spiritual journey with meaningful knowledge and guidance."
        />
        <meta
          property="og:image"
          content="https://www.myomnamo.com/assets/images/new/logo.png"
        />
        <meta
          property="og:url"
          content="https://www.myomnamo.com/spiritual-gyan"
        />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="MyOmNamo" />
        <meta property="og:locale" content="en_IN" /> */}

        {/* Twitter Card Tags */}

        {/* <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Spiritual Gyan - Insights, Rituals & Divine Knowledge"
        />
        <meta
          name="twitter:description"
          content="Explore spiritual wisdom, rituals, and divine insights on our blog. Enhance your spiritual journey with meaningful knowledge and guidance."
        />
        <meta
          name="twitter:image"
          content="https://www.myomnamo.com/assets/images/new/logo.png"
        /> */}

        {/* Schema Markup - Breadcrumbs */}

        {/* <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org/",
            "@type": "BreadcrumbList",
            itemListElement: [
              {
                "@type": "ListItem",
                position: 1,
                name: "Home",
                item: "https://www.myomnamo.com/",
              },
              {
                "@type": "ListItem",
                position: 2,
                name: "Spiritual Gyan",
                item: "https://www.myomnamo.com/spiritual-gyan",
              },
            ],
          })}
        </script> */}

        {/* WebPage Schema */}

        {/* <script type="application/ld+json">
          {JSON.stringify({
            "@context": "http://schema.org",
            "@type": "WebPage",
            name: "Spiritual Gyan - Insights, Rituals & Divine Knowledge",
            url: "https://www.myomnamo.com/spiritual-gyan",
            description:
              "Explore spiritual wisdom, rituals, and divine insights on our blog. Enhance your spiritual journey with meaningful knowledge and guidance.",
            publisher: {
              "@type": "Organization",
              name: "MyOmNamo",
            },
          })}
        </script> */}
      </Helmet>
      <div
        className="container"
        style={{ display: "felx", flexDirection: "column" }}
      >
        <div
          className="row title"
          style={{
            marginBottom: "15px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <div className="col-8">
            <span
              style={{
                fontSize: "22px",
                fontWeight: "500",
                display: "flex",
                alignItems: "center",
                width: "auto",
              }}
            >
              Recent Posts
            </span>
          </div>
          <div className="col-4" style={{ padding: "0" }}>
            {/* Search Bar */}
            <div
              className="search-box"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flex: "1",
                maxWidth: "100%",
                marginRight: "10px",
              }}
            >
              <input
                type="text"
                placeholder="Search..."
                className=" custom-search-input"
                value={searchKeyWord}
                onChange={(e) => setSearchKeyword(e.target.value)}
                // style={{
                //   width: "100%",
                //   minWidth: "100px",
                //   padding: "8px",
                //   borderRadius: "5px",
                // }}
              />
              {/* <FaSearch className="position-absolute top-50 end-0 me-3 translate-middle-y text-warning" /> */}
            </div>
          </div>
        </div>

        <div className="row">
          {/* Current Blog Section */}
          {currentBlog && (
            <div
              // style={{ cursor: "pointer" }}
              className="col-lg-8 col-md-5 col-sm-12"
              // onClick={() =>
              //   navigate("/blog-post", {
              //     state: {
              //       currentBlog: currentBlog,
              //       filteredBlogs: filteredBlogs,
              //     },
              //   })
              // }
            >
              <div className=" zoom-effect">
                <img
                  src={currentBlog.BLOG_IMAGE}
                  alt={currentBlog.BLOG_TITLE}
                  className="blog-img"
                  style={{
                    width: "100%",
                    height: "350px",
                    objectFit: "fill",
                    borderRadius: "10px",
                  }}
                />

                <div className="card-body">
                  <h5 className="card-title mt-3">{currentBlog.BLOG_TITLE}</h5>

                  <p dangerouslySetInnerHTML={{ __html: currentBlog.BLOG }}></p>

                  {/* {currentBlog.BLOG.length > 120 && (
                    <a href="#" className="btn btn-primary btn-animated">
                      View More
                    </a>
                  )} */}
                  {/* <p className="text-success text-right mt-2">
                    <span className="span-date bg-success text-white p-2 px-3">
                      {currentBlog.REG_DATE}
                    </span>
                  </p> */}
                </div>
              </div>
            </div>
          )}

          {/* Blog List Section */}
          <div
            className="col-lg-4 col-md-7 col-sm-12"
            style={{ padding: "0", margin: "0" }}
          >
            {/* Blog Cards */}
            <div className="row" style={{ padding: "0", margin: "0" }}>
              {filteredBlogs.map((data, index) => (
                <div
                  className="col-md-12 fade-in-up mb-3"
                  key={index}
                  style={{ padding: "0", margin: "0", cursor: "pointer" }}
                  onClick={() => {
                    setCurrentBlog(data);
                    navigate(`/spiritual-gyan/${data.Blog_Slug}`);
                  }}
                >
                  <div
                    className="card blog-card overflow-hidden card-shadow-sty rounded-3 border-1"
                    style={{ padding: "0", margin: "0" }}
                  >
                    <div
                      className="row g-0"
                      style={{ padding: "0", margin: "0" }}
                    >
                      <div
                        className="col-md-4 position-relative"
                        style={{ padding: "0", margin: "0" }}
                      >
                        <img
                          src={data.BLOG_IMAGE}
                          alt={data.BLOG_TITLE}
                          className="card-img blog-img object-fit-cover rounded-start"
                          height={"100%"}
                        />
                      </div>
                      <div className="col-md-8 d-flex flex-column justify-content-between p-3">
                        <div>
                          {/* <span>Date: 14/03/2025</span> */}
                          <h5
                            className="card-title text-truncate"
                            style={{ color: "black", fontWeight: "500" }}
                          >
                            {data.BLOG_TITLE.length > 20
                              ? `${data.BLOG_TITLE.slice(0, 20)}...`
                              : data.BLOG_TITLE}
                          </h5>

                          <p className="card-text text-muted small">
                            {stripHtml(data.BLOG).length > 80
                              ? `${stripHtml(data.BLOG).substring(0, 80)}...`
                              : stripHtml(data.BLOG)}
                          </p>
                        </div>
                        {/* <p className="text-end mb-0">
                          <span
                            className="badge p-2 px-3"
                            style={{ background: "#a52444" }}
                          >
                            {data.REG_DATE}
                          </span>
                        </p> */}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <MobileAppAd />
          </div>
        </div>
      </div>{" "}
    </>
  );
};

export default Blogs;
