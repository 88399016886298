import React from "react";
import { Link, useNavigate } from "react-router-dom";
import DailyPooja from "../../assets/images/new/DailyPooja.jpg";
import Ecart from "../../assets/images/new/e-cart.jpg";
import Horoscope from "../../assets/images/new/Horoscope.jpg";
import panditBooking from "../../assets/images/new/panditBooking.jpg";
import poojaAtTemple from "../../assets/images/new/poojaAtTemple.jpg";
import templeBooking from "../../assets/images/new/templeBooking.jpg";
import Batuk from "../../assets/images/new/Batuk2-removebg-preview.png";
import batuk from "../../assets/images/new/fav-removebg-preview.png";
import panditIcon from "../../assets/images/new/Pandit.png";
import templeIcon from "../../assets/images/new/Temple.png";
import pujaIcon from "../../assets/images/new/Pooja.png";
import estoreIcon from "../../assets/images/new/E-store.png";
import groupIcon from "../../assets/images/new/Group 1000004477.png";
import panditBookingImg from "../../assets/images/new/panditBooking.png";
import dailyBookingImg from "../../assets/images/new/dailyPujaBooking.png";
import templePujaImg from "../../assets/images/new/PujaAtTemple.png";
import darshanBookingImg from "../../assets/images/new/darshanBooking.png";
import horoscopeImg from "../../assets/images/new/horoscope.png";
import pujaBookingServiceImg from "../../assets/images/new/PujaBookingService.png";
import dailyBookingServiceImg from "../../assets/images/new/DailyPujaBookingService.png";
import darshanBookingServiceImg from "../../assets/images/new/DarshanBookingService.png";
import templePujaServiceImg from "../../assets/images/new/PujaAtTempleService.png";
import horoscopeServiceImg from "../../assets/images/new/HoroscopeService.png";

const BestServices = () => {
  const navigate = useNavigate();

  const services = [
    {
      key: 0,
      title: "Puja Booking",
      image: pujaBookingServiceImg,
      serviceIcon: panditIcon,
      icon: "https://www.vedicyatras.com/assets/front/images/kumbhmela.svg",
      text: "Book experienced Pandits for your puja and ceremonies.",
      color: "#ffa415",
      route: "/services/puja-booking",
    },
    {
      key: 1,
      title: "Daily Puja Booking",
      image: dailyBookingServiceImg,
      serviceIcon: pujaIcon,
      icon: "https://www.vedicyatras.com/assets/front/images/kumbhmela.svg",
      text: "Book a Pandit for your daily morning and evening pujas.",
      color: "#fc5528",
      route: "/services/daily-puja-booking",
    },
    {
      key: 2,
      title: "E-Store",
      image: Ecart,
      serviceIcon: estoreIcon,
      icon: "https://www.vedicyatras.com/assets/front/images/kumbhmela.svg",
      text: "Shop authentic puja samgari, puja kit and puja essentials online in our E-store.",
      color: "#8139e7",
      route: "/services/e-store",
    },
    {
      key: 3,
      title: "Darshan Booking",
      image: darshanBookingServiceImg,
      serviceIcon: batuk,
      icon: "https://www.vedicyatras.com/assets/front/images/kumbhmela.svg",
      text: "Easily book darshans at temples across India.",
      route: "/services/darshan-booking",
    },
    {
      key: 4,
      title: "Puja At Temple",
      image: templePujaServiceImg,
      serviceIcon: templeIcon,
      icon: "https://www.vedicyatras.com/assets/front/images/kumbhmela.svg",
      text: "You can do puja in the temple by booking our Pandit.",
      route: "/services/temple-puja",
    },
    {
      key: 5,
      title: "Horoscope",
      image: horoscopeServiceImg,
      serviceIcon: groupIcon,
      icon: "https://www.vedicyatras.com/assets/front/images/kumbhmela.svg",
      text: "Get accurate horoscope readings and astrology guidance.",
      color: "#d340c3",
      route: "/services/horoscope",
    },
  ];
  return (
    <section className="service-two">
      <div className="service-two__shape"></div>
      <div
        className="container"
        style={{ display: "flex", flexDirection: "column" }}
      >
        <div className="sec-title text-center">
          <h6 className="sec-title__tagline bw-split-in-right">
            <img className="tagline-img" src={batuk} alt="" />
            Best Services
          </h6>
          <h3 className="sec-title__title bw-split-in-left">
            {/* Book Puja Services At your Fingertips */}
            Your One-Stop Destination for Spiritual Services
          </h3>
        </div>
        <div className="row gutter-y-30">
          {services.map((service, index) => (
            <div
              key={index}
              style={{ cursor: "pointer" }}
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay={`${index * 100}ms`}
              onClick={() => {
                navigate(`${service.route}`, { state: { key: service.key } });
                window.scrollTo({ top: 0, behavior: "instant" }); // Instantly move to the top without scroll animation
              }}
            >
              <div
                className="service-two__item text-center"
                // style={{ "--accent-color": service.color }}
                style={{ color: "black" }}
              >
                <div
                  className="service-two__item__shape"
                  // style={{
                  //   backgroundImage: "url(assets/images/new/leftside.png)",
                  // }}
                ></div>
                <div className="service-two__item__image">
                  <img
                    src={service.image}
                    alt="MyOm Namo"
                    style={{ width: "300px", objectFit: "contain" }}
                  />
                </div>
                <div className="service-two__item__icon">
                  <img
                    src={Batuk}
                    alt=""
                    className="service_i"
                    style={{ borderRadius: "50%" }}
                  />
                </div>
                <h3 className="service-two__item__title">{service.title}</h3>
                <p
                  className="service-two__item__text"
                  // style={{ color: "black" }}
                >
                  {service.text}
                </p>
                <div className="service-two__item__rm">
                  {/* Navigate to the respective route */}
                  <Link to={service.route}>Read More</Link>
                  <i className="icon-right-arrow"></i>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default BestServices;
