import { useEffect, useState } from "react";
import "./DarshanTemple.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import dayjs from "dayjs";
import DatePicker from "react-date-picker";
import Select from "react-select";
import { ERROR_MESSAGES } from "../GlobalErrorMessage";
import { SelectLanguageOptions } from "../../middlewares/Utils";
import { GetPanditListDataAPI } from "../../ApiServices/PanditList/PanditList";
import TempleCodModal from "../Darshan Booking/TempleCodModal";
// import placeholderImg from "../../assets/images/new/Batuk2-removebg-preview.png";
import axios from "axios";
import { BaseUrl } from "../../BaseUrl/BaseUrl";
import Mylogo from "../../assets/images/logo.png";
import "../../Pages/Stepper Form/stepper.css";
import placeholderImg from "../../assets/images/new/Batuk2-removebg-preview.png";
import { ServiceDiscounts } from "../enum";
import {
  LanguageList,
  PoojaAtTemple,
} from "../../ApiServices/Pooja At Temple/PoojaTempleApi";
const steps = [
  "Booking Details",
  // "Select Pandit",
  "Booking Preview",
  "Payment Status",
];

const PujaAtTempleBookingForm = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [showCODModal, setShowCODModal] = useState(null);
  const [selectedPandit, setSelectedPandit] = useState(null);
  const [orderID, setOrderID] = useState();
  const [panditSelectionError, setPanditSelectionError] = useState(null);
  const [error, setError] = useState(null);
  const [searchKeyword, setSearchKeyword] = useState(null);
  const [showBookingReviewModal, setShowBookingReviewModal] = useState(null);
  const [panditDataList, setPanditDataList] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const [temple, setTemple] = useState(null);
  const [findPandit, setFindPandit] = useState(null);
  const [productBookingData, setProductBookingData] = useState({
    PATB_DATE: null,
    PATB_TIME: null,
    languageID: null,
    landmark: null,
    address: null,
    pincode: null,
    city: null,
    state: null,
    needSamagri: null,
  });
  const [modelRequestData, setModelRequestData] = useState({
    totalPrice: null,
    amountEntered: null,
  });

  const fromDate = dayjs();
  const userID = localStorage.getItem("userID");
  const product = location.state?.product;
  const pujaDetails = location.state?.pujaDetailsData;
  const [languageList, setLanguageList] = useState([]);

  console.log(location.state, "32dsfdnew");

  // console.log("productBookingData", productBookingData);
  console.log(selectedPandit, "sdiojoewi");

  const [razorPayData, setRazorPayData] = useState({
    PB_DATE: null,
    PB_TIME: null,
    PB_LANGAUGE: null,
    PB_SAMAGRI: null,
    PB_PRICE: null,
    PB_SAMAGRIPRICE: null,
    PANDIT_ID: null,
    USER_ID: null,
    REFERENCES_NO: "",
    ADDRESS: null,
    PUJA_ID: null,
    PAYMENT_TYPE: null,
    CONVINIENCE_FEE: null,
    TAX: null,
    EXTRA: null,
    TRANSACTION_ID: "",
    TRANSACTION_AMT: "",
  });

  // console.log("razorPayData", location.state);

  const totalPrice =
    parseFloat(product?.PUJA_PRICE || 0) +
    (productBookingData.needSamagri === "yes"
      ? parseFloat(location.state?.PAT_SAMAGRIPRICE || 0)
      : 0) +
    parseFloat(product?.DAKSHINA || 0) +
    parseFloat(product?.CONVENIENCE_FEE || 0) +
    2; //Tax amount

  const nextStep = () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
    }
  };

  const prevStep = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  useEffect(() => {
    GetPanditListData();
  }, [productBookingData?.languageID]);

  useEffect(() => {
    // debugger;
    // setRazorPayData((prev) => ({
    //   ...prev,
    //   PB_DATE: productBookingData.PATB_DATE,
    //   PB_TIME: productBookingData.PATB_TIME,
    //   PB_LANGAUGE: SelectLanguageOptions.find(
    //     (item) => item.value === productBookingData.languageID
    //   )?.label,
    //   PB_SAMAGRI: productBookingData.needSamagri,
    //   PB_PRICE: pujaDetails?.pujaPrice,
    //   PB_SAMAGRIPRICE: pujaDetails?.pujaSamagriPrice,
    //   PANDIT_ID: selectedPandit,
    //   USER_ID: userID,
    //   REFERENCES_NO: "",
    //   ADDRESS: productBookingData?.address,
    //   PUJA_ID: pujaDetails?.pujaId,
    //   PAYMENT_TYPE: "ONLINE",
    //   CONVINIENCE_FEE: pujaDetails?.convenienceFee,
    //   TAX: null,
    //   EXTRA: null,
    //   TRANSACTION_ID: "",
    //   TRANSACTION_AMT: "",
    // }));
    GetLanguageListLookupListData();
  }, []);

  const GetLanguageListLookupListData = async () => {
    try {
      const response = await LanguageList(); // Ensure it's correctly imported
      console.log("Extracted Language List:", response.DATA); // Debug
      // debugger
      if (response?.ResponseCode === "0") {
        const languageLookupList = response?.DATA || [];

        const formattedLangList = languageLookupList.map((Lang) => ({
          value: Lang.LANG_ID,
          label: Lang.LANG_NAME,
        }));
        setLanguageList(formattedLangList);
      } else {
        console.error(
          "Failed to fetch sim Type lookup list:",
          response?.data?.statusMessage || "Unknown error"
        );
      }
    } catch (error) {
      console.error("Error fetching sim Type lookup list:", error);
    }
  };
  //   const GetPanditListData = () => {};
  const GetPanditListData = async () => {
    // debugger;
    try {
      const response = await GetPanditListDataAPI(
        userID,
        3,
        productBookingData?.languageID,
        {
          START: 1,
          END: 10,
          WORD: "None",
          LATITUDE: "20.0086678",
          LONGITUDE: "73.7639264",
        }
      );

      if (response) {
        setPanditDataList(response.DATA);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const HandleProductBookingBtnClicked = () => {
    // debugger;
    let isValid = false;

    if (
      productBookingData.PATB_DATE === null ||
      productBookingData.PATB_DATE === undefined ||
      productBookingData.PATB_DATE === "" ||
      productBookingData.PATB_TIME === null ||
      productBookingData.PATB_TIME === undefined ||
      productBookingData.PATB_TIME === "" ||
      productBookingData.needSamagri === null ||
      productBookingData.needSamagri === undefined ||
      productBookingData.needSamagri === ""
    ) {
      isValid = true;
      setError(true);
    }

    if (!isValid) {
      nextStep();
    }
  };

  const SGST = 9;
  const CGST = 9;
  const convenienceFee = parseFloat(location?.state?.PAT_CONVENIENCEFEE) || 0; // Ensure it's a number

  // Calculate Dynamic Tax
  const DynamicTax = ((SGST + CGST) / 100) * convenienceFee;

  // Convert all values to numbers
  const brahminDakshina = parseFloat(location?.state?.PAT_PRICE) || 0;
  const samagriPrice =
    productBookingData?.needSamagri === "yes"
      ? parseFloat(location?.state?.PAT_SAMAGRIPRICE) || 0
      : 0;
  const convenienceFeeFinal =
    parseFloat(location?.state?.PAT_CONVENIENCEFEE) || 0;
  const tax = parseFloat(DynamicTax) || 0;

  // Final total calculation (ensuring proper addition)
  const FinaltotalPrice = brahminDakshina + samagriPrice + convenienceFeeFinal + location.state?.ConvinienceFeeTaxAmount;

  const handleRazorpayPayment = () => {
    // debugger;
    // let totalAmount = parseInt(modelRequestData.totalVal); // Assuming totalVal includes shipping

    let orderIDReturned = null;

    // axios
    //   .post(`${BaseUrl}/PoojaAtTemple`, {
    //     PATB_ID: "1",
    //     PAT_ID: location.state.PAT_ID,
    //     USER_ID: userID,
    //     TOTAL_PRICE: FinaltotalPrice,
    //     CONVIENCE_FEE: convenienceFeeFinal,
    //     PANDIT_ID: selectedPandit,
    //     PATB_ADDRESS: location.state.PAT_ADDRESS,
    //     PATB_LANG: "English",
    //     PATB_DATE: productBookingData.PATB_DATE,
    //     PATB_TIME: productBookingData.PATB_TIME,
    //     REFERAL_NO: "",
    //     PAYMENT_TYPE: "ONLINE",
    //     TRANSACTION_ID: "",
    //     PAYMENT_STATUS: "",
    //     PAT_SAMAGRIPRICE: samagriPrice,
    //     PAT_SAMAGRI: location.state.PAT_SAMAGRI,
    //   })
    //   .then((res) => {
    //     if (res) {
    //       console.log(res);
    //       setOrderID(res.data.ID);
    //       // localStorage.setItem("orderID", JSON.stringify(res.data.ID));
    //       orderIDReturned = res.data.ID;
    //     }
    //   });

    const options = {
      key: "rzp_test_vv1FCZvuDRF6lQ",
      key_secret: "P4JAUwn4VdE6xDLJ6p2Zy8RQ",
      amount: grandTotal * 100,
      currency: "INR",
      name: "My Om Namo- WowInfotech",
      description: "working",
      image: Mylogo,
      handler: function (response) {
        // console.log(response, "Payment Success");
        const paymentId = response.razorpay_payment_id;

        console.log(response, "sfsdf");
        const todayFormatted = new Date().toLocaleDateString("en-GB"); // "06/03/2025" (DD/MM/YYYY)

        if (response) {
          // axios
          //   .post(`${BaseUrl}/UpdatePaymentStatus?TYPE=PujaAtTemple`, {
          //     USER_ID: userID,
          //     ORDER_ID: orderIDReturned,
          //     TRANSACTION_ID: paymentId,
          //     PAYMENT_STATUS: "Success",
          //     TRANSACTION_STATUS: "Success",
          //     TRANSACTION_AMT: FinaltotalPrice,
          //     REMAING_AMOUNT: 0,
          //     PAYMENT_DATE: todayFormatted,
          //   })
          //   .then((res) => {
          //     // debugger;
          //     if (res.data.ResponseMessage === "successfully.") {
          //       setShowCODModal(false);
          //       nextStep();
          //     } else {
          //       alert("Order placement failed, please try again.");
          //     }
          //   });
          debugger
          axios
            .post(`${BaseUrl}/PoojaAtTemple`, {
              BramhanDakshina: location.state.PAT_PRICE,
              PATB_ID: location.state.PATB_ID,
              PAT_ID: location.state.PAT_ID,
              USER_ID: userID,
              TOTAL_PRICE: grandTotal,
              CONVIENCE_FEE: convenienceFeeFinal,
              PANDIT_ID: selectedPandit,
              PATB_ADDRESS: location.state.PAT_ADDRESS,
              PATB_LANG: productBookingData?.languageID,
              PATB_DATE: productBookingData.PATB_DATE,
              PATB_TIME: productBookingData.PATB_TIME,
              REFERAL_NO: "",
              PAYMENT_TYPE: "ONLINE",
              TRANSACTION_ID: paymentId,
              TRANSACTION_AMT: grandTotal,
              PAYMENT_STATUS: "",
              PAT_SAMAGRIPRICE: samagriPrice,
              PAT_SAMAGRI: location.state.PAT_SAMAGRI,
              PAT_TaxPercentage: "",
              PAT_TaxAmount: Tax,
              TRANSACTION_AMT: grandTotal,
              SamagriPriceTaxAmount: location?.state?.SamagriPriceTaxAmount || 0,
              ConvinienceFeeTaxAmount: location?.state?.ConvinienceFeeTaxAmount || 0,
              SamagriPriceTaxPercent: location?.state?.SamagriPriceTaxPercent || 0,
              ConvinienceFeeTaxPercent: location?.state?.ConvinienceFeeTaxPercent || 0

            })
            .then((res) => {
              if (res) {
                console.log(res);
                setOrderID(res.data.ID);
                setShowCODModal(false);
                nextStep();
                // localStorage.setItem("orderID", JSON.stringify(res.data.ID));
                orderIDReturned = res.data.ID;
              }
            });
        }
      },
      theme: {
        color: "#ff416c",
      },
    };

    const rzp = new window.Razorpay(options);
    rzp.open();
  };

  // saurbh tested Api
  const handleRazorpayPaymentSaurabh = () => {
    // debugger;
    // let totalAmount = parseInt(modelRequestData.totalVal); // Assuming totalVal includes shipping
    let minPayableAmount = modelRequestData.amountEntered
      ? modelRequestData.amountEntered
      : Number(grandTotal);

    let orderIDReturned = null;

    // axios
    //   .post(`${BaseUrl}/BookPooja`, {
    //     PB_DATE: productBookingData.date,
    //     PB_TIME: productBookingData.time,
    //     PB_LANGAUGE: SelectLanguageOptions.find(
    //       (item) => item.value === productBookingData.languageID
    //     )?.label,
    //     PB_SAMAGRI: productBookingData.needSamagri,
    //     PB_PRICE: pujaDetails?.pujaPrice,
    //     PB_SAMAGRIPRICE: pujaDetails?.pujaSamagriPrice,
    //     PANDIT_ID: findPandit === true ? null : selectedPandit,
    //     USER_ID: userID,
    //     REFERENCES_NO: "",
    //     ADDRESS: productBookingData?.address,
    //     PUJA_ID: pujaDetails?.pujaId,
    //     PAYMENT_TYPE: modelRequestData.amountEntered ? "COD" : "ONLINE",
    //     CONVINIENCE_FEE: pujaDetails?.convenienceFee,
    //     TAX: null,
    //     EXTRA: null,
    //     TRANSACTION_ID: "",
    //     TRANSACTION_AMT: "",
    //     // New params
    //     PB_DiscountPercentage: pujaDetails.pujaDiscount,
    //     PB_DiscountAmount: pujaDetails.DiscountAmount,
    //     PB_Total: pujaDetails.pujaPrice,
    //     PB_TaxPercentage: ServiceDiscounts.panditBookingDiscount,
    //     PB_TaxAmount: Tax,
    //     PB_GrandTotal: grandTotal,
    //     PAYMENT_STATUS:
    //       minPayableAmount === totalPrice ? "Completed" : "Pending",
    //     TRANSACTION_STATUS: "Success",
    //   })
    //   .then((res) => {
    //     if (res) {
    //       console.log(res);
    //       setOrderID(res.data.ID);
    //       // localStorage.setItem("orderID", JSON.stringify(res.data.ID));
    //       orderIDReturned = res.data.ID;
    //     }
    //   });

    const options = {
      key: "rzp_test_vv1FCZvuDRF6lQ",
      key_secret: "P4JAUwn4VdE6xDLJ6p2Zy8RQ",
      amount: minPayableAmount * 100,
      currency: "INR",
      name: "My Om Namo- WowInfotech",
      description: "working",
      image: Mylogo,
      handler: function (response) {
        // console.log(response, "Payment Success");
        const paymentId = response.razorpay_payment_id;

        console.log(response, "sfsdf");

        if (response) {
          // axios
          //   .post(`${BaseUrl}/UpdatePaymentStatus?TYPE=Pandit`, {
          //     USER_ID: userID,
          //     ORDER_ID: orderIDReturned,
          //     // ORDER_ID: JSON.parse(localStorage.getItem("orderID")),
          //     TRANSACTION_ID: paymentId,
          //     PAYMENT_STATUS:
          //       minPayableAmount === totalPrice ? "Completed" : "Pending",
          //     TRANSACTION_STATUS: "Success",
          //     TRANSACTION_AMT: minPayableAmount,
          //     REMAING_AMOUNT:
          //       Number(totalPrice) +
          //       Number(pujaDetails?.pujaSamagriPrice) +
          //       Number(pujaDetails?.convenienceFee) -
          //       minPayableAmount,
          //     PAYMENT_DATE: dayjs().format("YYYY-MM-DD"),
          //   })
          //   .then((res) => {
          //     // debugger;
          //     if (res.data.ResponseMessage === "successfully.") {
          //       // localStorage.setItem("cartItemCount", "0");
          //       // window.dispatchEvent(new Event("cartUpdated"));
          //       setShowCODModal(false);
          //       nextStep();
          //     } else {
          //       alert("Order placement failed, please try again.");
          //     }
          //   });
          axios
            .post(`${BaseUrl}/BookPooja`, {
              PB_DATE: productBookingData.date,
              PB_TIME: productBookingData.time,
              PB_LANGAUGE: SelectLanguageOptions.find(
                (item) => item.value === productBookingData.languageID
              )?.label,
              PB_SAMAGRI: productBookingData.needSamagri,
              PB_PRICE: pujaDetails?.pujaPrice,
              PB_SAMAGRIPRICE: pujaDetails?.pujaSamagriPrice,
              PANDIT_ID: findPandit === true ? null : selectedPandit,
              USER_ID: userID,
              REFERENCES_NO: "",
              ADDRESS: productBookingData?.address,
              PUJA_ID: pujaDetails?.pujaId,
              PAYMENT_TYPE: modelRequestData.amountEntered ? "COD" : "ONLINE",
              CONVINIENCE_FEE: pujaDetails?.convenienceFee,
              TAX: null,
              EXTRA: null,
              TRANSACTION_ID: paymentId,
              TRANSACTION_AMT: grandTotal,
              // New params
              PB_DiscountPercentage: pujaDetails.pujaDiscount,
              PB_DiscountAmount: pujaDetails.DiscountAmount,
              PB_Total: pujaDetails.pujaPrice,
              PB_TaxPercentage: ServiceDiscounts.panditBookingDiscount,
              PB_TaxAmount: Tax,
              PB_GrandTotal: grandTotal,
              PAYMENT_STATUS:
                minPayableAmount === totalPrice ? "Completed" : "Pending",
              TRANSACTION_STATUS: "Success",
            })
            .then((res) => {
              if (res) {
                console.log(res);
                setOrderID(res.data.ID);
                setShowCODModal(false);
                nextStep();
                // localStorage.setItem("orderID", JSON.stringify(res.data.ID));
                orderIDReturned = res.data.ID;
              }
            });
        }

        // const updatedPayload = {
        //   ...razorPayData,
        //   TRANSACTION_ID: paymentId,
        //   TRANSACTION_AMT: minPayableAmount,
        // };

        // axios.post(`${BaseUrl}/BookPooja`, updatedPayload).then((res) => {
        //   debugger;
        //   if (res.data.ResponseMessage === "Order Success.") {
        //     localStorage.setItem("cartItemCount", "0");
        //     window.dispatchEvent(new Event("cartUpdated"));
        //     navigate("/thank-you");
        //   } else {
        //     alert("Order placement failed, please try again.");
        //   }
        // });
      },
      theme: {
        color: "#ff416c",
      },
    };

    const rzp = new window.Razorpay(options);
    rzp.open();
  };

  const handlePanditSelectionNext = () => {
    // debugger;
    if (
      selectedPandit !== null &&
      selectedPandit !== undefined &&
      selectedPandit !== ""
    ) {
      // console.log("Panditselection");
      nextStep();
    } else {
      setPanditSelectionError(true);
    }
  };

  const handleToDateChange = (newValue) => {
    if (dayjs(newValue).isValid()) {
      const newToDate = dayjs(newValue).format("YYYY-MM-DD");
      setProductBookingData((prev) => ({ ...prev, PATB_DATE: newToDate }));
    } else {
      setProductBookingData((prev) => ({ ...prev, PATB_DATE: null }));
    }
  };

  const handleLanguageChange = (selectedValue) => {
    setProductBookingData((prev) => ({
      ...prev,
      languageID: selectedValue.value,
    }));
  };

  const handlePanditCardClicked = (pandit) => {
    setSelectedPandit(pandit.PANDIT_ID);
    // console.log(pandit);
  };

  const handleCODClicked = () => {
    setModelRequestData((prev) => ({
      ...prev,
      totalPrice: totalPrice,
    }));
    setShowCODModal(true);
  };

  console.log(totalPrice);
  const renderStarRating = (rating) => {
    const stars = [];
    for (let i = 0; i < 5; i++) {
      if (i < Math.floor(rating)) {
        stars.push(<i key={i} className="fa fa-star filled-star"></i>); // Full Star
      } else if (i < rating) {
        stars.push(<i key={i} className="fa fa-star-half-alt half-star"></i>); // Half Star
      } else {
        stars.push(<i key={i} className="fa fa-star empty-star"></i>); // Empty Star
      }
    }
    return stars;
  };
  const handleFindPandit = () => {
    setFindPandit(true);
    nextStep();
  };

  const Tax = Math.round(
    (ServiceDiscounts.PujaAtTemple / 100) * Number(FinaltotalPrice)
  );
  const grandTotal = parseFloat(Math.round(FinaltotalPrice) || 0);
  console.log('location', location?.state)
  return (
    <div style={{ background: "#F1F6F7", paddingTop: "30px" }}>
      {/* Step Indicator */}
      <div className="stepper-wrapper">
        {steps.map((step, index) => (
          <div
            key={index}
            className={`stepper-item ${index < currentStep
              ? "completed"
              : index === currentStep
                ? "active"
                : ""
              }`}
          >
            <div className="step-counter">{index + 1}</div>
            <div className="step-name" style={{ fontSize: "small" }}>
              {step}
            </div>
          </div>
        ))}
      </div>

      {/* Step Content */}
      <div
        className="step-content"
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "10px 30px",
          background: "#F1F6F7",
        }}
      >
        {currentStep === 0 && (
          <>
            <div className="booking-details-container ">
              <div
                className="booking-details-left"
              // style={{
              //   padding: "10px 20px",
              //   display: "flex",
              //   alignItems: "center",
              //   justifyContent: "center",
              // }}
              >
                <h5>Booking Details</h5>
                <hr />
                <div className="row">
                  <div className="col-md-6 col-sm-12 mb-2 d-flex flex-column">
                    <label htmlFor="bookingDate" style={{ textAlign: "left" }}>
                      Date<span style={{ color: "red" }}>*</span>
                    </label>
                    <DatePicker
                      id="bookingDate"
                      value={productBookingData?.PATB_DATE || ""}
                      minDate={new Date(new Date().setDate(new Date().getDate() + 3))}
                      clearIcon={null}
                      onChange={handleToDateChange}
                      format="dd/MM/yyyy"
                      className="custom-date-picker"
                    />
                    {error && !productBookingData.PATB_DATE && (
                      <span style={{ color: "red" }}>{ERROR_MESSAGES}</span>
                    )}
                  </div>
                  <div className="col-md-6 col-sm-12 mb-2 d-flex flex-column">
                    <label htmlFor="bookingTime" style={{ textAlign: "left" }}>
                      Time<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      id="bookingTime"
                      type="time"
                      className="form-control"
                      value={productBookingData.PATB_TIME || ""}
                      onClick={(e) =>
                        e.target.showPicker && e.target.showPicker()
                      }
                      onChange={(e) =>
                        setProductBookingData((prev) => ({
                          ...prev,
                          PATB_TIME: e.target.value,
                        }))
                      }
                    />
                    {error && !productBookingData.PATB_TIME && (
                      <span style={{ color: "red" }}>{ERROR_MESSAGES}</span>
                    )}
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6 col-sm-12 mb-2 d-flex flex-column">
                    <label htmlFor="language" style={{ textAlign: "left" }}>
                      Select Language<span style={{ color: "red" }}>*</span>
                    </label>
                    <Select
                      options={languageList}
                      onChange={handleLanguageChange}
                      value={languageList.find(
                        (item) => item.value === productBookingData.languageID
                      )}
                      styles={{
                        control: (base) => ({
                          ...base,
                          height: "45px",
                        }),
                      }}
                    />
                    {error && !productBookingData.languageID && (
                      <span className="error-msg">{ERROR_MESSAGES}</span>
                    )}
                  </div>{" "}
                  <div className="col-md-6 col-sm-12 mb-2 d-flex flex-column"></div>{" "}
                </div>
                <div className="row">
                  <div className="col-md-6 col-sm-12 mb-2 d-flex flex-column">
                    <label
                      htmlFor="needSamagri"
                      style={{ marginRight: "5px " }}
                    >
                      Do you want Samagri for this Puja?
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <div>
                      <label className="me-3">
                        <input
                          type="radio"
                          name="samagri"
                          value="yes"
                          checked={productBookingData.needSamagri === "yes"}
                          onChange={(e) =>
                            setProductBookingData((prev) => ({
                              ...prev,
                              needSamagri: e.target.value,
                            }))
                          }
                          className="me-1"
                        />
                        Yes
                      </label>
                      <label>
                        <input
                          type="radio"
                          name="samagri"
                          value="no"
                          checked={productBookingData.needSamagri === "no"}
                          onChange={(e) =>
                            setProductBookingData((prev) => ({
                              ...prev,
                              needSamagri: e.target.value,
                            }))
                          }
                          className="me-1"
                        />
                        No
                      </label>
                    </div>
                    {error &&
                      (productBookingData.needSamagri === null ||
                        productBookingData.needSamagri === undefined ||
                        productBookingData.needSamagri === "") ? (
                      <span style={{ color: "red" }}>{ERROR_MESSAGES}</span>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="col-md-6 col-sm-12 mb-2 d-flex flex-column">
                    <span>
                      Your puja samagri amount is: ₹{" "}
                      {location.state.PAT_SAMAGRIPRICE}
                    </span>
                  </div>
                </div>
              </div>
              <div className=" booking-details-right">
                <div className="price-summary">
                  <h5>{location?.state?.PAT_NAME}</h5>
                  <hr />
                  <ul>
                    <li>
                      <span>Collected on behalf of pandit <br></br> & paid to pandit:</span>
                      <span> <br></br>Rs {location.state.PAT_PRICE}</span>
                    </li>
                    <hr></hr>
                    {productBookingData.needSamagri === "yes" && (
                      <>
                        <li>
                          <span>Samagri Price:</span>
                          <span>Rs {location.state?.PAT_SAMAGRIPRICE}</span>
                        </li>
                        <li>
                          <span>Samagri Tax ({location.state?.SamagriPriceTaxPercent}%) :</span>
                          <span>
                            + Rs{" "}
                            {productBookingData.needSamagri === "yes"
                              ?
                              Number(Math.round(Number(location.state?.SamagriPriceTaxAmount)))

                              : 0}
                          </span>
                        </li>
                        <hr></hr>
                      </>

                    )}

                    <li>
                      <span>Convenience Fee:</span>
                      <span>Rs {location.state.PAT_CONVENIENCEFEE || 0}</span>
                    </li>
                    <li>
                      <span> Convenience  Tax ({location.state?.ConvinienceFeeTaxPercent}%):</span>
                      <span>Rs {Math.round(location.state?.ConvinienceFeeTaxAmount)}</span>
                    </li>
                    <hr />


                    <li>
                      <span>Grand Total :</span>
                      <span> Rs {Math.round(grandTotal)}</span>
                    </li>
                  </ul>
                  {/* <p>Payment Type: COD</p>
                <button className="place-order-btn">Place Order</button> */}
                </div>
              </div>
            </div>
          </>
        )}

        {/* {currentStep === 1 && (
          <div className="panditList" style={{ width: "100%" }}>
            <div className="container">
              <div className="row mb-4">
                <div className="col-md-12 px-lg-5">

                  <div className="padit-list-products mt-3">
                    <div className="row">
                      {panditDataList?.map((pandit) => (
                        <div
                          className="col-lg-3 col-md-4 col-sm-6 col-12 mb-4"
                          key={pandit.PANDIT_ID}
                        >
                          <div
                            className={`card pandit-card ${selectedPandit === pandit.PANDIT_ID
                              ? "selected"
                              : ""
                              }`}
                            onClick={() => handlePanditCardClicked(pandit)}
                          >
                            <div
                              className="card-img-container"
                              style={{ padding: "10px", objectFit: "cover" }}
                            >
                              <img
                                src={pandit.PHOTO}
                                onError={(e) => {
                                  e.target.onerror = null;
                                  e.target.src = placeholderImg;
                                }}
                                alt={pandit.PANDIT_NAME}
                                className="card-img-top"
                              />
                            </div>

                            <div className="card-body text-center">
                              <h5 className="card-title">
                                {pandit.PANDIT_NAME}
                              </h5>
                              <p className="card-text">
                                <strong>Mobile:</strong> {pandit.MOBILE_NO}
                              </p>
                              <p className="card-text">
                                <strong>Experience:</strong> {pandit.EXPERIENCE}{" "}
                                years
                              </p>
                              <p className="card-text">
                                <strong>DOB:</strong>{" "}
                                {new Date(pandit.DOB).toLocaleDateString()}
                              </p>

                              <div className="rating">
                                {renderStarRating(
                                  parseFloat(pandit.PANDIT_RATING)
                                )}
                                <span className="rating-text">
                                  ({pandit.PANDIT_RATING})
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {" "}
                    {panditSelectionError &&
                      (selectedPandit === null ||
                        selectedPandit === undefined ||
                        selectedPandit === "") ? (
                      <span style={{ color: "red" }}>Please Select Pandit</span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <span className="text-center">
                  {" "}
                  Didn't find a pandit you like?
                  <span
                    onClick={handleFindPandit}
                    style={{ color: "#a42642", cursor: "pointer" }}
                  >
                    {" "}
                    Click here, and we will find one for you.
                  </span>
                </span>
              </div>
            </div>
          </div>
        )} */}

        {currentStep === 1 && (
          <div className="py-4" style={{ width: "100%" }}>
            <div className="p-3">
              <div className="row g-4 d-flex">
                <div
                  className="booking-preview col-md-5 text-center"
                  style={{
                    flex: "2",
                  }}
                >
                  <div className="booking-preview-details-puja">
                    <div className="booking-preview-details-puja-top">
                      <div className="booking-preview-details-puja-top-left">
                        <img
                          src={location?.state?.PAT_IMAGE}
                          onError={(e) => {
                            e.target.onerror = null; // Prevent infinite loop
                            // e.target.src = placeholderImg; // Set fallback image
                          }}
                          alt=""
                        />
                      </div>
                      <div className="booking-preview-details-puja-top-right">
                        <span className="puja-name">
                          {location?.state?.PAT_NAME}
                        </span>
                        <span>
                          Booking Language:{" "}
                          {
                            languageList.filter(
                              (item) =>
                                item.value === productBookingData.languageID
                            )[0]?.label
                          }
                        </span>
                        <span>
                          Booking Date: {productBookingData.PATB_DATE}
                        </span>
                        <span>
                          Booking Time: {productBookingData.PATB_TIME}
                        </span>
                        {/* <span>
                          Pandit Name:{" "}
                          {
                            panditDataList.filter(
                              (item) => item.PANDIT_ID === selectedPandit
                            )[0]?.PANDIT_NAME
                          }
                        </span> */}
                        <p className="fw-bold">Note : You will receive pandit details within 24 hours</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className=" booking-details-right">
                  <div className="price-summary">
                    <h5>{location?.state?.PAT_NAME}</h5>
                    <hr />
                    <ul>
                      <li>
                        <span>Collected on behalf of pandit <br></br> & paid to pandit:</span>
                        <span> <br></br>Rs {location.state.PAT_PRICE}</span>
                      </li>
                      <hr></hr>
                      {productBookingData.needSamagri === "yes" && (
                        <>
                          <li>
                            <span>Samagri Price:</span>
                            <span>Rs {location.state?.PAT_SAMAGRIPRICE}</span>
                          </li>
                          <li>
                            <span>Samagri Tax ({location.state?.SamagriPriceTaxPercent}%) :</span>
                            <span>
                              + Rs{" "}
                              {productBookingData.needSamagri === "yes"
                                ?
                                Number(Math.round(Number(location.state?.SamagriPriceTaxAmount)))

                                : 0}
                            </span>
                          </li>
                          <hr></hr>
                        </>

                      )}

                      <li>
                        <span>Convenience Fee:</span>
                        <span>Rs {location.state.PAT_CONVENIENCEFEE || 0}</span>
                      </li>
                      <li>
                        <span> Convenience  Tax ({location.state?.ConvinienceFeeTaxPercent}%):</span>
                        <span>Rs {Math.round(location.state?.ConvinienceFeeTaxAmount)}</span>
                      </li>
                      <hr />


                      <li>
                        <span>Grand Total :</span>
                        <span> Rs {Math.round(grandTotal)}</span>
                      </li>
                    </ul>
                    <div className="mt-3 d-flex justify-content-end gap-2">
                      {/* <button
                        style={{ backgroundColor: "#a53444", color: "white" }}
                        className="btn px-4"
                        onClick={handleCODClicked}
                      >
                        Cash On Delivery
                      </button> */}
                      <button
                        style={{ backgroundColor: "#a53444", color: "white" }}
                        className="btn px-4"
                        onClick={handleRazorpayPayment}
                      >
                        Pay
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {currentStep === 2 && (
          <div>
            <div className="carousel-inner" role="listbox">
              <div className="item carousel-item-four active">
                <div className="">
                  <div className="row">
                    <div className="carousel slide carousel-slider">
                      <div className="carousel-position-four">
                        <div className="carousel-inner">
                          <div
                            className="banner-breadcrumb"
                            style={{
                              // backgroundImage: `url(${StupaImg})`,
                              width: "100%",
                              height: "100px",
                            }}

                          // style={{ backgroundImage: `url(${AboutImg})` }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row margin-bottom-40">
              <div className="col-md-12 col-sm-12">
                <div className="thankyou-box text-center bg-white p-5">
                  <h2
                    style={{
                      fontFamily: "Ubuntu",
                      textTransform: "capitalize",
                      color: "#504e4e",
                      fontSize: "32px",
                      marginBottom: "20px",
                    }}
                  >
                    Thank You for Ordering !
                  </h2>
                  <h4
                    className="text-success"
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "18px",
                      fontWeight: "450",
                    }}
                  >
                    Your Order Is Successful
                  </h4>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* Navigation Buttons */}
      {currentStep === 0 ? (
        <div
          className="navigation-buttons"
          style={{ marginRight: "50px", paddingBottom: "30px" }}
        >
          {/* <button
          onClick={prevStep}
          disabled={currentStep === 0}
          className="btn btn-secondary"
          style={{ backgroundColor: "#d7d7d7", color: "black" }}
        >
          Previous
        </button> */}
          <button
            onClick={
              currentStep === 0
                ? HandleProductBookingBtnClicked
                : currentStep === 1
                  ? handlePanditSelectionNext
                  : ""
            }
            disabled={currentStep === steps.length - 1}
            className="next-btn"
          >
            Next
          </button>
        </div>
      ) : currentStep === 1 ? (
        <div
          className="navigation-buttons"
          style={{ marginRight: "50px", paddingBottom: "30px" }}
        >
          <button
            className="btn btn-secondary"
            style={{ backgroundColor: "#d7d7d7", color: "black" }}
            onClick={prevStep}
          >
            Previous
          </button>
        </div>
      ) : currentStep === 2 ? (
        <div
          className="navigation-buttons"
          style={{ marginRight: "50px", paddingBottom: "30px" }}
        >
          <button className=" prev-btn" onClick={() => navigate("/")}>
            Home
          </button>
          <button
            className=" prev-btn"
            onClick={() => navigate("/my-order-puja-At-Temple")}
          >
            My Orders
          </button>
        </div>
      ) : (
        <div
          className="navigation-buttons"
          style={{ marginRight: "50px", paddingBottom: "30px" }}
        >
          <button
            onClick={prevStep}
            disabled={currentStep === 0}
            className="btn btn-secondary"
            style={{ backgroundColor: "#d7d7d7", color: "black" }}
          >
            Previous
          </button>
          <button
            onClick={
              currentStep === 0
                ? HandleProductBookingBtnClicked
                : currentStep === 1
                  ? handlePanditSelectionNext
                  : ""
            }
            disabled={currentStep === steps.length - 1}
            className="next-btn"
          >
            Next
          </button>
        </div>
      )}
      {/* <CODModal
        show={showCODModal}
        onHide={() => setShowCODModal(false)}
        onConfirm={handleRazorpayPayment}
        modelRequestData={modelRequestData}
        setModelRequestData={setModelRequestData}
      /> */}
    </div>
  );
};

export default PujaAtTempleBookingForm;
