import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import React, { useContext, useEffect, useRef, useState } from 'react'
import logo from '../assets/images/logo.png'
import { GetPanditBookingPaymentReceipt } from '../ApiServices/Receipt/ReceiptApi';
import { useLocation, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
const ReceiptDetailsPage = () => {
    const receiptRef = useRef()
    const location = useLocation()
    const navigate = useNavigate()
    const user_id = localStorage.getItem("userID");
    const [receiptObj, setReceiptObj] = useState([])

    useEffect(() => {
        GetPanditBookingPaymentReceiptData()
    }, [])
    const GetPanditBookingPaymentReceiptData = async () => {

        try {
            const response = await GetPanditBookingPaymentReceipt(
                location?.state?.orderID,
                location?.state?.moduleName
            );
            console.log(response);
            if (
                response.ResponseMessage === "list" &&
                response.ResponseCode === "0"
            ) {
                const modelData = response.DATA;
                setReceiptObj(modelData)
            }
        } catch (error) {
            console.log(error);
        }
    };



    const handleDownloadPDF = () => {
        if (!receiptRef.current) return;

        html2canvas(receiptRef.current, { scale: 2 }).then((canvas) => {
            const imgData = canvas.toDataURL("image/png");
            const pdf = new jsPDF("p", "mm", "a4");

            const imgWidth = 210; // A4 width in mm
            const imgHeight = (canvas.height * imgWidth) / canvas.width;

            const marginTop = 10; // Add margin at the top

            pdf.addImage(imgData, "PNG", 0, marginTop, imgWidth, imgHeight);
            pdf.save(`Receipt_${receiptObj?.receiptNumber}.pdf`);
        });
    };

    return (
        <>
            <div ref={receiptRef}>
                <div
                    className="border border-dark p-4 m-4 w-75 mx-auto shadow bg-white mt-4"
                    style={{ borderWidth: '2px' }}
                >
                    <div style={{ padding: '10px' }}>
                        <div className="d-flex justify-content-between align-items-start">
                            <div>
                                <img src={logo} height={70} width={120} alt="Om Namo Logo" />
                            </div>
                            <div className="border border-2 border-dark px-4 py-2 fw-bold fs-5">Receipt</div>
                        </div>
                        <table className="table table-bordered mt-4 small">
                            <tbody>
                                <tr>
                                    <td className="fw-bold">Payment Date</td>
                                    <td className="fw-bold">Payment Amount</td>
                                    <td className="fw-bold">Payment Type</td>
                                    <td className="fw-bold">Note</td>
                                    <td className="fw-bold">Action</td>
                                </tr>
                                {receiptObj?.map((value, index) => (
                                    <tr key={index}>
                                        <td>{dayjs(value?.PaymentDate).format('DD/MM/YYYY')}</td>
                                        <td>{value?.PaymentAmount}</td>
                                        <td>{value?.PaymentType}</td>
                                        <td>{value?.Note}</td>
                                        <td ><button className='btn' style={{ backgroundColor: '#A52444', color: 'white' }}
                                            onClick={(() => navigate("/view-receipt", {
                                                state: value,
                                            }))}>view</button></td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>



                    </div>
                </div>
            </div>
            {/* <div className="mt-4 pt-2 text-center fw-bold">
                <button onClick={handleDownloadPDF} className="btn" style={{ backgroundColor: '#A52444', color: 'white' }}>Download</button>
            </div> */}

        </>
    )
}

export default ReceiptDetailsPage
