import { postApiWithAuthorization, postApiWithoutAuthorization } from "../ApiMethod/ApiMethod";


export const birth_details = async (params) => {
    const url = `https://json.astrologyapi.com/v1/birth_details`;
    const res = await postApiWithAuthorization(url, params);
    return res;
};
export const astro_details = async (params) => {
    const url = `https://json.astrologyapi.com/v1/astro_details`;
    const res = await postApiWithAuthorization(url, params);
    return res;
};
export const basic_panchang = async (params) => {
    const url = `https://json.astrologyapi.com/v1/basic_panchang`;
    const res = await postApiWithAuthorization(url, params);
    return res;
};
export const numero_table = async (params) => {
    const url = `https://json.astrologyapi.com/v1/numero_table  `;
    const res = await postApiWithAuthorization(url, params);
    return res;
};
export const daily_nakshatra_prediction = async (params) => {
    const url = `https://json.astrologyapi.com/v1/daily_nakshatra_prediction  `;
    const res = await postApiWithAuthorization(url, params);
    return res;
};
