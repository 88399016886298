import React, { useEffect, useState } from "react";
import { FaRegCalendarAlt, FaRegClock, FaSun, FaMoon } from "react-icons/fa";
import { basic_panchang } from "../../../ApiServices/Horoscope/BirthDetailsApi";
import { GetUserModel } from "../../../ApiServices/Profile/ProfileApi";
import dayjs from "dayjs";

const DailyPanchang = () => {
  const [animate, setAnimate] = useState(false);
  const [panchangDetails, setPanchangDetailsObj] = useState([]);
  const [otherDetails, setOtherDetailsObj] = useState([]);
  useEffect(() => {
    GetUserModelData();
    setTimeout(() => {
      setAnimate(true);
    }, 300); // Delay animation to make it smoother
  }, []);

  const GetUserModelData = async () => {
    // setLoader(true);

    const userID = JSON.parse(localStorage.getItem("userID"));
    try {
      const response = await GetUserModel(userID);
      if (response?.ResponseCode == 0) {
        // setLoader(false);
        const ModelData = response.DATA || []; // Assuming the data is an array and we need the first item
        // debugger
        const dob = new Date(ModelData[0]?.DOB);

        const year = dob.getFullYear();
        const month = dob.getMonth() + 1; // Months are zero-based (0 = January)
        const day = dob.getDate();

        const [hours, minutes] = ModelData[0]?.BIRTH_TIME.split(":");
        const apiParam = {
          day: day,
          month: month,
          year: year,
          hour: hours,
          min: minutes,
          lat: 25.7464,
          lon: 25.7464,
          tzone: 5.5,
        };
        await GetBasicPanchangData(
          apiParam,
          dayjs(ModelData[0]?.DOB).format("DD/MM/YYYY")
        );
      } else {
        // setLoader(false);

        console.error(
          "Error fetching data: ",
          response?.data?.data?.statusCode
        );
      }
    } catch (error) {
      // setLoader(false);

      console.error("Error", error);
    }
  };
  const GetBasicPanchangData = async (apiParam, birthDate) => {
    try {
      const response = await basic_panchang(apiParam);
      if (response !== null && response !== undefined) {
        // setLoader(false);
        setPanchangDetailsObj((prev) => ({
          ...prev,
          tithi: response?.tithi,
          nakshatra: response?.nakshatra,
          yog: response?.yog,
          karan: response?.karan,
          vedic_sunrise: response?.vedic_sunrise,
          vedic_sunset: response?.vedic_sunset,
        }));
        setOtherDetailsObj((prev) => ({
          ...prev,
          birthDate: birthDate,
          moonRise: "05:30",
          moonSet: "06:30",
          Day: "Monday",
          sunrise: response?.sunrise,
          sunset: response?.sunset,
        }));
      } else {
        // setLoader(false);

        console.error("Error fetching data: ", response);
      }
    } catch (error) {
      // setLoader(false);

      console.error("Error", error);
    }
  };
  return (
    <>
      <div className="container mt-4">
        {/* Top Section: Two Cards Per Row & Panchang Table */}
        <div className="row">
          {/* Left Side: Two Cards Per Row */}
          <div className="col-md-7">
            <div className="row">
              {Object?.entries(otherDetails || {}).map(
                ([key, value], index) => (
                  <div className="col-md-6 mb-3" key={index}>
                    <div className="card shadow-sm p-3 text-center panchangCard">
                      <h6 className="text-uppercase ">
                        <span className="fw-bold"> {key}</span>
                      </h6>
                      <p className="mb-0">{value}</p>
                    </div>
                  </div>
                )
              )}
            </div>
          </div>

          {/* Right Side: Panchang Table */}
          <div className="col-md-5 ">
            <div className="p-3 border rounded bg-white shadow-sm">
              <h5 className="text-center fw-bold">PANCHANG DETAILS</h5>
              <table className="table">
                <tbody>
                  {Object?.entries(panchangDetails || {}).map(
                    ([key, value], index) => (
                      <tr key={index}>
                        <th className="text-left">{key}</th>
                        <th className="text-center">:</th>
                        <td className="text-left">{value}</td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        {/* Bottom Section: Full Panchang Table */}
        {/* <div className="mt-4 p-3 border rounded bg-white shadow-sm">
                    <h5 className="text-center fw-bold">PANCHANG DETAILS</h5>
                    <table className="table">
                        <tbody>
                            {astroDetails.map(({ key, label, timeStart, timeEnd }, index) => (
                                <tr key={index} className="table-row">
                                    <th className="text-start">{key}</th>
                                    <th>:</th>
                                    <td>{label}</td>
                                    {timeStart && <td>{timeStart}</td>}
                                    {timeEnd && <td>{timeEnd}</td>}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div> */}
      </div>
    </>
  );
};

export default DailyPanchang;
