import React from "react";
import WhyChooseUs1 from "../../assets/images/shapes/about-3-shape-1.png";
import WhyChooseUs2 from "../../assets/images/new/banner1 (2).jpg";
import WhyChooseUs3 from "../../assets/images/new/whyc.jpg";
import WorshipImg from "../../assets/images/new/worship.png";
import whyChooseUs1 from "../../assets/images/new/whyChooseUs1.jpg";
import whyChooseUs2 from "../../assets/images/new/whyChooseUs2.jpg";
import batuk from "../../assets/images/new/fav-removebg-preview.png";
import whyChooseUsImg from "../../assets/images/new/whyChooseUs.png";

const WhyChooseUs = () => {
  return (
    <section className="about-three">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 wow fadeInLeft" data-wow-delay="100ms">
            <div className="about-three__content">
              <div className="sec-title text-left">
                <h6 className="sec-title__tagline bw-split-in-right">
                  <img className=" tagline-img" src={batuk} alt="" /> Why Choose
                  Us
                </h6>
                <h3 className="sec-title__title bw-split-in-left">
                  Easy and Helpful Spiritual Services
                </h3>
              </div>
              <p className="about-three__content__text text-dark">
                Choose My OmNamo for easy and reliable spiritual services. We
                make booking pandits, pujas, horoscopes, and temple visits
                simple and convenient.
                <br /> We connect you with experienced pandits for personalized
                rituals, ensuring authenticity and convenience. With features
                like daily puja bookings, darshan booking, and we cater to
                diverse spiritual needs.
              </p>
              <p className="about-three__content__text text-dark">
                Our user-friendly services simplify the process, making
                spiritual engagements more accessible. We provide a blend of
                tradition and modernity, ensuring your spiritual journey is both
                meaningful and effortless.
              </p>
              {/* <div className="row gutter-y-30">
                <div className="col-md-6">
                  <div
                    className="about-three__content__box"
                    style={{ "--accent-color": "#ffa415" }}
                  >
                    <div className="about-three__content__box__icon">
                      <span className="icon-zakat"></span>
                    </div>
                    <h5 className="about-three__content__box__title">
                      Treatment Help
                    </h5>
                  </div>
                </div>
                <div className="col-md-6">
                  <div
                    className="about-three__content__box"
                    style={{ "--accent-color": "#ff5528" }}
                  >
                    <div className="about-three__content__box__icon">
                      <span className="icon-charity-two"></span>
                    </div>
                    <h5 className="about-three__content__box__title">
                      Start Donate
                    </h5>
                  </div>
                </div>
              </div> */}
              {/* <p className="about-three__content__text-two">
                Poor address a range of simply application and infrastructure
                this of passages of available, but the Poor.
              </p> */}
            </div>
          </div>
          <div className="col-lg-6">
            <div
              className=""
              data-wow-delay="200ms"
              style={{ paddingTop: "0" }}
            >
              <div className="about-three__image__shape-one">
                {/* <img src={WhyChooseUs1} alt="careox" /> */}
              </div>
              <img src={whyChooseUsImg} className="height2" alt="careox" />
              <div
                className="about-three__image__right wow fadeInUp"
                data-wow-delay="300ms"
              >
                {/* <img src={whyChooseUs2} className="h10" alt="careox" /> */}
              </div>
              <div className="about-three__image__shape-two">
                {/* <img src={WorshipImg} alt="careox" /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhyChooseUs;
