import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

const BookingReviewModal = ({ show, onHide, product, productBookingData }) => {
  const totalPrice =
    parseFloat(product?.pujaPrice || 0) +
    (productBookingData.needSamagri === "yes"
      ? parseFloat(product?.pujaSamagriPrice || 0)
      : 0) +
    parseFloat(product?.DAKSHINA || 0) +
    parseFloat(product?.convenienceFee || 0) +
    parseFloat(product?.TAX || 0) -
    parseFloat(product?.pujaDiscount || 0); // Subtract discount if applicable

  console.log("productBookingData", productBookingData);

  return (
    <Modal
      show={show}
      onHide={onHide}
      backdrop="static"
      keyboard={false}
      centered
      size="md"
    >
      <Modal.Header closeButton>
        <Modal.Title className="fw-bold">Booking Review</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="text-center">
          <h5 className="fw-bold">{product?.pujaName}</h5>
          <img
            src={product?.pujaImage || ""}
            alt={product?.pujaName}
            className="img-fluid mb-3"
            style={{ maxWidth: "250px" }}
          />

          <div className="row">
            <div className="col-6 mb-2 d-flex">
              <strong>Price:</strong>
            </div>
            <div className="col-6 text-end">Rs {product.pujaPrice || 0}</div>

            <div className="col-6 mb-2 d-flex">
              <strong>Samagri Price:</strong>
            </div>
            <div className="col-6 text-end">
              Rs {product.pujaSamagriPrice || 0}
            </div>
            <div className="col-6 mb-2 d-flex">
              <strong>Convenience Fee:</strong>
            </div>
            <div className="col-6 text-end">
              Rs {product.convenienceFee || 0}
            </div>
            <div className="col-6 mb-2 d-flex">
              <strong>Puja Discount:</strong>
            </div>
            <div className="col-6 text-end">
              - Rs {product.pujaDiscount || 0}
            </div>
            <div className="col-6 mb-2 d-flex">
              <strong>Tax:</strong>
            </div>
            <div className="col-6 text-end">Rs 0</div>
            <div className="col-6 mb-2 d-flex">
              <strong>Total:</strong>
            </div>
            <div className="col-6 text-end">Rs {totalPrice}</div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          CLOSE
        </Button>
        <Button variant="primary">BOOK NOW</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default BookingReviewModal;
