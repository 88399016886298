import { useContext, useEffect, useState } from "react";
import { useParams, Link, Navigate, useNavigate } from "react-router-dom";
import { Container, Card, Button, Row, Col, Image } from "react-bootstrap";
import { DarshanBook_TempleList } from "../../ApiServices/Darshan Booking/DarshanBookingApi";
import LoginPopUp from "../LoginPopup";
import { AuthContextProvider } from "../../AuthContext/AuthContext";

// API function (replace this with your actual function)

const TempleDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams(); // Get temple ID from URL
  const [loginPopUp, setLoginPopUp] = useState(null);
  const [temple, setTemple] = useState(null);
  const [loading, setLoading] = useState(true);
  const { isAuthenticated } = useContext(AuthContextProvider);

  const [modelRequestData, setModelRequestData] = useState({
    Action: null,
    DARSHAN_PRICE: null,
    DARSHAN_TIME: null,
    TR_ID: null,
    CONVENIENCE_FEE: null,
    TR_SHORTDETAILS: null,
  });

  useEffect(() => {
    const fetchTempleDetails = async () => {
      const userID = localStorage.getItem("userID") || "";

      const params = {
        START: 0,
        END: 10,
        WORD: "None",
        LATITUDE: "20.008581",
        LONGITUDE: "73.76413099999999",
      };

      try {
        const response = await DarshanBook_TempleList(params, userID);
        if (
          response.ResponseCode === "0" &&
          response.ResponseMessage === "list"
        ) {
          const selectedTemple = response.DATA.find(
            (item) => item.TR_ID === parseInt(id)
          );
          if (selectedTemple) {
            setTemple(selectedTemple);
          }
        }
      } catch (error) {
        console.error("Error fetching temple details:", error);
      }
      setLoading(false);
    };

    fetchTempleDetails();
  }, [id]);

  if (loading) {
    return <div className="text-center mt-5">Loading temple details...</div>;
  }

  if (!temple) {
    return <div className="text-center mt-5">Temple not found.</div>;
  }

  const clickToBook = (temple) => {
    console.log(temple, "ddddddddddds33");

    {
      setModelRequestData({
        ...modelRequestData,
        Action: null,
        DARSHAN_PRICE: temple.DARSHAN_PRICE,
        DARSHAN_TIME: temple.DARSHAN_TIME,
        TR_ID: temple.TR_ID,
        CONVENIENCE_FEE: temple.CONVENIENCE_FEE,
        TR_SHORTDETAILS: temple.TR_SHORTDETAILS,

        BEST_SEASON: temple.BEST_SEASON,
        CONVENIENCE_FEE: temple.CONVENIENCE_FEE,
        DARSHAN_PRICE: temple.DARSHAN_PRICE,
        DARSHAN_TIME: temple.DARSHAN_TIME,
        LATITUDE: temple.LATITUDE,
        LONGITUDE: temple.LONGITUDE,
        Row: temple.Row,
        SEATING_CAPACITY: temple.SEATING_CAPACITY,
        TR_ADDRESS: temple.TR_ADDRESS,
        TR_DETAILS: temple.TR_DETAILS,
        TR_ID: temple.TR_ID,
        TR_IMAGE: temple.TR_IMAGE,
        TR_NAME: temple.TR_NAME,
        TR_RULES: temple.TR_RULES,
        TR_SHORTDETAILS: temple.TR_SHORTDETAILS,
      });
    }
    let addRawMaterialRequestData = {
      Action: null,
      DARSHAN_PRICE: temple.DARSHAN_PRICE,
      DARSHAN_TIME: temple.DARSHAN_TIME,
      TR_ID: temple.TR_ID,
      CONVENIENCE_FEE: temple.CONVENIENCE_FEE,
      TR_SHORTDETAILS: temple.TR_SHORTDETAILS,
      BEST_SEASON: temple.BEST_SEASON,
      CONVENIENCE_FEE: temple.CONVENIENCE_FEE,
      DARSHAN_PRICE: temple.DARSHAN_PRICE,
      DARSHAN_TIME: temple.DARSHAN_TIME,
      LATITUDE: temple.LATITUDE,
      LONGITUDE: temple.LONGITUDE,
      Row: temple.Row,
      SEATING_CAPACITY: temple.SEATING_CAPACITY,
      TR_ADDRESS: temple.TR_ADDRESS,
      TR_DETAILS: temple.TR_DETAILS,
      TR_ID: temple.TR_ID,
      TR_IMAGE: temple.TR_IMAGE,
      TR_NAME: temple.TR_NAME,
      TR_RULES: temple.TR_RULES,
      TR_SHORTDETAILS: temple.TR_SHORTDETAILS,
      Action: null,
    };
    if (isAuthenticated) {
      navigate("/temple-booking-form", { state: addRawMaterialRequestData });
    } else {
      setLoginPopUp(true);
    }
  };

  return (
    <Container className="mt-4">
      <Row className="justify-content-center">
        <Col lg={10}>
          <Card className="shadow-lg p-3 border-0">
            <Row className="g-4 align-items-center">
              {/* Temple Image */}
              <Col md={5} className="text-center">
                <Image
                  //   src={temple.TR_IMAGE}
                  src={temple.TR_IMAGE}
                  alt={temple.TR_NAME}
                  width={100}
                  height={80}
                  fluid
                  className="rounded w-100 shadow-sm"
                />
              </Col>

              {/* Temple Details */}
              <Col md={7}>
                <Card.Body>
                  <h2 className="fw-bold mb-3 ">
                    {temple.TR_NAME}
                  </h2>
                  <p >
                    <strong>Location:</strong> {temple.TR_ADDRESS}
                  </p>
                  <p>
                    <strong>Darshan Price:</strong> ₹{temple.DARSHAN_PRICE}
                  </p>
                  <p>
                    <strong>Darshan Time:</strong> {temple.DARSHAN_TIME}
                  </p>
                  <p>
                    <strong>Best Season:</strong>{" "}
                    {temple.BEST_SEASON || "Anytime"}
                  </p>
                  <p >
                    <strong>Rules:</strong> {temple.TR_RULES}
                  </p>

                  {/* Action Buttons */}
                  <div className="d-flex flex-wrap gap-2 mt-3">
                    <Button
                    style={{background:'#8f3246'}}
                      href={`https://www.google.com/maps?q=${temple.LATITUDE},${temple.LONGITUDE}`}
                      target="_blank"
                     
                      className="px-4"
                    >
                      View on Map
                    </Button>
                    <Button
                      variant="success"
                      onClick={() => clickToBook(temple)}
                      className="btn text-white px-4"
                      style={{ background: "#8f3246" }}
                    >
                      Book Darshan
                    </Button>
                  </div>
                </Card.Body>
              </Col>
            </Row>
          </Card>

          {/* Detailed Description */}
          <Card className="mt-4 border-0 shadow-sm p-3 bg-light">
            <Card.Body>
              <h4 className="fw-bold">About {temple.TR_NAME}</h4>
              <p
                dangerouslySetInnerHTML={{ __html: temple.TR_SHORTDETAILS }}
              ></p>
            </Card.Body>
          </Card>

          {/* Back to Listings */}
          <div className="text-center mt-4">
            <Link to="/darshan-booking" className="btn btn-secondary px-4">
              Back to Temple List
            </Link>
          </div>
        </Col>
      </Row>
      {loginPopUp && (
        <LoginPopUp show={loginPopUp} onHide={() => setLoginPopUp(false)} />
      )}
    </Container>
  );
};

export default TempleDetails;
