import { BaseUrl } from "../../BaseUrl/BaseUrl";
import {
  getApiWithAuthorization,
  postApiWithoutAuthorization,
} from "../ApiMethod/ApiMethod";

export const PoojaAtTemple = async (params, USER_ID) => {
  const url = `${BaseUrl}/PoojaAtTemple?TYPE=All&USER_ID=${USER_ID}&TYPE=All`;
  const res = await postApiWithoutAuthorization(url, params);
  return res;
};

export const DarshanBook_TempleListGETTimeSlot = async (tr_ID, user_id) => {
  const url = `${BaseUrl}/DarshanBook_TempleList?TR_ID=${tr_ID}&USER_ID=${user_id}`;
  const res = await getApiWithAuthorization(url);

  return res;
};
export const LanguageList = async () => {
  // const url = `${BaseUrl}/GetPoojaList?TYPE=All&USER_ID=${user_id}`;
  const url = `${BaseUrl}/LanguageList`;
  const res = await getApiWithAuthorization(url);
  return res;
};
export const PoojaAtTempleInvoice = async (USER_ID, PATB_ID) => {
  // const url = `${BaseUrl}/GetPoojaList?TYPE=All&USER_ID=${user_id}`;
  const url = `${BaseUrl}/PoojaAtTempleInvoice?USER_ID=${USER_ID}&PATB_ID=${PATB_ID}`;
  const res = await getApiWithAuthorization(url);
  return res;
};
