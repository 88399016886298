import React, { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { FaCalendarAlt, FaSearch } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";

const BlogPost = () => {
  const location = useLocation();

  const blogObj = {
    blogID: location.state.currentBlog.BLG_ID,
    blogContent: location.state.currentBlog.BLOG,
    blogImg: location.state.currentBlog.BLOG_IMAGE,
    blogTitle: location.state.currentBlog.BLOG_TITLE,
    blogDate: location.state.currentBlog.REG_DATE,
    filteredBlogs: location.state.filteredBlogs,
  };

  const stripHtml = (html) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;
    return tempDiv.innerText || tempDiv.textContent || "";
  };

  const navigate = useNavigate();

  console.log(location.state);
  return (
    <div className="container mt-4">
      <div className="row">
        {/* Blog Content */}
        <div className="col-md-8">
          <div className="card border-0">
            <img src={blogObj.blogImg} className="card-img-top" alt="Farming" />
            <div className="card-body">
              <h2 className="fw-bold">{blogObj.blogTitle}</h2>
              {/* <p>{blogObj.blogContent}</p> */}
              <p dangerouslySetInnerHTML={{ __html: blogObj.blogContent }}></p>
              <div className="d-flex align-items-center gap-3">
                {/* <button className="btn btn-warning fw-bold text-white">
                  Innovations In Farming
                </button> */}
                <div className="d-flex align-items-center">
                  <div className="rounded-circle bg-light p-2"></div>
                  <span className="ms-2">Om Namo</span>
                </div>
                <div className="d-flex align-items-center">
                  <FaCalendarAlt className="text-warning me-2" />{" "}
                  {blogObj.blogDate}
                </div>
              </div>
              {/* <p className="mt-3">
                Sustainable farming practices are emerging as a transformative
                solution, offering a path toward environmental conservation and
                enhanced livelihoods for farmers.
              </p> */}
              {/* <h4 className="fw-bold">
                The Importance of Eco-Friendly Fertilizers and Modern Farming
                Techniques
              </h4>
              <p>
                Traditional farming methods often rely heavily on chemical
                inputs that degrade soil health and reduce productivity over
                time. Sustainable farming shifts this paradigm by emphasizing
                organic and eco-friendly fertilizers.
              </p> */}
            </div>
          </div>
        </div>

        {/* Sidebar */}
        <div className="col-md-4">
          <div className="mb-4 position-relative">
            <input
              type="text"
              className="form-control border-0 shadow-sm rounded-3 py-2"
              placeholder="Search Here..."
            />
            <FaSearch className="position-absolute top-50 end-0 me-3 translate-middle-y text-warning" />
          </div>

          {/* Categories */}

          {/* Blog Cards */}
          <div className="row" style={{ padding: "0", margin: "0" }}>
            {blogObj.filteredBlogs?.map((data, index) => (
              <div
                className="col-md-12 fade-in-up mb-3"
                key={index}
                style={{ padding: "0", margin: "0" }}
                onClick={() =>
                  navigate("/blog-post", {
                    state: {
                      currentBlog: {
                        blogID: location.state.currentBlog.BLG_ID,
                        blogContent: location.state.currentBlog.BLOG,
                        blogImg: location.state.currentBlog.BLOG_IMAGE,
                        blogTitle: location.state.currentBlog.BLOG_TITLE,
                        blogDate: location.state.currentBlog.REG_DATE,
                      },
                      filteredBlogs: blogObj.filteredBlogs,
                    },
                  })
                }
              >
                <div
                  className="card shadow-lg blog-card overflow-hidden rounded-3 border-0"
                  style={{ padding: "0", margin: "0" }}
                >
                  <div
                    className="row g-0"
                    style={{ padding: "0", margin: "0" }}
                  >
                    <div
                      className="col-md-4 position-relative"
                      style={{ padding: "0", margin: "0" }}
                    >
                      <img
                        src={data.BLOG_IMAGE}
                        alt={data.BLOG_TITLE}
                        className="card-img blog-img object-fit-cover rounded-start"
                        height={"80%"}
                        style={{ marginTop: "10px" }}
                      />
                    </div>
                    <div className="col-md-8 d-flex flex-column justify-content-between p-3">
                      <div>
                        <h5
                          className="card-title text-truncate fw-bold"
                          style={{ color: "black", fontWeight: "400" }}
                        >
                          {data.BLOG_TITLE}
                        </h5>
                        <p className="card-text text-muted small">
                          {stripHtml(data.BLOG).length > 80
                            ? `${stripHtml(data.BLOG).substring(0, 80)}...`
                            : stripHtml(data.BLOG)}
                        </p>
                      </div>
                      {/* <p className="text-end mb-0">
                          <span
                            className="badge p-2 px-3"
                            style={{ background: "#a52444" }}
                          >
                            {data.REG_DATE}
                          </span>
                        </p> */}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogPost;
