import React from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
// import StupaImg from "../Images/stupa.jpg";
import './ThankYou.css'
const ThankYou = () => {
  const location = useLocation();

  const navigate=useNavigate()

  console.log(location.state.paymentData,'dsds32222423');
  console.log(location.state,'dsds32423');
  
  return (
    
    <div class="container mt-5">
        <div class="banner-breadcrumb"></div>
        <div class="thankyou-box text-center">
            <h2 class="text-dark">Thank You for Ordering!</h2>
            <h4 style={{color:'#8f3246'}}>Your Order Is Successful</h4>
            <table class="table table-bordered mt-4">
                <tbody>
                    {/* <tr>
                        <th>Order ID:</th>
                        <td>{location.state.paymentData.ORDER_ID}</td>
                    </tr>
                    <tr>
                        <th>Payment Date:</th>
                        <td>{location.state.paymentData.PAYMENT_DATE}</td>
                    </tr> */}
                    <tr>
                        <th>Transaction Number:</th>
                        <td>{location.state.paymentData.TRANSACTION_ID}</td>
                    </tr>
                    <tr>
                        <th>Amount:</th>
                        <td>{location.state.paymentData.TRANSACTION_AMT}</td>
                    </tr>
                </tbody>
            </table>
          
            <button  style={{background:'#8f3246'}} onClick={()=>{navigate('/my-store')}} class="btn text-white mt-3 me-2">Go to Store</button>
            <button style={{background:'#8f3246'}} onClick={()=>{navigate('/my-order-list')}} class="btn text-white mt-3 ms-2">my order</button>
            {/* <a href="/" class="btn btn-primary mt-3">Back to Home</a>
            <a href="/" class="btn btn-primary mt-3">Back to Home</a> */}
        </div>
    </div>
             
  
  );
};

export default ThankYou;
