import { useEffect, useState } from "react";
import "./DarshanTemple.css";
import { ServiceDiscounts } from "../enum";
import { useLocation, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import DatePicker from "react-date-picker";
import Select from "react-select";
import { ERROR_MESSAGES } from "../../Components/GlobalErrorMessage";
import { SelectIdProof, SelectLanguageOptions } from "../../middlewares/Utils";
import { GetPanditListDataAPI } from "../../ApiServices/PanditList/PanditList";
import CODModal from "../../Pages/PanditBooking/CODModal";
import axios from "axios";
import { Tooltip } from "@mui/material";
import { BaseUrl } from "../../BaseUrl/BaseUrl";
import Mylogo from "../../assets/images/logo.png";
import "../../Pages/Stepper Form/stepper.css";
import TempleCodModal from "./TempleCodModal";
import { DarshanBook_TempleListGETTimeSlot } from "../../ApiServices/Darshan Booking/DarshanBookingApi";

const steps = [
  "Booking Details",
  "Show Details",
  "Booking Preview",
  "Payment Status",
];

const TempleBookingForm = () => {
  const [selectedIdProof, setSelectedIdProof] = useState("Adhar Card");
  const [currentStep, setCurrentStep] = useState(0);
  const [showCODModal, setShowCODModal] = useState(null);
  const [selectedPandit, setSelectedPandit] = useState(null);
  const [orderID, setOrderID] = useState();
  const [panditSelectionError, setPanditSelectionError] = useState(null);
  const [error, setError] = useState(null);
  const [searchKeyword, setSearchKeyword] = useState(null);
  const [showBookingReviewModal, setShowBookingReviewModal] = useState(null);
  const [panditDataList, setPanditDataList] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const [timeSlotOption, setTimeSlotOption] = useState([]);
  const [timeSlotMap, setTimeSlotMap] = useState({});

  const [members, setMembers] = useState([]);
  const [showMembers, setShowMembers] = useState(false);

  const [templeBookingObj, setTempleBookingObj] = useState({
    time: null,
    languageID: null,
    landmark: null,
    address: null,
    pincode: null,
    ID_PROOF: 'Adhar Card',
    needSamagri: null,
    TR_ID: null,
    NO_SEATS: null,
    TRB_PRICE: null,
    TRB_TOTALPRICE: null,
    REFERAN_CODE: null,
    ID_PROOF_NO: null,
    TRB_DATE: null,
    TTS_ID: null,
    PAYMENT_TYPE: null,
    TRANSACTION_ID: null,
    PAYMENT_STATUS: null,
    USER_ID: null,
    CONVINIENCE_FEE: null,
    TAX: null,
    EXTRA: null,
    ID_PROOF_PIC: null,
    AGE: null,
    BookingPersonDetails: [],
    GENDERID: 1
  });
  const [modelRequestData, setModelRequestData] = useState({
    totalPrice: null,
    amountEntered: null,
    convenienceFeeAddedWithTax: null
  });

  const genderOptions = [
    { value: "Male", label: "Male" },
    { value: "Female", label: "Female" },
  ];

  const fromDate = dayjs();
  const userID = localStorage.getItem("userID");
  const product = location.state?.product;
  const langID = location.state?.langID
  const pujaDetails = location.state?.pujaDetailsData;
  // console.log("templeBookingObj", templeBookingObj);

  const [razorPayData, setRazorPayData] = useState({
    PB_DATE: null,
    PB_TIME: null,
    PB_LANGAUGE: null,
    PB_SAMAGRI: null,
    PB_PRICE: null,
    PB_SAMAGRIPRICE: null,
    PANDIT_ID: null,
    USER_ID: null,
    REFERENCES_NO: "",
    ADDRESS: null,
    PUJA_ID: null,
    PAYMENT_TYPE: null,
    CONVINIENCE_FEE: null,
    TAX: null,
    EXTRA: null,
    TRANSACTION_ID: "",
    TRANSACTION_AMT: "",
  });

  const nextStep = () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
    }
  };

  const prevStep = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  useEffect(() => {
    GetPanditListData();
  }, []);

  const handleGenderChange = (selectedOption) => {
    // handleMemberChange(
    //   0,
    //   "Gender",
    //   selectedOption ? selectedOption.value : null
    // )
    setTempleBookingObj((prev) => ({
      ...prev,
      GENDERID: selectedOption ? selectedOption.value : null,
    }));
  };

  const SGST = 9;
  const CGST = 9;
  const convenienceFee = parseFloat(location.state.CONVENIENCE_FEE) || 0; // Ensure it's a number
  // const DynamicTax = ((SGST + CGST) / 100) * convenienceFee; // Tax Calculation



  useEffect(() => {
    setRazorPayData((prev) => ({
      ...prev,
      PB_DATE: templeBookingObj.date,
      PB_TIME: templeBookingObj.time,
      PB_LANGAUGE: SelectLanguageOptions.find(
        (item) => item.value === templeBookingObj.languageID
      )?.label,
      PB_SAMAGRI: templeBookingObj.needSamagri,
      PB_PRICE: pujaDetails?.pujaPrice,
      PB_SAMAGRIPRICE: pujaDetails?.pujaSamagriPrice,
      PANDIT_ID: selectedPandit,
      USER_ID: userID,
      REFERENCES_NO: "",
      ADDRESS: templeBookingObj?.address,
      PUJA_ID: pujaDetails?.pujaId,
      PAYMENT_TYPE: "ONLINE",
      CONVINIENCE_FEE: pujaDetails?.convenienceFee,
      TAX: null,
      EXTRA: null,
      TRANSACTION_ID: "",
      TRANSACTION_AMT: "",
    }));
  }, []);

  // Calculate Total Price
  // const totalPrice1 =
  //   Number(templeBookingObj.NO_SEATS) * Number(location.state.DARSHAN_PRICE) +
  //   Number(location.state.CONVENIENCE_FEE) +
  //   DynamicTax;

  const totalBeforeTax =
    Math.round(Number(templeBookingObj.NO_SEATS) * Number(location.state.DARSHAN_PRICE) +
      Number(location.state.CONVENIENCE_FEE));


  const DynamicTax = Math.ceil((totalBeforeTax * Number(ServiceDiscounts?.DailyBookingDiscount)) / 100);

  // const totalPrice1 = totalBeforeTax + DynamicTax;
  const totalPrice1 = Math.round(totalBeforeTax + DynamicTax);
  const convenienceFeeAddedWithTax = Math.round(Number(location.state.CONVENIENCE_FEE) + DynamicTax);

  // const convenienceFeeAddedWithTax= DynamicTax + location.state.CONVENIENCE_FEE

  //   const GetPanditListData = () => {};
  const GetPanditListData = async () => {
    try {
      const response = await GetPanditListDataAPI(
        userID,
        location.state.product.PUJA_ID,

        {
          START: 1,
          END: 10,
          WORD: "None",
          LATITUDE: "20.0086678",
          LONGITUDE: "73.7639264",
        }
      );

      if (response) {
        setPanditDataList(response.DATA);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const HandleTempleDarshanBookingBtnClicked = () => {

    let isValid = false;
    const numSeats = parseInt(templeBookingObj.NO_SEATS, 10);

    if (
      templeBookingObj.TRB_DATE === null ||
      templeBookingObj.TRB_DATE === undefined ||
      templeBookingObj.TRB_DATE === "" ||
      templeBookingObj.NO_SEATS === null ||
      templeBookingObj.NO_SEATS === undefined ||
      templeBookingObj.NO_SEATS === "" ||
      templeBookingObj.AGE === null ||
      templeBookingObj.AGE === undefined ||
      templeBookingObj.AGE === "" ||
      templeBookingObj.ID_PROOF === null ||
      templeBookingObj.ID_PROOF === undefined ||
      templeBookingObj.ID_PROOF === "" ||
      templeBookingObj.ID_PROOF_NO === null ||
      templeBookingObj.ID_PROOF_NO === undefined ||
      templeBookingObj.ID_PROOF_NO === ""
    ) {
      isValid = true;
      setError(true);
    }
    // Validate each member's details
    // Validate number of seats is valid
    if (isNaN(numSeats) || numSeats < 0) {
      // Changed to < 0
      isValid = true;
      setError(true);
    }

    // Validate members if seats are required
    // Handle member validation based on seat count
    if (numSeats === 0) {
      // If seats are 0, ensure no members exist
      if (templeBookingObj.BookingPersonDetails && templeBookingObj.BookingPersonDetails.length > 0) {
        isValid = true;
        setError(true);
      }
    } else if (numSeats > 0) {
      // Validate members when seats are positive
      if (
        !templeBookingObj.BookingPersonDetails ||
        templeBookingObj.BookingPersonDetails.length !== numSeats - 1
      ) {
        isValid = true;
        setError(true);
      }

      templeBookingObj.BookingPersonDetails?.forEach((member) => {
        if (
          member.Name === null ||
          member.Name === "" ||
          member.Name === undefined ||
          member.Age === null ||
          member.Age === undefined ||
          member.Age === "" ||
          member.Gender === "" ||
          member.Gender === null ||
          member.Gender === undefined
        ) {
          isValid = true;
          setError(true);
        }
      });
    }

    if (!isValid) {
      nextStep();
      // alert(JSON.stringify(templeBookingObj))
    }
  };

  const handleRazorpayPayment = () => {
    // let totalAmount = parseInt(modelRequestData.totalVal); // Assuming totalVal includes shipping
    let minPayableAmount = modelRequestData.amountEntered
      ? modelRequestData.amountEntered
      : Number(totalPrice1);

    let orderIDReturned = null;

    // axios
    //   .post(`${BaseUrl}/DarshanBook_Booking?TYPE`, {
    //     TR_ID: location?.state?.TR_ID,
    //     NO_SEATS: templeBookingObj.NO_SEATS,
    //     TRB_PRICE: location?.state?.DARSHAN_PRICE,
    //     TRB_TOTALPRICE: totalPrice1,
    //     REFERAN_CODE: templeBookingObj?.REFERAN_CODE,
    //     AGE: templeBookingObj?.AGE,
    //     ID_PROOF: templeBookingObj?.ID_PROOF,
    //     TRB_DATE: templeBookingObj.TRB_DATE,
    //     USER_ID: userID,
    //     TTS_ID: templeBookingObj?.TTS_ID,
    //     ADDRESS: templeBookingObj?.address,
    //     PAYMENT_TYPE: modelRequestData.amountEntered ? "COD" : "ONLINE",
    //     CONVINIENCE_FEE: location.state.CONVENIENCE_FEE,
    //     TAX: DynamicTax,
    //     EXTRA: null,
    //     TRANSACTION_ID: "",
    //     TRANSACTION_AMT: "",
    //     PAYMENT_STATUS: "",
    //     EXTRA: "",
    //     ID_PROOF_PIC: "",
    //     ID_PROOF_NO: templeBookingObj.ID_PROOF_NO,
    //     BookingPersonDetails:templeBookingObj.BookingPersonDetails
    //   })
    //   .then((res) => {
    //     if (res) {
    //       console.log(res);
    //       setOrderID(res.data.ID);
    //       // localStorage.setItem("orderID", JSON.stringify(res.data.ID));
    //       orderIDReturned = res.data.ID;
    //     }
    //   });

    const options = {
      key: "rzp_test_vv1FCZvuDRF6lQ",
      key_secret: "P4JAUwn4VdE6xDLJ6p2Zy8RQ",
      amount: minPayableAmount * 100,
      currency: "INR",
      name: "My Om Namo- WowInfotech",
      description: "working",
      image: Mylogo,
      handler: function (response) {
        // console.log(response, "Payment Success");
        const paymentId = response.razorpay_payment_id;

        console.log(response, "sfsdf");

        if (response) {
          // axios
          //   .post(`${BaseUrl}/UpdatePaymentStatus?TYPE=Darshan`, {
          //     USER_ID: userID,
          //     ORDER_ID: orderIDReturned,
          //     // ORDER_ID: JSON.parse(localStorage.getItem("orderID")),
          //     TRANSACTION_ID: paymentId,
          //     PAYMENT_STATUS: "Success",
          //     TRANSACTION_STATUS: "Success",
          //     TRANSACTION_AMT: minPayableAmount,
          //     // REMAING_AMOUNT:
          //     //   Number(totalPrice1) +
          //     //   Number(location.state.CONVENIENCE_FEE) -
          //     //   minPayableAmount,
          //     REMAING_AMOUNT: Number(totalPrice1) - minPayableAmount,

          //     PAYMENT_DATE: templeBookingObj.TRB_DATE,
          //   })
          //   .then((res) => {
          //     if (res.data.ResponseMessage === "successfully.") {
          //       // localStorage.setItem("cartItemCount", "0");
          //       // window.dispatchEvent(new Event("cartUpdated"));
          //       setShowCODModal(false);
          //       nextStep();
          //     } else {
          //       alert("Order placement failed, please try again.");
          //     }
          //   });

          axios
            .post(`${BaseUrl}/DarshanBook_Booking?TYPE`, {
              TR_ID: location?.state?.TR_ID,
              NO_SEATS: templeBookingObj.NO_SEATS,
              TRB_PRICE: location?.state?.DARSHAN_PRICE,
              TRB_TOTALPRICE: totalPrice1,
              REFERAN_CODE: "",
              AGE: templeBookingObj?.AGE,
              ID_PROOF: templeBookingObj?.ID_PROOF,
              TRB_DATE: templeBookingObj.TRB_DATE,
              USER_ID: userID,
              Tax_Perc: ServiceDiscounts?.darshanBooking,
              Tax_Amount: DynamicTax,
              PAYMENT_STATUS:
                minPayableAmount == totalPrice ? "Completed" : "Pending",

              TTS_ID: templeBookingObj?.TTS_ID,
              ADDRESS: templeBookingObj?.address,
              PAYMENT_TYPE: modelRequestData.amountEntered ? "COD" : "ONLINE",
              CONVINIENCE_FEE: location.state.CONVENIENCE_FEE,
              TAX: DynamicTax,
              // Tax_Amount: convenienceFeeAddedWithTax,
              EXTRA: null,
              PAYMENT_STATUS: "",
              TRANSACTION_STATUS: "Success",
              EXTRA: "",
              TRANSACTION_ID: paymentId,
              TRANSACTION_AMT: minPayableAmount,
              ID_PROOF_PIC: "",
              ID_PROOF_NO: templeBookingObj.ID_PROOF_NO,
              BookingPersonDetails: templeBookingObj.BookingPersonDetails
            })
            .then((res) => {
              if (res) {
                console.log(res);
                setOrderID(res.data.ID);
                setShowCODModal(false);
                nextStep();
                // localStorage.setItem("orderID", JSON.stringify(res.data.ID));
                orderIDReturned = res.data.ID;
              }
            });

        }
      },
      theme: {
        color: "#ff416c",
      },
    };

    const rzp = new window.Razorpay(options);
    rzp.open();
  };

  const handlePanditSelectionNext = () => {
    nextStep();
  };

  const handleToDateChange = (newValue) => {
    if (dayjs(newValue).isValid()) {
      const newToDate = dayjs(newValue).format("YYYY-MM-DD");
      setTempleBookingObj((prev) => ({ ...prev, TRB_DATE: newToDate }));
    } else {
      setTempleBookingObj((prev) => ({ ...prev, TRB_DATE: null }));
    }
  };

  const handleIdProofChange = (selectedOption) => {
    setSelectedIdProof(selectedOption.value);
    setTempleBookingObj((prev) => ({
      ...prev,
      ID_PROOF: selectedOption.value,
    }));
  };

  const handlePanditCardClicked = (pandit) => {
    setSelectedPandit(pandit.PANDIT_ID);
    // console.log(pandit);
  };

  const handleCODClicked = () => {
    setModelRequestData((prev) => ({
      ...prev,
      convenienceFeeAddedWithTax: convenienceFeeAddedWithTax,
      totalPrice1: totalPrice1,
    }));
    setShowCODModal(true);
  };

  const totalPrice =
    parseFloat(product?.PUJA_PRICE || 0) +
    (templeBookingObj.needSamagri === "yes"
      ? parseFloat(product?.SAMAGRI_PRICE || 0)
      : 0) +
    parseFloat(product?.DAKSHINA || 0) +
    parseFloat(product?.CONVENIENCE_FEE || 0) +
    2 - //Tax amount
    parseFloat(product?.PUJA_DISC || 0); // Subtract discount if applicable

  console.log(product?.PUJA_PRICE);
  useEffect(() => {
    GetMyBookPanditCategoryData();
  }, []);

  const GetMyBookPanditCategoryData = async () => {
    try {
      const response = await DarshanBook_TempleListGETTimeSlot(
        location?.state?.TR_ID,
        userID
      );

      if (
        response.ResponseMessage === "list" &&
        response.ResponseCode === "0" &&
        Array.isArray(response.DATA)
      ) {
        // Transform the data into the format required by react-select
        const formattedOptions = response.DATA.map((slot) => ({
          value: slot.TTS_ID,
          label: slot.TTS_NAME,
        }));

        setTimeSlotOption(formattedOptions);
        // Create a mapping of IDs to names for easy lookup
        const idToNameMap = {};
        response.DATA.forEach((slot) => {
          idToNameMap[slot.TTS_ID] = slot.TTS_NAME;
        });
        setTimeSlotMap(idToNameMap);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getTimeSlotName = (id) => {
    return id ? timeSlotMap[id] || "Unknown slot" : "No slot selected";
  };

  const handleTimeSlotChange = (selectedOption) => {
    if (selectedOption) {
      setTempleBookingObj((prev) => ({
        ...prev,
        TTS_ID: selectedOption.value,
      }));
    } else {
      // Handle clearing of selection
      setTempleBookingObj((prev) => ({
        ...prev,
        TTS_ID: null,
      }));
    }
  };

  const handleAddMembers = (value) => {
    const numSeats = parseInt(templeBookingObj.NO_SEATS, 10);
    if (numSeats > 0) {
      const membersArray = Array.from({ length: numSeats - 1 }, () => ({
        Name: "",
        Age: "",
        Gender: null,
      }));

      setTempleBookingObj((prev) => ({
        ...prev,
        BookingPersonDetails: membersArray,
      }));

      setShowMembers(true);
    }
  };

  const handleMemberChange = (index, field, value) => {
    // debugger
    const updatedMembers = [...templeBookingObj.BookingPersonDetails];
    updatedMembers[index][field] = value;

    setTempleBookingObj((prev) => ({
      ...prev,
      BookingPersonDetails: updatedMembers,
    }));
  };

  return (
    <div style={{ background: "#F1F6F7", paddingTop: "30px" }}>
      {/* Step Indicator */}
      <div className="stepper-wrapper">
        {steps.map((step, index) => (
          <div
            key={index}
            className={`stepper-item ${index < currentStep
              ? "completed"
              : index === currentStep
                ? "active"
                : ""
              }`}
          >
            <div className="step-counter">{index + 1}</div>
            <div className="step-name" style={{ fontSize: 'small' }}>{step}</div>
          </div>
        ))}
      </div>

      {/* Step Content */}
      <div
        className="step-content"
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "10px 70px",
          background: "#F1F6F7",
        }}
      >
        {currentStep === 0 && (
          <>
            <div
              style={{
                background: "white",
                padding: "15px",
                borderRadius: "8px",
                boxShadow: "0px 4px 6px rgba(0,0,0,0.1)",
                marginBottom: "20px",
              }}
            >
              <div className="row">
                <div className="col-md-3 col-sm-6 mb-2 d-flex flex-column">
                  <label htmlFor="bookingDate" style={{ textAlign: "left" }}>
                    Date<span style={{ color: "red", fontSize: 'small' }}>*</span>
                  </label>
                  <DatePicker
                    id="bookingDate"
                    value={templeBookingObj?.TRB_DATE || ""}
                    minDate={fromDate.toDate()}
                    clearIcon={null}
                    onChange={handleToDateChange}
                    format="dd/MM/yyyy"
                  />
                  {error && !templeBookingObj.TRB_DATE && (
                    <span style={{ color: "red", fontSize: 'small' }}>{ERROR_MESSAGES}</span>
                  )}
                </div>
                <div className="col-md-3 col-sm-6 mb-2 d-flex flex-column">
                  <label htmlFor="bookingTime" style={{ textAlign: "left" }}>
                    Time<span style={{ color: "red", fontSize: 'small' }}>*</span>
                  </label>
                  <Select
                    options={timeSlotOption}
                    value={
                      timeSlotOption.find(
                        (option) => option.value === templeBookingObj.TTS_ID
                      ) || null
                    }
                    onChange={handleTimeSlotChange}
                    placeholder="Select Time Slot"
                    isClearable
                  />

                  {error && !templeBookingObj.TTS_ID && (
                    <span style={{ color: "red", fontSize: 'small' }}>{ERROR_MESSAGES}</span>
                  )}
                </div>

                {/* <div className="col-md-3 col-sm-6 mb-2 d-flex flex-column">
                  <label htmlFor="REFERAN_CODE" style={{ textAlign: "left" }}>
                    Referral Code

                  </label>
                  <input
                    id="REFERAN_CODE"
                    maxLength={10}
                    type="text"
                    className="form-control"
                    placeholder="Enter Contact no."
                    value={templeBookingObj.REFERAN_CODE}
                    onChange={(e) => {
                      const value = e.target.value;
                      let FormattedNumber = value.replace(/[^0-9]/g, "");


                      FormattedNumber = FormattedNumber.replace(/^[0-5]/, "");
                      setTempleBookingObj((prev) => ({
                        ...prev,
                        REFERAN_CODE: FormattedNumber,
                      }));
                    }}
                  />

                </div> */}

                <div className="col-md-3 col-sm-6 mb-2 d-flex flex-column">
                  <label htmlFor="NameUser" style={{ textAlign: "left" }}>
                    Name<span style={{ color: "red", fontSize: 'small' }}>*</span>
                  </label>
                  <input
                    id="NameUser"
                    type="text"
                    className="form-control"
                    value={templeBookingObj.landmark || ""}
                    onChange={(e) => {
                      // handleAddMembers(false)
                      let inputVal = e.target.value.trimStart();
                      // handleMemberChange(0, "Name", inputVal)
                      setTempleBookingObj((prev) => ({
                        ...prev,
                        landmark: inputVal,
                      }));
                    }}
                    placeholder="Enter Name"
                  />
                  {error && !templeBookingObj.landmark && (
                    <span style={{ color: "red", fontSize: 'small' }}>{ERROR_MESSAGES}</span>
                  )}
                </div>

                <div className="col-md-3 col-sm-6 mb-2 d-flex flex-column">
                  <label htmlFor="address" style={{ textAlign: "left" }}>
                    Address<span style={{ color: "red", fontSize: 'small' }}>*</span>
                  </label>
                  <textarea
                    id="address"
                    className="form-control"
                    value={templeBookingObj.address || ""}
                    onChange={(e) => {
                      let inputVal = e.target.value.trimStart();
                      setTempleBookingObj((prev) => ({
                        ...prev,
                        address: inputVal,
                      }));
                    }}
                    placeholder="Enter Address"
                  />
                  {error && !templeBookingObj.address && (
                    <span style={{ color: "red", fontSize: 'small' }}>{ERROR_MESSAGES}</span>
                  )}
                </div>
              </div>
              <div className="row">

                <div className="col-md-3 col-sm-6 mb-2 d-flex flex-column">
                  <label htmlFor="state" style={{ textAlign: "left" }}>
                    Phone<span style={{ color: "red", fontSize: 'small' }}>*</span>
                  </label>
                  <input
                    id="state"
                    maxLength={10}
                    type="text"
                    className="form-control"
                    placeholder="Enter Contact no."
                    value={templeBookingObj.state}
                    onChange={(e) => {
                      const value = e.target.value;
                      let FormattedNumber = value.replace(/[^0-9]/g, ""); // Allows only numbers

                      // Apply regex to ensure the first digit is between 6 and 9
                      FormattedNumber = FormattedNumber.replace(/^[0-5]/, "");
                      setTempleBookingObj((prev) => ({
                        ...prev,
                        state: FormattedNumber,
                      }));
                    }}
                  />
                  {error && !templeBookingObj.state && (
                    <span style={{ color: "red", fontSize: 'small' }}>{ERROR_MESSAGES}</span>
                  )}
                </div>
                <div className="col-md-3 col-sm-6 mb-2 d-flex flex-column">
                  <label htmlFor="IdProof" style={{ textAlign: "left" }}>
                    Select Id Proof
                    <span style={{ color: "red", fontSize: 'small' }}>*</span>
                  </label>
                  <Select
                    options={SelectIdProof}
                    onChange={handleIdProofChange}
                    value={SelectIdProof.find(
                      (item) => item.value === selectedIdProof
                    )}
                    placeholder="Select Id Proof"
                  />
                  {error &&
                    (selectedIdProof === null ||
                      selectedIdProof === undefined ||
                      selectedIdProof === "") ? (
                    <span style={{ color: "red", fontSize: 'small' }}>{ERROR_MESSAGES}</span>
                  ) : (
                    ""
                  )}
                </div>

                {/* Conditional Input Field */}
                <div className="col-md-3 col-sm-6 mb-2 d-flex flex-column">
                  {selectedIdProof === "Pan Card" && (
                    <>
                      <label htmlFor="panCard" style={{ textAlign: "left" }}>
                        Enter PAN Card Number{" "}
                        <span style={{ color: "red", fontSize: 'small' }}>*</span>
                      </label>
                      <input
                        type="text"
                        maxLength={10}
                        className="form-control"
                        placeholder="Enter PAN Card Number"
                        value={templeBookingObj.ID_PROOF_NO || ""}
                        onChange={(e) =>
                          setTempleBookingObj((prev) => ({
                            ...prev,
                            ID_PROOF_NO: e.target.value,
                          }))
                        }
                      />
                      {error &&
                        (templeBookingObj.ID_PROOF_NO === null ||
                          templeBookingObj.ID_PROOF_NO === undefined ||
                          templeBookingObj.ID_PROOF_NO === "") ? (
                        <span style={{ color: "red", fontSize: 'small' }}>{ERROR_MESSAGES}</span>
                      ) : (
                        ""
                      )}
                    </>
                  )}

                  {selectedIdProof === "Adhar Card" && (
                    <>
                      <label htmlFor="adharCard" style={{ textAlign: "left" }}>
                        Enter Aadhaar Number{" "}
                        <span style={{ color: "red", fontSize: 'small' }}>*</span>
                      </label>
                      <input
                        type="text"
                        maxLength={12}
                        className="form-control"
                        placeholder="Enter Aadhaar Number"
                        value={templeBookingObj.ID_PROOF_NO || ""}
                        onChange={(e) =>
                          setTempleBookingObj((prev) => ({
                            ...prev,
                            ID_PROOF_NO: e.target.value,
                          }))
                        }
                      />
                      {error &&
                        (templeBookingObj.ID_PROOF_NO === null ||
                          templeBookingObj.ID_PROOF_NO === undefined ||
                          templeBookingObj.ID_PROOF_NO === "") ? (
                        <span style={{ color: "red", fontSize: 'small', fontSize: 'small' }}>{ERROR_MESSAGES}</span>
                      ) : (
                        ""
                      )}
                    </>

                  )}
                </div>

                <div className="col-md-3 col-sm-6 mb-2 d-flex flex-column">
                  <label htmlFor="noOfSeats" style={{ textAlign: "left" }}>
                    Age<span style={{ color: "red", fontSize: 'small' }}>*</span>
                  </label>
                  <input
                    id="noOfSeats"
                    type="text"
                    maxLength={2}
                    className="form-control"
                    value={templeBookingObj.AGE || ""}
                    onChange={(e) => {
                      let inputVal = e.target.value.replace(/\D/g, ""); // Remove non-digit characters
                      // handleMemberChange(
                      //   1,
                      //   "Age",
                      //   inputVal
                      // )
                      setTempleBookingObj((prev) => ({
                        ...prev,
                        AGE: inputVal,
                      }));
                    }}
                    placeholder="Enter Age"
                  />
                  {error && !templeBookingObj.AGE && (
                    <span style={{ color: "red", fontSize: 'small' }}>{ERROR_MESSAGES}</span>
                  )}
                </div>
                <div className="col-md-3 col-sm-6 mb-2 d-flex flex-column">
                  <label htmlFor="bookingTime" style={{ textAlign: "left" }}>
                    Gender<span style={{ color: "red", fontSize: 'small' }}>*</span>
                  </label>
                  <Select
                    options={genderOptions}
                    placeholder="Select Gender"
                    isClearable
                    value={genderOptions.find(
                      (option) => option.value === templeBookingObj.GENDERID
                    )}
                    onChange={handleGenderChange}
                  />

                  {error && (templeBookingObj.GENDERID === null || templeBookingObj.GENDERID === undefined || templeBookingObj.GENDERID === '') ? (
                    <span style={{ color: "red", fontSize: 'small' }}>{ERROR_MESSAGES}</span>
                  ) : ''}
                </div>
                <div className="col-md-3 col-sm-6 mb-2 d-flex flex-column">
                  <label htmlFor="noOfSeats" style={{ textAlign: "left" }}>
                    No of Seats<span style={{ color: "red", fontSize: 'small' }}>*</span>
                  </label>
                  <input
                    id="noOfSeats"
                    type="text"
                    maxLength={1}
                    className="form-control"
                    value={templeBookingObj.NO_SEATS || ""}
                    onChange={(e) => {
                      let inputVal = e.target.value.replace(/\D/g, ""); // Remove non-digit characters
                      if (inputVal < 4) {
                        setTempleBookingObj((prev) => ({
                          ...prev,
                          NO_SEATS: inputVal,
                          BookingPersonDetails: [], // Reset members if seats change
                        }));
                      }


                      setShowMembers(false); // Reset members input if seats change
                    }}
                    placeholder="Enter No of Seats"
                  />
                  {error && !templeBookingObj.NO_SEATS && (
                    <span style={{ color: "red", fontSize: 'small' }}>{ERROR_MESSAGES}</span>
                  )}
                </div>
                <div className="col-md-3 col-sm-6 mb-2 d-flex flex-column"></div>
                <div className="col-md-3 col-sm-6 mb-2 d-flex flex-column"></div>
                {templeBookingObj.NO_SEATS > 0 && (
                  <div className="row justify-content-center">
                    <div className="col-md-3 text-center">
                      <button
                        style={{ background: "#8f3246", width: "100%" }}
                        className="text-white btn mt-2"
                        onClick={handleAddMembers}
                      >
                        Add Members
                      </button>
                    </div>
                  </div>
                )}

                {showMembers && (
                  <div className="row justify-content-center mt-3">
                    {templeBookingObj.BookingPersonDetails.map((member, index) => (
                      <div key={index} className="col-md-9 d-flex gap-2 mb-2">
                        {/* Name Input */}
                        <div style={{ width: "100%" }}>
                          <input
                            type="text"
                            maxLength={25}
                            className="form-control"
                            placeholder={`Member ${index + 1} Name`}
                            value={member?.Name}
                            onChange={(e) =>
                              handleMemberChange(index, "Name", e.target.value)
                            }
                          />
                          {error && !member?.Name && (
                            <span style={{ color: "red", fontSize: 'small' }}>
                              {ERROR_MESSAGES}
                            </span>
                          )}
                        </div>

                        {/* Age Input */}
                        <div style={{ width: "100%" }}>
                          <input
                            type="text"
                            maxLength={2}
                            className="form-control"
                            placeholder="Age"
                            value={member?.Age}
                            onChange={(e) =>
                              handleMemberChange(
                                index,
                                "Age",
                                e.target.value.replace(/\D/g, "")
                              )
                            }
                          />
                          {error && !member?.Age && (
                            <span style={{ color: "red", fontSize: 'small' }}>
                              {ERROR_MESSAGES}
                            </span>
                          )}
                        </div>

                        {/* Gender Dropdown */}
                        <div style={{ width: "100%" }}>
                          <Select
                            className="react-select-container"
                            classNamePrefix="react-select"
                            options={genderOptions}
                            placeholder="Select Gender"
                            isClearable
                            value={genderOptions.find(
                              (option) => option.value === member.Gender
                            )}
                            onChange={(selectedOption) =>
                              handleMemberChange(
                                index,
                                "Gender",
                                selectedOption ? selectedOption.value : null
                              )
                            }
                            styles={{
                              control: (base) => ({
                                ...base,
                                height: "38px", // Match input height
                                minHeight: "38px",
                              }),
                            }}
                          />
                          {error && !member.Gender && (
                            <span style={{ color: "red", fontSize: 'small' }}>
                              {ERROR_MESSAGES}
                            </span>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <div className="row">
                <div className="col-md-3 col-sm-6 mb-2 d-flex flex-column"></div>
              </div>
            </div>
          </>
        )}
        {currentStep === 1 && (
          <div className="panditList" style={{ width: "100%" }}>
            <div className="container">
              {/* Top Section - Pandit Image, Name, Description */}
              <div className="row mb-3 text-center">
                <div className="col-12">
                  <img
                    src={location.state.TR_IMAGE}
                    alt="Temple Name"
                    className="img-fluid rounded-circle"
                    style={{
                      width: "150px",
                      height: "150px",
                      objectFit: "cover",
                    }}
                  />
                  <h3 className="mt-2">{location.state.TR_NAME}</h3>

                  { }
                </div>
              </div>

              {/* Booking Details Section */}
              <div className="row mb-3">
                <div className="col-12">
                  <h5>Booking Details</h5>
                  <p>
                    <strong>Slot Time:</strong>{" "}
                    {getTimeSlotName(templeBookingObj.TTS_ID)}
                  </p>
                  <p>
                    <strong>Booking Date:</strong>
                    {templeBookingObj.TRB_DATE}
                  </p>
                  <p>
                    <strong>Seats:</strong> {templeBookingObj.NO_SEATS}
                  </p>
                  {/* <p>
                    <strong>
                      Tax  <span className="text-small"> ({ServiceDiscounts?.DailyBookingDiscount}) % :</span>
                    </strong>
                    ₹ {Number(DynamicTax).toFixed(2)}
                  </p>
                  <p>
                    <strong>Convenience Fee:</strong> ₹
                    {location.state.CONVENIENCE_FEE}
                  </p>
                  <p>
                    <strong>Booking Amount:</strong> ₹
                    {location.state.DARSHAN_PRICE}
                  </p> */}
                  {/* <p><strong>Total Pay:</strong> ₹1230</p> */}
                  {/* <p>
                    <strong>Total Pay:</strong> ₹
                    {Number(templeBookingObj.NO_SEATS) *
                      Number(location.state.DARSHAN_PRICE) +
                      Number(location.state.CONVENIENCE_FEE) +
                      10}
                  </p> */}
                </div>
              </div>

              {/* Contact Details Section */}
              <div className="row mb-3">
                <div className="col-12">
                  <h5>Contact Details</h5>
                  <p>
                    <strong>Name:</strong> {templeBookingObj.landmark}
                  </p>
                  <p>
                    <strong>Address:</strong> {templeBookingObj.address}
                  </p>
                  <p>
                    <strong>Mobile No:</strong> {templeBookingObj.state}
                  </p>
                  <p>
                    <strong>ID Proof:</strong>
                    {templeBookingObj.ID_PROOF}
                  </p>
                  <p>
                    <strong>Proof No:</strong> {templeBookingObj.ID_PROOF_NO}
                  </p>
                  {/* <p>
                    <strong>Reference No:</strong>{" "}
                    {templeBookingObj.REFERAN_CODE}
                  </p> */}
                </div>
              </div>
            </div>
          </div>
        )}

        {currentStep === 2 && (
          <div className="container py-3">
            <div className="row">
              {/* Image Section */}
              <div className="col-md-6">
                <div className="card p-3 shadow-sm d-flex flex-row align-items-center gap-3">
                  <img
                    src={location?.state?.TR_IMAGE || ""}
                    alt="Temple Name"
                    className="img-fluid rounded-circle"
                    style={{
                      width: "150px",
                      height: "150px",
                      objectFit: "cover",
                    }}
                  />
                  {/* <h5 className="fw-bold text-center mt-3">
                    {location?.state?.TR_NAME}
                  </h5> */}
                  <div>
                    <h5 className="fw-bold m-0">{location?.state?.TR_NAME}</h5>
                    <p className="mb-1"><strong>Booking Name:</strong> {templeBookingObj.landmark || "N/A"}</p>
                    {/* <p className="mb-1"><strong>Age:</strong> {templeBookingObj.AGE || "N/A"}</p> */}
                    <p className="mb-1"><strong>Phone:</strong> {templeBookingObj.state || "N/A"}</p>
                    <p className="mb-0"><strong>No. of Seats:</strong> {templeBookingObj.NO_SEATS || "N/A"}</p>
                  </div>
                </div>
              </div>

              {/* Details Section */}
              <div className="col-md-6">
                <div className="card p-3 shadow-sm">
                  <h5 className="fw-bold mb-3">Booking Details</h5>
                  <div className="row">



                    <div className="col-6">
                      <strong>Price </strong>
                      ({templeBookingObj?.NO_SEATS} × ₹{location?.state?.DARSHAN_PRICE}) :
                    </div>

                    <div className="col-6 text-end">
                      ₹{templeBookingObj?.NO_SEATS * location?.state?.DARSHAN_PRICE}
                    </div>



                    <div className="col-6">
                      <strong>Convenience Fee:</strong>
                    </div>

                    <div className="col-6 text-end">
                      ₹{location?.state?.CONVENIENCE_FEE}
                    </div>

                    {/* Horizontal Line */}
                    <div className="col-12">
                      <hr />
                    </div>

                    <div className="col-6">
                      <strong>Total :</strong>
                    </div>
                    <div className="col-6 text-end fw-bold">
                      {/* ₹ {templeBookingObj?.NO_SEATS * location?.state?.DARSHAN_PRICE} */}
                      ₹ {templeBookingObj?.NO_SEATS * location?.state?.DARSHAN_PRICE + Number(location?.state?.CONVENIENCE_FEE)}

                      {/* {(Number(location?.state?.DARSHAN_PRICE) + Number(location?.state?.CONVENIENCE_FEE)).toFixed(2)} */}
                    </div>
                    <div className="col-6">
                      <strong>Tax ({ServiceDiscounts?.darshanBooking}) % :</strong>
                    </div>
                    <div className="col-6 text-end">
                      ₹ {Number(DynamicTax).toFixed(2)}
                    </div>
                    {/* Horizontal Line */}
                    <div className="col-12">
                      <hr />
                    </div>

                    <div className="col-6">
                      <strong>Grand Total:</strong>
                    </div>
                    <div className="col-6 text-end fw-bold">
                      ₹ {totalPrice1}
                    </div>
                  </div>

                  {/* Buttons */}
                  <div className="d-flex justify-content-end mt-3">
                    {/* <button

                      className="btn text-white  mx-1"
                      style={{ backgroundColor: "#8f3246", color: "black" }}
                      onClick={handleCODClicked}
                    >
                      Cash On Delivery
                    </button> */}
                    <button
                      style={{ background: '#8f3246' }}
                      className="btn text-white"
                      onClick={handleRazorpayPayment}
                    >
                      Pay
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {currentStep === 3 && (
          <div>
            <div className="carousel-inner" role="listbox">
              <div className="item carousel-item-four active">
                <div className="">
                  <div className="row">
                    <div className="carousel slide carousel-slider">
                      <div className="carousel-position-four">
                        <div className="carousel-inner">
                          <div
                            className="banner-breadcrumb"
                            style={{
                              // backgroundImage: `url(${StupaImg})`,
                              width: "100%",
                              height: "100px",
                            }}

                          // style={{ backgroundImage: `url(${AboutImg})` }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row margin-bottom-40">
              <div className="col-md-12 col-sm-12">
                <div className="thankyou-box text-center bg-white p-5">
                  <h2
                    style={{
                      fontFamily: "Ubuntu",
                      textTransform: "capitalize",
                      color: "#504e4e",
                      fontSize: "32px",
                      marginBottom: "20px",
                    }}
                  >
                    Thank You for Ordering !
                  </h2>
                  <h4
                    className="text-success"
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "18px",
                      fontWeight: "450",
                    }}
                  >
                    Your Order Is Successful
                  </h4>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* Navigation Buttons */}
      {currentStep !== 3 && currentStep !== 2 ? (
        <div className="navigation-buttons" style={{ marginRight: "50px" }}>
          <button
            onClick={prevStep}
            disabled={currentStep === 0}
            className=" prev-btn"
          >
            Previous
          </button>
          <button
            onClick={
              currentStep === 0
                ? HandleTempleDarshanBookingBtnClicked
                : currentStep === 1
                  ? handlePanditSelectionNext
                  : ""
            }
            disabled={currentStep === steps.length - 1}
            className="next-btn"
          >
            Next
          </button>
        </div>
      ) : currentStep === 2 ? (
        <div className="navigation-buttons" style={{ marginRight: "50px" }}>
          <button
            className="btn btn-secondary"
            style={{ backgroundColor: "#d7d7d7", color: "black" }}
            onClick={prevStep}>
            Previous
          </button>
        </div>
      ) : (
        currentStep === 3 && (
          <div className="navigation-buttons" style={{ marginRight: "50px" }}>
            <button
              className=" prev-btn"
              onClick={() => navigate("/my-order-darshan-Booking")}
            >
              My Orders
            </button>
          </div>
        )
      )}
      <TempleCodModal
        show={showCODModal}
        onHide={() => setShowCODModal(false)}
        onConfirm={handleRazorpayPayment}
        modelRequestData={modelRequestData}
        setModelRequestData={setModelRequestData}
      />
    </div>
  );
};

export default TempleBookingForm;
