import React, { useState } from "react";
import { Modal, Button, Form, InputGroup } from "react-bootstrap";
import { FaSearch, FaMapMarkerAlt } from "react-icons/fa";

const LocationModal = ({ show, onHide, onSelectCity }) => {
  const [search, setSearch] = useState("");
  const [selectedCity, setSelectedCity] = useState("");

  const cities = [
    "Mumbai",
    "Navi Mumbai",
    "Thane",
    "Pune",
    "Chennai",
    "Bangalore",
    "Coimbatore",
    "Delhi",
    "Gurugram",
  ];

  const handleDetectLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;
          try {
            const response = await fetch(
              `https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=json`
            );
            const data = await response.json();
            const detectedCity = data.address.city || data.address.town;
            if (detectedCity) {
              alert(`Your location is ${detectedCity}`);
              setSelectedCity(detectedCity);
            }
          } catch (error) {
            console.error("Error fetching location:", error);
          }
        },
        () => alert("Location access denied!")
      );
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  };

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Pick a Location</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* Search Input */}
        <InputGroup className="mb-3">
          <Form.Control
            type="text"
            placeholder="Enter City or Pin Code"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <InputGroup.Text>
            <FaSearch style={{ background: "white" }} />
          </InputGroup.Text>
        </InputGroup>

        {/* Detect Location */}
        <div
          className="d-flex align-items-center mb-3 detect-location-div"
          style={{ cursor: "pointer" }}
          onClick={handleDetectLocation}
        >
          <FaMapMarkerAlt className="me-2" />
          Detect My Location
        </div>

        {/* Popular Cities */}
        <h6 className="mb-2">Popular Cities</h6>
        <div className="d-flex flex-wrap gap-2">
          {cities.map((city, index) => (
            <Button
              key={index}
              // variant={selectedCity === city ? "primary" : "outline-secondary"}
              className="rounded-pill pick-city-btn"
              onClick={() => setSelectedCity(city)}
              style={{
                textTransform: "none",
                backgroundColor: "white",
                color: "black",
                border:
                  selectedCity === city
                    ? "1px solid #a52444"
                    : "1px solid lightgray",
                borderRadius: "10px",
              }}
            >
              {city}
            </Button>
          ))}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          style={{
            background: "#a52444",
            outline: "none",
            textTransform: "none",
          }}
          onClick={() => onSelectCity(selectedCity)}
          disabled={!selectedCity}
        >
          Continue
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default LocationModal;
