import React, { useEffect, useState } from "react";
import {
  MyBookPanditCategoryAPI,
  MyBookPanditPujaListAPI,
} from "../../ApiServices/BookPanditAPI/BookPanditAPI";
import { Link, useNavigate } from "react-router-dom";
import { Tooltip } from "@mui/material";
import "./BookPandit.css";
import placeholderImg from "../../assets/images/new/Batuk2-removebg-preview.png";
import Batuk from "../../assets/images/new/Batuk2.png";

const BookPandit = () => {
  const [myBookPanditData, setMyBookPanditData] = useState([]);
  const [pujaListData, setPujaListData] = useState([]);
  const [catID, setCatID] = useState(null);
  const [search, setSearch] = useState("");
  const [selectedCatId, setSelectedCatId] = useState(null);

  const user_id = localStorage.getItem("userID");
  const navigate = useNavigate();

  useEffect(() => {
    // debugger;
    GetMyBookPanditCategoryData();
  }, []);

  useEffect(() => {
    GetMyBookPanditPujaListData();
  }, [catID]);

  const GetMyBookPanditCategoryData = async () => {
    const response = await MyBookPanditCategoryAPI(user_id);

    try {
      if (
        response.ResponseMessage === "list" &&
        response.ResponseCode === "0"
      ) {
        setMyBookPanditData(response.DATA);
        setCatID(response.DATA[0]?.PUJACAT_ID);
        setSelectedCatId(response.DATA[0]?.PUJACAT_ID);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const GetMyBookPanditPujaListData = async () => {
    // debugger;
    try {
      const response = await MyBookPanditPujaListAPI(user_id, catID, {
        START: 1,
        END: 10,
        WORD: "None",
        LATITUDE: "20.008581",
        LONGITUDE: "73.76413099999999",
      });

      if (response) {
        setPujaListData(response.DATA);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const viewDetail = (product) => {
    console.log("Navigating to details with product:", product);
    navigate("/services/book-puja/puja-details", {
      state: { product: product },
    });
  };

  console.log(myBookPanditData);

  const ProductCard = ({ product, viewDetail, placeholderImg }) => {
    return (
      <div
        className="col-lg-3 col-md-6 col-sm-6 product-item-one "
        style={{ marginBottom: "15px", width: "12em" }}
      >
        <div
          className=" card card-shadow-sty shadow-sm bg-white rounded p-2 w-100 d-flex h-100  "
          style={{ cursor: "pointer" }}
          onClick={() => viewDetail(product)}
        >
          <div className="product-image text-center position-relative ">
            <img
              src={product.PUJA_IMAGE || placeholderImg}
              alt={product.PUJA_NAME}
              className="img-fluid rounded w-100"
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = placeholderImg;
              }}
              style={{ objectFit: "cover", height: "180px" }}
            />
            {/* <span className="discount-badge position-absolute top-0 start-0 bg-danger text-white p-1 small">
              {product.PUJA_DISC ? `${product.PUJA_DISC}% OFF` : ""}
            </span> */}
          </div>
          <div
            className="product-details text-center d-flex align-item-center justify-content-center flex-column flex-grow-1 p-2"
            style={{ margin: "0" }}
          >
            <h6
              style={{ fontSize: "18px", color: "#8f3246", fontWeight: "600" }}
            >
              {product.PUJA_NAME}
            </h6>
            <div className="prod-details d-flex align-items-center flex-column">
              <span style={{ fontSize: "16px", whiteSpace: "nowrap" }}>
                ₹ {product.PUJA_PRICE - product.DiscountAmount}
              </span>
              <span
                style={{
                  whiteSpace: "nowrap",
                  color: "#a52444",
                  fontSize: "15px",
                  // borderBottom: "1px solid",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "5px",
                }}
              >
                View Details <i className="icon-right-arrow"></i>
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <>
      <section className="page-header">
        <div className="page-header__bg"></div>
        <div className="container">
          <h2 className="page-header__title bw-split-in-left">Book Puja</h2>
        </div>
      </section>

      <div className="main">
        <div className="container">
          {/* Use flex-column-reverse only for small screens */}
          <div className="row flex-column flex-md-row">
            {/* Category List - Always First in Mobile */}
            <div
              className="left col-md-3 col-sm-4 mt-4"
              style={{ padding: "20px" }}
            >
              <div>
                <h4
                  className="custom-list-item clearfix"
                  style={{
                    fontFamily: "poppins",
                    fontSize: "20px",
                    textTransform: "none",
                  }}
                >
                  Puja Category
                </h4>
                <ul className="margin-bottom-25 sidebar-menu">
                  {myBookPanditData?.length > 0 ? (
                    myBookPanditData.map((category, index) => (
                      <li className="list-group-item clearfix" key={index}>
                        <Link
                          to="#"
                          onClick={() => {
                            setCatID(category.PUJACAT_ID);
                            setSelectedCatId(category.PUJACAT_ID);
                          }}
                          className={`d-flex align-items-center ${
                            selectedCatId === category.PUJACAT_ID
                              ? "active"
                              : ""
                          }`}
                        >
                          <i className="fa fa-angle-right me-2"></i>
                          {category.PUJACAT_NAME?.length > 30 ? (
                            <Tooltip title={category.PUJACAT_NAME}>
                              {`${category.PUJACAT_NAME.substring(0, 19)}...`}
                            </Tooltip>
                          ) : (
                            category.PUJACAT_NAME
                          )}
                        </Link>
                      </li>
                    ))
                  ) : (
                    <li className="list-group-item">No categories available</li>
                  )}
                </ul>
              </div>
            </div>

            {/* Product List - Always Below Categories in Mobile */}
            <div className="right col-md-8 col-sm-8 px-lg-5">
              {pujaListData?.length !== 0 ? (
                <div className="d-flex flex-wrap gap-2 product-list">
                  {pujaListData
                    ?.filter(
                      (product) =>
                        search === "" ||
                        product.PUJA_NAME.toLowerCase().includes(
                          search.toLowerCase()
                        )
                    )
                    ?.map((product) => (
                      <ProductCard
                        key={product.PUJA_ID}
                        product={product}
                        viewDetail={viewDetail}
                        placeholderImg={placeholderImg}
                      />
                    ))}
                </div>
              ) : (
                <div className="d-flex flex-column align-items-center justify-content-center vh-90 text-center mt-4">
                  <img
                    src={Batuk}
                    alt="Empty Cart"
                    className="img-fluid mb-3"
                    style={{ maxWidth: "200px" }}
                  />
                  <h5 className="fw-bold">
                    No services available for this category
                  </h5>
                  <p className="text-muted">
                    Please explore our other categories.
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BookPandit;
