import React, { useEffect, useState } from "react";
import { DarshanBook_TempleList } from "../../ApiServices/Darshan Booking/DarshanBookingApi";
import { Link } from "react-router-dom";
import { PoojaAtTemple } from "../../ApiServices/Pooja At Temple/PoojaTempleApi";
import { Tooltip } from "@mui/material";
import Batuk from "../../assets/images/new/Batuk2-removebg-preview.png";

const PoojaAtTempleList = () => {
  const [darshanBookingList, setDarshanBookingList] = useState([]);
  useEffect(() => {
    DarshanBookingTempleListData();
  }, []);

  const DarshanBookingTempleListData = async () => {
    const userID = localStorage.getItem("userID") || "";

    if (!navigator.geolocation) {
      console.error("Geolocation is not supported by your browser");
      return;
    }

    navigator.geolocation.getCurrentPosition(
      async (position) => {
        const params = {
          START: 0,
          END: 10,
          WORD: "None",
          LATITUDE: position.coords.latitude.toString(),
          LONGITUDE: position.coords.longitude.toString(),
        };

        const response = await PoojaAtTemple(params, userID);

        if (
          response.ResponseCode === "0" &&
          response.ResponseMessage === "list"
        ) {
          const blogs = response?.DATA;

          setDarshanBookingList(blogs);
        }
      },
      (error) => {
        console.error("Error getting location:", error.message);
      }
    );
  };

  return (
    <div className="container mt-3">
      <div className="row">

        {darshanBookingList?.length !== 0 ? (
          darshanBookingList?.map((temple) => (
            <div key={temple.PAT_ID} className="col-md-4 mb-4">
              <div className="card temple-card">
                <img
                  src={temple.PAT_IMAGE}
                  alt={temple.PAT_IMAGE}
                  className="card-img-top"
                />
                <div className="card-body text-center">
                  <h5 className="card-title fw-bold">
                    {temple.PAT_NAME?.length > 20 ? (
                      <Tooltip
                        title={temple.PAT_NAME}
                      >{`${temple.PAT_NAME?.substring(0, 20)}...`}</Tooltip>
                    ) : (
                      <>{temple.PAT_NAME}</>
                    )}
                  </h5>
                  <p className="card-text mb-1">
                    <span role="img" aria-label="location">
                      📍
                    </span>
                    <strong> Location:</strong>
                    {temple.PAT_ADDRESS?.length > 20 ? (
                      <Tooltip
                        title={temple.PAT_ADDRESS}
                      >{`${temple.PAT_ADDRESS?.substring(0, 20)}...`}</Tooltip>
                    ) : (
                      <>{temple.PAT_ADDRESS}</>
                    )}
                  </p>
                  <p className="card-text mb-3">
                    <span role="img" aria-label="price">
                      💰
                    </span>
                    <strong> Darshan Price:</strong> ₹{temple.PAT_PRICE}
                  </p>
                  <Link
                    to={`/pooja-at-temple/${temple.PAT_ID}`}
                    className="btn "
                    style={{ color: "#A52444", display: "flex", justifyContent: 'center', alignItems: 'center' }}
                  >
                    View Details
                    <i className="icon-right-arrow"></i>
                  </Link>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="d-flex flex-column align-items-center justify-content-center vh-90 text-center mt-4">
            <img
              src={Batuk}
              alt="Empty Cart"
              className="img-fluid mb-3"
              style={{ maxWidth: "200px" }}
            />
            <h5 className="fw-bold">No services available for this catagory</h5>
            <p className="text-muted">Please explore our other categories.</p>
            {/* <Link to="/my-store">
                  <button
                    style={{ background: "#a52444" }}
                    className="text-white btn me-2"
                  >
                    Add Products
                  </button>
                </Link> */}
          </div>
        )}
      </div>

      <style>
        {`
        .temple-header {
          background: linear-gradient(45deg, #ff6b6b, #57a0d1);
        //   border-radius: 8px;
        
          box-shadow: 0px 4px 12px rgba(255, 99, 99, 0.6);
          transition: transform 0.3s ease-in-out;
        //   width:
        }

        .temple-header:hover {
          transform: scale(1.02);
        }

        .temple-card {
          border-radius: 10px;
          overflow: hidden;
          border: none;
          box-shadow: 0px 4px 10px rgba(255, 0, 0, 0.5);
          transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
        }

        .temple-card:hover {
          transform: translateY(-5px);
        //   box-shadow: 0px 8px 16px rgba(255, 0, 0, 0.8);
        }

        .card-img-top {
          height: 200px;
          object-fit: cover;
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
        }
      `}
      </style>
    </div>
  );
};

export default PoojaAtTempleList;
