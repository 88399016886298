import { useContext, useEffect, useState } from "react";
import { useParams, Link, Navigate, useNavigate } from "react-router-dom";
import { Container, Card, Button, Row, Col, Image } from "react-bootstrap";
import { DarshanBook_TempleList } from "../../ApiServices/Darshan Booking/DarshanBookingApi";
import { PoojaAtTemple } from "../../ApiServices/Pooja At Temple/PoojaTempleApi";
import { AuthContextProvider } from "../../AuthContext/AuthContext";
import LoginPopUp from "../LoginPopup";
import '../../Components/PoojaDetails/PujaDetails.css'
// API function (replace this with your actual function)

const PoojaAtTempleDetails = () => {
  const navigate = useNavigate();
  const [loginPopUp, setLoginPopUp] = useState(null);
  const { isAuthenticated } = useContext(AuthContextProvider);
  const { id } = useParams(); // Get temple ID from URL
  const [temple, setTemple] = useState(null);
  const [loading, setLoading] = useState(true);

  const [modelRequestData, setModelRequestData] = useState({
    Action: null,
    DARSHAN_PRICE: null,
    DARSHAN_TIME: null,
    TR_ID: null,
    CONVENIENCE_FEE: null,
    TR_SHORTDETAILS: null,

    Row: null,
    PAT_ID: null,
    PAT_NAME: null,
    PAT_PRICE: null,
    PAT_CONVENIENCEFEE: null,
    PAT_IMAGE: null,
    PAT_ADDRESS: null,
    PAT_DETAILS: null,
    PAT_SAMAGRI: null,
    PAT_SAMAGRIPRICE: null,
    PAT_TAC: null,
  });

  useEffect(() => {
    const fetchTempleDetails = async () => {
      const userID = localStorage.getItem("userID") || "";

      const params = {
        START: 0,
        END: 10,
        WORD: "None",
        LATITUDE: "20.008581",
        LONGITUDE: "73.76413099999999",
      };

      try {
        const response = await PoojaAtTemple(params, userID);
        if (
          response.ResponseCode === "0" &&
          response.ResponseMessage === "list"
        ) {
          const selectedTemple = response.DATA.find(
            (item) => item.PAT_ID === parseInt(id)
          );
          if (selectedTemple) {
            setTemple(selectedTemple);
          }
        }
      } catch (error) {
        console.error("Error fetching temple details:", error);
      }
      setLoading(false);
    };

    fetchTempleDetails();
  }, [id]);

  if (loading) {
    return <div className="text-center mt-5">Loading temple details...</div>;
  }

  if (!temple) {
    return <div className="text-center mt-5">Temple not found.</div>;
  }

  const clickToBook = (temple) => {
    console.log(temple, "ddddddddddds33");

    {
      setModelRequestData({
        ...modelRequestData,
        Action: null,
        Row: temple.Row,
        PAT_ID: temple.PAT_ID,
        PAT_NAME: temple.PAT_NAME,
        PAT_PRICE: temple.PAT_PRICE,
        PAT_CONVENIENCEFEE: temple.PAT_CONVENIENCEFEE,
        PAT_IMAGE: temple.PAT_IMAGE,
        PAT_ADDRESS: temple.PAT_ADDRESS,
        PAT_DETAILS: temple.PAT_DETAILS,
        PAT_SAMAGRI: temple.PAT_SAMAGRI,
        PAT_SAMAGRIPRICE: temple.PAT_SAMAGRIPRICE,
        PAT_TAC: temple.PAT_TAC,
        DARSHAN_PRICE: temple.DARSHAN_PRICE,
        DARSHAN_TIME: temple.DARSHAN_TIME,
        TR_ID: temple.TR_ID,
        CONVENIENCE_FEE: temple.CONVENIENCE_FEE,
        TR_SHORTDETAILS: temple.TR_SHORTDETAILS,
      });
    }
    let addRawMaterialRequestData = {
      Action: null,
      Row: temple.Row,
      PAT_ID: temple.PAT_ID,
      PAT_NAME: temple.PAT_NAME,
      PAT_PRICE: temple.PAT_PRICE,
      PAT_CONVENIENCEFEE: temple.PAT_CONVENIENCEFEE,
      PAT_IMAGE: temple.PAT_IMAGE,
      PAT_ADDRESS: temple.PAT_ADDRESS,
      PAT_DETAILS: temple.PAT_DETAILS,
      PAT_SAMAGRI: temple.PAT_SAMAGRI,
      PAT_SAMAGRIPRICE: temple.PAT_SAMAGRIPRICE,
      PAT_TAC: temple.PAT_TAC,
      DARSHAN_PRICE: temple.DARSHAN_PRICE,
      DARSHAN_TIME: temple.DARSHAN_TIME,
      TR_ID: temple.TR_ID,
      CONVENIENCE_FEE: temple.CONVENIENCE_FEE,
      TR_SHORTDETAILS: temple.TR_SHORTDETAILS,
    };
    if (isAuthenticated) {
      navigate("/pooja-at-temple-booking", {
        state: temple,
      });
    } else {
      setLoginPopUp(true);
    }
  };
  const createMarkup = (html) => {
    return { __html: html };
  };
  return (
    <div className="PujaDetails">
      <div
        className="PujaDetailsContainer"
        style={{
          display: "flex",
          gap: "20px",
          maxWidth: "1200px",
          margin: "auto",
          padding: "20px",
          height: "calc(100vh - 80px)", // Adjust height minus header
          overflow: "hidden",
        }}
      >
        {/* <div className="pujaDetails" style={{ display: "flex", gap: "20px" }}> */}
        <div
          className="left-puja-details text-center"
          style={{ width: "400px", margin: "auto" }}
        >
          <img
            src={temple.PAT_IMAGE}
            alt="Puja-Img"
            onError={(e) => {
              e.target.onerror = null; // Prevent infinite loop
              // e.target.src = placeholderImg; // Set fallback image
            }}
            className="img-fluid rounded" // Bootstrap classes for responsiveness and rounded corners
            style={{ maxWidth: "100%", height: "auto" }}
          />
          {/* {pujaDetailsData.pujaDiscount && (
          <div
            className="position-absolute top-0 start-0 bg-danger text-white px-2 py-1 rounded"
            style={{ fontSize: "14px", fontWeight: "bold", margin: "10px" }}
          >
            {pujaDetailsData.pujaDiscount}% OFF
          </div>
        )} */}
        </div>

        <div
          className="right"
          style={{
            flex: 1,
            overflowY: "scroll",
            height: "100%",
            paddingRight: "10px",
            scrollbarWidth: "none", // Hides scrollbar in Firefox
            msOverflowStyle: "none", // Hides scrollbar in IE/Edge
          }}
        >
          <div className="d-flex align-items-center justify-content-between">
            <h3 className="mb-0">{temple.PAT_NAME}</h3>
            <div className="button-container">
              <button
                className="careox-btn d-flex align-items-center justify-content-center"
                style={{
                  backgroundColor: "#A52444",
                  color: "white",
                }}
                onClick={() => clickToBook(temple)}
              >
                Proceed to Book
              </button>
            </div>
          </div>

          <div className="price-tag position-relative d-inline-block p-2 d-flex  align-items-center">
            <div
              className="text-dark px-3 py-1 rounded"
              style={{ background: "#efb423", marginRight: "10px" }}
            >
              <strong>
                ₹{" "}

                {temple.PAT_PRICE}

              </strong>
            </div>
            {/* <div
              className="text-muted"
              style={{ textDecoration: "line-through" }}
            >
              {pujaDetailsData?.DiscountAmount}
            </div> */}
          </div>

          {/* <h5>
            <span>
              <s>Rs {pujaDetailsData.pujaPrice}</s>
            </span>
            <span> ({pujaDetailsData.pujaDiscount}% Off)</span>
          </h5> */}

          <div className="mb-3 ">
            {/* <h5>Puja Details:</h5> */}
            <p
              dangerouslySetInnerHTML={{
                __html: temple.PAT_DETAILS,
              }}
            />
          </div>

          <div className="mb-3">
            <h5>Samagri:</h5>
            {temple.PAT_SAMAGRI ? (
              <div
                dangerouslySetInnerHTML={createMarkup(
                  temple.PAT_SAMAGRI
                )}
              />
            ) : (
              <p>No samagri details available.</p>
            )}
            <div className="text-center mt-4">
              <Link to="/pooja-at-temple" className="btn btn-secondary px-4">
                Back to Puja to Temple List
              </Link>
            </div>
          </div>

          {/* <h5>
            Samagri Price: Rs{" "}
            {formatToIndianCurrency(pujaDetailsData.pujaSamagriPrice)}
          </h5> */}

          {/* <div className="button-container">
            <button
              className="btn btn-primary"
              style={{ backgroundColor: "#A52444", color: "white" }}
              onClick={handleOpenProductBookingModal}
            >
              Proceed to Book
            </button>
          </div> */}

        </div>

        {/* </div> */}
      </div>


      {/* <ProductBookingModal
        show={productBookingModal}
        onHide={() => setProductBookingModal(false)}
        product={pujaDetailsData}
      />
      {loginPopUp && (
        <LoginPopUp show={loginPopUp} onHide={() => setLoginPopUp(false)} />
      )} */}
    </div>

  );
};

export default PoojaAtTempleDetails;
