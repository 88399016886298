import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { UserRegNewWithMobApi } from "../../ApiServices/My Profile/MyProfileApi";
import DatePicker from "react-date-picker";
import "react-calendar/dist/Calendar.css";
import "react-date-picker/dist/DatePicker.css";
// import { ERROR_MESSAGES } from "../GlobalErrorMessage";
import { GetUserModel } from "../../ApiServices/Profile/ProfileApi";
import toast from "react-hot-toast";
import { ERROR_MESSAGES } from "../../Components/GlobalErrorMessage";
import { AddParterForm } from "../../ApiServices/BecomeParterAPI/BecomePartnerAPI";

const BecomePandit = () => {
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const [errorMessage, setErrorMessage] = useState();
  const ItemAddedToast = () =>
    toast.success(
      "Data added successfully, our team will reach out to you soon!"
    );
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  //   const [agreementObj, setAgreementObj] = useState({
  //     DOB: null,
  //     BIRTH_PLACE: null,
  //     BIRTH_TIME: null,
  //     GENDER: null,
  //     USER_NAME: null,
  //     ADDRESS: null,
  //     ZODIAC_SIGN: null,
  //     MOBILE_NUMBER: null,
  //   });

  const [partnerObj, setPartnerObj] = useState({
    fullName: null,
    mobileNumber: null,
    email: null,
    address: null,
    dob: null,
    experience: null,
    pujaCount: null,
  });

  //   useEffect(() => {
  //     GetUserModelData();
  //   }, []);
  //   const GetUserModelData = async () => {
  //     // setLoader(true);
  //     // debugger;
  //     const userID = JSON.parse(localStorage.getItem("userID"));
  //     try {
  //       const response = await GetUserModel(userID);
  //       if (response?.ResponseCode == 0) {
  //         // setLoader(false);

  //         const ModelData = response.DATA || []; // Assuming the data is an array and we need the first item

  //         setAgreementObj((prev) => ({
  //           ...prev,
  //           DOB: ModelData[0]?.DOB,
  //           BIRTH_PLACE: ModelData[0]?.BIRTH_PLACE,
  //           BIRTH_TIME: ModelData[0]?.BIRTH_TIME,
  //           GENDER: ModelData[0]?.GENDER,
  //           USER_NAME: ModelData[0]?.FULL_NAME,
  //           ADDRESS: ModelData[0]?.ADDRESS,
  //           ZODIAC_SIGN: ModelData[0]?.ZODIAC_SIGN,
  //           MOBILE_NUMBER: ModelData[0]?.MOBILE_NO,
  //         }));
  //       } else {
  //         // setLoader(false);

  //         console.error(
  //           "Error fetching data: ",
  //           response?.data?.data?.statusCode
  //         );
  //       }
  //     } catch (error) {
  //       // setLoader(false);

  //       console.error("Error", error);
  //     }
  //   };
  const handleBecomePandit = () => {
    // debugger;
    let isValid = false;

    if (
      partnerObj.fullName === null ||
      partnerObj.fullName === undefined ||
      partnerObj.fullName === "" ||
      partnerObj.mobileNumber === null ||
      partnerObj.mobileNumber === undefined ||
      partnerObj.mobileNumber === "" ||
      partnerObj.address === "" ||
      partnerObj.address === null ||
      partnerObj.address === undefined ||
      partnerObj.email === "" ||
      partnerObj.email === null ||
      partnerObj.email === undefined ||
      partnerObj.pujaCount === "" ||
      partnerObj.pujaCount === null ||
      partnerObj.pujaCount === undefined ||
      partnerObj.experience === null ||
      partnerObj.experience === undefined ||
      partnerObj.experience === "" ||
      partnerObj.dob === null ||
      partnerObj.dob === undefined ||
      partnerObj.dob === "" ||
      partnerObj.mobileNumber?.length < 10
    ) {
      setError(true);
      isValid = true;
    } else if (partnerObj.email && !isValidEmail(partnerObj.email)) {
      setError(true);
      isValid = true;
    }

    const apiParam = {
      DOB: partnerObj.dob,
      FullName: partnerObj.fullName,
      Address: partnerObj.address,
      MobileNo: partnerObj.mobileNumber,
      NoOfPuja: partnerObj.pujaCount,
      ExpInYears: partnerObj.experience,
      Email: partnerObj.email,
    };
    // return isValid;
    if (!isValid) {
      console.log("apiParam", apiParam);
      AddUpdateBecomePanditData(apiParam);
    }
  };

  const AddUpdateBecomePanditData = async (apiParam) => {
    try {
      // let url = `/UserRegNewWithMob?USER_ID=${userID}`;

      const response = await AddParterForm(apiParam);
      if (response) {
        if (response?.ResponseCode == 0) {
          // setSuccessDone(true);
          ItemAddedToast();
          navigate("/");
        } else {
          console.log("api not call");
          setErrorMessage(response?.response?.data?.errorMessage);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleDateChange = (date) => {
    setPartnerObj((prevState) => ({
      ...prevState,
      dob: date, // Update state with selected date
    }));
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  return (
    <>
      <section className="page-header">
        <div className="page-header__bg"></div>
        <div className="container">
          <h2 className="page-header__title bw-split-in-left">
            Become a Partner
          </h2>
        </div>
      </section>
      <div
        className=""
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          marginTop: "2rem",
        }}
      >
        <div
          style={{
            border: "1px solid #e0e0e0",
            boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.1)",
            borderRadius: "12px",
            maxWidth: "90vw",
            width: "100%",
            backgroundColor: "#fff",
            padding: "20px",
          }}
          className="card"
        >
          <div className="card-body">
            <h3 className="text-center mb-4" style={{ color: "#a52444" }}>
              Partner Registration
            </h3>

            <div className="row gy-3">
              <div className="col-md-6">
                <label className="form-label fw-semibold">
                  Full Name <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control rounded-3"
                  placeholder="Enter Full Name"
                  value={partnerObj.fullName}
                  onChange={(e) => {
                    setErrorMessage("");
                    let inputVal = e.target.value;
                    if (inputVal.startsWith(" ")) {
                      inputVal = inputVal.trimStart();
                    }
                    setPartnerObj((obj) => ({
                      ...obj,
                      fullName: inputVal,
                    }));
                  }}
                />
                {(error && partnerObj.fullName === null) ||
                partnerObj.fullName === undefined ||
                partnerObj.fullName === "" ? (
                  <span className="error-msg">{ERROR_MESSAGES}</span>
                ) : (
                  ""
                )}
              </div>

              <div className="col-md-6">
                <label className="form-label fw-semibold">
                  Mobile Number <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control rounded-3"
                  maxLength={10}
                  placeholder="Enter Mobile Number"
                  value={partnerObj.mobileNumber}
                  onChange={(e) => {
                    setErrorMessage("");
                    let formattedNumber = e.target.value.replace(/[^0-9]/g, "");
                    formattedNumber = formattedNumber.replace(/^[0-5]/, "");
                    setPartnerObj((prev) => ({
                      ...prev,
                      mobileNumber: formattedNumber,
                    }));
                  }}
                />
                {(error && partnerObj.mobileNumber === null) ||
                partnerObj.mobileNumber === undefined ||
                partnerObj.mobileNumber === "" ? (
                  <span className="error-msg">{ERROR_MESSAGES}</span>
                ) : (
                  ""
                )}
              </div>
            </div>

            <div className="row gy-3">
              <div className="col-md-6">
                <label className="form-label fw-semibold">
                  Email <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control rounded-3"
                  placeholder="Enter Email"
                  value={partnerObj.email}
                  onChange={(e) => {
                    let inputVal = e.target.value;
                    if (inputVal.startsWith(" ")) {
                      inputVal = inputVal.trimStart();
                    }
                    setPartnerObj((obj) => ({
                      ...obj,
                      email: inputVal,
                    }));
                  }}
                />
                {(error && partnerObj.email === null) ||
                partnerObj.email === undefined ||
                partnerObj.email === "" ? (
                  <span className="error-msg">{ERROR_MESSAGES}</span>
                ) : error && !isValidEmail(partnerObj.email) ? (
                  <span className="error-msg">
                    Please enter valid email address
                  </span>
                ) : (
                  ""
                )}
              </div>

              <div className="col-md-6">
                <label className="form-label fw-semibold">
                  Address <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control rounded-3"
                  maxLength={200}
                  placeholder="Enter Address"
                  value={partnerObj.address}
                  onChange={(e) => {
                    setErrorMessage("");
                    let inputVal = e.target.value;
                    if (inputVal.startsWith(" ")) {
                      inputVal = inputVal.trimStart();
                    }
                    setPartnerObj((prev) => ({
                      ...prev,
                      address: inputVal,
                    }));
                  }}
                />
                {(error && partnerObj.address === null) ||
                partnerObj.address === undefined ||
                partnerObj.address === "" ? (
                  <span className="error-msg">This field is required</span>
                ) : (
                  ""
                )}
              </div>
            </div>

            <div className="row gy-3">
              <div className="col-md-6 d-flex flex-column">
                <label className="form-label fw-semibold">
                  Date Of Birth <span className="text-danger">*</span>
                </label>
                <DatePicker
                  value={partnerObj?.dob}
                  onChange={handleDateChange}
                  clearIcon={null}
                  popperPlacement="bottom-end"
                  format="dd-MM-yyyy"
                  // className="form-control"
                />
                {(error && partnerObj.dob === null) ||
                partnerObj.dob === undefined ||
                partnerObj.dob === "" ? (
                  <span className="error-msg">This field is required</span>
                ) : (
                  ""
                )}
              </div>

              <div className="col-md-6">
                <label className="form-label fw-semibold">
                  Experience (In Years) <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control rounded-3"
                  maxLength={2}
                  placeholder="Enter Experience"
                  value={partnerObj.experience}
                  onChange={(e) => {
                    setErrorMessage("");
                    let inputVal = e.target.value;
                    inputVal = inputVal.replace(/\D/g, "");
                    // Allow only digits (no letters or special characters)
                    setPartnerObj((prev) => ({
                      ...prev,
                      experience: inputVal,
                    }));
                  }}
                />
                {(error && partnerObj.experience === null) ||
                partnerObj.experience === undefined ||
                partnerObj.experience === "" ? (
                  <span className="error-msg">This field is required</span>
                ) : (
                  ""
                )}
              </div>
            </div>

            <div className="row gy-3">
              <div className="col-md-6">
                <label className="form-label fw-semibold">
                  Number of Puja's <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control rounded-3"
                  maxLength={3}
                  placeholder="Enter Number of Puja's"
                  value={partnerObj.pujaCount}
                  onChange={(e) => {
                    setErrorMessage("");
                    let inputVal = e.target.value;

                    // Allow only digits (no letters or special characters)
                    if (/^\d*$/.test(inputVal)) {
                      setPartnerObj((prev) => ({
                        ...prev,
                        pujaCount: inputVal,
                      }));
                    }
                  }}
                />
                {(error && partnerObj.pujaCount === null) ||
                partnerObj.pujaCount === undefined ||
                partnerObj.pujaCount === "" ? (
                  <span className="error-msg">This field is required</span>
                ) : (
                  ""
                )}
              </div>
              <div className="col-md-6"></div>
            </div>

            {/* Submit Button */}
            <div className="d-flex justify-content-end mt-4">
              <button
                className="btn px-4 py-2"
                style={{
                  backgroundColor: "#a52444",
                  color: "white",
                  fontWeight: "600",
                }}
                onClick={handleBecomePandit}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BecomePandit;
