import React, { StrictMode, useState } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import AuthContext from "./AuthContext/AuthContext";
import "../src/assets/vendors/bootstrap/css/bootstrap.min.css";
import "../src/assets/vendors/icofont/icofont.min.css";
import "../src/assets/vendors/jarallax/jarallax.css";
import "../src/assets/vendors/jquery-magnific-popup/jquery.magnific-popup.css";
import "../src/assets/vendors/jquery-ui/jquery-ui.css";
import "../src/assets/vendors/nouislider/nouislider.min.css";
import "../src/assets/vendors/nouislider/nouislider.pips.css";
import "../src/assets/vendors/owl-carousel/css/owl.carousel.min.css";
import "../src/assets/vendors/owl-carousel/css/owl.theme.default.min.css";
import "../src/assets/vendors/slick/slick.css";
import "../src/assets/vendors/tiny-slider/tiny-slider.css";
import "../src/assets/vendors/fontawesome/css/all.min.css";
import "../src/assets/vendors/careox-toolbar/css/careox-toolbar.css";
import "../src/assets/vendors/careox-icons/style.css";
import "../src/assets/vendors/bootstrap-select/bootstrap-select.min.css";
import "../src/assets/vendors/animate/animate.min.css";
import "../src/assets/css/careox.css";
import { Toaster } from "react-hot-toast";

// import "bootstrap/dist/css/bootstrap.min.css";
import $ from "jquery";
window.jQuery = $;




const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <StrictMode>
    <AuthContext>
      <Toaster />

      <App />
    </AuthContext>
  </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
