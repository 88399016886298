import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { BaseUrl } from "../../BaseUrl/BaseUrl";
import dayjs from "dayjs";
import toast from "react-hot-toast";
import Mylogo from "../../assets/images/logo.png";
import Select from "react-select";
import { ERROR_MESSAGES } from "../GlobalErrorMessage";
import CODPaymentModal from "../Payment Gateway/CODPaymentModal";
import fav from "../../assets/images/new/fav.png";
import "bootstrap/dist/js/bootstrap.bundle.min";
import { GetDeliveryCharges } from "../../ApiServices/EStore/EStoreApi";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
const ViewCart = () => {
  const [qty, setQty] = useState();
  const [product, setProduct] = useState([]);
  const [total, setTotal] = useState(0);
  const [showProductDetails, setShowProductDetails] = useState(true);
  const [discount, setDiscount] = useState(0);
  const location = useLocation();
  const [showCodModal, setShowCodModal] = useState(false);
  const [error, setError] = useState(false);
  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [qqty, setQqty] = useState(0);
  const [shipCharges, setShippingCharges] = useState(0);
  const [deliveryChargesAmt, setDeliveryChargesAmt] = useState([]);
  const [totalDiscountAmount, setTotalDiscountAmount] = useState(0);

  const [shipAdd, setShipAdd] = useState({
    PinCode: "",
    Flat: "",
    Colony: "",
    Landmark: "",
    City: "",
    State: "",
    Payment: "",
    countryID: null,
    districtID: "",
    stateID: "",
  });

  const [modelRequestData, setModelRequestData] = useState({
    Action: null,
    CodPayload: null,
    totalVal: null,
    productName: null,
    productPrice: null,
    productID: null,
  });
  const ValidationToast = () =>
    toast.error("Please fill all fields before proceeding with the payment.");
  useEffect(() => {
    getProducts();
    fetchCountryList();
  }, []);

  useEffect(() => {
    if (product?.length > 0) {
      const calculatedTotal = product?.reduce(
        (total, item) => total + item.PRODUCT_PRICE * item.QUANTITY,
        0
      );
      const calculatedTotalDiscount = product?.reduce(
        (total, item) =>
          total + Math.round((item.PRODUCT_PRICE * item.DISCOUNT) / 100),
        0
      );
      setTotal(calculatedTotal);
      setDiscount(calculatedTotalDiscount);
    }
  }, [product]);

  useEffect(() => {
    fetchStateList(selectedCountry);
  }, [selectedCountry]);
  useEffect(() => {
    fetchDistrictList(selectedState);
  }, [selectedState]);
  useEffect(() => {
    setModelRequestData((PREV) => ({
      ...PREV,
      totalVal: PREV.totalVal + deliveryChargesAmt,
    }));
  }, [shipCharges]);

  useEffect(() => {
    if (countryList.length > 0) {
      const firstCountry = countryList[0];
      setSelectedCountry(firstCountry.value);
      setShipAdd((prev) => ({
        ...prev,
        Country: firstCountry.label, // Store Country Name
        countryID: firstCountry.value, // Store Country ID
      }));
      fetchStateList(firstCountry.value); // Fetch states for the first country
    }
  }, [countryList]); // Runs when countryList is updated
  

  const fetchCountryList = async () => {
    try {
      const response = await axios.get(`${BaseUrl}/Country`);
      console.log("Country Response:", response.data); // Log the response

      // Check the response code correctly
      if (response.data.ResponseCode === "0") {
        const countries = response.data.DATA || []; // Access DATA array
        const formattedCountryList = countries.map((country) => ({
          value: country.COUNTRY_ID,
          label: country.COUNTRY_NAME,
        }));
        setCountryList(formattedCountryList);
      } else {
        console.error(
          "Error fetching countries:",
          response.data.ResponseMessage
        );
      }
    } catch (error) {
      console.error("Error fetching country data:", error);
    }
  };

  const fetchStateList = async (countryID) => {
    if (!countryID) {
      return;
    }
    try {
      const response = await axios.get(
        `${BaseUrl}/State?CountryID=${countryID}`
      );
      console.log("State Response:", response.data); // Log the response

      // Check the response code correctly
      if (response.data.ResponseCode === "0") {
        const states = response.data.DATA || []; // Access DATA array
        const formattedStateList = states.map((state) => ({
          value: state.StateID,
          label: state.StateName,
        }));
        setStateList(formattedStateList);
      } else {
        console.error("Error fetching states:", response.data.ResponseMessage);
      }
    } catch (error) {
      console.error("Error fetching state data:", error);
    }
  };
  useEffect(() => {
    GetDeliveryChargesLookupListData();
  }, [shipAdd]);

  const GetDeliveryChargesLookupListData = async () => {
    if (!shipAdd.districtID) {
      return;
    }
    try {
      const response = await GetDeliveryCharges(
        shipAdd.stateID,
        shipAdd.districtID
      ); // Ensure this function is imported correctly
      if (response?.ResponseCode === "0") {
        const CalculatedShippingCharges = response?.DATA[0].DeliveryCharges;
        setDeliveryChargesAmt(CalculatedShippingCharges);
      } else {
        console.error(
          "Failed to fetch sim Type lookup list:",
          response?.data?.statusMessage || "Unknown error"
        );
      }
    } catch (error) {
      console.error("Error fetching sim Type lookup list:", error);
    }
  };

  const fetchDistrictList = async (stateID) => {
    if (!stateID) {
      return;
    }
    try {
      const response = await axios.get(`${BaseUrl}/City?StateID=${stateID}`);
      console.log("District Response:", response.data); // Log the response

      // Check the response code correctly
      if (response.data.ResponseCode === "0") {
        const districts = response.data.DATA || []; // Access DATA array
        const formattedDistrictList = districts.map((district) => ({
          value: district.DistrictID, // Use DistrictID as the identifier
          label: district.DistrictName, // Use DistrictName as the label
        }));
        setCityList(formattedDistrictList); // Update state with the list of districts
      } else {
        console.error(
          "Error fetching districts:",
          response.data.ResponseMessage
        );
      }
    } catch (error) {
      console.error("Error fetching district data:", error);
    }
  };

  const data = localStorage.getItem("userID") || "";

  const getProducts = () => {
    const data = localStorage.getItem("userID");
    axios
      .post(`${BaseUrl}/Estore_Cart?USER_ID=${data}`, {
        USER_ID: data,
        START: 1,
        END: 10,
        WORD: "None",
      })
      .then((res) => {
        const productData = res.data.DATA || [];
        setProduct(productData);
        // Calculate total, discount, and quantity
        let discount = 0;
        let totalQuantity = 0;
        let subtotal = 0;

        productData.forEach((product) => {
          const quantity = parseInt(product.QUANTITY) || 0;
          const price =
            product.PRODUCT_PRICE * product.QUANTITY -
            Math.round(
              (product.PRODUCT_PRICE * (product.DISCOUNT * product.QUANTITY)) /
                100
            );

          // const price = parseFloat(product.PRODUCT_PRICE) || 0;
          const discountPercent = parseFloat(product.DISCOUNT) || 0;

          const productTotal = price * quantity;
          totalQuantity += quantity;
          subtotal += price;

          if (discountPercent > 0) {
            discount += discountPercent * quantity;
          }
        });

        const finalDiscountPercentage =
          totalQuantity > 0 ? discount / totalQuantity : 0;
        setDiscount(finalDiscountPercentage);
        setQqty(totalQuantity);
        setTotal(subtotal);
        setModelRequestData((PREV) => ({ ...PREV, totalVal: subtotal }));
      });
  };
  let value, name;

  const updateCartItemCount = (newCount) => {
    console.log("Updating cartItemCount:", newCount);
    localStorage.setItem("cartItemCount", newCount.toString());
    window.dispatchEvent(new Event("cartUpdated")); // Notify header component
  };

  const deleteProduct = (product) => {
    toast((t) => (
      <div>
        <span>Are you sure you want to delete this product?</span>
        <div style={{ marginTop: "10px" }}>
          <button
            style={{
              marginRight: "8px",
              padding: "5px 10px",
              background: "#8f3246",
              color: "white",
              border: "none",
              cursor: "pointer",
              borderRadius: "4px",
            }}
            onClick={() => {
              toast.dismiss(t.id);
              proceedWithDeletion(product); 
            }}
          >
            Yes
          </button>
          <button
            style={{
              padding: "5px 10px",
              background: "#dc3545",
              color: "white",
              border: "none",
              cursor: "pointer",
              borderRadius: "4px",
            }}
            onClick={() => toast.dismiss(t.id)} // 
          >
            No
          </button>
        </div>
      </div>
    ));
  };
  const proceedWithDeletion = (product) => {
    axios
      .post(`${BaseUrl}/Estore_Cart`, {
        USER_ID: data,
        CART_ID: product.CART_ID,
        PRODUCT_ID: product.PRODUCT_ID,
        QUANTITY: product.QUANTITY,
        TASK: "D",
      })
      .then((res) => {
        console.log("Delete Response:", res.data);
        getProducts(); // Refresh checkout page after deletion

        if (res.data.ResponseCode === "0") {
          let cartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
          console.log("Cart before deletion:", cartItems);

          cartItems = cartItems.filter(
            (item) => item.PRODUCT_ID != product.PRODUCT_ID
          );

          console.log("Cart after deletion:", cartItems);
          localStorage.setItem("cartItems", JSON.stringify(cartItems));
          localStorage.setItem(
            "cartItemCount",
            JSON.stringify(cartItems.length)
          );

          // ✅ Update cart count
          const newCount = cartItems.length;
          updateCartItemCount(newCount);
        }
      })
      .catch((error) => {
        console.error("Error deleting product:", error);
        alert("An error occurred while removing the item from the cart.");
      });
  };

  useEffect(() => {
    calculateTotals();
  }, [product]);

  const calculateTotals = () => {
    const totalDiscountPercentApi = product?.reduce(
      (acc, item) => acc + Number(item.DISCOUNT || 0),
      0
    );
    const totalDiscountRupeeApi = Math.round(
      product?.reduce((acc, item) => {
        return acc + Number(item.DiscountAmount || 0);
      }, 0)
    );
    const totalProductPriceWithOutDiscountApi = product?.reduce(
      (acc, item) => acc + Number(item.PRODUCT_PRICE || 0),
      0
    );
    const totalProductAfterDiscount = Math.round(
      totalProductPriceWithOutDiscountApi - totalDiscountRupeeApi
    );

    const taxAmount = Math.round((totalProductAfterDiscount * 18) / 100); // 18% tax (rounded)
    const finalTotalWithTax = Math.round(totalProductAfterDiscount + taxAmount); // Final total (rounded)
    // const finalTotalWithShippingCharge = Math.round(finalTotalWithTax + deliveryCharges);
    // Final total (rounded)

    setTotalDiscountAmount(totalDiscountRupeeApi);
  };
  const handleClick2 = (product) => {
    console.log(product.QUANTITY);
    const Quntity = Number(product.QUANTITY);
    console.log(typeof Quntity);
    if (product.QUANTITY == 20) {
      return setQty(20);
    } else {
      setQty(product.QUANTITY++);
    }
    //  setQty(product.QUANTITY++);
    axios
      .post(`${BaseUrl}/Estore_Cart`, {
        USER_ID: data,
        CART_ID: product.CART_ID,
        PRODUCT_ID: product.PRODUCT_ID,
        QUANTITY: product.QUANTITY,
        TASK: "EDIT",
      })
      .then((res) => {
        console.log(res.data);
        getProducts();
        // const newCount = product.length - 1; // Adjust this as necessary
        // updateCartItemCount(newCount);
      });
  };

  // Similar for handleClick1

  const handleClick1 = (product) => {
    console.log(product.QUANTITY);
    if (product.QUANTITY == 1) {
      return setQty(1);
    } else {
      setQty((product.QUANTITY -= 1));
    }
    axios
      .post(`${BaseUrl}/Estore_Cart`, {
        USER_ID: data,
        CART_ID: product.CART_ID,
        PRODUCT_ID: product.PRODUCT_ID,
        QUANTITY: product.QUANTITY,
        TASK: "EDIT",
      })
      .then((res) => {
        console.log(res.data);
        getProducts();
        // Dispatch custom event to notify header
        // window.dispatchEvent(new Event("cartUpdated"));
      });
  };

  const navigate = useNavigate();

  const functionOne = () => {
    let isValid = true;

    // Validate shipping address
    if (
      shipAdd?.PinCode === null ||
      shipAdd?.PinCode === undefined ||
      shipAdd?.PinCode === "" ||
      shipAdd?.Flat === null ||
      shipAdd?.Flat === undefined ||
      shipAdd?.Flat === "" ||
      shipAdd?.Colony === null ||
      shipAdd?.Colony === undefined ||
      shipAdd?.Colony === "" ||
      shipAdd?.Landmark === null ||
      shipAdd?.Landmark === undefined ||
      shipAdd?.Landmark === "" ||
      shipAdd?.City === null ||
      shipAdd?.City === undefined ||
      shipAdd?.City === ""
      // shipAdd?.State === null || shipAdd?.State === undefined || shipAdd?.State === ''
      // shipAdd?.Payment === null || shipAdd?.Payment === undefined || shipAdd?.Payment === ''
    ) {
      setError(true);
      isValid = false;
    }
    if (isValid) {
      PaymentApproved("online"); // Always online payment
    }
    // For cash payment, keep existing behavior; for online, process full payment
    // if (isValid && payment === 'cash') {
    //   setModelRequestData((prev) => ({
    //     ...prev, TotalPayableAmount: (total - discount).toFixed(2)
    //   }))
    //   setShowCodModal(true);
    //   const offcanvasElement = document.getElementById("offcanvasRight");
    //   if (offcanvasElement) {
    //     const offcanvasInstance = Offcanvas.getInstance(offcanvasElement);
    //     offcanvasInstance?.hide();
    //   }
    // } else if (isValid && payment === 'online') {
    //   PaymentApproved("online");
    // }
  };

  // const PaymentApproved = (paymentMode) => {

  //   const User_Id = localStorage.getItem("userID");
  //   const { PinCode, Flat, Colony, Landmark, City, State, Payment } = shipAdd;

  //   let Prod_Id = "";
  //   let Prod_Price = "";
  //   let Prod_Discount = "";
  //   let Prod_Quantity = "";

  //   product.forEach((products) => {
  //     Prod_Id = Prod_Id.concat(",", products.PRODUCT_ID);
  //     Prod_Price = Prod_Price.concat(",", products.PRODUCT_PRICE);
  //     Prod_Discount = Prod_Discount.concat(",", products.DISCOUNT);
  //     Prod_Quantity = Prod_Quantity.concat(",", products.QUANTITY);
  //   });

  //   const Prod_Id1 = Prod_Id.slice(1);
  //   const Prod_Price1 = Prod_Price.slice(1);
  //   const Prod_Discount1 = Prod_Discount.slice(1);
  //   const Prod_Quantity1 = Prod_Quantity.slice(1);

  //   // Calculate a delivery date 5 days from now
  //   let date = dayjs().add(5, 'day').format('YYYY-MM-DD');

  //   // Declare PAYLOAD first
  //   let PAYLOAD = {
  //     USER_ID: User_Id,
  //     TOTAL_QTY: `${qqty}`,
  //     TOTAL_PRICE: `${total}`,
  //     TOTAL_DISC: `${discount}`,
  //     RATE: `${Prod_Price1}`,
  //     PRODUCT_ID: `${Prod_Id1}`,
  //     QTY: `${Prod_Quantity1}`,
  //     DISCOUNT: `${Prod_Discount1}`,
  //     ORDER_ADDRESS: `${PinCode} ${Flat} ${Colony} ${Landmark} ${City} ${State} ${Payment}`,
  //     SHIPING_CHARGES: `${shipCharges}`,
  //     // DELIVERY_DATE: `${date}`,
  //     TRANSACTION_AMT: ``,
  //     TRANSACTION_ID: "",
  //   };

  //   // Calculate the total amount (price + shipping)
  //   // let ToTalAmount = parseFloat(PAYLOAD.SHIPING_CHARGES) + parseFloat(PAYLOAD.TOTAL_PRICE);
  //   const discountAmount = parseFloat(PAYLOAD.TOTAL_PRICE) * (discount / 100);

  //   // Calculate final total after discount, then add shipping charges
  //   const ToTalAmount = parseFloat(PAYLOAD.TOTAL_PRICE) + parseFloat(PAYLOAD.SHIPING_CHARGES) - discount;

  //   let finalPayment, remainingAmount;

  //   if (paymentMode === "online") {
  //     finalPayment = ToTalAmount.toFixed(2);
  //     remainingAmount = "0.00";
  //   } else {
  //     finalPayment = modelRequestData?.amountEntered
  //       ? parseFloat(modelRequestData.amountEntered).toFixed(2)
  //       : (ToTalAmount * 0.2).toFixed(2);
  //     remainingAmount = (ToTalAmount - modelRequestData?.amountEntered).toFixed(2);

  //   }

  //   // Continue if the shipping address fields are valid
  //   if (
  //     shipAdd.City !== "" &&
  //     shipAdd.PinCode !== "" &&
  //     shipAdd.Flat !== "" &&
  //     shipAdd.Colony !== "" &&
  //     shipAdd.Landmark !== "" &&
  //     shipAdd.State !== ""
  //   ) {
  //     // debugger
  //     axios.post(`${BaseUrl}/Estore_order`, PAYLOAD)
  //       .then((response) => {
  //         if (response.data.ResponseMessage === "Order Success.") {
  //           localStorage.setItem("cartItemCount", "0");
  //           window.dispatchEvent(new Event("cartUpdated"));
  //           if (response.data.ID) {
  //             let ORDER_ID = response.data.ID;

  //             const options = {
  //               key: "rzp_test_vv1FCZvuDRF6lQ",
  //               key_secret: "P4JAUwn4VdE6xDLJ6p2Zy8RQ",
  //               amount: paymentMode === 'cash' ? parseInt(finalPayment * 100) : parseInt(ToTalAmount * 100), // Use `ToTalAmount` for online payment
  //               currency: "INR",
  //               name: "My Om Namo - WowInfotech",
  //               description: "Order Payment",
  //               image: Mylogo,
  //               handler: function (response) {
  //                 console.log(response, "Payment Success");
  //                 const TRANSACTION_ID = response.razorpay_payment_id;

  //                 let PAYMENT_PAYLOAD = {
  //                   USER_ID: User_Id,
  //                   ORDER_ID: ORDER_ID,
  //                   TRANSACTION_ID: TRANSACTION_ID,
  //                   PAYMENT_STATUS: "Success",
  //                   TRANSACTION_STATUS: "Success",
  //                   TRANSACTION_AMT: paymentMode === 'cash' ? finalPayment : ToTalAmount, // Ensure full amount for online
  //                   REMAING_AMOUNT: remainingAmount,
  //                   PAYMENT_DATE: date,
  //                 };

  //                 axios.post(`${BaseUrl}/UpdatePaymentStatus?TYPE=Estore`, PAYMENT_PAYLOAD)
  //                   .then((res) => {
  //                     if (res.data.ResponseMessage === "successfully.") {
  //                       localStorage.setItem("cartItemCount", "0");
  //                       window.dispatchEvent(new Event("cartUpdated"));
  //                       const data = {
  //                         remainingAmount: remainingAmount,
  //                         transactionAmount: ToTalAmount, // Store the correct amount
  //                         orderID: ORDER_ID,
  //                         paymentMode: paymentMode,
  //                         PAYMENT_DATE: date,
  //                         PAYMENT_STATUS: "Success",
  //                         TRANSACTION_STATUS: "Success",
  //                         TRANSACTION_AMT: paymentMode === 'cash' ? finalPayment : ToTalAmount,
  //                       };
  //                       localStorage.setItem("paymentData", JSON.stringify(data));
  //                       // redirectToThankYou(data);

  //                       navigate('/thank-you', { state: { paymentData: PAYMENT_PAYLOAD } });

  //                       // navigate("/thank-you");
  //                     }
  //                      else {
  //                       alert("Order placement failed, please try again.");
  //                     }
  //                   })
  //                   .catch((err) => console.error("Payment Status Update Error:", err));
  //               },
  //               theme: { color: "#ff416c" },
  //             };

  //             const rzp = new window.Razorpay(options);
  //             rzp.open();
  //           }
  //         } else {
  //           alert("Order placement failed, please try again    m.");
  //         }
  //       })
  //       .catch((err) => console.error("Order Error:", err));
  //   } else {
  //     ValidationToast();
  //   }
  // };

  const PaymentApproved = (paymentMode) => {
    const User_Id = localStorage.getItem("userID");
    const { PinCode, Flat, Colony, Landmark, City, State, Payment } = shipAdd;

    let Prod_Id = "";
    let Prod_Price = "";
    let Prod_Discount = "";
    let Prod_Quantity = "";

    product.forEach((products) => {
      Prod_Id = Prod_Id.concat(",", products.PRODUCT_ID);
      Prod_Price = Prod_Price.concat(",", products.PRODUCT_PRICE);
      Prod_Discount = Prod_Discount.concat(",", products.DISCOUNT);
      Prod_Quantity = Prod_Quantity.concat(",", products.QUANTITY);
    });

    const Prod_Id1 = Prod_Id.slice(1);
    const Prod_Price1 = Prod_Price.slice(1);
    const Prod_Discount1 = Prod_Discount.slice(1);
    const Prod_Quantity1 = Prod_Quantity.slice(1);

    let date = dayjs().add(5, "day").format("YYYY-MM-DD");

    const ToTalAmount =
      parseFloat(total) + parseFloat(deliveryChargesAmt) - discount;
    let finalPayment =
      paymentMode === "online"
        ? ToTalAmount.toFixed(2)
        : (ToTalAmount * 0.2).toFixed(2);
    let remainingAmount =
      paymentMode === "online"
        ? "0.00"
        : (ToTalAmount - finalPayment).toFixed(2);

    if (
      shipAdd.City &&
      shipAdd.PinCode &&
      shipAdd.Flat &&
      shipAdd.Colony &&
      shipAdd.Landmark &&
      shipAdd.State
    ) {
      const options = {
        key: "rzp_test_vv1FCZvuDRF6lQ",
        key_secret: "P4JAUwn4VdE6xDLJ6p2Zy8RQ",
        amount: parseInt(grandTotal * 100),
        currency: "INR",
        name: "My Om Namo - WowInfotech",
        description: "Order Payment",
        image: Mylogo,
        handler: function (response) {
          console.log(response, "Payment Success");
          const TRANSACTION_ID = response.razorpay_payment_id;

          let PAYLOAD = {
            USER_ID: User_Id,
            TOTAL_QTY: `${qqty}`,
            TOTAL_PRICE: `${total}`,
            TOTAL_DISC: `${totalDiscountAmount}`,
            RATE: `${Prod_Price1}`,
            PRODUCT_ID: `${Prod_Id1}`,
            QTY: `${Prod_Quantity1}`,
            DISCOUNT: `${Prod_Discount1}`,
            ORDER_ADDRESS: `${PinCode} ${Flat} ${Colony} ${Landmark} ${City} ${State} ${Payment}`,
            SHIPING_CHARGES: `${deliveryChargesAmt}`,
            TRANSACTION_AMT: grandTotal,
            TRANSACTION_ID: TRANSACTION_ID,
            TotalItem: qqty,
            TotalDiscountAmount: DiscountAmountForApi,
            TotalAmountOfProduct: RealTotalPriceAmt,
            DeliveryCharges: deliveryChargesAmt,
            TotalAmount: SubTotalVal,
            TaxPercentage: 18,
            TaxAmount: TaxAmtVal,
            GrandTotal: grandTotal,
          };

          axios
            .post(`${BaseUrl}/Estore_order`, PAYLOAD)
            .then((response) => {
              if (response.data.ResponseMessage === "Order Success.") {
                localStorage.setItem("cartItemCount", "0");
                window.dispatchEvent(new Event("cartUpdated"));
                navigate("/thank-you", { state: { paymentData: PAYLOAD } });
              } else {
                alert("Order placement failed, please try again.");
              }
            })
            .catch((err) => console.error("Order Error:", err));
        },
        theme: { color: "#ff416c" },
      };

      const rzp = new window.Razorpay(options);
      rzp.open();
    } else {
      ValidationToast();
    }
  };

  const grandTotal = Math.round(
    (total - totalDiscountAmount) * 1.18 + Number(deliveryChargesAmt)
  );
  const DiscountAmountForApi = Math.round(totalDiscountAmount);
  const RealTotalPriceAmt = Math.round(total);
  const SubTotalVal = Math.round(total - totalDiscountAmount);

  const TaxAmtVal = Math.round((total - totalDiscountAmount) * 0.18);

  // const total-totalDiscountAmount
  console.log(RealTotalPriceAmt, "DiscountAmountdddddddddddddd3");
  const allFieldsFilled =
    shipAdd.City &&
    shipAdd.Flat &&
    shipAdd.Colony &&
    shipAdd.Landmark &&
    shipAdd.PinCode &&
    shipAdd.State;
  return (
    <>
      <div className="container mt-4 ">
        {product?.length > 0 ? (
          <>
            <div className="row w-100">
              {/* Left Section - Cart Items */}
              <div className="col-md-8">
                {showProductDetails ? (
                  // Product Details UI
                  <>
                    <h5>Product Details</h5>
                    {product?.map((item) => (
                      <div
                        key={item.id}
                        className="card mb-3 p-3 shadow-sm position-relative"
                      >
                        {/* Remove button */}
                        <button
                className="btn btn-sm btn-outline-secondary position-absolute"
                style={{ top: "10px", right: "10px" }}
                onClick={() => deleteProduct(item)}
              >
                <i className="fa-solid fa-trash"></i>

              </button>

                        <div className="row g-3 align-items-center">
                          {/* Product Image */}
                          <div className="col-3">
                            <img
                              src={item.PRODUCT_IMG}
                              alt={item.PRODUCT_NAME}
                              className="img-fluid rounded"
                              style={{
                                width: "100%",
                                height: "auto",
                                objectFit: "cover",
                              }}
                            />
                          </div>

                          {/* Product Details */}
                          <div className="col-9">
                            <h5 className="mb-1">{item.PRODUCT_NAME}</h5>
                            <h6 style={{ color: "#A42642" }}>
                              ₹{" "}
                              {item.PRODUCT_PRICE -
                                Math.round(
                                  (item.PRODUCT_PRICE * item.DISCOUNT) / 100
                                )}
                              {item.DISCOUNT !== 0 && (
                                <>
                                  <span
                                    className="text-muted ms-2"
                                    style={{
                                      textDecoration: "line-through",
                                      fontSize: "14px",
                                    }}
                                  >
                                    ₹ {item.PRODUCT_PRICE}
                                  </span>
                                  <p>
                                    <span
                                      style={{ background: "#8f3246" }}
                                      className="badge"
                                    >
                                      Discount {item.DISCOUNT}%
                                    </span>
                                  </p>
                                </>
                              )}
                            </h6>

                            {/* Quantity Controls */}
                            <div className="d-flex align-items-center gap-2">
                              <button
                                className="btn btn-sm btn-outline-custom"
                                onClick={() => handleClick1(item)}
                              >
                                -
                              </button>
                              <span>{item.QUANTITY}</span>
                              <button
                                className="btn btn-sm btn-outline-custom"
                                onClick={() => handleClick2(item)}
                              >
                                +
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </>
                ) : (
                  // Address Input UI
                  <>
                    <div class="mb-3 ">
                      <Select
                        name="Country"
                        placeholder="Select Country"
                        options={countryList}
                        value={
                          countryList.find(
                            (country) => country.value === shipAdd?.countryID
                          ) || null
                        } // Ensure correct list
                        onChange={(e) => {
                          const selectedCountry = countryList.find(
                            (country) => country.value === e.value
                          );

                          if (selectedCountry) {
                            setShipAdd((prev) => ({
                              ...prev,
                              Country: selectedCountry.label, // Store Country Name
                              countryID: selectedCountry.value, // Store Country ID
                            }));
                          }

                          setSelectedCountry(e.value);
                          setStateList([]); // Reset state when country changes
                          setCityList([]); // Reset city when country changes
                          fetchStateList(e.value); // Fetch new state list based on country
                        }}
                      />
                    </div>
                    <div class="mb-3 ">
                      <Select
                        name="State"
                        placeholder="Select State"
                        options={stateList}
                        value={
                          stateList.find(
                            (state) => state.value === shipAdd?.stateID
                          ) || null
                        }
                        onChange={(e) => {
                          setSelectedState(e.value);
                          const selectedState = stateList.find(
                            (state) => state.value === e.value
                          );

                          if (selectedState) {
                            setShipAdd((prev) => ({
                              ...prev,
                              State: selectedState.label,
                              stateID: selectedState.value, // Store StateID
                            }));
                          }
                        }}
                      />
                    </div>
                    <div class="mb-3 ">
                      <Select
                        name="City"
                        placeholder="Select City"
                        options={cityList}
                        value={
                          cityList.find(
                            (city) => city.value === shipAdd?.districtID
                          ) || null
                        } // Fix here
                        onChange={(e) => {
                          const selectedCity = cityList.find(
                            (city) => city.value === e.value
                          );

                          if (selectedCity) {
                            setShipAdd((prev) => ({
                              ...prev,
                              City: selectedCity.label,
                              districtID: selectedCity.value, // Store DistrictID
                            }));
                          }
                        }}
                      />
                    </div>

                    <div class="mb-3 ">
                      <input
                        type="text"
                        placeholder=" Pincode"
                        class="form-control"
                        id="pincode"
                        value={shipAdd?.PinCode || ""}
                        maxLength={6}
                        onChange={(e) => {
                          // Remove non-alphabet characters except spaces
                          const formattedInput = e.target.value
                            .replace(/[^\d]/g, "")
                            .trim();

                          setShipAdd((prev) => ({
                            ...prev,
                            PinCode: formattedInput, // Update Flat instead of name
                          }));
                        }}
                      />
                      {error &&
                      (shipAdd?.PinCode === "" ||
                        shipAdd?.PinCode === null ||
                        shipAdd?.PinCode === undefined) ? (
                        <span
                          className="text-danger"
                          style={{ fontSize: "small" }}
                        >
                          {ERROR_MESSAGES}
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="mb-3">
                      <input
                        type="text"
                        placeholder="House No./Building No."
                        className="form-control"
                        value={shipAdd?.Flat || ""}
                        onChange={(e) => {
                          setShipAdd((prev) => ({
                            ...prev,
                            Flat: e.target.value,
                          }));
                        }}
                      />
                      {error &&
                        (!shipAdd?.Flat || shipAdd?.Flat.trim() === "") && (
                          <span
                            className="text-danger"
                            style={{ fontSize: "small" }}
                          >
                            {ERROR_MESSAGES}
                          </span>
                        )}
                    </div>

                    <div className="mb-3">
                      <input
                        type="text"
                        placeholder="Road name/Area/Colony"
                        className="form-control"
                        value={shipAdd?.Colony || ""}
                        onChange={(e) => {
                          setShipAdd((prev) => ({
                            ...prev,
                            Colony: e.target.value,
                          }));
                        }}
                      />
                      {error &&
                        (!shipAdd?.Colony || shipAdd?.Colony.trim() === "") && (
                          <span
                            className="text-danger"
                            style={{ fontSize: "small" }}
                          >
                            {ERROR_MESSAGES}
                          </span>
                        )}
                    </div>

                    <div className="mb-3">
                      <input
                        type="text"
                        placeholder="Landmark"
                        className="form-control"
                        value={shipAdd?.Landmark || ""}
                        onChange={(e) => {
                          setShipAdd((prev) => ({
                            ...prev,
                            Landmark: e.target.value,
                          }));
                        }}
                      />
                      {error &&
                        (!shipAdd?.Landmark ||
                          shipAdd?.Landmark.trim() === "") && (
                          <span
                            className="text-danger"
                            style={{ fontSize: "small" }}
                          >
                            {ERROR_MESSAGES}
                          </span>
                        )}
                    </div>
                  </>
                )}
              </div>

              {/* Right Section - Price Summary */}
              <div className="col-md-4">
                <h5>Price Details ({product?.length} Items)</h5>
                <div className="card p-3 shadow-sm">
                  <div className="d-flex justify-content-between">
                    <p>Total Product Price ₹: </p>
                    <p> {total}</p>
                  </div>
                  <div className="d-flex justify-content-between">
                    <p>Discount amount ₹:</p>
                    <p>- {totalDiscountAmount}</p>
                  </div>
                  <hr />
                  <div className="d-flex justify-content-between">
                    <p>Sub Total ₹:</p>
                    <p> {Math.round(total - totalDiscountAmount)}</p>
                  </div>
                  <div className="d-flex justify-content-between">
                    <p>Tax (18 %) ₹:</p>
                    <p>+{Math.round((total - totalDiscountAmount) * 0.18)}</p>
                  </div>
                  <hr />

                  <div className="d-flex justify-content-between">
                    <p>Total Amount after Tax</p>
                    <p>₹{((total - totalDiscountAmount) * 1.18).toFixed(0)}</p>
                  </div>
                  <div className="d-flex justify-content-between">
                    <p>
                      Shipping Charges ₹:{" "}
                      <Tooltip title="Shipping Charges will calculate after selecting city.">
                        .<i class="fa-solid fa-circle-info"></i>
                      </Tooltip>
                    </p>
                    <p>+ {deliveryChargesAmt}</p>
                  </div>
                  {/* <div className="d-flex justify-content-between">
                    <p style={{ color: "#8f3246", fontWeight: "bold" }}>
                      Total Discount
                    </p>
                    <p style={{ color: "#8f3246", fontWeight: "bold" }}>
                      - ₹{discount}
                    </p>
                  </div> */}

                  <hr />
                  <div className="d-flex justify-content-between fw-bold">
                    <p>Grand Total</p>
                    <p>₹{grandTotal}</p>
                  </div>
                </div>
                <button
                  style={{ background: "#8f3246" }}
                  className="btn text-white  mb-3 me-2 mt-2 "
                  onClick={() => setShowProductDetails(!showProductDetails)}
                >
                  {showProductDetails
                    ? allFieldsFilled
                      ? "Edit Address"
                      : "Enter Address"
                    : "View Products"}
                </button>

                {shipAdd.City &&
                  shipAdd.Flat &&
                  shipAdd.Colony &&
                  shipAdd.Landmark &&
                  shipAdd.PinCode &&
                  shipAdd.State && (
                    <button
                     
                       style={{ background: "#8f3246" }}
                  className="btn text-white  mb-3 me-2 mt-2 "
                      onClick={() => PaymentApproved("online")}
                    >
                      Proceed to Payment
                    </button>
                  )}
                <div className="mt-3 text-center">
                  <p className="mt-2">Your Safety, Our Priority</p>
                  <p className="text-muted small">
                    We make sure that your package is safe at every point of
                    contact.
                  </p>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="d-flex justify-content-center align-items-center vh-100 w-100">
              <div className="text-center">
                <img
                  src={fav}
                  alt="Empty Cart"
                  className="img-fluid mb-3"
                  style={{ maxWidth: "400px" }}
                />
                <h5 className="fw-bold">Your cart is empty</h5>
                <p className="text-muted">
                  Just relax, let us help you find some first-class products
                </p>
                <Link to="/my-store">
                  <button
                    style={{ background: "#8f3246" }}
                    className="text-white btn me-2"
                  >
                    Add More Products{" "}
                    <i className="fa fa-shopping-cart ms-1"></i>
                  </button>
                </Link>
              </div>
            </div>
          </>
        )}
      </div>
      {showCodModal && (
        <CODPaymentModal
          show={showCodModal}
          onHide={() => setShowCodModal(false)}
          modelRequestData={modelRequestData}
          btnClick={() => PaymentApproved("cash")}
          setModelRequestData={setModelRequestData}
        />
      )}
    </>
  );
};

export default ViewCart;
