import axios from "axios";
import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { BaseUrl } from "../../BaseUrl/BaseUrl";
// import Mylogo from "../Images/logo.png";
import Mylogo from "../../assets/images/logo.png";
import Select from "react-select";
import CODPaymentModal from "../Payment Gateway/CODPaymentModal";
import toast from "react-hot-toast";
import dayjs from "dayjs";
const Checkout = () => {
  const ValidationToast = () =>
    toast.error("Please fill all fields before proceeding with the payment.");
  const [paymentMethod, setPaymentMethod] = useState("cash");

  const [shipAdd, setShipAdd] = useState({
    PinCode: "",
    Flat: "",
    Colony: "",
    Landmark: "",
    City: "",
    State: "",
    Payment: "",
  });

  const [modelRequestData, setModelRequestData] = useState({
    Action: null,
    CodPayload: null,
    totalVal: null,
  });

  // const finalTotal = total + shipCharges;

  const navigate = useNavigate();
  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [payment, setPayment] = useState(""); // Add this line

  const [product, setProduct] = useState([]);
  const [total, setTotal] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [qqty, setQqty] = useState(0);
  const [shipCharges, setShippingCharges] = useState(0);
  const location = useLocation();
  const [finalTotal, setFinalTotal] = useState(0); // Add state for finalTotal
  const [showCodModal, setShowCodModal] = useState(false);
  useEffect(() => {
    getProducts(); // Fetch products and set total
    fetchCountryList(); // Fetch country list
  }, []);

  useEffect(() => {
    // Calculate final total whenever total or shipCharges change
    setFinalTotal((total - total * (discount / 100) + shipCharges).toFixed(2));
  }, [total, shipCharges]);

  const getProducts = () => {
    const data = localStorage.getItem("userID");
    axios
      .post(`${BaseUrl}/Estore_Cart?USER_ID=${data}`, {
        USER_ID: data,
        START: 1,
        END: 10,
        WORD: "None",
      })
      .then((res) => {
        const productData = res.data.DATA;
        setProduct(productData);
        // Calculate total, discount, and quantity
        let totalDiscount = 0;
        let totalQuantity = 0;
        let subtotal = 0;

        productData.forEach((product) => {
          const quantity = parseInt(product.QUANTITY) || 0;
          const price =
            product.PRODUCT_PRICE * product.QUANTITY -
            Math.round(
              (product.PRODUCT_PRICE * (product.DISCOUNT * product.QUANTITY)) /
                100
            );

          // const price = parseFloat(product.PRODUCT_PRICE) || 0;
          const discountPercent = parseFloat(product.DISCOUNT) || 0;

          const productTotal = price * quantity;
          totalQuantity += quantity;
          subtotal += price;

          if (discountPercent > 0) {
            totalDiscount += discountPercent * quantity;
          }
        });

        const finalDiscountPercentage =
          totalQuantity > 0 ? totalDiscount / totalQuantity : 0;
        setDiscount(finalDiscountPercentage);
        setQqty(totalQuantity);
        setTotal(subtotal);
        setModelRequestData((PREV) => ({ ...PREV, totalVal: subtotal }));
      });
  };

  useEffect(() => {
    // Fetch or calculate your total here
    const calculatedTotal = 100; // Replace with your logic
    setTotal(calculatedTotal);
  }, []);

  const fetchCountryList = async () => {
    try {
      const response = await axios.get(`${BaseUrl}/Country`);
      console.log("Country Response:", response.data); // Log the response

      // Check the response code correctly
      if (response.data.ResponseCode === "0") {
        const countries = response.data.DATA || []; // Access DATA array
        const formattedCountryList = countries.map((country) => ({
          value: country.COUNTRY_ID,
          label: country.COUNTRY_NAME,
        }));
        setCountryList(formattedCountryList);
      } else {
        console.error(
          "Error fetching countries:",
          response.data.ResponseMessage
        );
      }
    } catch (error) {
      console.error("Error fetching country data:", error);
    }
  };

  const fetchStateList = async (countryID) => {
    try {
      const response = await axios.get(
        `${BaseUrl}/State?CountryID=${countryID}`
      );
      console.log("State Response:", response.data); // Log the response

      // Check the response code correctly
      if (response.data.ResponseCode === "0") {
        const states = response.data.DATA || []; // Access DATA array
        const formattedStateList = states.map((state) => ({
          value: state.StateID,
          label: state.StateName,
        }));
        setStateList(formattedStateList);
      } else {
        console.error("Error fetching states:", response.data.ResponseMessage);
      }
    } catch (error) {
      console.error("Error fetching state data:", error);
    }
  };

  const fetchDistrictList = async (stateID) => {
    try {
      const response = await axios.get(`${BaseUrl}/City?StateID=${stateID}`);
      console.log("District Response:", response.data); // Log the response

      // Check the response code correctly
      if (response.data.ResponseCode === "0") {
        const districts = response.data.DATA || []; // Access DATA array
        const formattedDistrictList = districts.map((district) => ({
          value: district.DistrictID, // Use DistrictID as the identifier
          label: district.DistrictName, // Use DistrictName as the label
        }));
        setCityList(formattedDistrictList); // Update state with the list of districts
      } else {
        console.error(
          "Error fetching districts:",
          response.data.ResponseMessage
        );
      }
    } catch (error) {
      console.error("Error fetching district data:", error);
    }
  };

  const inputHandle = (e, name) => {
    if (e && typeof e === "object" && "value" in e) {
      if (name === "Country") {
        setSelectedCountry(e.value);
        fetchStateList(e.value);
        setShipAdd((prev) => ({ ...prev, State: "", City: "" }));
      } else if (name === "State") {
        setSelectedState(e.value);
        fetchDistrictList(e.value);
        setShipAdd((prev) => ({ ...prev, State: e.label, City: "" })); // Update this line
      } else if (name === "City") {
        const selectedCity = cityList.find((city) => city.value === e.value);
        setShipAdd((prev) => ({ ...prev, City: selectedCity.label }));
        ShippingCharges(selectedCity.label, total);
      }
    } else if (e && e.target) {
      const { name, value } = e.target;

      if (name === "PinCode") {
        if (!/^\d{0,6}$/.test(value)) return; // Ensure only digits and max length of 6
      }

      if (name === "Payment") {
        setPayment(value); // Update payment state
      }

      setShipAdd((prev) => ({ ...prev, [name]: value }));
    }
  };

  const ShippingCharges = (city, total) => {
    const normalizedCity = city?.trim().toLowerCase();

    if (!city || city === "") {
      setShippingCharges(0);
    } else if (normalizedCity === "mumbai" && total < 100) {
      setShippingCharges(40);
    } else if (normalizedCity === "mumbai" && total >= 100 && total < 500) {
      setShippingCharges(50);
    } else if (normalizedCity === "mumbai" && total >= 500 && total < 1000) {
      setShippingCharges(55);
    } else if (normalizedCity === "mumbai" && total >= 1000) {
      setShippingCharges(10);
    } else if (normalizedCity !== "mumbai" && total < 1000) {
      setShippingCharges(100);
    } else if (normalizedCity !== "mumbai" && total >= 1000) {
      setShippingCharges(20);
    }
  };

  // Updated PaymentApproved to accept a mode parameter ("online" or "cash")
  const PaymentApproved = (paymentMode) => {
    // debugger;
    const User_Id = localStorage.getItem("userID");
    const { PinCode, Flat, Colony, Landmark, City, State, Payment } = shipAdd;

    let Prod_Id = "";
    let Prod_Price = "";
    let Prod_Discount = "";
    let Prod_Quantity = "";

    product.forEach((products) => {
      Prod_Id = Prod_Id.concat(",", products.PRODUCT_ID);
      Prod_Price = Prod_Price.concat(",", products.PRODUCT_PRICE);
      Prod_Discount = Prod_Discount.concat(",", products.DISCOUNT);
      Prod_Quantity = Prod_Quantity.concat(",", products.QUANTITY);
    });

    const Prod_Id1 = Prod_Id.slice(1);
    const Prod_Price1 = Prod_Price.slice(1);
    const Prod_Discount1 = Prod_Discount.slice(1);
    const Prod_Quantity1 = Prod_Quantity.slice(1);

    // Calculate a delivery date 5 days from now
    let date = dayjs().add(5, "day").format("YYYY-MM-DD");

    // Declare PAYLOAD first
    let PAYLOAD = {
      USER_ID: User_Id,
      TOTAL_QTY: `${qqty}`,
      TOTAL_PRICE: `${total}`,
      TOTAL_DISC: `${discount}`,
      RATE: `${Prod_Price1}`,
      PRODUCT_ID: `${Prod_Id1}`,
      QTY: `${Prod_Quantity1}`,
      DISCOUNT: `${Prod_Discount1}`,
      ORDER_ADDRESS: `${PinCode} ${Flat} ${Colony} ${Landmark} ${City} ${State} ${Payment}`,
      SHIPING_CHARGES: `${shipCharges}`,
      DELIVERY_DATE: `${date}`,
      TRANSACTION_AMT: ``,
      TRANSACTION_ID: "",
    };

    // Calculate the total amount (price + shipping)
    // let ToTalAmount = parseFloat(PAYLOAD.SHIPING_CHARGES) + parseFloat(PAYLOAD.TOTAL_PRICE);
    const discountAmount = parseFloat(PAYLOAD.TOTAL_PRICE) * (discount / 100);

    // Calculate final total after discount, then add shipping charges
    const ToTalAmount =
      parseFloat(PAYLOAD.TOTAL_PRICE) -
      discountAmount +
      parseFloat(PAYLOAD.SHIPING_CHARGES);

    let finalPayment, remainingAmount;

    if (payment === "online") {
      finalPayment = ToTalAmount.toFixed(2);
      remainingAmount = "0.00";
    } else {
      finalPayment = (ToTalAmount * 0.2).toFixed(2);
      remainingAmount = (ToTalAmount - modelRequestData?.amountEntered).toFixed(
        2
      );
    }
    // Continue if the shipping address fields are valid
    if (
      shipAdd.City !== "" &&
      shipAdd.PinCode !== "" &&
      shipAdd.Flat !== "" &&
      shipAdd.Colony !== "" &&
      shipAdd.Landmark !== "" &&
      shipAdd.State !== ""
    ) {
      axios
        .post(`${BaseUrl}/Estore_order`, PAYLOAD)
        .then((response) => {
          if (response.data.ResponseMessage === "Order Success.") {
            debugger
            localStorage.setItem("cartItemCount", "0");
            window.dispatchEvent(new Event("cartUpdated"));
            if (response.data.ID) {
              let ORDER_ID = response.data.ID;

              const options = {
                key: "rzp_test_vv1FCZvuDRF6lQ",
                key_secret: "P4JAUwn4VdE6xDLJ6p2Zy8RQ",
                // Multiply by 100 to convert to smallest currency unit (e.g., paise)
                amount: parseInt(modelRequestData?.amountEntered * 100),
                currency: "INR",
                name: "My Om Namo - WowInfotech",
                description: "Order Payment",
                image: Mylogo,
                handler: function (response) {
                  console.log(response, "Payment Success");
                  const TRANSACTION_ID = response.razorpay_payment_id;

                  let PAYMENT_PAYLOAD = {
                    USER_ID: User_Id,
                    ORDER_ID: ORDER_ID,
                    TRANSACTION_ID: TRANSACTION_ID,
                    PAYMENT_STATUS: "Success",
                    TRANSACTION_STATUS: "Success",
                    TRANSACTION_AMT: modelRequestData?.amountEntered,
                    REMAING_AMOUNT: remainingAmount,
                    PAYMENT_DATE: date,
                  };

                  axios
                    .post(
                      `${BaseUrl}/UpdatePaymentStatus?TYPE=Estore`,
                      PAYMENT_PAYLOAD
                    )
                    .then((res) => {
                      if (res.data.ResponseMessage === "successfully.") {
                        localStorage.setItem("cartItemCount", "0");
                        window.dispatchEvent(new Event("cartUpdated"));
                        const data = {
                          remainingAmount: remainingAmount,
                          transactionAmount: modelRequestData?.amountEntered,
                        };
                        localStorage.setItem(
                          "paymentData",
                          JSON.stringify(data)
                        );

                        navigate("/thank-you");
                      } else {
                        alert("Order placement failed, please try again.");
                      }
                    })
                    .catch((err) =>
                      console.error("Payment Status Update Error:", err)
                    );
                },
                theme: { color: "#ff416c" },
              };

              const rzp = new window.Razorpay(options);
              rzp.open();
            }
          } else {
            alert("Order placement failed, please try again.");
          }
        })
        .catch((err) => console.error("Order Error:", err));
    } else {
      ValidationToast();
    }
  };

  const functionOne = () => {
    const selectedPayment = document.querySelector(
      'input[name="Payment"]:checked'
    ).value;

    // Validate shipping address
    if (
      shipAdd?.PinCode === null ||
      shipAdd?.PinCode === undefined ||
      shipAdd?.PinCode === "" ||
      shipAdd?.Flat === null ||
      shipAdd?.Flat === undefined ||
      shipAdd?.Flat === "" ||
      shipAdd?.Colony === null ||
      shipAdd?.Colony === undefined ||
      shipAdd?.Colony === "" ||
      shipAdd?.Landmark === null ||
      shipAdd?.Landmark === undefined ||
      shipAdd?.Landmark === "" ||
      shipAdd?.City === null ||
      shipAdd?.City === undefined ||
      shipAdd?.City === "" ||
      shipAdd?.State === null ||
      shipAdd?.State === undefined ||
      shipAdd?.State === ""
      // shipAdd?.Payment === null || shipAdd?.Payment === undefined || shipAdd?.Payment === ''
    ) {
      ValidationToast();
      return false;
    }

    // For cash payment, keep existing behavior; for online, process full payment
    if (selectedPayment === "cash") {
      setModelRequestData((prev) => ({
        ...prev,
        TotalPayableAmount: (total + shipCharges).toFixed(2),
      }));
      setShowCodModal(true);
    } else {
      PaymentApproved("online");
    }
  };

  useEffect(() => {
    setModelRequestData((prevData) => ({
      ...prevData,
      totalVal: total + shipCharges,
    }));
  }, [finalTotal, discount]); // Dependencies ensure the value updates when `finalTotal` or `discount` changes

  return (
    <div
      className="p-5 py-4"
      style={{ border: "1px solid #ccc", borderRadius: "5px" }}
    >
      {/* Page Title */}
      <h2 className="mb-2">Checkout</h2>

      <div className="row">
        {/* Left Column: Product List + Order Summary */}
        <div className="col-lg-8">
          {/* Product List */}
          <div className="table-responsive mb-4">
            <table className="table">
              <tbody>
                {product.map((cartItem) => (
                  <tr key={cartItem.PRODUCT_ID}>
                    <td style={{ width: "100px" }}>
                      <img
                        src={cartItem.PRODUCT_IMG}
                        alt={cartItem.PRODUCT_NAME}
                        width={60}
                        height={50}
                      />
                    </td>
                    <td>
                      <h5 className="mb-1">{cartItem.PRODUCT_NAME}</h5>
                      <p className="text-success mb-0">
                        ₹{" "}
                        {cartItem.PRODUCT_PRICE * cartItem.QUANTITY -
                          Math.round(
                            (cartItem.PRODUCT_PRICE *
                              (cartItem.DISCOUNT * cartItem.QUANTITY)) /
                              100
                          )}
                      </p>
                    </td>
                    <td className="text-center" style={{ width: "80px" }}>
                      {cartItem.QUANTITY}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* Order Summary */}
          <div className="border p-3 rounded mb-4">
            <ul className="list-unstyled mb-0">
              <li className="d-flex justify-content-between mb-2">
                <span>Total Quantity</span>
                <span className="fw-bold">{qqty}</span>
              </li>
              <li className="d-flex justify-content-between mb-2">
                <span>Sub Total</span>
                <span className="fw-bold">₹ {Number(total).toFixed(2)}</span>
              </li>
              <li className="d-flex justify-content-between mb-2">
                <span>Shipping Charges</span>
                <span className="fw-bold">₹{shipCharges}</span>
              </li>
              {/* <li className="d-flex justify-content-between mb-2">
                <span>Total Discount</span>
                <span className="fw-bold">{Number(discount).toFixed(2)}%</span>
              </li> */}

              {/* <li className="d-flex justify-content-between mb-2">
                <span>Discount ({Number(discount).toFixed(2)}%)</span>
                <span className="fw-bold text-danger">
                  - ₹ {(total * (discount / 100)).toFixed(2)}
                </span>
              </li> */}

              <li className="d-flex justify-content-between mb-2">
                <span>Sub Total </span>
                <span className="fw-bold">
                  ₹ {(total + shipCharges).toFixed(2)}
                </span>
              </li>

              {/* <li className="d-flex justify-content-between fs-5">
                <span>Total + Shipping Charges</span>
                <span className="fw-bold">
                  ₹ {((total - total * (discount / 100)) + shipCharges).toFixed(2)}
                </span>
              </li> */}
            </ul>
          </div>
        </div>

        {/* Right Column: Shipping Address + Payment Method */}
        <div className="col-lg-4 card shadow">
          <h4 className="mb-3">Shipping Address</h4>
          <form>
            <div className="mb-3">
              <input
                type="text"
                name="PinCode"
                placeholder="Pin Code"
                className="form-control"
                maxLength="6"
                onChange={inputHandle}
                onKeyPress={(e) => {
                  if (!/^\d$/.test(e.key)) {
                    e.preventDefault(); // Prevent non-digit input
                  }
                }}
              />
            </div>
            <div className="mb-3">
              <input
                type="text"
                name="Flat"
                placeholder="Flat/House No./Floor/Building"
                className="form-control"
                onChange={inputHandle}
              />
            </div>
            <div className="mb-3">
              <input
                type="text"
                name="Colony"
                placeholder="Colony/Street"
                className="form-control"
                onChange={inputHandle}
              />
            </div>
            <div className="mb-3">
              <input
                type="text"
                name="Landmark"
                placeholder="Landmark"
                className="form-control"
                onChange={inputHandle}
              />
            </div>

            <div className="mb-3">
              <Select
                name="Country"
                placeholder="Select Country"
                options={countryList}
                onChange={(selectedOption) =>
                  inputHandle(selectedOption, "Country")
                }
              />
            </div>
            <div className="mb-3">
              <Select
                name="State"
                placeholder="Select State"
                options={stateList}
                onChange={(selectedOption) =>
                  inputHandle(selectedOption, "State")
                }
                isDisabled={!selectedCountry}
              />
            </div>
            <div className="mb-3">
              <Select
                name="City"
                placeholder="Select City"
                options={cityList}
                onChange={(selectedOption) =>
                  inputHandle(selectedOption, "City")
                }
                isDisabled={!selectedState}
              />
            </div>

            {/* Payment Method */}
            <div className="d-flex align-items-center">
              <div className="form-check me-4">
                <input
                  type="radio"
                  value="cash"
                  name="Payment"
                  className="form-check-input"
                  id="paymentCash"
                  onChange={inputHandle}
                  defaultChecked
                  style={{ appearance: "auto" }} // Ensures visibility
                />
                <label className="form-check-label" htmlFor="paymentCash">
                  Cash On Delivery
                </label>
              </div>
              <div className="form-check">
                <input
                  type="radio"
                  value="online"
                  name="Payment"
                  className="form-check-input"
                  id="paymentOnline"
                  onChange={inputHandle}
                  style={{ appearance: "auto" }} // Ensures visibility
                />
                <label className="form-check-label" htmlFor="paymentOnline">
                  Online Payment
                </label>
              </div>
            </div>
          </form>
        </div>
      </div>

      {/* Action Buttons */}
      <div className="row border-top pt-3 mt-3">
        <div className="col-12 text-end">
          <Link to="/my-store">
            <button
              style={{ background: "#8f3246" }}
              className="text-white btn me-2"
            >
              Add More Products <i className="fa fa-shopping-cart ms-1"></i>
            </button>
          </Link>
          <button
            style={{ background: "#8f3246" }}
            className="btn text-white "
            onClick={functionOne}
          >
Proceed to Payment <i class="fa-solid fa-wallet"></i>
          </button>
        </div>
      </div>
      {showCodModal && (
        <CODPaymentModal
          show={showCodModal}
          onHide={() => setShowCodModal(false)}
          modelRequestData={modelRequestData}
          btnClick={() => PaymentApproved()}
          setModelRequestData={setModelRequestData}
        />
      )}
    </div>
  );
};

export default Checkout;
