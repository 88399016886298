import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { GetPanditListDataAPI } from "../../ApiServices/PanditList/PanditList";
import "./PanditList.css";
import BookingReviewModal from "./BookingReviewModal";

const PanditList = () => {
  const [searchKeyword, setSearchKeyword] = useState(null);
  const [showBookingReviewModal, setShowBookingReviewModal] = useState(null);
  const [panditDataList, setPanditDataList] = useState([]);

  const location = useLocation();
  const userID = localStorage.getItem("userID");
  const product = location.state.product;
  const productBookingData = location.state.productBookingData;

  useEffect(() => {
    GetPanditListData();
  }, []);

  //   const GetPanditListData = () => {};
  const GetPanditListData = async () => {
    // debugger;
    try {
      const response = await GetPanditListDataAPI(
        userID,
        location.state.product.pujaId,
        {
          START: 1,
          END: 10,
          WORD: "None",
          LATITUDE: "20.0086678",
          LONGITUDE: "73.7639264",
        }
      );

      if (response) {
        setPanditDataList(response.DATA);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handlePanditCardClick = (data) => {
    setShowBookingReviewModal(true);
  };

  console.log(product, "product");
  console.log(panditDataList, "sdfgdf");
  return (
    <div className="panditList">
      <section className="page-header">
        <div className="page-header__bg"></div>
        <div className="container">
          <h2 className="page-header__title bw-split-in-left">Pandit List</h2>
        </div>
      </section>
      <div className="prod-container container">
        <div className="row margin-bottom-40">
          <div className="col-md-12 px-lg-5">
            {" "}
            {/* Adjusted to take full width */}
            <div className="searching" style={{ marginTop: "50px" }}>
              <div className="search-name">
                <div className="input-group mb-5">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search Pandit"
                    onChange={(e) => setSearchKeyword(e.target.value)}
                    aria-label="Search Pandit"
                    aria-describedby="basic-addon1"
                  />
                  <span className="input-group-text" id="basic-addon1">
                    <a href="/pandit-list">
                      <i className="fa fa-search search-btn"></i>
                    </a>
                  </span>
                </div>
              </div>
            </div>
            <div className="padit-list-products">
              <div className="row">
                {panditDataList?.map((pandit) => (
                  <div
                    className="col-lg-3 col-md-4 col-sm-6 mb-4" // Updated to show 4 cards in a row on large screens
                    key={pandit.PANDIT_ID}
                  >
                    <div
                      className="box shadow-sm bg-white"
                      style={{ cursor: "pointer" }}
                      onClick={() => handlePanditCardClick(pandit)}
                    >
                      <div
                        className="figure"
                        style={{ display: "flex", background: "white" }}
                      >
                        <img
                          src={pandit.PHOTO}
                          alt={pandit.PANDIT_NAME}
                          className="img-responsive"
                          style={{
                            height: "250px",
                            objectFit: "contain",
                            background: "white",
                          }}
                        />
                      </div>
                      <div className="details">
                        <h4>{pandit.PANDIT_NAME}</h4>
                        <h5>
                          <strong>Mobile No:</strong> {pandit.MOBILE_NO}
                        </h5>
                        <h5>
                          <strong>Experience:</strong> {pandit.EXPERIENCE} years
                        </h5>
                        <h5>
                          <strong>DOB:</strong>{" "}
                          {new Date(pandit.DOB).toLocaleDateString()}
                        </h5>
                        <h5>
                          <strong>Rating:</strong> {pandit.PANDIT_RATING}
                        </h5>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <BookingReviewModal
        show={showBookingReviewModal}
        onHide={() => setShowBookingReviewModal(false)}
        product={product}
        productBookingData={productBookingData}
      />
    </div>
  );
};

export default PanditList;
