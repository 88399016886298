import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import logo from '../../src/assets/images/logo.png'
import { EstoreInvoice } from "../ApiServices/EStore/EStoreApi";
import dayjs from "dayjs";

const EStoreInvoice = () => {

  const [productArray, setProductArray] = useState([])
  const user_id = localStorage.getItem("userID");


  const [eStoreData, setEStoreData] = useState({


    FULL_NAME: null,
    ADDRESS: null,
    MOBILE_NO: null,
    TotalItem: null,
    TotalAmountOfProduct: null,
    TotalDiscountAmount: null,
    TotalAmount: null,
    TaxPercentage: null,
    TaxAmount: null,
    GrandTotal: null,
    DeliveryCharges: null,
    InvoiceNumber: null,
    InvoiceDate: null

  })

  const location = useLocation();
  console.log(location.state);

  useEffect(() => {

    GetEstoreInvoiceInvoice()

  }, [location.state]);
  console.log(location.state, 'jkhgdsa876agv23d');


  const GetEstoreInvoiceInvoice = async () => {
    debugger;

    try {
      const response = await EstoreInvoice(
        user_id,
        location.state.OrderID
      );
      console.log(response);
      if (
        response.ResponseMessage === "Success" &&
        response.ResponseCode === "0"
      ) {
        debugger
        const modelData = response.DATA.Table[0];
        const productData = response.DATA.Table1;
        setProductArray(productData)
        setEStoreData((prev) => ({
          ...prev,
          FULL_NAME: modelData.FULL_NAME,
          ADDRESS: modelData.ADDRESS,
          MOBILE_NO: modelData.MOBILE_NO,
          TotalItem: modelData.TotalItem,
          TotalAmountOfProduct: modelData.TotalAmountOfProduct,
          TotalDiscountAmount: modelData.TotalDiscountAmount,
          TotalAmount: modelData.TotalAmount,
          TaxPercentage: modelData.TaxPercentage,
          TaxAmount: modelData.TaxAmount,
          GrandTotal: modelData.GrandTotal,
          DeliveryCharges: modelData.DeliveryCharges,
          InvoiceNumber: modelData.InvoiceNumber,
          InvoiceDate: modelData.InvoiceDate
        }));
      }

    } catch (error) {
      console.log(error);
    }
  };
  const handleDownloadPDF = () => {
    const invoiceElement = document.getElementById("invoice-content");

    html2canvas(invoiceElement, { scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      const imgWidth = 210;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
      pdf.save(`Invoice_${eStoreData.FULL_NAME}.pdf`);
    });
  };




  return (
    <div className="container d-flex flex-column justify-content-center align-items-center mt-2" style={{ minHeight: "100vh" }}>
      <div id="invoice-content" style={{ width: "70vw" }}>
        <div style={{ margin: '10px' }}>
          <div className="card shadow-sm" style={{
            border: "2px solid black",
            padding: "20px",
            borderRadius: "10px",
            backgroundColor: 'white',

          }}>
            <div className="d-flex justify-content-between align-items-center border-bottom p-3">

              <div >
                <h1 className="h3 fw-bold" style={{ color: "#A52444" }}>
                  INVOICE
                </h1>
              </div>
              <div>
                <img
                  src={logo}
                  alt="Buildous Logo"
                  className="img-fluid"
                  style={{ height: "50px", width: "120PX" }}
                />
              </div>
            </div>

            <div className="card-body" >
              <div className="row d-flex justify-content-between">
                <div className="col-md-6">
                  <h5 style={{ fontSize: '20px' }} EStore>Invoice No.</h5>

                  <p >

                    {/* #INV1212 */}
                    {eStoreData.InvoiceNumber}
                  </p>
                </div>
                <div className="col-md-6 d-flex justify-content-end">
                  <div>
                    <h5 style={{ fontSize: '20px' }} EStore>Invoice Date</h5>
                    <p style={{ fontSize: '15px' }}>{dayjs(eStoreData.InvoiceDate).format('DD/MM/YYYY')} </p>
                  </div>
                </div>
              </div>
              <div className="row d-flex justify-content-between">
                <div className="col-md-12">
                  <h5 style={{ fontSize: '20px' }}>Customer Info</h5>
                  <p style={{ margin: 'auto', fontSize: '15px' }}>
                    <strong style={{ marginRight: '10px' }}>Name:</strong>
                    {eStoreData.FULL_NAME}
                    {/* <strong>Name:</strong> Test customer */}
                  </p>
                  <p style={{ margin: 'auto', fontSize: '15px' }} >

                    <strong style={{ marginRight: '10px' }}>Address:</strong>
                    {eStoreData.ADDRESS}
                  </p>
                  <p style={{ fontSize: '15px' }} >
                    {/* <strong>Address:</strong> {location.state.Address} */}
                    <strong style={{ marginRight: '10px' }}>Mobile No.:</strong>
                    {eStoreData.MOBILE_NO}
                  </p>
                </div>
              </div>
              <table className="table table-bordered">
                <thead className="thead-light">
                  <tr>
                    <th style={{ fontSize: '15px' }}>Sr No.</th>
                    {/* <th style={{ fontSize: '15px' }}>Product Image</th> */}
                    <th style={{ fontSize: '15px' }}>Product Name</th>
                    <th style={{ fontSize: '15px' }}>Quantity</th>
                    <th style={{ fontSize: '15px' }}>Rate (₹)</th>
                    <th style={{ fontSize: '15px' }}>Discount (%)</th>
                    {/* <th style={{ fontSize: '15px' }}>Subtotal</th> */}
                    <th style={{ fontSize: '15px' }}>Total (₹)</th>
                  </tr>
                </thead>
                <tbody>
                  {productArray.map((product, index) => {
                    const subtotal = product.QTY * product.RATE;
                    const discountAmount = (subtotal * product.DISCOUNT) / 100;
                    const grandTotal = subtotal - discountAmount;

                    return (
                      <tr key={index} style={{ fontSize: '15px' }}>
                        <td>{index + 1}</td>
                        {/* <td>
                          <img src={product.PRODUCT_IMG} alt={product.PRODUCT_NAME} width="50" height="50" />
                        </td> */}
                        <td>{product.PRODUCT_NAME}</td>
                        <td>{product.QTY}</td>
                        <td>{product.RATE}</td>
                        <td>{product.DISCOUNT}%</td>
                        {/* <td>₹{subtotal.toFixed(2)}</td> */}
                        <td>{grandTotal}</td>

                      </tr>
                    );
                  })}
                </tbody>
              </table>


              <div className="row">
                <div className="col-md-8">
                  <h5 style={{ fontSize: '20px' }}>Notes</h5>
                  <p>Thank you for shopping with us!</p>
                </div>
                <div className="col-md-4">
                  <table className="table">
                    <tbody>

                      <tr>
                        <td style={{ fontSize: '15px' }}>
                          <strong>Subtotal:</strong>
                        </td>
                        <td className="text-right" style={{ fontSize: '15px' }}>
                          ₹{" "}

                          {Number(eStoreData.TotalAmountOfProduct)}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ fontSize: '15px' }}>
                          <strong>Discount Amount:</strong>
                        </td>
                        <td className="text-right" style={{ fontSize: '15px' }}>
                          ₹{" "}

                          {Number(eStoreData.TotalDiscountAmount)}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ fontSize: '15px' }}>
                          <strong>Tax (18%):</strong>
                        </td>
                        <td className="text-right" style={{ fontSize: '15px' }}>
                          {/* ₹ {location.state.shippingCharges} */}₹{" "}

                          {eStoreData.TaxAmount}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ fontSize: '15px' }}>
                          <strong>Delivery Charges:</strong>
                        </td>
                        <td className="text-right" style={{ fontSize: '15px' }}>
                          ₹{" "}   {eStoreData.DeliveryCharges}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ fontSize: '15px' }}>
                          <strong>Total Amount:</strong>
                        </td>
                        <td className="text-right" style={{ fontSize: '15px' }}>


                          ₹{" "}{eStoreData.GrandTotal}

                        </td>
                      </tr>
                    </tbody>
                  </table>

                </div>
              </div>
            </div>

            {/* Card Footer with Download Button */}


          </div>
        </div>

      </div>
      <div className="mt-3">
        <button className="btn" style={{
          backgroundColor: "#a52444",
          color: "white",
          padding: "10px 20px",
          fontSize: "16px",
          borderRadius: "5px"
        }} onClick={handleDownloadPDF}>
          <i class="fa-solid fa-download"></i> {" "}
          Download EStore Invoice
        </button>
      </div>
    </div>
  );
};

export default EStoreInvoice;
