import { useEffect, useState } from "react";
import React from "react";
import axios from "axios";
import { BaseUrl } from "../../BaseUrl/BaseUrl";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Mylogo from "../../assets/images/logo.png";
import dayjs from "dayjs";
import { ServiceDiscounts } from "../../Components/enum";

const DBOrderDetails = () => {
  const userID = JSON.parse(localStorage.getItem("userID"));
  //   useEffect(() => {
  //     axios
  //       .get(`${BaseUrl}/Estore_order?ORDER_ID=${location.state.ORDER_ID} `)
  //       .then((response) => {
  //         console.log("Response...", response.data.DATA);
  //         setOrders(response.data.DATA);
  //       });
  //   }, []);

  const location = useLocation();
  const navigate = useNavigate();
  const order = location.state.order;
  const SGST = 9;
  const CGST = 9;
  // const needSamagri = "Yes";
  const needSamagri = order?.NEED_SAMAGRI;

  // const Tax = Math.round(
  //   (ServiceDiscounts.DailyBookingDiscount / 100) * parseFloat(totalAmount)
  // );

  const numberOfDays = (startDate, endDate) => {
    const totalDays =
      (new Date(endDate) - new Date(startDate)) / (1000 * 60 * 60 * 24);

    return totalDays + 1;
  };

  const totalAmount = (
    price,
    fromDate,
    toDate,
    samagriPrice,
    needSamagri,
    convenienceFee,
    discount
  ) => {
    // debugger;
    const res =
      Number(price) * numberOfDays(fromDate, toDate) +
      (needSamagri === "yes"
        ? Number(samagriPrice || 0) * numberOfDays(fromDate, toDate)
        : 0) +
      Number(convenienceFee) -
      discount;

    return res;
  };

  const tax = Math.round(
    (ServiceDiscounts.DailyBookingDiscount / 100) *
    Number(order?.CONVENIENCE_FEE)
  );

  // const grandTotal = parseFloat(totalAmount || 0) + tax;

  const CalculateGrandTotal = (
    price,
    fromDate,
    toDate,
    samagriPrice,
    needSamagri,
    convenienceFee,
    discount
  ) => {
    // debugger;
    return (
      totalAmount(
        price,
        fromDate,
        toDate,
        samagriPrice,
        needSamagri,
        convenienceFee,
        discount
      ) +
      Math.round(
        (ServiceDiscounts.panditBookingDiscount / 100) *
        Number(
          totalAmount(
            price,
            fromDate,
            toDate,
            samagriPrice,
            needSamagri,
            convenienceFee,
            discount
          )
        )
      )
    );
  };

  // const Remaining_Amount =
  //   Number(order?.DPB_PRICE) +
  //   Number(needSamagri === "yes" ? order?.DPB_SAMAGRIPRICE : 0) +
  //   Number(order?.CONVENIENCE_FEE) +
  //   Tax - //Added Tax
  //   order?.TRANSACTION_AMT;

  const Remaining_Amount = Math?.round(order?.RemainingAmount)


  const handleProceedRemainingPayment = async () => {
    debugger;
    if (Remaining_Amount === 0) {
      alert("All the amount already paid");
      return;
    }
    // let totalAmount = parseInt(modelRequestData.totalVal); // Assuming totalVal includes shipping
    // let minPayableAmount = Remaining_Amount; // 20% of the total amount

    let orderIDReturned = order?.DPB_ID;
    // axios
    //   .post(`${BaseUrl}/BookPooja`, {
    //     PB_DATE: productBookingData.date,
    //     PB_TIME: productBookingData.time,
    //     PB_LANGAUGE: SelectLanguageOptions.find(
    //       (item) => item.value === productBookingData.languageID
    //     )?.label,
    //     PB_SAMAGRI: productBookingData.needSamagri,
    //     PB_PRICE: pujaDetails?.pujaPrice,
    //     PB_SAMAGRIPRICE: pujaDetails?.pujaSamagriPrice,
    //     PANDIT_ID: selectedPandit,
    //     USER_ID: userID,
    //     REFERENCES_NO: "",
    //     ADDRESS: productBookingData?.address,
    //     PUJA_ID: pujaDetails?.pujaId,
    //     PAYMENT_TYPE: "ONLINE",
    //     CONVINIENCE_FEE: pujaDetails?.convenienceFee,
    //     TAX: null,
    //     EXTRA: null,
    //     TRANSACTION_ID: "",
    //     TRANSACTION_AMT: "",
    //   })
    //   .then((res) => {
    //     if (res) {
    //       console.log(res);
    //       setOrderID(res.data.ID);
    //       // localStorage.setItem("orderID", JSON.stringify(res.data.ID));
    //       orderIDReturned = res.data.ID;
    //     }
    //   });

    const options = {
      key: "rzp_test_vv1FCZvuDRF6lQ",
      key_secret: "P4JAUwn4VdE6xDLJ6p2Zy8RQ",
      amount: Remaining_Amount * 100,
      currency: "INR",
      name: "My Om Namo- WowInfotech",
      description: "working",
      image: Mylogo,
      handler: function (response) {
        // console.log(response, "Payment Success");
        const paymentId = response.razorpay_payment_id;

        if (response) {
          axios
            .post(`${BaseUrl}/UpdatePaymentStatus?TYPE=DailyPuja`, {
              USER_ID: userID,
              ORDER_ID: orderIDReturned,
              // ORDER_ID: JSON.parse(localStorage.getItem("orderID")),
              TRANSACTION_ID: paymentId,
              PAYMENT_STATUS: "Success",
              TRANSACTION_STATUS: "Success",
              TRANSACTION_AMT: order?.RemainingAmount,
              // TRANSACTION_AMT: CalculateGrandTotal(
              //   order.DPB_PRICE,
              //   order.DPB_FROMDATE,
              //   order.DPB_TODATE,
              //   order.DPB_SAMAGRIPRICE,
              //   order.NEED_SAMAGRI,
              //   order.CONVENIENCE_FEE,
              //   order.DiscountAmount
              // ),
              REMAING_AMOUNT: 0,
              PAYMENT_DATE: dayjs().format("YYYY-MM-DD"),
            })
            .then((res) => {
              if (res.data.ResponseMessage === "successfully.") {
                // localStorage.setItem("cartItemCount", "0");
                // window.dispatchEvent(new Event("cartUpdated"));
                // setShowCODModal(false);
                // nextStep();
                navigate("/my-order-daily-Booking");
              } else {
                alert("Order placement failed, please try again.");
              }
            });
        }

        // const updatedPayload = {
        //   ...razorPayData,
        //   TRANSACTION_ID: paymentId,
        //   TRANSACTION_AMT: minPayableAmount,
        // };

        // axios.post(`${BaseUrl}/BookPooja`, updatedPayload).then((res) => {
        //   debugger;
        //   if (res.data.ResponseMessage === "Order Success.") {
        //     localStorage.setItem("cartItemCount", "0");
        //     window.dispatchEvent(new Event("cartUpdated"));
        //     navigate("/thank-you");
        //   } else {
        //     alert("Order placement failed, please try again.");
        //   }
        // });
      },
      theme: {
        color: "#ff416c",
      },
    };

    const rzp = new window.Razorpay(options);
    rzp.open();
  };

  console.log(order);

  const paymentBtnClick = () => { };

  const createMarkup = (html) => {
    return { __html: html };
  };

  function formatToIndianCurrency(amount) {
    if (!amount) return "";

    const [integerPart, decimalPart] = amount.toString().split(".");

    // Format the integer part using Indian numbering system
    const formattedIntegerPart = integerPart.replace(
      // Match groups of 3 digits initially, then groups of 2 digits
      /(\d)(?=(\d\d)+\d$)/g,
      "$1,"
    );

    // Return the formatted value with the decimal part (if present)
    return decimalPart !== undefined
      ? `${formattedIntegerPart}.${decimalPart}`
      : formattedIntegerPart;
  }

  const formatTime = (time) => {
    if (!time) return "";
    const [hours, minutes] = time.split(":").map(Number);
    return new Date(0, 0, 0, hours, minutes).toLocaleString("en-US", {
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    });
  };

  return (
    <>
      <div>
        <div className="top">
          <div className="pujaDetails" style={{ display: "flex" }}>
            <div className="left">
              <img src={order?.DP_IMAGE} alt="Puja-Img" />
            </div>
            <div className="right">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center", // Ensures vertical alignment
                  marginBottom: "10px",
                }}
              >
                {/* <h6
                  className="list-group-item custom-list-item clearfix"
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "20px",
                    textTransform: "none",
                    margin: 0, // Remove default margin for better alignment
                  }}
                >
                  Puja Details
                </h6> */}
                <h2
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "20px",
                    textTransform: "none",
                    margin: 0, // Remove default margin for better alignment
                  }}
                >
                  {order?.DP_NAME}
                </h2>
                {Remaining_Amount > 0 && (
                  <div className="button-container">
                    <button
                      className="btn btn-primary"
                      style={{
                        backgroundColor: "#A52444",
                        color: "white",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      onClick={handleProceedRemainingPayment}
                    >
                      Proceed To Payment
                    </button>
                  </div>
                )}
              </div>

              {/* <h4>
                {" "}
                <span>
                  {" "}
                  {formatToIndianCurrency(
                    order?.DPB_PRICE - order?.DiscountAmount
                  )}
                </span>
                <span
                  style={{
                    textDecoration: "line-through",
                    color: "#777",
                    marginLeft: "10px",
                  }}
                >
                  {order?.DPB_PRICE}
                </span>
                <span
                  style={{
                    color: "black",
                    marginLeft: "10px",
                    fontSize: "18px",
                  }}
                >
                  ({Math.round((order?.DiscountAmount / order?.DPB_PRICE) * 100)}%
                  OFF)
                </span>
              </h4> */}
              {/* <h5>
                <span>
                  <s>Rs {order?.PB_PRICE}</s>
                </span>
              </h5> */}

              <div className="mb-3 ">
                {/* <h5>Puja Details:</h5> */}
                <p
                  dangerouslySetInnerHTML={{
                    __html: order?.DP_DESC,
                  }}
                />
              </div>

              <div className="mb-3">
                <h5>Samagri:</h5>
                {order?.DP_SAMAGRI ? (
                  <div
                    dangerouslySetInnerHTML={createMarkup(order?.DP_SAMAGRI)}
                  />
                ) : (
                  <p>No samagri details available.</p>
                )}
              </div>

              <h5>
                Samagri Price: Rs{" "}
                {formatToIndianCurrency(order?.DPB_SAMAGRIPRICE)}
              </h5>

              {/* <div className="button-container">
              <button
                className="btn btn-primary"
                style={{ backgroundColor: "#A52444", color: "white" }}
                onClick={handleOpenProductBookingModal}
              >
                Proceed to Book
              </button>
            </div> */}
            </div>
          </div>
        </div>
        <div className="container my-4">
          <div className="row g-4">
            <div className="col-md-4">
              <div className="card shadow-sm">
                <div className="card-body">
                  <h5 className="card-title text-center fw-bold">
                    Puja Details
                  </h5>
                  <table className="table table-bordered table-striped">
                    <tbody>
                      <tr>
                        <td>Puja Name</td>
                        <td style={{ fontWeight: "bold" }}>
                          {order?.DP_NAME}
                        </td>
                      </tr>
                      <tr>
                        <td>Booking Date</td>
                        <td>{order?.REG_DATE}</td>
                      </tr>
                      <tr>
                        <td>Language</td>
                        <td>{order?.DPB_LANGAUGE}</td>
                      </tr>
                      <tr>
                        <td>Puja Date</td>
                        <td>
                          {order?.DPB_FROMDATE} To {order?.DPB_TODATE}
                        </td>
                      </tr>
                      <tr>
                        <td>Time</td>
                        <td>
                          {`${formatTime(order?.DPB_FROMTIME)} To ${formatTime(
                            order?.DPB_TOTIME
                          )}`}
                        </td>
                      </tr>
                      <tr>
                        <td>Samagri</td>
                        <td>{order?.DP_SAMAGRI ? "Yes" : "No"}</td>
                      </tr>
                      <tr>
                        <td>Payment Mode</td>
                        <td>{order?.PAYMENT_TYPE}</td>
                      </tr>
                      <tr>
                        <td>Payment Status</td>
                        <td className="fw-bold">{order?.PAYMENT_STATUS === 'Complete' ? 'Completed' : order?.PAYMENT_STATUS}</td>
                      </tr>
                      <tr>
                        <td>Transaction Amount</td>
                        <td>{formatToIndianCurrency(Math.round(order?.TRANSACTION_AMT))}</td>
                      </tr>
                      <tr>
                        <td>Remaining Amount</td>
                        <td>
                          {formatToIndianCurrency(Math.round(order?.RemainingAmount)) || 0}
                          {/* {formatToIndianCurrency(
                            CalculateGrandTotal(
                              order.DPB_PRICE,
                              order.DPB_FROMDATE,
                              order.DPB_TODATE,
                              order.DPB_SAMAGRIPRICE,
                              order.NEED_SAMAGRI,
                              order.CONVENIENCE_FEE,
                              order.DiscountAmount
                            ) - order.TRANSACTION_AMT
                          ) || 0} */}
                          {/* {formatToIndianCurrency(
                          Number(order.DPB_PRICE) +
                            Number(
                              needSamagri === "yes" ? order.DPB_SAMAGRIPRICE : 0
                            ) +
                            Number(order.CONVENIENCE_FEE) +
                            Tax - //Added Tax
                            order.TRANSACTION_AMT
                        ) || 0} */}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card shadow-sm">
                <div className="card-body">
                  <h5 className="card-title text-center fw-bold">
                    Pandit Details
                  </h5>
                  <table className="table table-bordered table-striped">
                    <tbody>
                      <tr>
                        <td>Pandit Name</td>
                        <td style={{ fontWeight: "bold" }}>
                          {order?.PANDIT_NAME}
                        </td>
                      </tr>
                      <tr>
                        <td>Pandit Contact</td>
                        <td>{order?.MOBILE_NO}</td>
                      </tr>
                      <tr>
                        <td>Pandit Status</td>
                        <td className="fw-bold">{order?.PANDIT_STATUS === 'Complete' ? 'Completed' : order?.PANDIT_STATUS}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="card shadow-sm">
                <div className="card-body">
                  <h5 className="card-title text-center fw-bold">
                    Price Details
                  </h5>
                  <table className="table table-bordered table-striped">
                    <tbody>
                      <tr>
                        <td>Brahmin Dakshina (₹)</td>
                        <td>
                          {formatToIndianCurrency(order?.DPB_PRICE)}
                          {/* {formatToIndianCurrency(
                            order?.DPB_PRICE *
                              numberOfDays(order?.DPB_FROMDATE, order?.DPB_TODATE)
                          )} */}
                        </td>
                      </tr>
                      {Number(order?.DP_DISC) !== 0 && (
                        <tr>
                          <td>Discount ({order?.DP_DISC}%)</td>
                          <td>
                            - {formatToIndianCurrency(order?.DiscountAmount)}
                          </td>
                        </tr>
                      )}

                      <tr>
                        <td>Samagri Price (₹)</td>
                        <td>

                          {needSamagri === "yes"
                            ? formatToIndianCurrency(
                              order?.DPB_SAMAGRIPRICE
                              // order.DPB_SAMAGRIPRICE *
                              //   numberOfDays(
                              //     order.DPB_FROMDATE,
                              //     order.DPB_TODATE
                              //   )
                            )
                            : 0}
                        </td>
                      </tr>
                      <tr>
                        <td>Samagri Tax ({order?.SamagriPriceTaxPercent}%)</td>
                        <td>
                          {formatToIndianCurrency(
                            order?.SamagriPriceTaxAmount
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>Convenience Fee (₹)</td>
                        <td>
                          {formatToIndianCurrency(order?.CONVENIENCE_FEE)}
                        </td>
                      </tr>
                      <tr>
                        <td>Convenience Tax ({Math.round(order?.ConvinienceFeeTaxPercent)}%)</td>
                        <td>
                          {formatToIndianCurrency(Math.round(order?.ConvinienceFeeTaxAmount))}
                        </td>
                      </tr>


                      <tr>
                        <td>Grand Total (₹)</td>
                        <td>

                          {formatToIndianCurrency(
                            Math.round(order?.DPB_GrandTotal)


                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              {order?.PAYMENT_STATUS === 'Complete' && order?.PANDIT_STATUS === 'Complete' ? (
                <button
                  className="careox-btn mt-2"
                  onClick={() =>
                    navigate("/invoice", {
                      state: {
                        moduleName: "dailyBooking",
                        DPB_ID: order?.DPB_ID,
                      },
                    })
                  }
                >
                  View Invoice
                </button>
              ) : ''}
              <button
                className="careox-btn mt-2 mx-2"
                onClick={() => {

                  if (order?.PAYMENT_TYPE == 'COD') {
                    navigate("/receipt-details", {
                      state: { moduleName: "DailyPujaBooking", orderID: order?.DPB_ID },
                    })
                  } else {

                    navigate("/view-receipt", {
                      state: { moduleName: "DailyPujaBooking", orderID: order?.DPB_ID },
                    })
                  }
                }
                }

              >
                View Receipt
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DBOrderDetails;
