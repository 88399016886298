import React, { useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import PanditBookingImg from "../../assets/images/myOrder/panditbooking.png";
import eStoreImg from "../../assets/images/myOrder/estore.png";
import worship from "../../assets/images/new/worship.png";
import DailyPandit from "../../assets/images/myOrder/dailypandit.png";
import darshanImg from "../../assets/images/myOrder/temple.png";
import worshipImg from "../../assets/images/myOrder/worship.png";
import HoroscopeProfile from "./HoroscopeProfile/HoroscopeProfile";
import { GetUserModel } from "../../ApiServices/Profile/ProfileApi";
import toast from "react-hot-toast";
import LoginPopUp from "../LoginPopup";

const HoroscopeListCards = () => {
  const navigate = useNavigate();
  const [showProfile, setProfile] = useState(false);

  const [showOpenLoginModal, setShowOpenLoginModal] = useState(false);
  const ItemAddedToast = () => toast("Please Update Your Profile");
  const services = [
    {
      title: "Astrology",
      image: "assets/images/new/services1 (1).jpg",
      icon: PanditBookingImg,
      text: "Book Astrology for your spiritual rituals and ceremonies.",
      color: "#ffa415",
      route: "/astrology-list",
    },
    {
      title: "Panchang",
      image: "assets/images/new/services1 (2).jpg",
      icon: worship,
      text: "Get a Panchang for your up and down ",
      color: "#fc5528",
      route: "/daily-panchang",
    },
    {
      title: "Numerology",
      image: "assets/images/new/services1 (3).jpg",
      icon: DailyPandit,
      text: "Numerology for better life",
      color: "#8139e7",
      route: "/numerology",
    },
    {
      title: "Prediction",
      image: "assets/images/new/services1 (2).jpg",
      icon: darshanImg,
      text: "Know about Prediction",
      color: "#399be7",
      route: "/Prediction ",
    },
    {
      title: "Horoscope Profile",
      image: "assets/images/new/services1 (3).jpg",
      icon: worshipImg,
      text: "Update horoscope profile",
      color: "#44c895",
      route: "/my-profile",
    },
  ];

  const GetUserModelData = async (value) => {
    // setLoader(true);

    const userID = JSON.parse(localStorage.getItem("userID"));
    if (userID === null || userID === undefined) {
      // navigate("/");
      setShowOpenLoginModal(true)
    }
    try {
      const response = await GetUserModel(userID);
      if (response?.ResponseCode == 0) {
        // setLoader(false);
        const ModelData = response.DATA || []; // Assuming the data is an array and we need the first item
        if (
          ModelData[0]?.DOB === null ||
          ModelData[0]?.DOB === undefined ||
          ModelData[0]?.DOB === ""
        ) {
          ItemAddedToast();
          navigate("/my-profile");
        } else {
          navigate(value?.route);
        }
      } else {
        // setLoader(false);

        console.error(
          "Error fetching data: ",
          response?.data?.data?.statusCode
        );
      }
    } catch (error) {
      // setLoader(false);

      console.error("Error", error);
    }
  };

  const handleClick = (value) => {
    GetUserModelData(value);
  };

  const handleOnConfirm = () => {

  }
  return (
    <>
      <section className="">
        <div className="service-two__shape"></div>
        <div
          className="container"
          style={{ display: "flex", flexDirection: "column" }}
        >
          <div className="sec-title text-center">
            <h5 className="sec-title__title bw-split-in-left">Horoscope</h5>
          </div>
          <div className="row gutter-y-30">
            {services.map((service, index) => (
              <div
                key={index}
                style={{ cursor: "pointer" }}
                className="col-lg-4 col-md-6 wow fadeInUp "
                data-wow-delay={`${index * 100}ms`}
                onClick={() => handleClick(service)}
              >
                <div
                  className="service-two__item text-center"
                  style={{ "--accent-color": service.color }}
                >
                  <div
                    className="service-two__item__shape"
                    style={{
                      backgroundImage: "url(assets/images/new/leftside.png)",
                    }}
                  ></div>
                  <div className="service-two__item__image">
                    <img src={service.image} alt="MyOm Namo" />
                  </div>
                  <div className="service-two__item__icon">
                    <img src={service.icon} alt="" className="service_i" />
                  </div>
                  <h3 className="service-two__item__title">{service.title}</h3>
                  <p className="service-two__item__text">{service.text}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <LoginPopUp show={showOpenLoginModal} onHide={(() => setShowOpenLoginModal(false))} onConfirm={handleOnConfirm} />
    </>
  );
};

export default HoroscopeListCards;
