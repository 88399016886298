import React, { useContext, useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
  Outlet,
} from "react-router-dom";

import AuthContext, {
  AuthContextProvider,
  LoaderProvider,
} from "./AuthContext/AuthContext";
import Login from "./Pages/Auth/Login/Login";
import Home from "./Pages/Home/Home";
import LoginOTP from "./Pages/Auth/LoginOTP/LoginOTP";
import Register from "./Pages/Auth/Register/Register";
import RegisterOTP from "./Pages/Auth/RegisterOTP/RegisterOTP";
import AboutUsPage from "./Pages/AboutUsPage/AboutUsPage";
import ContactUsPage from "./Pages/ContactUsPage/ContactUsPage";
import Blogs from "./Pages/BlogsPage/Blogs";
import Navbar from "./Components/Navbar/Navbar";
import Footer from "./Components/FooterSection/FooterSection";
import MyStore from "./Components/My Store/MyStore";
import Cart from "./Components/My Store/Cart";
import ViewCart from "./Components/My Store/ViewCart";
import Checkout from "./Components/My Store/Checkout";
import ThankYou from "./Components/Thank You/ThankYou";
import BookPandit from "./Pages/BookPandit/BookPandit";
import PoojsDetails from "./Components/PoojaDetails/PujaDetails";
import PanditList from "./Components/PanditList/PanditList";
import MyOrder from "./Components/My Store/MyOrder";
import OrderDetails from "./Components/My Store/OrderDetails";
import ScrollToTop from "./Components/ScrollToTop";
import ServiceList from "./Components/Service Order/ServiceList";
import DarshanBookingList from "./Components/Darshan Booking/DarshanBookingList";
import MyProfile from "./Components/My Profile/MyProfile";
import HoroscopeListCards from "./Components/Horoscope/HoroscopeListCards";
import TempleBookingForm from "./Components/Darshan Booking/TempleBookingForm";
import AstrologyList from "./Components/Horoscope/Astrology/AstrologyList";
import StepperForm from "./Pages/Stepper Form/StepperForm";
import PanditBooking from "./Pages/PanditBooking/PanditBooking";
import MyPanditBookingOrders from "./Pages/PanditBooking/MyPanditBookingOrders";
import PBOrderDetails from "./Pages/PanditBooking/PBOrderDetails";
import DailyBooking from "./Pages/DailyBooking/DailyBooking";
import DailyBookingDetails from "./Pages/DailyBooking/DailyBookingDetails";
import DailyPujaBooking from "./Pages/DailyBooking/DailyPujaBooking";
import DailyBookingOrders from "./Pages/DailyBooking/DailyBookingOrders";
import DBOrderDetails from "./Pages/DailyBooking/DailyBookingOrderDetails";
import TempleDetails from "./Components/Darshan Booking/TempleDetails";
import PoojaAtTempleList from "./Components/Puja At Temple/PoojaAtTemple";
import PujaAtTempleBookingForm from "./Components/Puja At Temple/PujaAtTempleBookingForm";
import PoojaAtTempleDetails from "./Components/Puja At Temple/PoojaAtTempleDetails";
import DarshanBookingOrderList from "./Components/Darshan Booking/DarshanBookingOrderList";
import DarshanBookingOrderDetails from "./Components/Darshan Booking/DarshanBookingOrderDetails";
import PujaAtTempleBookingOrderList from "./Components/Puja At Temple/PujaAtTempleBookingOrderList";
import PujaAtTempleOrderDetails from "./Components/Puja At Temple/PujaAtTempleOrderDetails";
import ServicesLandingPage from "./Pages/ServicesLandingPage/ServicesLandingPage";
import BasicDetails from "./Components/Horoscope/Astrology/BasicDetails";
import DailyPrediction from "./Components/Horoscope/DaliyPrediction/DailyPrediction";
import DailyPanchang from "./Components/Horoscope/DailyPanchang/DailyPanchang";
import Numerology from "./Components/Horoscope/Numerology/Numerology";
import Prediction from "./Components/Horoscope/Prediction/Prediction";
import BlogPost from "./Pages/BlogsPage/BlogPost";
import Invoice from "./Components/Invoice";
import LoginPopUp from "./Components/LoginPopup";
import FAQPage from "./Pages/FAQs/FAQsPage";
import BecomePandit from "./Pages/BecomePandit/BecomePandit";
import TempleDarshanInvoice from "./Components/Darshan Booking/TempleDarshanInvoice";
import PujaAtTempleInvoice from "./Components/Puja At Temple/PujaAtTempleInvoice";
import CommonBlogPage from "./Pages/BlogsPage/CommonBlogPage";
import ReceiptDetailsPage from "./Components/ReceiptDetailsPage";
import ViewReceipt from "./Components/ViewReceipt";
import EStoreInvoice from "./Components/E-StoreInvoice";
import { HelmetProvider } from "react-helmet-async";
import PrivacyPolicyPage from "../src/Components/PrivacyPolicy/PrivacyPolicyPage";
import TermsAndConditionPage from "../src/Components/TermsAndConditon/TermsAndConditionPage";

const App = () => {
  const { setLoader, setIsAuthenticated, isAuthenticated } =
    useContext(AuthContextProvider);
  const updateAuthStatus = () => {
    setIsAuthenticated(!!localStorage.getItem("userID"));
    // setIsAuthenticated(!!localStorage.getItem("username"));
  };

  useEffect(() => {
    updateAuthStatus(); // Sync state with localStorage on load
    window.addEventListener("storage", updateAuthStatus); // Sync across tabs/windows
    return () => window.removeEventListener("storage", updateAuthStatus);
  }, []);

  const Layout = () => {
    return (
      <>
        <Navbar />
        <main>
          <Outlet /> {/* This will render the current page */}
        </main>
        <Footer />
      </>
    );
  };

  const AuthHandler = () => {
    const { setIsAuthenticated } = useContext(AuthContextProvider);
    const location = useLocation();

    useEffect(() => {
      if (location.pathname === "/logout") {
        localStorage.clear();
        setIsAuthenticated(false);
      }
    }, [location.pathname]);

    return null; // This component doesn't render anything, it just handles auth logic
  };

  console.log("isAuthenticated", isAuthenticated);

  const ProtectedRoute = ({ element }) => {
    // const { isAuthenticated } = useContext(AuthContext);
    const location = useLocation();
    const [showLogin, setShowLogin] = useState(false);

    useEffect(() => {
      if (!isAuthenticated) {
        setShowLogin(true); // Show login popup when user is not authenticated
      }
    }, [isAuthenticated]); // Runs when authentication status changes

    if (!isAuthenticated) {
      return <LoginPopUp show={showLogin} onHide={() => setShowLogin(false)} />;
    }

    return element;
  };

  return (
    <HelmetProvider>
      <Router>
        <ScrollToTop />
        <AuthHandler />
        <LoaderProvider>
          <Routes>
            <Route element={<Layout />}>
              {/* <Route path="/login" element={<LoginPopUp />} /> */}
              <Route path="/register" element={<Register />} />
              <Route path="/registerotp" element={<RegisterOTP />} />
              {/* <Route path="/login" element={<Login />} /> */}
              {/* <Route path="/loginotp" element={<LoginOTP />} /> */}
              <Route path="/checkout" element={<Checkout />} />
              <Route path="/*" element={<Login />} />
              <Route path="/my-store" element={<MyStore />} />
              <Route path="/my-store/:id" element={<MyStore />} />
              <Route path="/cart" element={<Cart />} />
              <Route path="/" element={<Home />} />
              <Route path="/:id" element={<Home />} />
              <Route path="/darshan-booking" element={<DarshanBookingList />} />
              <Route path="/temple/:id" element={<TempleDetails />} />
              <Route path="/horoscope" element={<HoroscopeListCards />} />
              {/* <Route path="/spiritual-gyan" element={<Blogs />} /> */}
              <Route path="/blog-post" element={<BlogPost />} />
              <Route path="/contact-us" element={<ContactUsPage />} />
              <Route path="/about-us" element={<AboutUsPage />} />
              <Route path="/faq" element={<FAQPage />} />
              <Route path="/astrology-list" element={<AstrologyList />} />
              <Route path="/stepper-form" element={<StepperForm />} />
              <Route path="/services/book-puja" element={<BookPandit />} />
              <Route
                path="/services/book-puja/puja-details"
                element={<PoojsDetails />}
              />
              <Route
                path="/services/daily-booking"
                element={<DailyBooking />}
              />
              <Route
                path="/services/daily-booking/puja-details"
                element={<DailyBookingDetails />}
              />
              <Route
                path="/services/:servicename"
                element={<ServicesLandingPage />}
              />
              <Route path="/basic-details" element={<BasicDetails />} />
              <Route path="/daily-prediction" element={<DailyPrediction />} />
              {/* {isAuthenticated && ( */}
              <>
                <Route path="/" element={<Home />} />
                <Route path="/become-pandit" element={<BecomePandit />} />
                <Route path="/:id" element={<Home />} />
                <Route path="/about-us" element={<AboutUsPage />} />
                <Route path="/contact-us" element={<ContactUsPage />} />
                <Route
                  path="/services/book-puja/puja-details"
                  element={<PoojsDetails />}
                />
                <Route
                  path="/services/daily-booking"
                  element={<DailyBooking />}
                />
                <Route
                  path="/services/daily-booking/puja-details"
                  element={<DailyBookingDetails />}
                />
                <Route
                  path="/Terms-And-Condition"
                  element={<TermsAndConditionPage />}
                />
                <Route path="/Privacy-policy" element={<PrivacyPolicyPage />} />
                <Route
                  path="/services/daily-puja/add-service"
                  element={<ProtectedRoute element={<DailyPujaBooking />} />}
                />
                <Route
                  path="/services/book-puja/add-service"
                  element={<ProtectedRoute element={<PanditBooking />} />}
                  // element={<PanditBooking />}
                />
                <Route
                  path="/temple-darshan-invoice"
                  element={
                    <ProtectedRoute element={<TempleDarshanInvoice />} />
                  }
                  // element={<PanditBooking />}
                />
                <Route
                  path="/puja-at-temple-invoice"
                  element={<ProtectedRoute element={<PujaAtTempleInvoice />} />}
                  // element={<PanditBooking />}
                />
                <Route
                  path="/my-order-pandit-Booking"
                  // element={<MyPanditBookingOrders />}
                  element={
                    <ProtectedRoute element={<MyPanditBookingOrders />} />
                  }
                />
                <Route
                  path="/my-order-daily-Booking"
                  // element={<DailyBookingOrders />}
                  element={<ProtectedRoute element={<DailyBookingOrders />} />}
                />
                <Route
                  path="/view-cart"
                  // element={<ViewCart />}
                  element={<ProtectedRoute element={<ViewCart />} />}
                />
                <Route path="/thank-you" element={<ThankYou />} />
                <Route path="/services/book-puja" element={<BookPandit />} />
                {/* <Route path="/register" element={<Register />} /> */}
                <Route
                  path="/my-order"
                  element={<ProtectedRoute element={<MyOrder />} />}
                />
                <Route path="/order-details" element={<OrderDetails />} />
                <Route path="/" element={<Home />} />
                <Route path="/:id" element={<Home />} />
                <Route path="/about-us" element={<AboutUsPage />} />
                <Route path="/contact-us" element={<ContactUsPage />} />
                <Route path="/spiritual-gyan/:slug" element={<Blogs />} />
                <Route path="/spiritual-gyan" element={<CommonBlogPage />} />
                <Route path="/blog-post" element={<BlogPost />} />
                <Route path="/my-store" element={<MyStore />} />
                <Route path="/my-store/:id" element={<MyStore />} />
                <Route path="/temple/:id" element={<TempleDetails />} />
                <Route path="/cart" element={<Cart />} />
                <Route path="/checkout" element={<Checkout />} />
                <Route path="/invoice" element={<Invoice />} />
                <Route
                  path="/services/book-puja/puja-details"
                  element={<PoojsDetails />}
                />
                <Route
                  path="/services/book-puja/puja-details/pandit-list"
                  element={<PanditList />}
                />
                <Route path="/view-cart" element={<ViewCart />} />
                {/* <Route path="/login" element={<Login />} />
              <Route path="/loginotp" element={<LoginOTP />} /> */}
                <Route path="/thank-you" element={<ThankYou />} />
                <Route path="/services/book-puja" element={<BookPandit />} />
                <Route path="/register" element={<Register />} />
                <Route path="/my-order" element={<MyOrder />} />
                <Route
                  path="/pandit-Booking-order-details"
                  element={<PBOrderDetails />}
                />
                <Route
                  path="/daily-Booking-order-details"
                  element={<DBOrderDetails />}
                />
                <Route path="/order-details" element={<OrderDetails />} />
                <Route path="/my-profile" element={<MyProfile />} />
                <Route path="/my-order-list" element={<ServiceList />} />
                <Route
                  path="/temple-booking-form"
                  element={<TempleBookingForm />}
                />
                <Route path="/astrology-list" element={<AstrologyList />} />
                <Route path="/stepper-form" element={<StepperForm />} />
                <Route
                  path="/pooja-at-temple"
                  element={<PoojaAtTempleList />}
                />
                <Route
                  path="/pooja-at-temple-booking"
                  element={<PujaAtTempleBookingForm />}
                />
                <Route
                  path="/pooja-at-temple/:id"
                  element={<PoojaAtTempleDetails />}
                />
                <Route
                  path="/my-order-darshan-Booking"
                  element={<DarshanBookingOrderList />}
                />
                <Route path="/e-store-invoice" element={<EStoreInvoice />} />
                <Route
                  path="/darshan-order-details"
                  element={<DarshanBookingOrderDetails />}
                />
                <Route
                  path="/my-order-puja-At-Temple"
                  element={<PujaAtTempleBookingOrderList />}
                />
                <Route
                  path="/order-details-puja-At-Temple"
                  element={<PujaAtTempleOrderDetails />}
                />
                <Route
                  path="/services/:servicename"
                  element={<ServicesLandingPage />}
                />
                <Route path="/basic-details" element={<BasicDetails />} />
                <Route path="/daily-prediction" element={<DailyPrediction />} />
                <Route path="/daily-panchang" element={<DailyPanchang />} />
                <Route path="/numerology" element={<Numerology />} />
                <Route path="/prediction" element={<Prediction />} />
                <Route
                  path="/receipt-details"
                  element={<ReceiptDetailsPage />}
                />
                <Route path="/view-receipt" element={<ViewReceipt />} />
              </>
              {/* )} */}
            </Route>
          </Routes>
        </LoaderProvider>
      </Router>
    </HelmetProvider>
  );
};

export default App;
