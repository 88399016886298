import { BaseUrl } from "../../BaseUrl/BaseUrl";
import {
  getApiWithAuthorization,
  postApiWithAuthorization,
} from "../ApiMethod/ApiMethod";

export const DailyPanditBookingAPI = async (userID, params) => {
  const url = `${BaseUrl}/DailyPujaGet?TYPE=All&USER_ID=${userID}`;
  const res = await postApiWithAuthorization(url, params);

  return res;
};

export const LanguageList = async () => {
  // const url = `${BaseUrl}/GetPoojaList?TYPE=All&USER_ID=${user_id}`;
  const url = `${BaseUrl}/LanguageList`;
  const res = await getApiWithAuthorization(url);

  return res;
};

export const GetDailyBookingInvoiceAPI = async (user_id, dpb_id) => {
  // const url = `${BaseUrl}/GetPoojaList?TYPE=All&USER_ID=${user_id}`;
  const url = `${BaseUrl}/DailyPanditBookingInvoice?USER_ID=${user_id}&DPB_ID=${dpb_id}`;
  const res = await getApiWithAuthorization(url);

  return res;
};
