export const countrySelect = [
  { value: 1, label: "+91" },
  { value: 2, label: "+1" },
];

export const SelectLanguageOptions = [
  { value: 1, label: "Bodo" },
  { value: 2, label: "Dogri" },
  { value: 3, label: "English" },
  { value: 4, label: "Gujarati" },
  { value: 5, label: "Kannada" },
  { value: 6, label: "Hindi" },
  { value: 7, label: "Kashmiri" },
  { value: 8, label: "Konkani" },
  { value: 9, label: "Maithili" },
  { value: 10, label: "Malayalam" },
  { value: 11, label: "Meitei" },
  { value: 12, label: "Marathi" },
  { value: 13, label: "Nepali" },
  { value: 14, label: "Odia" },
  { value: 15, label: "Punjabi" },
  { value: 16, label: "Sanskrit" },
  { value: 17, label: "Sindhi" },
  { value: 18, label: "Tamil" },
  { value: 19, label: "Telugu" },
  { value: 20, label: "Urdu" },
];

export const SelectIdProof = [
  { value: "Adhar Card", label: "Adhar Card" },
  { value: "Pan Card", label: "Pan Card" },
];
export const DurationLookupList = [
  { value: 1, label: "Week" },
  { value: 2, label: "Month" },
];
export const WeekDays = [
  { value: 1, label: 1 },
  { value: 2, label: 2 },
  { value: 3, label: 3 },
  { value: 4, label: 4 },

];
export const MonthDays = [
  { value: 1, label: 1 },
  { value: 2, label: 2 },
  { value: 3, label: 3 },
  { value: 5, label: 5 },
  { value: 6, label: 6 },
  { value: 7, label: 7 },
  { value: 8, label: 8 },
  { value: 9, label: 9 },
  { value: 10, label: 10 },
  { value: 11, label: 11 },
  { value: 12, label: 12 },
  { value: 13, label: 13 },
  { value: 14, label: 14 },
  { value: 15, label: 15 },
  { value: 16, label: 16 },
  { value: 17, label: 17 },
  { value: 18, label: 18 },
  { value: 19, label: 19 },
  { value: 20, label: 20 },
  { value: 21, label: 21 },
  { value: 22, label: 22 },
  { value: 23, label: 23 },
  { value: 24, label: 24 },

];