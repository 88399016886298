import axios from "axios";
import React, { useEffect, useState } from "react";
import { BaseUrl } from "../../BaseUrl/BaseUrl";
import QRCode from "react-qr-code";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Mylogo from "../../assets/images/logo.png";
import dayjs from "dayjs";
const DarshanBookingOrderDetails = () => {
  const userID = JSON.parse(localStorage.getItem("userID"));
  const [localData, setLocalData] = useState(null);
  const [orders, setOrders] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  console.log("location?.state", location?.state);

  const handleProceedRemainingPayment = async () => {
    if (location.state.TRB_TOTALPRICE - location.state.TRANSACTION_AMT === 0) {
      alert("All the amount already paid");
      return;
    }
    // let totalAmount = parseInt(modelRequestData.totalVal); // Assuming totalVal includes shipping
    // let minPayableAmount = Remaining_Amount; // 20% of the total amount

    let orderIDReturned = location.state.TRB_ID;
    const finalAmount = Number(location.state.TRB_TOTALPRICE);
    const remainingAmount = location?.state?.RemainingAmount;
    const options = {
      key: "rzp_test_vv1FCZvuDRF6lQ",
      key_secret: "P4JAUwn4VdE6xDLJ6p2Zy8RQ",
      amount: remainingAmount * 100,
      currency: "INR",
      name: "My Om Namo- WowInfotech",
      description: "working",
      image: Mylogo,
      handler: function (response) {
        // console.log(response, "Payment Success");
        const paymentId = response.razorpay_payment_id;

        if (response) {
          axios
            .post(`${BaseUrl}/UpdatePaymentStatus?TYPE=Darshan`, {
              USER_ID: userID,
              ORDER_ID: orderIDReturned,
              // ORDER_ID: JSON.parse(localStorage.getItem("orderID")),
              TRANSACTION_ID: paymentId,
              PAYMENT_STATUS: "Success",
              TRANSACTION_STATUS: "Success",
              TRANSACTION_AMT: remainingAmount,
              REMAING_AMOUNT: 0,
              PAYMENT_DATE: dayjs().format("YYYY-MM-DD"),
            })
            .then((res) => {
              if (res.data.ResponseMessage === "successfully.") {
                // localStorage.setItem("cartItemCount", "0");
                // window.dispatchEvent(new Event("cartUpdated"));
                // setShowCODModal(false);
                // nextStep();
                navigate("/my-order-darshan-Booking");
              } else {
                alert("Order placement failed, please try again.");
              }
            });
        }

        // const updatedPayload = {
        //   ...razorPayData,
        //   TRANSACTION_ID: paymentId,
        //   TRANSACTION_AMT: minPayableAmount,
        // };

        // axios.post(`${BaseUrl}/BookPooja`, updatedPayload).then((res) => {
        //   debugger;
        //   if (res.data.ResponseMessage === "Order Success.") {
        //     localStorage.setItem("cartItemCount", "0");
        //     window.dispatchEvent(new Event("cartUpdated"));
        //     navigate("/thank-you");
        //   } else {
        //     alert("Order placement failed, please try again.");
        //   }
        // });
      },
      theme: {
        color: "#ff416c",
      },
    };

    const rzp = new window.Razorpay(options);
    rzp.open();
  };

  const myomnamoQRLink = location?.state?.TRB_ID;

  return (
    <>
      <div className="m-5 p-5 my-4">
        <div className="row">
          {/* Left Section: Multiple Order Items */}
          <div className="col-md-8">
            <div className="card p-3 mb-3 shadow-sm">
              <div className="d-flex align-items-center">
                {/* Product Image on the Left */}
                <img
                  src={location?.state?.TR_IMAGE}
                  alt="Product"
                  className="rounded"
                  style={{
                    width: "180px",
                    height: "150px",
                    objectFit: "contain",
                  }}
                />

                {/* Product Details in the Center */}
                <div className="flex-grow-1 px-3">
                  <h6 className="fw-bold">{location?.state?.TR_NAME}</h6>
                  <p className="text-muted mb-1">
                    Temple Address : {location?.state?.TR_ADDRESS}
                  </p>
                  <p className="text-muted mb-1">
                    Booking Date : {location?.state?.TRB_DATE}
                  </p>
                  <p className="text-muted mb-1">
                    Booking Time : {location?.state?.TTS_NAME}
                  </p>
                  <p className="text-muted mb-1">
                    Number Of Seats : {location?.state?.NO_SEATS}
                  </p>
                </div>

                {/* Price on the Right */}
                <div className="text-end">
                  <h5 className="fw-bold  mb-0">
                    ₹{location?.state?.TRB_TOTALPRICE}
                  </h5>
                </div>
              </div>

              {/* Order Status */}
              <hr />
            </div>

            {/* <div className="d-flex align-items-center">
              <p className="mb-0">
                Order Confirmed, {location?.state?.REG_DATE}
              </p>
            </div> */}

            {/* Cancellation Message */}
            {/* <p className="text-muted mt-2">
              Your order was cancelled as the courier could not deliver it even
              after multiple attempts.
            </p> */}
            <div>
              <p style={{ fontSize: "17px", fontWeight: "400" }}>
                When you are at temple, please scan below QR code to complete
                your darshan.
              </p>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "30px",
                }}
              >
                <QRCode
                  size={150}
                  style={{ height: "auto", maxWidth: "30%", width: "30%" }}
                  value={myomnamoQRLink}
                  viewBox={`0 0 150 150`}
                />
                {/* <QRCodeCanvas
                  value={`${myomnamoQRLink}`}
                  size={150}
                  bgColor="#ffffff"
                  fgColor="#000000"
                  level="H"
                /> */}
              </div>
            </div>
          </div>

          {/* Right Section: Single Product's Shipping & Price Details */}
          <div className="col-md-4">
            {/* Shipping Details */}
            {/* <div className="card p-3 mb-3 shadow-sm">
              <h6 className="fw-bold">Other details</h6>

              <p className="text-muted small">
                {location?.state?.PAT_ADDRESS}
              </p>

              <p className="fw-bold mb-0">Phone number: {location?.state?.MOBILE_NO}</p>
            </div> */}

            {/* Price Details (Using First Product) */}

            <div className="card p-3 shadow-sm">
              <h6 className="fw-bold">Price Details</h6>
              <div className="d-flex justify-content-between">
                <p className=" fw-bold">Price per person </p>
                <p className="fw-bold">{location?.state?.TRB_PRICE}</p>
              </div>
              <div className="d-flex justify-content-between">
                <p className=" fw-bold">Total Seats</p>
                <p className="fw-bold">{location?.state?.NO_SEATS}</p>
              </div>

              <div className="d-flex justify-content-between">
                <p className="fw-bold"> Convenience Fee </p>
                <p className=" fw-bold">
                  <span> {location?.state?.Convinience_Fee}</span>
                </p>
              </div>
              <hr></hr>
              <div className="d-flex justify-content-between">
                <p className="fw-bold">Total Amount</p>
                <p className=" fw-bold">
                  <span>
                    {" "}
                    {Number(location?.state?.TRB_PRICE) *
                      Number(location?.state?.NO_SEATS) +
                      Number(location?.state?.Convinience_Fee)}
                  </span>
                </p>
              </div>
              <div className="d-flex justify-content-between">
                <p className="fw-bold">Tax ({location?.state?.Tax_Perc}%)</p>
                <p className=" fw-bold">
                  <span>{location?.state?.Tax_Amount}</span>
                </p>
              </div>
              <div className="d-flex justify-content-between">
                <p className="fw-bold">Grand Total</p>
                <p className=" fw-bold">
                  <span>{Number(location?.state?.TRB_TOTALPRICE)}</span>
                </p>
              </div>
            </div>
            <button
              className="careox-btn mt-2"
              onClick={() => {
                if (location?.state?.PAYMENT_TYPE == "COD") {
                  navigate("/receipt-details", {
                    state: {
                      moduleName: "Darshan",
                      orderID: location?.state?.TRB_ID,
                    },
                  });
                } else {
                  navigate("/view-receipt", {
                    state: {
                      moduleName: "Darshan",
                      orderID: location?.state?.TRB_ID,
                    },
                  });
                }
              }}
            >
              View Receipt
            </button>
            {location?.state?.RemainingAmount == 0 ? (
              <button
                className="careox-btn mt-2 mx-2"
                onClick={() =>
                  navigate("/temple-darshan-invoice", {
                    state: {
                      Address: location?.state?.PATB_ADDRESS,
                      products: orders,
                      totalAmount: location?.state?.TOTAL_PRICE,
                      TRB_ID: location?.state?.TRB_ID,
                    },
                  })
                }
              >
                View Invoice
              </button>
            ) : (
              <button
                className="careox-btn mt-2 mx-2"
                onClick={handleProceedRemainingPayment}
              >
                Make Payment
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default DarshanBookingOrderDetails;
