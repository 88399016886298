import React, { useEffect, useState } from 'react'
import { FaRegCalendarAlt, FaRegClock, FaSun, FaMoon } from "react-icons/fa";
import { numero_table } from '../../../ApiServices/Horoscope/BirthDetailsApi';
import { GetUserModel } from '../../../ApiServices/Profile/ProfileApi';
const Numerology = () => {
    const [numeroLogyList, setNumeroLogyList] = useState([])

    useEffect(() => {
        GetUserModelData()
    }, [])
    const GetUserModelData = async () => {
        // setLoader(true);

        const userID = JSON.parse(localStorage.getItem("userID"));
        try {
            const response = await GetUserModel(userID);
            if (response?.ResponseCode == 0) {
                // setLoader(false);
                const ModelData = response.DATA || []; // Assuming the data is an array and we need the first item

                const dob = new Date(ModelData[0]?.DOB);

                const year = dob.getFullYear();
                const month = dob.getMonth() + 1; // Months are zero-based (0 = January)
                const day = dob.getDate();

                const [hours, minutes] = ModelData[0]?.BIRTH_TIME.split(":");
                const apiParam = {
                    "day": day,
                    "month": month,
                    "year": year,
                    'name': ModelData[0]?.FULL_NAME
                }

                await GetNumberDetails(apiParam)

            } else {
                // setLoader(false);

                console.error('Error fetching data: ', response?.data?.data?.statusCode);
            }
        } catch (error) {
            // setLoader(false);

            console.error('Error', error);
        }
    };
    const GetNumberDetails = async (apiParam) => {
        try {
            const response = await numero_table(apiParam);
            if (response !== null && response !== undefined) {
                // setLoader(false);



                setNumeroLogyList((prev) => ({
                    ...prev,
                    name: response?.name,
                    date: response?.date,
                    destiny_number: response?.destiny_number,
                    radical_number: response?.radical_number,
                    name_number: response?.name_number,
                    evil_num: response?.evil_num,
                    fav_color: response?.fav_color,
                    fav_day: response?.fav_day,
                    fav_god: response?.fav_god,
                    fav_mantra: response?.fav_mantra,
                    fav_metal: response?.fav_metal,
                    fav_stone: response?.fav_stone,
                    fav_substone: response?.fav_substone,
                    friendly_num: response?.friendly_num,
                    neutral_num: response?.neutral_num,
                    radical_num: response?.fav_stone,
                    radical_ruler: response?.radical_ruler,


                }));
            } else {
                // setLoader(false);

                console.error('Error fetching data: ', response);
            }
        } catch (error) {
            // setLoader(false);

            console.error('Error', error);
        }
    };
    return (
        <>
            <div className="container mt-4">
                {/* Top Section: Two Cards Per Row & Panchang Table */}

                <div className="row">
                    <h3 className='text-center' >Numerology</h3>
                    {/* Left Side: Two Cards Per Row */}
                    <div className="col-md-7">
                        <div className="row">
                            {Object?.entries(numeroLogyList || {}).map(([key, value], index) => (
                                <div className="col-md-6 mb-3" key={index}>
                                    <div className="card shadow-sm p-3 text-center panchangCard">
                                        <h6 className="text-uppercase ">
                                            <span className='fw-bold'> {key}</span>
                                        </h6>
                                        <p className="mb-0">{value}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Numerology
