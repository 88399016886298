import { useEffect, useState } from "react";
// import "./PanditBooking.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import DatePicker from "react-date-picker";
import Select from "react-select";
import { ERROR_MESSAGES } from "../../Components/GlobalErrorMessage";
import { DurationLookupList, MonthDays, SelectLanguageOptions, WeekDays } from "../../middlewares/Utils";
import { GetPanditListDataAPI } from "../../ApiServices/PanditList/PanditList";
// import CODModal from "./CODModal";
import axios from "axios";
import { BaseUrl } from "../../BaseUrl/BaseUrl";
import Mylogo from "../../assets/images/logo.png";
import "../Stepper Form/stepper.css";
import CODModalDailyBooking from "./CODModalDailyBooking";
import placeholderImg from "../../assets/images/new/Batuk2-removebg-preview.png";
import { ServiceDiscounts } from "../../Components/enum";
import { LanguageList } from "../../ApiServices/DailyPanditAPI/DailyPanditApi";
import { CityLookupList, StateLookupList } from "../../ApiServices/Other/StateLookupListApi";
import { GetDurationDays } from "../../ApiServices/Other/DurationDaysApi";

const steps = [
  "Daily Booking Details",
  // "Select Pandit",
  "Booking Preview",
  "Payment Status",
];

const DailyPujaBooking = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [languageOption, setLanguageOption] = useState([]);
  const [durationList, setDurationList] = useState([]);
  const [daysList, setDaysList] = useState([]);
  const [findPandit, setFindPandit] = useState(null);
  const [totalDays, setTotalDays] = useState(0);
  const [showCODModal, setShowCODModal] = useState(null);
  const [selectedPandit, setSelectedPandit] = useState(null);
  const [orderID, setOrderID] = useState();
  const [panditSelectionError, setPanditSelectionError] = useState(null);
  const [error, setError] = useState(null);
  const [searchKeyword, setSearchKeyword] = useState(null);
  const [showBookingReviewModal, setShowBookingReviewModal] = useState(null);
  const [panditDataList, setPanditDataList] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();

  const [selectedDuration, setSelectedDuration] = useState(null);
  const [selectedDays, setSelectedDays] = useState(null);


  const [stateLookupList, setStateLookupList] = useState([])
  const [cityLookupList, setCityLookupList] = useState([])

  const [productBookingData, setProductBookingData] = useState({
    startDate: dayjs().format("YYYY-MM-DD"),
    endDate: dayjs().format("YYYY-MM-DD"),
    startTime: null,
    endTime: null,
    languageID: null,
    landmark: null,
    address: null,
    pincode: null,
    city: null,
    state: null,
    needSamagri: "yes",
  });
  const [modelRequestData, setModelRequestData] = useState({
    totalPrice: null,
    amountEntered: null,
    tax: null,
    convinienceFee: null,
  });

  const fromDate = dayjs();
  const userID = localStorage.getItem("userID");
  const puja = location.state?.puja;
  console.log("puja", puja);
  console.log("productBookingData", productBookingData);

  const SGST = 9;
  const CGST = 9;

  // const tax = Math.round(
  //   ((SGST + CGST) / 100) * parseFloat(puja.CONVENIENCE_FEE)
  // );

  // let totalDays = null;

  useEffect(() => {
    // debugger;
    const calculatedDays =
      1 +
      (new Date(productBookingData.endDate) -
        new Date(productBookingData.startDate)) /
      (1000 * 60 * 60 * 24);
    // setTotalDays(calculatedDays);
  }, [productBookingData.startDate, productBookingData.endDate]);

  const [razorPayData, setRazorPayData] = useState({
    PB_DATE: null,
    PB_TIME: null,
    PB_LANGAUGE: null,
    PB_SAMAGRI: null,
    PB_PRICE: null,
    PB_SAMAGRIPRICE: null,
    PANDIT_ID: null,
    USER_ID: null,
    REFERENCES_NO: "",
    ADDRESS: null,
    PUJA_ID: null,
    PAYMENT_TYPE: null,
    CONVINIENCE_FEE: null,
    TAX: null,
    EXTRA: null,
    TRANSACTION_ID: "",
    TRANSACTION_AMT: "",
  });

  // console.log("razorPayData", location.state);
  debugger
  const totalPrice =
    (puja?.DP_PRICE - puja?.DiscountAmount) +
    (productBookingData.needSamagri === "yes"
      ? parseFloat(puja?.DP_SAMAGRI_PRICE)
      : 0) + parseFloat(puja?.CONVENIENCE_FEE) + parseFloat(puja?.ConvinienceFeeTaxAmount) + parseFloat(puja?.SamagriPriceTaxAmount);

  const nextStep = () => {
    if (currentStep < steps.length - 1) {
      if (currentStep === 0) {
        setCurrentStep(1); // Move to "Booking Preview"
      } else {
        setCurrentStep(currentStep + 1);
      }
    }
  };

  const prevStep = () => {
    if (currentStep > 0) {
      if (currentStep === 2) {
        setCurrentStep(1); // Move back to "Booking Preview"
      } else {
        setCurrentStep(currentStep - 1);
      }
    }
  };

  const tax = Math.round(
    (ServiceDiscounts.DailyBookingDiscount / 100) * Number(totalPrice)
  );

  const grandTotal = parseFloat(totalPrice * totalDays || 0);


  useEffect(() => {
    GetPanditListData();
  }, [productBookingData?.languageID]);
  useEffect(() => {
    GetLanguageListLookupListData();
    GetDurationListLookupListData();
    GetStateLookupListData();
  }, []);

  useEffect(() => {

    if (productBookingData?.state !== null && productBookingData?.state !== undefined && productBookingData?.state !== '') {
      GetCityLookupListData(productBookingData?.state)
    }

  }, [productBookingData?.state])

  useEffect(() => {
    if (selectedDuration !== null && selectedDuration !== undefined && selectedDuration !== '') {
      console.log('selectedDuration', selectedDuration)
      GetDaysLookupListData()
    }
  }, [selectedDuration])

  useEffect(() => {
    let calculatedDays = 0;

    if (selectedDuration === 1) {
      calculatedDays = selectedDays * 7;
    } else if (selectedDuration === 2) {
      calculatedDays = selectedDays * 30;
    }

    if (calculatedDays > 0 && productBookingData?.startDate) {
      const startDate = new Date(productBookingData.startDate); // Ensure it's a Date object
      if (!isNaN(startDate)) { // Check if the conversion was successful
        const newEndDate = new Date(startDate);
        newEndDate.setDate(startDate.getDate() + calculatedDays);

        setProductBookingData((prev) => ({
          ...prev,
          endDate: newEndDate.toISOString().split("T")[0], // Format as YYYY-MM-DD if needed
        }));
      } else {
        console.error("Invalid startDate:", productBookingData.startDate);
      }
    }
  }, [selectedDays, selectedDuration, productBookingData?.startDate]);


  //   useEffect(() => {
  //     debugger;
  //     setRazorPayData((prev) => ({
  //       ...prev,
  //       PB_DATE: productBookingData.date,
  //       PB_TIME: productBookingData.time,
  //       PB_LANGAUGE: SelectLanguageOptions.find(
  //         (item) => item.value === productBookingData.languageID
  //       )?.label,
  //       PB_SAMAGRI: productBookingData.needSamagri,
  //       PB_PRICE: pujaDetails?.pujaPrice,
  //       PB_SAMAGRIPRICE: pujaDetails?.pujaSamagriPrice,
  //       PANDIT_ID: selectedPandit,
  //       USER_ID: userID,
  //       REFERENCES_NO: "",
  //       ADDRESS: productBookingData?.address,
  //       PUJA_ID: pujaDetails?.pujaId,
  //       PAYMENT_TYPE: "ONLINE",
  //       CONVINIENCE_FEE: pujaDetails?.convenienceFee,
  //       TAX: null,
  //       EXTRA: null,
  //       TRANSACTION_ID: "",
  //       TRANSACTION_AMT: "",
  //     }));
  //   }, []);

  //   const GetPanditListData = () => {};
  const GetPanditListData = async () => {
    // debugger;
    try {
      const response = await GetPanditListDataAPI(
        userID,
        puja.DP_ID,
        productBookingData?.languageID,
        {
          START: 1,
          END: 10,
          WORD: "None",
          LATITUDE: "20.0086678",
          LONGITUDE: "73.7639264",
        }
      );

      if (response) {
        setPanditDataList(response.DATA);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const HandleProductBookingBtnClicked = () => {
    // debugger;
    let isValid = false;

    if (
      productBookingData.startDate === null ||
      productBookingData.startDate === undefined ||
      productBookingData.startDate === "" ||
      productBookingData.endDate === null ||
      productBookingData.endDate === undefined ||
      productBookingData.endDate === "" ||
      productBookingData.startTime === null ||
      productBookingData.startTime === undefined ||
      productBookingData.startTime === "" ||
      productBookingData.endTime === null ||
      productBookingData.endTime === undefined ||
      productBookingData.endTime === "" ||
      productBookingData.landmark === null ||
      productBookingData.landmark === undefined ||
      productBookingData.landmark === "" ||
      productBookingData.languageID === null ||
      productBookingData.languageID === undefined ||
      productBookingData.languageID === "" ||
      productBookingData.address === null ||
      productBookingData.address === undefined ||
      productBookingData.address === "" ||
      productBookingData.state === null ||
      productBookingData.state === undefined ||
      productBookingData.state === "" ||
      productBookingData.city === null ||
      productBookingData.city === undefined ||
      productBookingData.city === "" ||
      productBookingData.pincode === null ||
      productBookingData.pincode === undefined ||
      productBookingData.pincode === "" ||
      productBookingData.needSamagri === null ||
      productBookingData.needSamagri === undefined ||
      productBookingData.needSamagri === ""
    ) {
      isValid = true;
      setError(true);
    }

    // const api_Params = {
    //   date: productBookingData.date,
    //   time: productBookingData.time,
    //   languageID: productBookingData.languageID,
    //   landmark: productBookingData.landmark,
    //   address: productBookingData.address,
    //   pincode: productBookingData.pincode,
    //   city: productBookingData.city,
    //   state: productBookingData.state,
    //   needSamagri: productBookingData.needSamagri,
    // };

    if (!isValid) {
      //   navigate("/services/book-puja/puja-details/pandit-list", {
      //     state: { productBookingData: productBookingData, product: product },
      //   });
      handleFindPandit()
      // nextStep();
    }
  };

  const handleRazorpayPayment = () => {

    // let totalAmount = parseInt(modelRequestData.totalVal); // Assuming totalVal includes shipping
    let minPayableAmount = modelRequestData.amountEntered
      ? modelRequestData.amountEntered
      : Number(grandTotal);

    let orderIDReturned = null;

    // axios
    //   .post(`${BaseUrl}/DailyPujaBook`, {
    //     DPB_FROMDATE: productBookingData.startDate,
    //     DPB_TODATE: productBookingData.endDate,
    //     DPB_FROMTIME: productBookingData.startTime,
    //     DPB_TOTIME: productBookingData.endTime,
    //     DPB_LANGAUGE: SelectLanguageOptions.find(
    //       (item) => item.value === productBookingData.languageID
    //     )?.label,
    //     DPB_SAMAGRI: puja.DP_SAMAGRI,
    //     NEED_SAMAGRI: productBookingData.needSamagri,
    //     DPB_PRICE: puja?.DP_PRICE,
    //     DPB_SAMAGRIPRICE: puja?.DP_SAMAGRI_PRICE,
    //     PANDIT_ID: findPandit === true ? null : selectedPandit,
    //     USER_ID: userID,
    //     REFERENCES_NO: "",
    //     ADDRESS: productBookingData?.address,
    //     DP_ID: puja?.DP_ID,
    //     PAYMENT_TYPE: modelRequestData.amountEntered ? "COD" : "ONLINE",
    //     CONVINIENCE_FEE: puja?.CONVENIENCE_FEE,
    //     TAX: tax,
    //     EXTRA: null,
    //     // TRANSACTION_ID: "",
    //     // TRANSACTION_AMT: "",
    //     DPB_DiscountPercentage: puja.DP_DISC,
    //     DPB_DiscountAmount: puja.DiscountAmount,
    //     DPB_Total: puja.DP_PRICE,
    //     DPB_TaxPercentage: 22,
    //     DPB_TaxAmount: ServiceDiscounts.DailyBookingDiscount,
    //     DPB_GrandTotal: grandTotal,
    //   })
    //   .then((res) => {
    //     if (res) {
    //       console.log(res);
    //       setOrderID(res.data.ID);
    //       // localStorage.setItem("orderID", JSON.stringify(res.data.ID));
    //       orderIDReturned = res.data.ID;
    //     }
    //   });

    const options = {
      key: "rzp_test_vv1FCZvuDRF6lQ",
      key_secret: "P4JAUwn4VdE6xDLJ6p2Zy8RQ",
      amount: Math.round(minPayableAmount) * 100,
      currency: "INR",
      name: "My Om Namo- WowInfotech",
      description: "working",
      image: Mylogo,
      handler: function (response) {
        // console.log(response, "Payment Success");
        const paymentId = response.razorpay_payment_id;



        if (response) {
          // axios
          //   .post(`${BaseUrl}/UpdatePaymentStatus?TYPE=DailyPuja`, {
          //     USER_ID: userID,
          //     ORDER_ID: orderIDReturned,
          //     // ORDER_ID: JSON.parse(localStorage.getItem("orderID")),
          //     TRANSACTION_ID: paymentId,
          //     PAYMENT_STATUS: "Success",
          //     TRANSACTION_STATUS: "Success",
          //     TRANSACTION_AMT: minPayableAmount,
          //     REMAING_AMOUNT:
          //       Number(totalPrice) +
          //       Number(puja?.DP_SAMAGRI_PRICE) +
          //       Number(puja?.CONVENIENCE_FEE) -
          //       minPayableAmount,
          //     PAYMENT_DATE: dayjs().format("YYYY-MM-DD"),
          //   })
          //   .then((res) => {
          //     // debugger;
          //     if (res.data.ResponseMessage === "successfully.") {
          //       // localStorage.setItem("cartItemCount", "0");
          //       // window.dispatchEvent(new Event("cartUpdated"));
          //       setShowCODModal(false);
          //       nextStep();
          //     } else {
          //       alert("Order placement failed, please try again.");
          //     }
          //   });
          axios
            .post(`${BaseUrl}/DailyPujaBook`, {
              DPB_FROMDATE: productBookingData?.startDate,
              DPB_TODATE: productBookingData?.endDate,
              DPB_FROMTIME: productBookingData?.startTime,
              DPB_TOTIME: productBookingData?.endTime,
              DPB_LANGAUGE: SelectLanguageOptions.find(
                (item) => item.value === productBookingData?.languageID
              )?.value,
              DPB_SAMAGRI: puja.DP_SAMAGRI,
              NEED_SAMAGRI: productBookingData.needSamagri,
              DPB_PRICE: Number(puja?.DP_PRICE) * totalDays,
              DPB_SAMAGRIPRICE: Number(puja?.DP_SAMAGRI_PRICE) * totalDays,
              PANDIT_ID: findPandit === true ? null : selectedPandit,
              USER_ID: userID,
              REFERENCES_NO: "",
              ADDRESS: productBookingData?.address,
              DP_ID: puja?.DP_ID,
              PAYMENT_TYPE: modelRequestData.amountEntered ? "COD" : "ONLINE",
              CONVENIENCE_FEE: Number(puja?.CONVENIENCE_FEE) * totalDays,
              TAX: Number(tax),
              EXTRA: null,
              TRANSACTION_ID: paymentId,
              TRANSACTION_AMT: Number(minPayableAmount),
              DPB_DiscountPercentage: puja.DP_DISC,
              DPB_DiscountAmount: Number(puja.DiscountAmount) * totalDays,
              DPB_Total: Number(puja.DP_PRICE),
              DPB_TaxPercentage: 22,
              DPB_TaxAmount: tax,
              DPB_GrandTotal: grandTotal,
              PAYMENT_STATUS:
                minPayableAmount === grandTotal ? "Completed" : "Pending",
              TRANSACTION_STATUS: "Success",
              SamagriPriceTaxAmount: puja?.SamagriPriceTaxAmount,
              SamagriPriceTaxPercent: puja?.SamagriPriceTaxPercent,
              ConvinienceFeeTaxPercent: puja?.ConvinienceFeeTaxPercent,
              ConvinienceFeeTaxAmount: puja?.ConvinienceFeeTaxAmount
            })
            .then((res) => {
              if (res) {
                console.log(res);
                setOrderID(res.data.ID);
                setShowCODModal(false);
                // localStorage.setItem("orderID", JSON.stringify(res.data.ID));
                orderIDReturned = res.data.ID;
                nextStep();
              }
            });
        }

        // const updatedPayload = {
        //   ...razorPayData,
        //   TRANSACTION_ID: paymentId,
        //   TRANSACTION_AMT: minPayableAmount,
        // };

        // axios.post(`${BaseUrl}/BookPooja`, updatedPayload).then((res) => {
        //   debugger;
        //   if (res.data.ResponseMessage === "Order Success.") {
        //     localStorage.setItem("cartItemCount", "0");
        //     window.dispatchEvent(new Event("cartUpdated"));
        //     navigate("/thank-you");
        //   } else {
        //     alert("Order placement failed, please try again.");
        //   }
        // });
      },
      theme: {
        color: "#ff416c",
      },
    };

    const rzp = new window.Razorpay(options);
    rzp.open();
  };
  console.log(puja?.DP_PRICE * totalDays, "puja?.DP_PRICE * totalDays");
  // const BookPujaAPI = async () => {
  //   debugger;
  //   axios
  //     .post(`${BaseUrl}/BookPooja`, {
  //       PB_DATE: productBookingData.date,
  //       PB_TIME: productBookingData.time,
  //       PB_LANGAUGE: SelectLanguageOptions.find(
  //         (item) => item.value === productBookingData.languageID
  //       )?.label,
  //       PB_SAMAGRI: productBookingData.needSamagri,
  //       PB_PRICE: pujaDetails?.pujaPrice,
  //       PB_SAMAGRIPRICE: pujaDetails?.pujaSamagriPrice,
  //       PANDIT_ID: selectedPandit,
  //       USER_ID: userID,
  //       REFERENCES_NO: "",
  //       ADDRESS: productBookingData?.address,
  //       PUJA_ID: pujaDetails?.pujaId,
  //       PAYMENT_TYPE: "ONLINE",
  //       CONVINIENCE_FEE: pujaDetails?.convenienceFee,
  //       TAX: null,
  //       EXTRA: null,
  //       TRANSACTION_ID: "",
  //       TRANSACTION_AMT: "",
  //     })
  //     .then((res) => {
  //       if (res) {
  //         console.log(res);
  //         setOrderID(res.data.ID);
  //         localStorage.setItem("orderID", JSON.stringify(res.data.ID));
  //         orderIDReturned = res.data.ID;
  //       }
  //     });
  // };

  const handlePanditSelectionNext = () => {
    // debugger;
    if (
      selectedPandit !== null &&
      selectedPandit !== undefined &&
      selectedPandit !== ""
    ) {
      // console.log("Panditselection");
      nextStep();
    } else {
      setPanditSelectionError(true);
    }
  };

  const handleStartDateChange = (newValue) => {
    if (dayjs(newValue).isValid()) {
      const newToDate = dayjs(newValue).format("YYYY-MM-DD");
      setProductBookingData((prev) => ({ ...prev, startDate: newToDate }));
    } else {
      setProductBookingData((prev) => ({ ...prev, startDate: null }));
    }
  };

  const handleEndDateChange = (newValue) => {
    if (dayjs(newValue).isValid()) {
      const newToDate = dayjs(newValue).format("YYYY-MM-DD");
      setProductBookingData((prev) => ({ ...prev, endDate: newToDate }));
    } else {
      setProductBookingData((prev) => ({ ...prev, endDate: null }));
    }
  };

  const handleLanguageChange = (selectedValue) => {
    setProductBookingData((prev) => ({
      ...prev,
      languageID: selectedValue.value,
    }));
  };
  const handleDurationChange = (selectedValue) => {
    setSelectedDuration(selectedValue.value)
  };
  const handleDaysChange = (selectedValue) => {
    debugger
    setSelectedDays(selectedValue.value)
    setTotalDays(selectedValue?.totalDays)
  };

  const handlePanditCardClicked = (pandit) => {
    setSelectedPandit(pandit.PANDIT_ID);
    // console.log(pandit);
  };

  const handleCODClicked = () => {

    setModelRequestData((prev) => ({
      ...prev,
      totalPrice: grandTotal,
      tax: tax,
      convinienceFee: Number(puja.CONVENIENCE_FEE) * totalDays,
      convenienceFeeFax: Number(puja?.ConvinienceFeeTaxAmount) * totalDays,
      SamagriPriceTaxAmount: puja.ConvinienceFeeTaxPercent != 0 ? Number(puja?.SamagriPriceTaxAmount) * totalDays : 0
    }));
    setShowCODModal(true);
  };

  const handleFindPandit = () => {
    setFindPandit(true);
    nextStep();
  };

  console.log(totalPrice);

  const renderStarRating = (rating) => {
    const stars = [];
    for (let i = 0; i < 5; i++) {
      if (i < Math.floor(rating)) {
        stars.push(<i key={i} className="fa fa-star filled-star"></i>); // Full Star
      } else if (i < rating) {
        stars.push(<i key={i} className="fa fa-star-half-alt half-star"></i>); // Half Star
      } else {
        stars.push(<i key={i} className="fa fa-star empty-star"></i>); // Empty Star
      }
    }
    return stars;
  };

  function formatToIndianCurrency(amount) {
    if (!amount) return "";

    const [integerPart, decimalPart] = amount.toString().split(".");

    // Format the integer part using Indian numbering system
    const formattedIntegerPart = integerPart.replace(
      // Match groups of 3 digits initially, then groups of 2 digits
      /(\d)(?=(\d\d)+\d$)/g,
      "$1,"
    );

    // Return the formatted value with the decimal part (if present)
    return decimalPart !== undefined
      ? `${formattedIntegerPart}.${decimalPart}`
      : formattedIntegerPart;
  }

  const formatTime = (timeString) => {
    if (!timeString) return "";
    const [hours, minutes] = timeString.split(":"); // Assuming time is in "HH:mm" format
    const date = new Date();
    date.setHours(hours, minutes);

    return date.toLocaleString("en-US", {
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    });
  };
  console.log('totalDays', totalDays)


  const GetStateLookupListData = async () => {
    try {
      const response = await StateLookupList(1); // Ensure it's correctly imported

      if (response?.ResponseCode === "0") {

        const languageLookupList = response?.DATA || [];

        const formattedLangList = languageLookupList.map((Lang) => ({
          value: Lang.StateID,
          label: Lang.StateName,
        }));

        setStateLookupList(formattedLangList);
      } else {
        console.error(
          "Failed to fetch sim Type lookup list:",
          response?.data?.statusMessage || "Unknown error"
        );
      }
    } catch (error) {
      console.error("Error fetching sim Type lookup list:", error);
    }
  };
  const GetCityLookupListData = async (ID) => {

    try {
      const response = await CityLookupList(ID); // Ensure it's correctly imported

      if (response?.ResponseCode === "0") {

        const languageLookupList = response?.DATA || [];

        const formattedLangList = languageLookupList.map((Lang) => ({
          value: Lang.DistrictID,
          label: Lang.DistrictName,
        }));

        setCityLookupList(formattedLangList);
      } else {
        console.error(
          "Failed to fetch sim Type lookup list:",
          response?.data?.statusMessage || "Unknown error"
        );
      }
    } catch (error) {
      console.error("Error fetching sim Type lookup list:", error);
    }
  };

  const GetLanguageListLookupListData = async () => {
    try {
      const response = await LanguageList(); // Ensure it's correctly imported

      if (response?.ResponseCode === "0") {
        const languageLookupList = response?.DATA || [];

        const formattedLangList = languageLookupList.map((Lang) => ({
          value: Lang.LANG_ID,
          label: Lang.LANG_NAME,
        }));

        setLanguageOption(formattedLangList);
      } else {
        console.error(
          "Failed to fetch sim Type lookup list:",
          response?.data?.statusMessage || "Unknown error"
        );
      }
    } catch (error) {
      console.error("Error fetching sim Type lookup list:", error);
    }
  };
  const GetDurationListLookupListData = async () => {
    try {
      // const response = await LanguageList(); // Ensure it's correctly imported
      const response = DurationLookupList

      if (response?.ResponseCode === "0" || response !== null) {
        // const languageLookupList = response?.DATA || [];
        setDurationList(response)
        // const formattedLangList = languageLookupList.map((Lang) => ({
        //   value: Lang.LANG_ID,
        //   label: Lang.LANG_NAME,
        // }));

        // setLanguageOption(formattedLangList);
      } else {
        console.error(
          "Failed to fetch sim Type lookup list:",
          response?.data?.statusMessage || "Unknown error"
        );
      }
    } catch (error) {
      console.error("Error fetching sim Type lookup list:", error);
    }
  };
  const GetDaysLookupListData = async () => {

    try {
      const response = await GetDurationDays(selectedDuration); // Ensure it's correctly imported
      // const response = selectedDuration === 1 ? WeekDays : MonthDays

      if (response?.ResponseCode === "0") {
        const languageLookupList = response?.DATA || [];

        const formattedLangList = languageLookupList.map((Lang) => ({
          value: Lang.Days,
          label: Lang.Days,
          totalDays: Lang.TotalDays
        }));
        setDaysList(formattedLangList)
        // setLanguageOption(formattedLangList);
      } else {
        console.error(
          "Failed to fetch sim Type lookup list:",
          response?.data?.statusMessage || "Unknown error"
        );
      }
    } catch (error) {
      console.error("Error fetching sim Type lookup list:", error);
    }
  };
  return (
    <div style={{ background: "#F1F6F7", paddingTop: "30px" }}>
      {/* Step Indicator */}
      <div className="stepper-wrapper">
        {steps.map((step, index) => (
          <div
            key={index}
            className={`stepper-item ${index < currentStep
              ? "completed"
              : index === currentStep
                ? "active"
                : ""
              }`}
          >
            <div className="step-counter">{index + 1}</div>
            <div className="step-name" style={{ fontSize: "small" }}>
              {step}
            </div>
          </div>
        ))}
      </div>

      {/* Step Content */}
      <div
        className="step-content"
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "10px 30px",
          background: "#F1F6F7",
        }}
      >
        {currentStep === 0 && (
          <>
            <div className="booking-details-container ">
              <div
                className="booking-details-left"
              // style={{
              //   padding: "10px 20px",
              //   display: "flex",
              //   alignItems: "center",
              //   justifyContent: "center",
              // }}
              >
                <h5>Booking Details</h5>
                <hr />
                <div className="row">
                  <div className="col-md-6 col-sm-12 mb-2 d-flex flex-column">
                    <label htmlFor="bookingDateStart" style={{ textAlign: "left" }}>
                      Start Date<span style={{ color: "red" }}>*</span>
                    </label>
                    <DatePicker
                      id="bookingDateStart"
                      value={productBookingData?.startDate || ""}
                      minDate={new Date(new Date().setDate(new Date().getDate() + 3))}
                      clearIcon={null}
                      onChange={handleStartDateChange}
                      format="dd/MM/yyyy"
                      className="custom-date-picker"
                    />

                    {error && !productBookingData.startDate && (
                      <span style={{ color: "red" }}>{ERROR_MESSAGES}</span>
                    )}
                  </div>
                  <div className="col-md-6 col-sm-12 mb-2 d-flex flex-column">
                    <label htmlFor="language" style={{ textAlign: "left" }}>
                      Select Duration<span style={{ color: "red" }}>*</span>
                    </label>
                    <Select
                      options={durationList}
                      onChange={handleDurationChange}
                      value={durationList.find(
                        (item) => item.value === selectedDuration
                      )}
                    />
                    {error && !selectedDuration && (
                      <span style={{ color: "red" }}>{ERROR_MESSAGES}</span>
                    )}
                  </div>
                  <div className="col-md-6 col-sm-12 mb-2 d-flex flex-column">
                    <label htmlFor="language" style={{ textAlign: "left" }}>
                      Select Days<span style={{ color: "red" }}>*</span>
                    </label>
                    <Select
                      options={daysList}
                      onChange={handleDaysChange}
                      value={daysList.find(
                        (item) => item.value === selectedDays
                      )}
                    />
                    {error && !selectedDays && (
                      <span style={{ color: "red" }}>{ERROR_MESSAGES}</span>
                    )}
                  </div>
                  <div className="col-md-6 col-sm-12 mb-2 d-flex flex-column">
                    <label htmlFor="bookingDateEnd" style={{ textAlign: "left" }}>
                      End Date<span style={{ color: "red" }}>*</span>
                    </label>
                    <DatePicker
                      id="bookingDateEnd"
                      disabled
                      value={productBookingData?.endDate || ""}
                      minDate={new Date(new Date().setDate(new Date().getDate() + 3))}
                      clearIcon={null}
                      onChange={handleEndDateChange}
                      format="dd/MM/yyyy"
                      className="custom-date-picker"
                    />

                    {error && !productBookingData.endDate && (
                      <span style={{ color: "red" }}>{ERROR_MESSAGES}</span>
                    )}
                    {/* {error && !productBookingData.time && (
                      <span className="error-msg">{ERROR_MESSAGES}</span>
                    )} */}
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6 col-sm-12 mb-2 d-flex flex-column">
                    <label htmlFor="bookingTime" style={{ textAlign: "left" }}>
                      Start Time<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      id="bookingTime"
                      type="time"
                      className="form-control"
                      onClick={(e) =>
                        e.target.showPicker && e.target.showPicker()
                      }
                      value={productBookingData.startTime || ""}
                      onChange={(e) =>
                        setProductBookingData((prev) => ({
                          ...prev,
                          startTime: e.target.value,
                        }))
                      }
                    />
                    {error && !productBookingData.startTime && (
                      <span style={{ color: "red" }}>{ERROR_MESSAGES}</span>
                    )}
                  </div>{" "}
                  {/* State */}

                  <div className="col-md-6 col-sm-12 mb-2 d-flex flex-column">
                    <label htmlFor="bookingTime" style={{ textAlign: "left" }}>
                      End Time<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      id="bookingTime"
                      type="time"
                      onClick={(e) =>
                        e.target.showPicker && e.target.showPicker()
                      }
                      className="form-control"
                      value={productBookingData.endTime || ""}
                      onChange={(e) =>
                        setProductBookingData((prev) => ({
                          ...prev,
                          endTime: e.target.value,
                        }))
                      }
                    />
                    {error && !productBookingData.endTime && (
                      <span style={{ color: "red" }}>{ERROR_MESSAGES}</span>
                    )}
                  </div>
                </div>

                <div className="row">
                  {" "}
                  <div className="col-md-6 col-sm-12 mb-2 d-flex flex-column">
                    <label htmlFor="language" style={{ textAlign: "left" }}>
                      Select Language<span style={{ color: "red" }}>*</span>
                    </label>
                    <Select
                      options={languageOption}
                      onChange={handleLanguageChange}
                      value={languageOption.find(
                        (item) => item.value === productBookingData.languageID
                      )}
                    />
                    {error && !productBookingData.languageID && (
                      <span style={{ color: "red" }}>{ERROR_MESSAGES}</span>
                    )}
                  </div>{" "}
                  {/* Landmark */}
                  <div className="col-md-6 col-sm-12 mb-2 d-flex flex-column">
                    <label htmlFor="landmark" style={{ textAlign: "left" }}>
                      Landmark<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      id="landmark"
                      type="text"
                      className="form-control"
                      value={productBookingData.landmark || ""}
                      onChange={(e) => {
                        let inputVal = e.target.value.trimStart();
                        setProductBookingData((prev) => ({
                          ...prev,
                          landmark: inputVal,
                        }));
                      }}
                      placeholder="Enter Landmark"
                    />
                    {error && !productBookingData.landmark && (
                      <span style={{ color: "red" }}>{ERROR_MESSAGES}</span>
                    )}
                  </div>
                </div>

                <div className="row">
                  {/* Address */}
                  <div className="col-md-6 col-sm-12 mb-2 d-flex flex-column">
                    <label htmlFor="address" style={{ textAlign: "left" }}>
                      Address<span style={{ color: "red" }}>*</span>
                    </label>
                    <textarea
                      id="address"
                      className="form-control"
                      value={productBookingData.address || ""}
                      onChange={(e) => {
                        let inputVal = e.target.value.trimStart();
                        setProductBookingData((prev) => ({
                          ...prev,
                          address: inputVal,
                        }));
                      }}
                      placeholder="Enter Address"
                    />
                    {error && !productBookingData.address && (
                      <span style={{ color: "red" }}>{ERROR_MESSAGES}</span>
                    )}
                  </div>

                  <div className="col-md-6 col-sm-12 mb-2 d-flex flex-column">
                    <label htmlFor="state" style={{ textAlign: "left" }}>
                      State<span style={{ color: "red" }}>*</span>
                    </label>
                    <Select
                      options={stateLookupList}
                      value={stateLookupList.find(
                        (item) => item.value === productBookingData.state
                      )}
                      onChange={(selectedOption) => {
                        setProductBookingData((prev) => ({ ...prev, state: selectedOption?.value }));
                      }}
                    />

                    {error && !productBookingData.state && (
                      <span style={{ color: "red" }}>{ERROR_MESSAGES}</span>
                    )}
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6 col-sm-12 mb-2 d-flex flex-column">
                    <label htmlFor="City" style={{ textAlign: "left" }}>
                      City
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <Select
                      options={cityLookupList}

                      value={cityLookupList.find(
                        (item) => item.value === productBookingData.city
                      )}

                      onChange={(selectedOption) => {
                        setProductBookingData((prev) => ({ ...prev, city: selectedOption?.value }));
                      }}
                    />
                    {error &&
                      (productBookingData.city === null ||
                        productBookingData.city === undefined ||
                        productBookingData.city === "") ? (
                      <span style={{ color: "red" }}>{ERROR_MESSAGES}</span>
                    ) : (
                      ""
                    )}
                  </div>{" "}
                  <div className="col-md-6 col-sm-12 mb-2 d-flex flex-column">
                    <label htmlFor="pincode" style={{ textAlign: "left" }}>
                      Pincode
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      id="pincode"
                      type="text"
                      maxLength={6}
                      className="form-control"
                      value={productBookingData.pincode || ""}
                      onChange={(e) => {
                        let inputVal = e.target.value;

                        // Remove any non-numeric characters
                        inputVal = inputVal.replace(/\D/g, "");

                        setProductBookingData((prev) => ({
                          ...prev,
                          pincode: inputVal,
                        }));
                      }}
                      placeholder="Enter Pincode"
                    //   style={{ padding: "0px" }}
                    />
                    {error &&
                      (productBookingData.pincode === null ||
                        productBookingData.pincode === undefined ||
                        productBookingData.pincode === "") ? (
                      <span style={{ color: "red" }}>{ERROR_MESSAGES}</span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 col-sm-12 mb-2 d-flex flex-column">
                    <label
                      htmlFor="needSamagri"
                      style={{ marginRight: "5px " }}
                    >
                      Do you want Samagri for this Puja?
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <div>
                      <label className="me-3">
                        <input
                          type="radio"
                          name="samagri"
                          value="yes"
                          checked={productBookingData.needSamagri === "yes"}
                          onChange={(e) =>
                            setProductBookingData((prev) => ({
                              ...prev,
                              needSamagri: e.target.value,
                            }))
                          }
                          className="me-1"
                        />
                        Yes
                      </label>
                      <label>
                        <input
                          type="radio"
                          name="samagri"
                          value="no"
                          checked={productBookingData.needSamagri === "no"}
                          onChange={(e) =>
                            setProductBookingData((prev) => ({
                              ...prev,
                              needSamagri: e.target.value,
                            }))
                          }
                          className="me-1"
                        />
                        No
                      </label>
                    </div>
                    {error &&
                      (productBookingData.needSamagri === null ||
                        productBookingData.needSamagri === undefined ||
                        productBookingData.needSamagri === "") ? (
                      <span style={{ color: "red" }}>{ERROR_MESSAGES}</span>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="col-md-6 col-sm-12 mb-2 d-flex flex-column">
                    {productBookingData.needSamagri === "yes" ? (
                      <span>
                        Your puja samagri amount is: ₹{" "}
                        {formatToIndianCurrency(puja?.DP_SAMAGRI_PRICE)}
                      </span>
                    ) : (
                      <span>Samagri will be arranged by you.</span>
                    )}
                    {/* <span>
                      Your puja samagri amount is: ₹ {puja?.DP_SAMAGRI_PRICE}
                    </span> */}
                  </div>
                </div>
              </div>
              <div className=" booking-details-right">
                <div className="price-summary">
                  <h5>{puja?.DP_NAME} ({totalDays} Days)</h5>
                  <hr />
                  <ul>
                    <li>
                      <span>Collected on behalf of pandit <br></br> & paid to pandit:</span>
                      <span>
                        <br></br>
                        Rs{" "}
                        {formatToIndianCurrency((puja?.DP_PRICE - puja?.DiscountAmount) * totalDays) || 0}
                      </span>
                    </li>
                    <hr></hr>
                    {/* <li>
                      <span>Discount ({puja?.DP_DISC}%):</span>
                      <span>
                        - Rs{" "}
                        {formatToIndianCurrency(
                          puja?.DiscountAmount * totalDays
                        ) || 0}
                      </span>
                    </li> */}
                    {productBookingData.needSamagri === "yes" && (
                      <>
                        <li>
                          <span>Samagri Price:</span>
                          <span>
                            + Rs{" "}
                            {productBookingData.needSamagri === "yes"
                              ? formatToIndianCurrency(
                                Number(puja?.DP_SAMAGRI_PRICE) * totalDays
                              )
                              : 0}
                          </span>
                        </li>
                        <li>
                          <span>Samagri Tax ({puja?.SamagriPriceTaxPercent}%) :</span>
                          <span>
                            + Rs{" "}
                            {productBookingData.needSamagri === "yes"
                              ? formatToIndianCurrency(
                                Number(Math.round(Number(puja?.SamagriPriceTaxAmount) * totalDays))
                              )
                              : 0}
                          </span>
                        </li>
                        <hr></hr>
                      </>


                    )}
                    <li>
                      <span>Convenience Fee:</span>
                      <span>
                        + Rs{" "}
                        {formatToIndianCurrency(
                          Number(puja?.CONVENIENCE_FEE) * totalDays
                        ) || 0}
                      </span>
                    </li>
                    <li className="">
                      <span>
                        Convenience   Tax ({puja?.ConvinienceFeeTaxPercent}%):
                      </span>
                      <span>+ Rs {formatToIndianCurrency(Math.round(Number(puja?.ConvinienceFeeTaxAmount) * totalDays))}</span>
                    </li>
                    <hr />
                    {/* <li>
                      <span>Total Days:</span>
                      <span>{totalDays} Days</span>
                    </li> */}
                    {/* <li>
                      <span>Tax:</span>
                      <span>Rs {tax}</span>
                    </li> */}



                    <li className="">
                      <span>Grand Total:</span>
                      <span>Rs {formatToIndianCurrency(Math.round(grandTotal))}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/* <div className="row">
              <div className="col-md-6 col-sm-12 mb-2 d-flex flex-column">
                <label htmlFor="bookingDate" style={{ textAlign: "left" }}>
                  Start Date<span style={{ color: "red" }}>*</span>
                </label>
                <DatePicker
                  id="bookingDate"
                  value={productBookingData?.startDate || ""}
                  minDate={fromDate.toDate()}
                  clearIcon={null}
                  onChange={handleStartDateChange}
                  format="dd/MM/yyyy"
                />
                {error && !productBookingData.startDate && (
                  <span style={{ color: "red" }}>{ERROR_MESSAGES}</span>
                )}
              </div>
              <div className="col-md-3 col-sm-6 mb-2 d-flex flex-column">
                <label htmlFor="bookingDate" style={{ textAlign: "left" }}>
                  End Date<span style={{ color: "red" }}>*</span>
                </label>
                <DatePicker
                  id="bookingDate"
                  value={productBookingData?.endDate || ""}
                  minDate={
                    productBookingData?.startDate
                      ? new Date(productBookingData.startDate)
                      : fromDate.toDate()
                  }
                  clearIcon={null}
                  onChange={handleEndDateChange}
                  format="dd/MM/yyyy"
                />
                {error && !productBookingData.endDate && (
                  <span style={{ color: "red" }}>{ERROR_MESSAGES}</span>
                )}
              </div>
              <div className="col-md-3 col-sm-6 mb-2 d-flex flex-column">
                <label htmlFor="bookingTime" style={{ textAlign: "left" }}>
                  Start Time<span style={{ color: "red" }}>*</span>
                </label>
                <input
                  id="bookingTime"
                  type="time"
                  className="form-control"
                  onClick={(e) => e.target.showPicker && e.target.showPicker()}
                  value={productBookingData.startTime || ""}
                  onChange={(e) =>
                    setProductBookingData((prev) => ({
                      ...prev,
                      startTime: e.target.value,
                    }))
                  }
                />
                {error && !productBookingData.startTime && (
                  <span style={{ color: "red" }}>{ERROR_MESSAGES}</span>
                )}
              </div>
              <div className="col-md-3 col-sm-6 mb-2 d-flex flex-column">
                <label htmlFor="bookingTime" style={{ textAlign: "left" }}>
                  End Time<span style={{ color: "red" }}>*</span>
                </label>
                <input
                  id="bookingTime"
                  type="time"
                  onClick={(e) => e.target.showPicker && e.target.showPicker()}
                  className="form-control"
                  value={productBookingData.endTime || ""}
                  onChange={(e) =>
                    setProductBookingData((prev) => ({
                      ...prev,
                      endTime: e.target.value,
                    }))
                  }
                />
                {error && !productBookingData.endTime && (
                  <span style={{ color: "red" }}>{ERROR_MESSAGES}</span>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-md-3 col-sm-6 mb-2 d-flex flex-column">
                <label htmlFor="language" style={{ textAlign: "left" }}>
                  Select Language<span style={{ color: "red" }}>*</span>
                </label>
                <Select
                  options={SelectLanguageOptions}
                  onChange={handleLanguageChange}
                  value={SelectLanguageOptions.find(
                    (item) => item.value === productBookingData.languageID
                  )}
                />
                {error && !productBookingData.languageID && (
                  <span style={{ color: "red" }}>{ERROR_MESSAGES}</span>
                )}
              </div>
              <div className="col-md-3 col-sm-6 mb-2 d-flex flex-column">
                <label htmlFor="landmark" style={{ textAlign: "left" }}>
                  Landmark<span style={{ color: "red" }}>*</span>
                </label>
                <input
                  id="landmark"
                  type="text"
                  className="form-control"
                  value={productBookingData.landmark || ""}
                  onChange={(e) => {
                    let inputVal = e.target.value.trimStart();
                    setProductBookingData((prev) => ({
                      ...prev,
                      landmark: inputVal,
                    }));
                  }}
                  placeholder="Enter Landmark"
                />
                {error && !productBookingData.landmark && (
                  <span style={{ color: "red" }}>{ERROR_MESSAGES}</span>
                )}
              </div>
              <div className="col-md-3 col-sm-6 mb-2 d-flex flex-column">
                <label htmlFor="address" style={{ textAlign: "left" }}>
                  Address<span style={{ color: "red" }}>*</span>
                </label>
                <textarea
                  id="address"
                  className="form-control"
                  value={productBookingData.address || ""}
                  onChange={(e) => {
                    let inputVal = e.target.value.trimStart();
                    setProductBookingData((prev) => ({
                      ...prev,
                      address: inputVal,
                    }));
                  }}
                  placeholder="Enter Address"
                />
                {error && !productBookingData.address && (
                  <span style={{ color: "red" }}>{ERROR_MESSAGES}</span>
                )}
              </div>
              <div className="col-md-3 col-sm-6 mb-2 d-flex flex-column">
                <label htmlFor="state" style={{ textAlign: "left" }}>
                  State<span style={{ color: "red" }}>*</span>
                </label>
                <input
                  id="state"
                  type="text"
                  className="form-control"
                  value={productBookingData.state || ""}
                  onChange={(e) => {
                    let inputVal = e.target.value.trimStart();
                    setProductBookingData((prev) => ({
                      ...prev,
                      state: inputVal,
                    }));
                  }}
                  placeholder="Enter State"
                />
                {error && !productBookingData.state && (
                  <span style={{ color: "red" }}>{ERROR_MESSAGES}</span>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-md-3 col-sm-6 mb-2 d-flex flex-column">
                <label htmlFor="City" style={{ textAlign: "left" }}>
                  City
                  <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  id="City"
                  type="text"
                  className="form-control"
                  value={productBookingData.city || ""}
                  onChange={(e) => {
                    let inputVal = e.target.value;
                    if (inputVal.startsWith(" ")) {
                      inputVal = inputVal.trimStart();
                    }
                    setProductBookingData((prev) => ({
                      ...prev,
                      city: inputVal,
                    }));
                  }}
                  placeholder="Enter City"
                  //   style={{ padding: "0px" }}
                />
                {error &&
                (productBookingData.city === null ||
                  productBookingData.city === undefined ||
                  productBookingData.city === "") ? (
                  <span style={{ color: "red" }}>{ERROR_MESSAGES}</span>
                ) : (
                  ""
                )}
              </div>
              <div className="col-md-3 col-sm-6 mb-2 d-flex flex-column">
                <label htmlFor="pincode" style={{ textAlign: "left" }}>
                  Pincode
                  <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  id="pincode"
                  type="text"
                  maxLength={6}
                  className="form-control"
                  value={productBookingData.pincode || ""}
                  onChange={(e) => {
                    let inputVal = e.target.value;

                    // Remove any non-numeric characters
                    inputVal = inputVal.replace(/\D/g, "");

                    setProductBookingData((prev) => ({
                      ...prev,
                      pincode: inputVal,
                    }));
                  }}
                  placeholder="Enter Pincode"
                  //   style={{ padding: "0px" }}
                />
                {error &&
                (productBookingData.pincode === null ||
                  productBookingData.pincode === undefined ||
                  productBookingData.pincode === "") ? (
                  <span style={{ color: "red" }}>{ERROR_MESSAGES}</span>
                ) : (
                  ""
                )}
              </div>
              <div className="col-md-3 col-sm-6 mb-2 d-flex flex-column">
                <label htmlFor="needSamagri" style={{ marginRight: "5px " }}>
                  Do you want Samagri for this Puja?
                  <span style={{ color: "red" }}>*</span>
                </label>
                <div>
                  <label className="me-3">
                    <input
                      type="radio"
                      name="samagri"
                      value="yes"
                      checked={productBookingData.needSamagri === "yes"}
                      onChange={(e) =>
                        setProductBookingData((prev) => ({
                          ...prev,
                          needSamagri: e.target.value,
                        }))
                      }
                      className="me-1"
                    />
                    Yes
                  </label>
                  <label>
                    <input
                      type="radio"
                      name="samagri"
                      value="no"
                      checked={productBookingData.needSamagri === "no"}
                      onChange={(e) =>
                        setProductBookingData((prev) => ({
                          ...prev,
                          needSamagri: e.target.value,
                        }))
                      }
                      className="me-1"
                    />
                    No
                  </label>
                </div>
                {error &&
                (productBookingData.needSamagri === null ||
                  productBookingData.needSamagri === undefined ||
                  productBookingData.needSamagri === "") ? (
                  <span style={{ color: "red" }}>{ERROR_MESSAGES}</span>
                ) : (
                  ""
                )}
              </div>
              <div className="col-md-3 col-sm-6 mb-2 d-flex flex-column">
                <span>
                  Your puja samagri amount is: ₹ {puja?.DP_SAMAGRI_PRICE}
                </span>
              </div>
            </div> */}
          </>
        )}

        {/* {currentStep === 1 && (
          <div className="panditList" style={{ width: "100%" }}>
            <div className="container">
              <div className="row mb-4">
                <div className="col-md-12 px-lg-5">

                  <div className="padit-list-products">
                    <div className="row">
                      {panditDataList?.map((pandit) => (
                        <div
                          className="col-lg-3 col-md-4 col-sm-6 col-12 mb-4"
                          key={pandit.PANDIT_ID}
                        >
                          <div
                            className={`card pandit-card ${selectedPandit === pandit.PANDIT_ID
                              ? "selected"
                              : ""
                              }`}
                            onClick={() => handlePanditCardClicked(pandit)}
                          >
                           
                            <div className="card-img-container">
                              <img
                                style={{ padding: "10px", objectFit: "cover" }}
                                src={pandit.PHOTO}
                                onError={(e) => {
                                  e.target.onerror = null; 
                                  e.target.src = placeholderImg; 
                                }}
                                alt={pandit.PANDIT_NAME}
                                className="card-img-top"
                              />
                            </div>

                           
                            <div className="card-body text-center">
                              <h5 className="card-title">
                                {pandit.PANDIT_NAME}
                              </h5>
                              <p className="card-text">
                                <strong>Mobile:</strong> {pandit.MOBILE_NO}
                              </p>
                              <p className="card-text">
                                <strong>Experience:</strong> {pandit.EXPERIENCE}{" "}
                                years
                              </p>
                              <p className="card-text">
                                <strong>DOB:</strong>{" "}
                                {new Date(pandit.DOB).toLocaleDateString()}
                              </p>

                            
                              <div className="rating">
                                {renderStarRating(
                                  parseFloat(pandit.PANDIT_RATING)
                                )}
                                <span className="rating-text">
                                  ({pandit.PANDIT_RATING})
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  {panditSelectionError &&
                    (selectedPandit === null ||
                      selectedPandit === undefined ||
                      selectedPandit === "") ? (
                    <span style={{ color: "red" }}>
                      Please select atleast 1 pandit
                    </span>
                  ) : (
                    ""
                  )}
                </div>
                <span className="text-center">
                  {" "}
                  Don't find a pandit of your like?
                  <span
                    onClick={handleFindPandit}
                    style={{ color: "#a42642", cursor: "pointer" }}
                  >
                    {" "}
                    Click here and we will find a pandit for you.
                  </span>
                </span>
              </div>
            </div>
          </div>
        )} */}

        {currentStep === 1 && (
          <div className="py-4" style={{ width: "100%" }}>
            <div className="p-3">
              <div className="row g-4 d-flex">
                <div
                  className="booking-preview col-md-5 text-center"
                  style={{
                    flex: "2",
                  }}
                >
                  <div className="booking-preview-details-puja">
                    <div className="booking-preview-details-puja-top">
                      <div className="booking-preview-details-puja-top-left">
                        <img
                          src={puja.DP_IMAGE}
                          alt=""
                          onError={(e) => {
                            e.target.onerror = null; // Prevent infinite loop
                            e.target.src = placeholderImg; // Set fallback image
                          }}
                        />
                      </div>
                      <div className="booking-preview-details-puja-top-right">
                        <span className="puja-name">{puja.DP_NAME}</span>
                        <span>
                          Puja Language:{" "}
                          {
                            languageOption.filter(
                              (item) =>
                                item.value === productBookingData.languageID
                            )[0]?.label
                          }
                        </span>
                        <span>
                          Puja Start Date: {productBookingData.startDate}

                        </span>
                        <span>
                          Puja End Date: {productBookingData.endDate}

                        </span>

                        <span>
                          Puja Time: {formatTime(productBookingData.startTime)}{" "}
                          to {formatTime(productBookingData.endTime)}
                        </span>

                        {/* <span>
                          Pandit Name:{" "}
                          {
                            panditDataList.filter(
                              (item) => item.PANDIT_ID === selectedPandit
                            )[0]?.PANDIT_NAME
                          }
                        </span> */}
                      </div>
                    </div>
                    <p className="fw-bold">Note : You will receive pandit details within 24 hours</p>
                  </div>
                </div>
                <div className=" booking-details-right">
                  <div className="price-summary">
                    <h5>{puja?.DP_NAME} ({totalDays} Days)</h5>
                    <hr />
                    <ul>
                      <li>
                        <span>Collected on behalf of pandit <br></br> & paid to pandit:</span>
                        <span>
                          <br></br>
                          Rs{" "}
                          {formatToIndianCurrency((puja?.DP_PRICE - puja?.DiscountAmount) * totalDays) || 0}
                        </span>
                      </li>
                      <hr></hr>
                      {/* <li>
                      <span>Discount ({puja?.DP_DISC}%):</span>
                      <span>
                        - Rs{" "}
                        {formatToIndianCurrency(
                          puja?.DiscountAmount * totalDays
                        ) || 0}
                      </span>
                    </li> */}
                      {productBookingData.needSamagri === "yes" && (
                        <>
                          <li>
                            <span>Samagri Price:</span>
                            <span>
                              + Rs{" "}
                              {productBookingData.needSamagri === "yes"
                                ? formatToIndianCurrency(
                                  Number(puja?.DP_SAMAGRI_PRICE) * totalDays
                                )
                                : 0}
                            </span>
                          </li>
                          <li>
                            <span>Samagri Tax ({puja?.SamagriPriceTaxPercent}%) :</span>
                            <span>
                              + Rs{" "}
                              {productBookingData.needSamagri === "yes"
                                ? formatToIndianCurrency(
                                  Number(Math.round(Number(puja?.SamagriPriceTaxAmount) * totalDays))
                                )
                                : 0}
                            </span>
                          </li>
                          <hr></hr>
                        </>


                      )}
                      <li>
                        <span>Convenience Fee:</span>
                        <span>
                          + Rs{" "}
                          {formatToIndianCurrency(
                            Number(puja?.CONVENIENCE_FEE) * totalDays
                          ) || 0}
                        </span>
                      </li>
                      <li className="">
                        <span>
                          Convenience   Tax ({puja?.ConvinienceFeeTaxPercent}%):
                        </span>
                        <span>+ Rs {formatToIndianCurrency(Math.round(Number(puja?.ConvinienceFeeTaxAmount) * totalDays))}</span>
                      </li>
                      <hr />
                      {/* <li>
                      <span>Total Days:</span>
                      <span>{totalDays} Days</span>
                    </li> */}
                      {/* <li>
                      <span>Tax:</span>
                      <span>Rs {tax}</span>
                    </li> */}



                      <li className="">
                        <span>Grand Total:</span>
                        <span>Rs {formatToIndianCurrency(Math.round(grandTotal))}</span>
                      </li>
                    </ul>
                    <div className="mt-3 d-flex justify-content-end gap-2">
                      <button
                        style={{ backgroundColor: "#a53444", color: "white" }}
                        className="btn px-4"
                        onClick={handleCODClicked}
                      >
                        Cash On Delivery
                      </button>
                      <button
                        style={{ backgroundColor: "#a53444", color: "white" }}
                        className="btn px-4"
                        onClick={handleRazorpayPayment}
                      >
                        Pay
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {currentStep === 2 && (
          <div>
            <div className="carousel-inner" role="listbox">
              <div className="item carousel-item-four active">
                <div className="">
                  <div className="row">
                    <div className="carousel slide carousel-slider">
                      <div className="carousel-position-four">
                        <div className="carousel-inner">
                          <div
                            className="banner-breadcrumb"
                            style={{
                              // backgroundImage: `url(${StupaImg})`,
                              width: "100%",
                              height: "100px",
                            }}

                          // style={{ backgroundImage: `url(${AboutImg})` }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row margin-bottom-40">
              <div className="col-md-12 col-sm-12">
                <div className="thankyou-box text-center bg-white p-5">
                  <h2
                    style={{
                      fontFamily: "Ubuntu",
                      textTransform: "capitalize",
                      color: "#504e4e",
                      fontSize: "32px",
                      marginBottom: "20px",
                    }}
                  >
                    Thank You for Ordering !
                  </h2>
                  <h4
                    className="text-success"
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "18px",
                      fontWeight: "450",
                    }}
                  >
                    Your Order Is Successful
                  </h4>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* Navigation Buttons */}
      {currentStep === 0 ? (
        <div
          className="navigation-buttons"
          style={{ marginRight: "50px", paddingBottom: "30px" }}
        >
          {/* <button
            className="btn btn-secondry"
            style={{ backgroundColor: "#d7d7d7", color: "black" }}
            onClick={prevStep}
          >
            Previous
          </button> */}
          <button
            onClick={
              currentStep === 0
                ? HandleProductBookingBtnClicked
                : currentStep === 1
                  ? handlePanditSelectionNext
                  : ""
            }
            disabled={currentStep === steps.length - 1}
            className="next-btn"
          >
            Next
          </button>
        </div>
      ) : currentStep === 1 ? (
        <div
          className="navigation-buttons"
          style={{ marginRight: "50px", paddingBottom: "30px" }}
        >
          <button
            className="btn btn-secondry"
            style={{ backgroundColor: "#d7d7d7", color: "black" }}
            onClick={prevStep}
          >
            Previous
          </button>
        </div>
      ) : currentStep === 2 ? (
        <div
          className="navigation-buttons"
          style={{ marginRight: "50px", paddingBottom: "30px" }}
        >
          <button className=" prev-btn" onClick={() => navigate("/")}>
            Home
          </button>
          <button
            className=" prev-btn"
            onClick={() => navigate("/my-order-daily-Booking")}
          >
            My Orders
          </button>
        </div>
      ) : (
        currentStep === 1 && (
          <div
            className="navigation-buttons"
            style={{ marginRight: "50px", paddingBottom: "30px" }}
          >
            <button
              onClick={prevStep}
              disabled={currentStep === 0}
              className="btn btn-secondry"
              style={{ backgroundColor: "#d7d7d7", color: "black" }}
            >
              Previous
            </button>
            <button
              onClick={
                currentStep === 0
                  ? HandleProductBookingBtnClicked
                  : currentStep === 1
                    ? handlePanditSelectionNext
                    : ""
              }
              disabled={currentStep === steps.length - 1}
              className="next-btn"
            >
              Next
            </button>
          </div>
        )
      )}
      <CODModalDailyBooking
        show={showCODModal}
        onHide={() => setShowCODModal(false)}
        onConfirm={handleRazorpayPayment}
        modelRequestData={modelRequestData}
        setModelRequestData={setModelRequestData}
      />
    </div>
  );
};

export default DailyPujaBooking;
