import React from "react";
import { Button, Modal } from "react-bootstrap";
import { motion } from "framer-motion";
import Batuk2 from "../assets/images/new/Batuk2.png";
const ComingSoonModal = ({ show, onClose }) => {
  const handleClose = () => {
    onClose(false);
  };
  return (
    <>
      <Modal
        show={show}
        backdrop="static"
        keyboard={false}
        centered
        dialogClassName="custom-modal-width"
      >
        <Modal.Header closeButton onClick={handleClose}>
          <Modal.Title className="w-100 text-center"></Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          {/* Logo */}
          <motion.img
            src={Batuk2}
            alt="Coming Soon"
            className="mb-3"
            style={{ width: "150px" }}
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          />

          {/* Animated Text */}
          <motion.h3
            style={{ fontWeight: "600" }}
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.6, ease: "easeOut" }}
          >
            Coming Soon!
            <p>Thank You.</p>
          </motion.h3>
        </Modal.Body>
        {/* <Modal.Footer className="justify-content-center">
                    <Button
                        variant="primary"
                        onClick={handleClose}
                        style={{
                            backgroundColor: "#EFB423",
                            color: "#2B1B10",
                            border: "none",
                        }}
                    >
                        Close
                    </Button>
                </Modal.Footer> */}
      </Modal>
    </>
  );
};

export default ComingSoonModal;
