import { BaseUrl } from "../../BaseUrl/BaseUrl";
import {
  getApiWithAuthorization,
  postApiWithoutAuthorization,
} from "../ApiMethod/ApiMethod";


export const UserRegNewWithMobApi = async (url = '', params) => {
    if (params.bookingAgreementID === null || params.bookingAgreementID === undefined) {
      delete params.bookingAgreementID;
    }
  
    const res = await postApiWithoutAuthorization(`${BaseUrl}${url}`, params);
    return res;
  };