import React, { useEffect, useState, useRef } from "react";
import Navbar from "../../Components/Navbar/Navbar";
import MainSlider from "../../Components/MainSlider/MainSlider";
import AboutUS from "../../Components/AboutUs/AboutUs";
import BestServices from "../../Components/BestServices/BestServices";
import WhyChooseUs from "../../Components/WhyChooseUs/WhyChooseUs";
import Journey from "../../Components/Journey/Journey";
import Support from "../../Components/SupportComponent/Support";
import TestimonialsSection from "../../Components/TestimonialSection/Testimonial";
import FAQSection from "../../Components/FAQSection/FAQ";
import BlogCarousel from "../../Components/BlogsSection/BlogsSection";
import Footer from "../../Components/FooterSection/FooterSection";
import LocationModal from "../../Components/LocationModal";
import audioFile from "../../assets/om_sound_new.mp3";
import Newsletter from "../../Components/NewsletterModal";
import { Helmet } from "react-helmet-async";
// import audioFile from '../../public/assets/om_sound_new.mp3'

const Home = () => {
  const audioRef = useRef(null);

  const [userCity, setUserCity] = useState(localStorage.getItem("userCity"));
  const [showModal, setShowModal] = useState(!userCity);

  useEffect(() => {
    const audio = audioRef.current;
    if (audio) {
      audio
        .play()
        .catch((error) => console.error("Audio playback failed:", error));
    }
  }, []);
  const handleSelectCity = (city) => {
    if (city) {
      localStorage.setItem("userCity", city);
      setUserCity(city);
      setShowModal(false);
    }
  };

  const organizationSchema = {
    "@context": "https://schema.org",
    "@type": "Organization",
    name: "MyOmNamo",
    alternateName: "Myomnamo Spiritual Services",
    url: "https://www.myomnamo.com/",
    logo: "https://www.myomnamo.com/assets/images/new/logo.png",
    contactPoint: {
      "@type": "ContactPoint",
      telephone: "8879222277",
      contactType: "customer service",
      contactOption: "TollFree",
      areaServed: "IN",
      availableLanguage: ["en", "Hindi"],
    },
    sameAs: [
      "https://www.facebook.com/omnamoapp/",
      "https://x.com/My_OmNamo",
      "https://www.instagram.com/myomnamo_official/",
      "https://www.youtube.com/@myomnamo-beliefredefined9691",
      "https://www.linkedin.com/company/my-om-namo/",
    ],
  };

  const webpageSchema = {
    "@context": "http://schema.org",
    "@type": "WebPage",
    name: "Complete Spiritual Services Online - Pandits, Pujas, Horoscopes & More",
    url: "https://www.myomnamo.com/",
    description:
      "Start your spiritual journey with MyOmNamo. Book pandits, pujas, horoscopes, and temple visits online. Explore our e-store for pooja samagri and spiritual services.",
    publisher: {
      "@type": "Organization",
      name: "MyOmNamo",
    },
  };

  const localBusinessSchema = {
    "@context": "https://schema.org",
    "@type": "LocalBusiness",
    name: "MyOmNamo",
    image: "https://www.myomnamo.com/assets/images/new/logo.png",
    "@id": "",
    url: "https://www.myomnamo.com/",
    telephone: "+91 8879222277",
    address: {
      "@type": "PostalAddress",
      streetAddress:
        "BiG Strategic Advisors Group A 321, Lodha Supremus II, Beside Thane Passport Office, Road No 22, Wagle Estate, Thane west,",
      addressLocality: "Thane",
      postalCode: "400604",
      addressCountry: "IN",
    },
    sameAs: [
      "https://www.facebook.com/omnamoapp/",
      "https://x.com/My_OmNamo",
      "https://www.instagram.com/myomnamo_official/",
      "https://www.youtube.com/@myomnamo-beliefredefined9691",
      "https://www.linkedin.com/company/my-om-namo/",
    ],
  };

  const faqSchema = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity: [
      {
        "@type": "Question",
        name: "What services does My OmNamo offer?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Pandit Booking, Daily Pandit Booking, E-Store, Darshan Booking, Puja at Temple, Horoscope.",
        },
      },
      {
        "@type": "Question",
        name: "Can I schedule a temple visit through My OmNamo?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Yes, you can easily book temple visits online through our platform at your convenience.",
        },
      },
      {
        "@type": "Question",
        name: "Can I book personalized consultations with spiritual mentors?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Yes, you can book sessions with experienced spiritual mentors through our platform.",
        },
      },
    ],
  };

  return (
    <div>
      {showModal && (
        <LocationModal
          show={showModal}
          onHide={() => setShowModal(false)}
          onSelectCity={handleSelectCity}
        />
      )}

      <audio ref={audioRef} src={audioFile} />

      <Helmet>
        <title>
          Complete Spiritual Services Online - Pandits, Pujas, Horoscopes & More
        </title>
        <meta
          name="description"
          content="Start your spiritual journey with MyOmNamo. Book pandits, pujas, horoscopes, and temple visits online. Explore our e-store for pooja samagri and spiritual services."
        />
        <link rel="canonical" href="https://www.myomnamo.com/" />

        <meta
          property="og:title"
          content="Complete Spiritual Services Online - Pandits, Pujas, Horoscopes & More"
        />
        <meta
          property="og:description"
          content="Start your spiritual journey with MyOmNamo. Book pandits, pujas, horoscopes, and temple visits online. Explore our e-store for pooja samagri and spiritual services."
        />
        <meta
          property="og:image"
          content="https://www.myomnamo.com/assets/images/new/logo.png"
        />
        <meta property="og:url" content="https://www.myomnamo.com/" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Myomnamo" />
        <meta property="og:locale" content="en_IN" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Complete Spiritual Services Online - Pandits, Pujas, Horoscopes & More"
        />
        <meta
          name="twitter:description"
          content="Start your spiritual journey with MyOmNamo. Book pandits, pujas, horoscopes, and temple visits online. Explore our e-store for pooja samagri and spiritual services."
        />
        <meta
          name="twitter:image"
          content="https://www.myomnamo.com/assets/images/new/logo.png"
        />

        {/* JSON-LD Structured Data */}
        <script type="application/ld+json">
          {JSON.stringify(organizationSchema)}
        </script>
        <script type="application/ld+json">
          {JSON.stringify(webpageSchema)}
        </script>
        <script type="application/ld+json">
          {JSON.stringify(localBusinessSchema)}
        </script>
        <script type="application/ld+json">{JSON.stringify(faqSchema)}</script>
      </Helmet>

      {/* <Navbar /> */}
      <MainSlider />
      {/* <Newsletter /> */}
      <AboutUS />
      <BestServices />
      <WhyChooseUs />
      {/* <Journey /> */}
      <Support />
      <TestimonialsSection />
      <FAQSection />
      <BlogCarousel />
      {/* <Footer /> */}
    </div>
  );
};

export default Home;
