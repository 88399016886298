import React from "react";
import TestimonialImg1 from "../../assets/images/resources/testi-1-1.jpg";
import TestimonialImg2 from "../../assets/images/resources/testi-1-2.jpg";
import TestimonialImg3 from "../../assets/images/resources/testi-1-3.jpg";
import TestimonialImg4 from "../../assets/images/resources/testi-1-4.jpg";
import OwlCarousel from "react-owl-carousel3";
import batuk from "../../assets/images/new/fav-removebg-preview.png";

const testimonials = [
  {
    name: "Rohit Joshi",
    designation: "Kolkata",
    image: `${TestimonialImg1}`,
    content:
      "My OmNamo made booking a pandit and organizing a puja easy. The process was easy, and the experience was truly peaceful. I highly recommend it.",
    accentColor: "#ffa415",
  },
  {
    name: "Akash Mehta",
    designation: "Pune",
    image: `${TestimonialImg2}`,
    content:
      "I've used My OmNamo for horoscope readings and temple visit bookings. Their service is reliable, and it’s so convenient to have everything in one place.",
    accentColor: "#fc5528",
  },
  {
    name: "Rupali Patil",
    designation: "Mumbai",
    image: `${TestimonialImg3}`,
    content:
      "Their services helped me connect with my spirituality in a simple and meaningful way. The ease of booking pujas online is amazing.",
    accentColor: "#8139e7",
  },
  // {
  //   name: "Larry Aguilar",
  //   designation: "Reviewer",
  //   image: `${TestimonialImg4}`,
  //   content:
  //     "There are many variations of passages of Lorem Ipsum, but the majority have suffered alteration in some form, by injected humour or.",
  //   accentColor: "#44c895",
  // },
];

const TestimonialsSection = () => {
  return (
    <section className="testimonials-page">
      <div
        className="container"
        style={{ display: "flex", flexDirection: "column" }}
      >
        <div className="sec-title text-center">
          <h6 className="sec-title__tagline" style={{ perspective: "400px" }}>
            <img className=" tagline-img" src={batuk} alt="" /> Testimonial
            {/* <span className="sec-title__tagline__border"></span> Testimonial */}
          </h6>
          <h3 className="sec-title__title" style={{ perspective: "400px" }}>
            What Our Customers Say
          </h3>
        </div>
        <OwlCarousel
          className="testimonials-page__carousel owl-theme"
          items={1}
          margin={30}
          loop={false}
          smartSpeed={700}
          nav={false}
          dots={true}
          autoplay={false}
          responsive={{
            0: { items: 1 },
            768: { items: 2 },
          }}
        >
          {testimonials.map((testimonial, index) => (
            <div className="item h-100" key={index}>
              <div
                className="testimonials-page__card"
                style={{
                  "--accent-color": testimonial.accentColor,
                  height: "298px",
                }}
              >
                {/* <div className="testimonials-page__card__image">
                  <img src={testimonial.image} alt={testimonial.name} />
                </div> */}
                <div className="testimonials-page__card__rating">
                  {[...Array(5)].map((_, i) => (
                    <i key={i} className="icofont-star"></i>
                  ))}
                </div>
                <div className="testimonials-page__card__content">
                  {testimonial.content}
                </div>
                <h3 className="testimonials-page__card__name">
                  {testimonial.name}
                </h3>
                <p className="testimonials-page__card__designation">
                  {testimonial.designation}
                </p>
                <div className="testimonials-page__card__quote">
                  <i className="icofont-quote-left"></i>
                </div>
              </div>
            </div>
          ))}
        </OwlCarousel>
      </div>
    </section>
  );
};

export default TestimonialsSection;
