import { BaseUrl } from "../../BaseUrl/BaseUrl";
import {
  getApiWithAuthorization,
  postApiWithoutAuthorization,
} from "../ApiMethod/ApiMethod";

export const CurrentBlogData = async (params) => {
  const url = `${BaseUrl}/Blog`;
  const res = await postApiWithoutAuthorization(url, params);
  return res;
};
