import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { LoginUser, SendOTP } from "../../../ApiServices/Login/Login";
import { AuthContextProvider } from "../../../AuthContext/AuthContext";
import "./Login.css";
import { ERROR_MESSAGES } from "../../../Components/GlobalErrorMessage";
import Logo from "../../../assets/images/new/logo.png";
import Select from "react-select";
import { countrySelect } from "../../../middlewares/Utils";
import LoginImg from "../../../assets/images/new/AbuotUs.png";

function Login() {
  const navigate = useNavigate();
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [isResendDisabled, setIsResendDisabled] = useState(false);
  const [error, setError] = useState(false);
  const [userID, setUserId] = useState();
  const [timer, setTimer] = useState(60);
  const [otpLoader, setOtpLoader] = useState(null);
  const [loginObj, setLoginObj] = useState({
    username: "",
    email: "",
    countryID: 1,
    mobileNo: null,
  });
  const { setLoader, setIsAuthenticated } = useContext(AuthContextProvider);
  const [incorrectCred, setIncorrectCred] = useState(null);
  const [apiError, setApiError] = useState(null);

  // useEffect(() => {
  //   const registerKey = sessionStorage.getItem("registerKey");
  //   if (!registerKey) {
  //     navigate("/");
  //   }
  // }, [navigate]);

  // useEffect(() => {
  //   if (isResendDisabled && timer > 0) {
  //     const interval = setInterval(() => {
  //       setTimer((prev) => prev - 1);
  //     }, 1000);
  //     return () => clearInterval(interval);
  //   } else if (timer === 0) {
  //     setIsResendDisabled(false);
  //   }
  // }, [isResendDisabled, timer]);

  const LoginData = () => {
    // debugger;
    let isValid = false;
    if (
      loginObj.username === "" ||
      loginObj.username === null ||
      loginObj.username === undefined ||
      loginObj.email === "" ||
      loginObj.email === null ||
      loginObj.email === undefined ||
      loginObj.mobileNo === "" ||
      loginObj.mobileNo === null ||
      loginObj.mobileNo === undefined ||
      loginObj.countryID === "" ||
      loginObj.countryID === null ||
      loginObj.countryID === undefined
    ) {
      isValid = true;
      setError(true);
    } else if (loginObj.email !== null && !validateEmail(loginObj.email)) {
      isValid = true;
      setError(true);
    } else if (loginObj?.mobileNo?.length !== 10) {
      isValid = true;
      setError(true);
    }

    const apiParams = {
      UserName: loginObj.username,
      MobileNo: loginObj.mobileNo,
      Email: loginObj.email,
      CuntryID: loginObj.countryID,
      Type: "SignIn",
    };

    if (!isValid) {
      RegisterUserData(apiParams);
      setOtpLoader(false);
      navigate("/loginotp", {
        state: {
          mobileNumber: loginObj?.mobileNo,
          username: loginObj.username,
          email: loginObj.email,
          countryID: loginObj.countryID,
        },
      });
    }
  };

  const RegisterUserData = async (apiParams) => {
    // debugger;
    // setLoader(true);
    setOtpLoader(true);
    const RegisterUserRes = await SendOTP(apiParams);

    try {
      if (RegisterUserRes?.ResponseMessage == "Success...") {
        // setLoader(false);
        setOtpLoader(false);
        // setTimeout(() => {
        //   navigate("/loginotp", {
        //     state: { mobileNumber: loginObj?.mobileNo },
        //   });
        // }, 100);
        // navigate("/loginotp", { state: { email: loginObj?.email } });
      } else {
        // setLoader(false);
        setOtpLoader(false);
        setApiError(RegisterUserRes.ResponseMessage); // Set API error if not successful
      }
    } catch (error) {
      // setLoader(false);
      setOtpLoader(false);
      console.log(error);
    }
  };

  const HandleCountryChange = (selectedValue) => {
    setLoginObj((prev) => ({
      ...prev,
      countryID: selectedValue.value,
    }));
  };

  const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  return (
    <div className="e-store main">
      <div className="signinn mb-5" style={{ marginTop: "3rem" }}>
        <div className="container" id="container" style={{ padding: "0px" }}>
          <div className="overlay-container">
            <div className="overlay">
              <div className="overlay-panel overlay-right">
                {/* <h1 className="text-black">Hello, Friend!</h1>
                <p className="text-black">
                  Sign in to your Account and Explore Get Your Products
                </p> */}
                <img src={LoginImg} alt="" className="login-page-img" />
              </div>
            </div>
          </div>
          <div className="form-container sign-in-container">
            <div>
              <div className="my-img">
                <img src={Logo} alt="img" className="p-2 img-responsive" />
              </div>

              <input
                type="text"
                name="name"
                className="form-control"
                placeholder="Full Name"
                onChange={(e) => {
                  setError(false);
                  let inputVal = e.target.value;

                  // Prevent leading spaces
                  if (inputVal.startsWith(" ")) {
                    inputVal = inputVal.trimStart();
                  }

                  // Allow only alphabets and spaces
                  inputVal = inputVal.replace(/[^A-Za-z\s]/g, "");

                  setLoginObj((prev) => ({
                    ...prev,
                    username: inputVal,
                  }));
                }}
                value={loginObj.username}
                style={{ margin: "5px" }}
                maxLength={30}
              />
              {error &&
              (loginObj.username === "" ||
                loginObj.username === undefined ||
                loginObj.username === null) ? (
                <span className="error-msg" style={{ marginLeft: "10px" }}>
                  {ERROR_MESSAGES}
                </span>
              ) : (
                ""
              )}

              <input
                type="text"
                name="email"
                className="form-control"
                placeholder="Email"
                onChange={(e) => {
                  setError(false);
                  let inputVal = e.target.value;
                  if (inputVal.startsWith(" ")) {
                    inputVal = inputVal.trimStart();
                  }
                  setLoginObj((prev) => ({
                    ...prev,
                    email: inputVal,
                  }));
                }}
                value={loginObj.email}
                style={{ margin: "5px" }}
                maxLength={50}
              />
              {error &&
                (!loginObj.email || loginObj.email.trim() === "" ? (
                  <span className="error-msg" style={{ marginLeft: "10px" }}>
                    {ERROR_MESSAGES}
                  </span>
                ) : loginObj.email !== null &&
                  !validateEmail(loginObj.email) ? (
                  <span style={{ color: "red" }}>Invalid Email ID</span>
                ) : (
                  ""
                ))}

              {/* <Select
                options={countrySelect}
                value={countrySelect.find(
                  (val) => val.value === loginObj.countryID
                )}
                placeholder="Select country"
                onChange={HandleCountryChange}
              /> */}

              {/* {error &&
              (loginObj.countryID === "" ||
                loginObj.countryID === undefined ||
                loginObj.countryID === null) ? (
                <span
                  style={{
                    color: "red",
                    fontFamily: "Ubuntu",
                    fontSize: "15px",
                    marginRight: "160px",
                    marginLeft: "10px",
                  }}
                >
                  {ERROR_MESSAGES}
                </span>
              ) : (
                ""
              )} */}

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "5px",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Select
                    options={countrySelect}
                    value={countrySelect.find(
                      (val) => val.value === loginObj.countryID
                    )}
                    placeholder="Select country"
                    onChange={HandleCountryChange}
                    styles={{
                      control: (base) => ({
                        ...base,
                        width: "max-content", // Adjust width as needed
                        marginLeft: "6px",
                        fontSize: "15px",
                        height: "45px",
                      }),
                    }}
                  />

                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <input
                      type="text"
                      name="mobile"
                      className="form-control"
                      placeholder="Mobile Number"
                      onChange={(e) => {
                        const numericValue = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
                        setLoginObj((prev) => ({
                          ...prev,
                          mobileNo: numericValue,
                        }));
                      }}
                      value={loginObj.mobileNo}
                      style={{ margin: "5px" }}
                      maxLength={10} // Set maxLength based on selected country
                    />
                  </div>
                </div>
                <div style={{ alignSelf: "flex-start", width: "100%" }}>
                  {error &&
                    (!loginObj.mobileNo || loginObj.mobileNo.trim() === "" ? (
                      <span className="error-msg text-left">
                        {ERROR_MESSAGES}
                      </span>
                    ) : loginObj.mobileNo !== null &&
                      loginObj?.mobileNo?.length !== 10 ? (
                      <span className="error-msg">Invalid Mobile Number</span>
                    ) : (
                      ""
                    ))}
                </div>
              </div>

              {apiError && (
                <span
                  style={{
                    color: "red",
                    fontFamily: "Poppins",
                    fontSize: "16px",
                    display: "block",
                  }}
                >
                  {apiError}
                </span>
              )}

              <button
                type="submit"
                className="mt-4 mb-3 signin-btn otp-btn"
                disabled={otpLoader}
                onClick={LoginData}
              >
                {otpLoader ? <div className="small-loader"></div> : "Get OTP"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
