import React from "react";
import About1 from "../../assets/images/new/about2.png";
import About2 from "../../assets/images/new/about1.png";
import About1Shape from "../../assets/images/shapes/about-2-shape-1.png";
import About2Shape from "../../assets/images/shapes/about-2-shape-2.png";
import OmImg from "../../assets/images/new/om.png";
import batuk from "../../assets/images/new/fav-removebg-preview.png";
import AbuotUs from "../../assets/images/new/aboutPageImg.png";

const AboutUS = () => {
  return (
    <section className="about-two">
      <div className="container">
        <div className="row">
          <div className="col-lg-5">
            <div
              className="about-two__image animate__animated animate__fadeInLeft"
              data-wow-delay="100ms"
            >
              <img src={AbuotUs} alt="MyOm Namo" />
              {/* <div className="about-two__image__text">
                <a
                  href="https://www.youtube.com/watch?v=h9MbznbxlLc"
                  className="video-popup"
                >
                  <i className="icofont-ui-play"></i>
                </a>
                Always Help Us!
              </div> */}
              <div
                className="about-two__image__left animate__animated animate__fadeInUp"
                data-wow-delay="200ms"
              >
                {/* <img src={About2} alt="MyOm Namo" /> */}
              </div>
              <div className="about-two__image__shape-one">
                <img src={About1Shape} alt="MyOm Namo" />
              </div>
              <div className="about-two__image__shape-two">
                <img src={About2Shape} alt="MyOm Namo" />
              </div>
            </div>
          </div>
          <div
            className="col-lg-6 animate__animated animate__fadeInRight"
            data-wow-delay="200ms"
          >
            <div className="about-two__content">
              <div className="sec-title text-left">
                <h6 className="sec-title__tagline bw-split-in-right d-flex align-items-center">
                  <img className=" tagline-img" src={batuk} alt="" />
                  {/* <span className="sec-title__tagline__border"></span>  */}
                  About us
                </h6>
                <h3 className="sec-title__title bw-split-in-left">
                  Your One-Stop Destination for Spiritual Services
                </h3>
              </div>
              <p
                className="about-two__content__text"
                style={{ color: "black", textAlign: "justify" }}
              >
                We specialize in providing a seamless and enriching spiritual
                experience through our diverse range of services, including
                pandit bookings, puja arrangements, horoscope readings, temple
                visit bookings, and an exclusive e-store for spiritual products.
                Whether you seek divine blessings, astrological insights, or
                sacred rituals, we ensure your spiritual needs are met with
                authenticity.
                {/* <br /> */}
              </p>
              <p
                className="about-two__content__text"
                style={{ color: "black", textAlign: "justify" }}
              >
                Our platform connects you with highly experienced pandits for
                various rituals, ensuring each ceremony is performed with
                dedication and adherence to traditional practices. From
                auspicious pujas for success, health, and prosperity to
                customized religious ceremonies, we handle every detail so you
                can focus on your devotion.
              </p>
              <a href="/about-us" className="careox-btn mt-3">
                <span>Read More</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUS;
