import { BaseUrl } from "../../BaseUrl/BaseUrl";
import {
  postApiWithAuthorization,
  getApiWithAuthorization,
} from "../ApiMethod/ApiMethod";

export const MyBookPanditCategoryAPI = async (user_id, cat_id) => {
  // const url = `${BaseUrl}/GetPoojaList?TYPE=All&USER_ID=${user_id}`;
  const url = `${BaseUrl}/GetPoojaList?TYPE=All`;
  const res = await getApiWithAuthorization(url);

  return res;
};

export const MyBookPanditPujaListAPI = async (user_id, catID, params) => {
  const url = `${BaseUrl}/GetPoojaList?TYPE=All&USER_ID=${user_id}&CAT_ID=${catID}`;
  const res = await postApiWithAuthorization(url, params);

  return res;
};

export const GetPujaBookingInvoiceAPI = async (user_id, pb_id) => {
  // const url = `${BaseUrl}/GetPoojaList?TYPE=All&USER_ID=${user_id}`;
  const url = `${BaseUrl}/PanditBookingInvoice?USER_ID=${user_id}&PB_ID=${pb_id}`;
  const res = await getApiWithAuthorization(url);

  return res;
};
