import React from "react";
import "./stepper.css";
import { useState } from "react";

const StepperForm = () => {
  const steps = ["First", "Second", "Third", "Fourth"];
  const [currentStep, setCurrentStep] = useState(0);

  const nextStep = () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
    }
  };

  const prevStep = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };
  return (
    <div className="mt-2">
      <div className="stepper-wrapper">
        {steps.map((step, index) => (
          <div
            key={index}
            className={`stepper-item ${
              index < currentStep
                ? "completed"
                : index === currentStep
                ? "active"
                : ""
            }`}
          >
            <div className="step-counter">{index + 1}</div>
            <div className="step-name">{step}</div>
          </div>
        ))}
      </div>
      <div className="buttons mt-4 flex justify-between">
        <button
          className="px-4 py-2 bg-gray-400 text-dark rounded disabled:opacity-50"
          onClick={prevStep}
          disabled={currentStep === 0}
        >
          Back
        </button>
        <button
          className="px-4 py-2 bg-blue-500 text-dark rounded disabled:opacity-50"
          onClick={nextStep}
          disabled={currentStep === steps.length - 1}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default StepperForm;
