import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { UserRegNewWithMobApi } from "../../ApiServices/My Profile/MyProfileApi";
import DatePicker from "react-date-picker";
import "react-calendar/dist/Calendar.css";
import "react-date-picker/dist/DatePicker.css";
import { ERROR_MESSAGES } from "../GlobalErrorMessage";
import { GetUserModel } from "../../ApiServices/Profile/ProfileApi";
import toast from "react-hot-toast";
const birthSigns = [
  { value: "aries", label: "Aries" },
  { value: "taurus", label: "Taurus" },
  { value: "gemini", label: "Gemini" },
  { value: "cancer", label: "Cancer" },
  { value: "leo", label: "Leo" },
  { value: "virgo", label: "Virgo" },
  { value: "libra", label: "Libra" },
  { value: "scorpio", label: "Scorpio" },
  { value: "sagittarius", label: "Sagittarius" },
  { value: "capricorn", label: "Capricorn" },
  { value: "aquarius", label: "Aquarius" },
  { value: "pisces", label: "Pisces" },
];

const MyProfile = () => {
  const [error, setError] = useState(null);

  const [machineOptions, setMachineOptions] = useState([]);
  const [errorMessage, setErrorMessage] = useState();
  const navigate = useNavigate();
  const [customerOptions, setCustomerOptions] = useState([]);
  const ItemAddedToast = () => toast.success("Profile Updated successfully");
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const [agreementObj, setAgreementObj] = useState({
    DOB: null,
    BIRTH_PLACE: null,
    BIRTH_TIME: null,
    GENDER: null,
    USER_NAME: null,
    ADDRESS: null,
    ZODIAC_SIGN: null,
    MOBILE_NUMBER: null,
  });

  useEffect(() => {
    GetUserModelData();
  }, []);
  const GetUserModelData = async () => {
    // setLoader(true);
    // debugger;
    const userID = JSON.parse(localStorage.getItem("userID"));
    try {
      const response = await GetUserModel(userID);
      if (response?.ResponseCode == 0) {
        // setLoader(false);

        const ModelData = response.DATA || []; // Assuming the data is an array and we need the first item

        setAgreementObj((prev) => ({
          ...prev,
          DOB: ModelData[0]?.DOB,
          BIRTH_PLACE: ModelData[0]?.BIRTH_PLACE,
          BIRTH_TIME: ModelData[0]?.BIRTH_TIME,
          GENDER: ModelData[0]?.GENDER,
          USER_NAME: ModelData[0]?.FULL_NAME,
          ADDRESS: ModelData[0]?.ADDRESS,
          ZODIAC_SIGN: ModelData[0]?.ZODIAC_SIGN,
          MOBILE_NUMBER: ModelData[0]?.MOBILE_NO,
        }));
      } else {
        // setLoader(false);

        console.error(
          "Error fetching data: ",
          response?.data?.data?.statusCode
        );
      }
    } catch (error) {
      // setLoader(false);

      console.error("Error", error);
    }
  };
  const AddUpdateCustomerBtnClick = () => {
    debugger;
    let isValid = false;

    if (
      agreementObj.DOB === null ||
      agreementObj.DOB === undefined ||
      agreementObj.DOB === "" ||
      agreementObj.BIRTH_PLACE === null ||
      agreementObj.BIRTH_PLACE === undefined ||
      agreementObj.BIRTH_PLACE === "" ||
      agreementObj.BIRTH_TIME === "" ||
      agreementObj.BIRTH_TIME === null ||
      agreementObj.BIRTH_TIME === undefined ||
      agreementObj.GENDER === "" ||
      agreementObj.GENDER === null ||
      agreementObj.GENDER === undefined ||
      agreementObj.USER_NAME === "" ||
      agreementObj.USER_NAME === null ||
      agreementObj.USER_NAME === undefined ||
      agreementObj.ADDRESS === null ||
      agreementObj.ADDRESS === undefined ||
      agreementObj.ADDRESS === "" ||
      agreementObj.ZODIAC_SIGN === null ||
      agreementObj.ZODIAC_SIGN === undefined ||
      agreementObj.ZODIAC_SIGN === "" ||
      agreementObj.MOBILE_NUMBER === null ||
      agreementObj.MOBILE_NUMBER === undefined ||
      agreementObj.MOBILE_NUMBER === "" ||
      agreementObj.MOBILE_NUMBER?.length < 10
    ) {
      setError(true);
      isValid = true;
    } else {
      setError(false);
      isValid = false;
    }

    const apiParam = {
      DOB: agreementObj.DOB,
      BIRTH_PLACE: agreementObj.BIRTH_PLACE,
      BIRTH_TIME: agreementObj.BIRTH_TIME,
      GENDER: agreementObj.GENDER,
      USER_NAME: agreementObj.USER_NAME,
      ADDRESS: agreementObj.ADDRESS,
      ZODIAC_SIGN: agreementObj.ZODIAC_SIGN,
      MOBILE_NUMBER: agreementObj.MOBILE_NUMBER,
    };
    // return isValid;
    if (!isValid) {
      AddUpdateAgreementData(apiParam);
    }
  };

  const AddUpdateAgreementData = async (apiParam) => {
    const userID = localStorage.getItem("userID") || "";

    try {
      let url = `/UserRegNewWithMob?USER_ID=${userID}`; // Default URL for Adding Data

      const response = await UserRegNewWithMobApi(url, apiParam);
      if (response) {
        if (response?.ResponseCode == 0) {
          //   setSuccessDone(true);
          ItemAddedToast();
        } else {
          console.log("api not call");
          setErrorMessage(response?.response?.data?.errorMessage);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleDateChange = (date) => {
    setAgreementObj((prevState) => ({
      ...prevState,
      DOB: date, // Update state with selected date
    }));
  };

  return (
    <>
      <section className="page-header">
        <div className="page-header__bg"></div>
        <div className="container">
          <h2 className="page-header__title bw-split-in-left">My Profile</h2>
        </div>
      </section>
      <div
        className=""
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          marginTop: "2rem",
        }}
      >
        <div
          style={{
            border: "1px solid #e0e0e0",
            boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.1)",
            borderRadius: "12px",
            maxWidth: "90vw",
            width: "100%",
            backgroundColor: "#fff",
            padding: "20px",
          }}
          className="card"
        >
          <div className="card-body">
            <h3 className="text-center mb-4" style={{ color: "#a52444" }}>
              User Profile
            </h3>

            {/* User Name & Mobile Number */}
            <div className="row gy-3">
              <div className="col-md-6">
                <label className="form-label fw-semibold">
                  User Name <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control rounded-3"
                  placeholder="Enter User Name"
                  value={agreementObj.USER_NAME}
                  onChange={(e) =>
                    setAgreementObj((obj) => ({
                      ...obj,
                      USER_NAME: e.target.value,
                    }))
                  }
                />
                {(error && agreementObj.USER_NAME === null) ||
                agreementObj.USER_NAME === undefined ||
                agreementObj.USER_NAME === "" ? (
                  <span className="text-danger">This field is required</span>
                ) : (
                  ""
                )}
              </div>

              <div className="col-md-6">
                <label className="form-label fw-semibold">
                  Contact No. <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control rounded-3"
                  maxLength={10}
                  placeholder="Enter Contact Number"
                  value={agreementObj.MOBILE_NUMBER}
                  onChange={(e) => {
                    setErrorMessage("");
                    let formattedNumber = e.target.value.replace(/[^0-9]/g, "");
                    formattedNumber = formattedNumber.replace(/^[0-5]/, "");
                    setAgreementObj((prev) => ({
                      ...prev,
                      MOBILE_NUMBER: formattedNumber,
                    }));
                  }}
                />
                {(error && agreementObj.MOBILE_NUMBER === null) ||
                agreementObj.MOBILE_NUMBER === undefined ||
                agreementObj.MOBILE_NUMBER === "" ? (
                  <span className="text-danger">This field is required</span>
                ) : (
                  ""
                )}
              </div>
            </div>

            {/* Gender & DOB */}
            <div className="row gy-3">
              <div className="col-md-6">
                <label className="form-label fw-semibold">
                  Gender <span className="text-danger">*</span>
                </label>
                <div className="d-flex gap-3">
                  <div>
                    <input
                      type="radio"
                      name="gender"
                      value="Male"
                      checked={agreementObj.GENDER === "Male"}
                      onChange={(e) =>
                        setAgreementObj((obj) => ({
                          ...obj,
                          GENDER: e.target.value,
                        }))
                      }
                    />
                    <span className="ms-2">Male</span>
                  </div>
                  <div>
                    <input
                      type="radio"
                      name="gender"
                      value="Female"
                      checked={agreementObj.GENDER === "Female"}
                      onChange={(e) =>
                        setAgreementObj((obj) => ({
                          ...obj,
                          GENDER: e.target.value,
                        }))
                      }
                    />
                    <span className="ms-2">Female</span>
                  </div>
                  {(error && agreementObj.GENDER === null) ||
                  agreementObj.GENDER === undefined ||
                  agreementObj.GENDER === "" ? (
                    <span className="text-danger">This field is required</span>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <div className="col-md-6 d-flex flex-column">
                <label className="form-label fw-semibold">
                  Date Of Birth <span className="text-danger">*</span>
                </label>
                <DatePicker
                  value={agreementObj?.DOB}
                  onChange={handleDateChange}
                  clearIcon={null}
                  popperPlacement="bottom-end"
                  format="dd-MM-yyyy"
                  // className="form-control"
                />
                {(error && agreementObj.DOB === null) ||
                agreementObj.DOB === undefined ||
                agreementObj.DOB === "" ? (
                  <span className="text-danger">This field is required</span>
                ) : (
                  ""
                )}
              </div>
            </div>

            {/* Birth Details */}
            <div className="row gy-3">
              <div className="col-md-6">
                <label className="form-label fw-semibold">
                  Birth Place <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control rounded-3"
                  placeholder="Enter Birth Place"
                  value={agreementObj.BIRTH_PLACE}
                  onChange={(e) =>
                    setAgreementObj((obj) => ({
                      ...obj,
                      BIRTH_PLACE: e.target.value,
                    }))
                  }
                />
                {(error && agreementObj.BIRTH_PLACE === null) ||
                agreementObj.BIRTH_PLACE === undefined ||
                agreementObj.BIRTH_PLACE === "" ? (
                  <span className="text-danger">This field is required</span>
                ) : (
                  ""
                )}
              </div>

              <div className="col-md-6">
                <label className="form-label fw-semibold">
                  Birth Time <span className="text-danger">*</span>
                </label>
                <input
                  type="time"
                  className="form-control rounded-3"
                  value={agreementObj.BIRTH_TIME}
                  onChange={(e) =>
                    setAgreementObj((obj) => ({
                      ...obj,
                      BIRTH_TIME: e.target.value,
                    }))
                  }
                />
                {(error && agreementObj.BIRTH_TIME === null) ||
                agreementObj.BIRTH_TIME === undefined ||
                agreementObj.BIRTH_TIME === "" ? (
                  <span className="text-danger">This field is required</span>
                ) : (
                  ""
                )}
              </div>
            </div>

            {/* Zodiac Sign & Address */}
            <div className="row gy-3">
              <div className="col-md-6">
                <label className="form-label fw-semibold">
                  Zodiac Sign / Birth Sign{" "}
                  <span className="text-danger">*</span>
                </label>
                <Select
                  options={birthSigns}
                  placeholder="Select Sign"
                  value={birthSigns.find(
                    (option) => option.value === agreementObj.ZODIAC_SIGN
                  )}
                  onChange={(selectedOption) =>
                    setAgreementObj((prev) => ({
                      ...prev,
                      ZODIAC_SIGN: selectedOption.value,
                    }))
                  }
                />
                {(error && agreementObj.ZODIAC_SIGN === null) ||
                agreementObj.ZODIAC_SIGN === undefined ||
                agreementObj.ZODIAC_SIGN === "" ? (
                  <span className="text-danger">This field is required</span>
                ) : (
                  ""
                )}
              </div>

              <div className="col-md-6">
                <label className="form-label fw-semibold">
                  Address <span className="text-danger">*</span>
                </label>
                <textarea
                  className="form-control rounded-3"
                  placeholder="Enter Address"
                  value={agreementObj.ADDRESS}
                  onChange={(e) =>
                    setAgreementObj((obj) => ({
                      ...obj,
                      ADDRESS: e.target.value,
                    }))
                  }
                  rows="3"
                />
                {(error && agreementObj.ADDRESS === null) ||
                agreementObj.ADDRESS === undefined ||
                agreementObj.ADDRESS === "" ? (
                  <span className="text-danger">This field is required</span>
                ) : (
                  ""
                )}
              </div>
            </div>

            {/* Submit Button */}
            <div className="d-flex justify-content-end mt-4">
              <button
                className="btn px-4 py-2"
                style={{
                  backgroundColor: "#a52444",
                  color: "white",
                  fontWeight: "600",
                }}
                onClick={AddUpdateCustomerBtnClick}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyProfile;
