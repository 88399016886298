import React, { useEffect, useState } from "react";
import About1 from "../../assets/images/about1.png";
import About2 from "../../assets/images/about2.png";
import Trishul from "../../assets/images/trishul.png";
import Icon from "../../assets/images/omnamo/icon.png";
import Swastik from "../../assets/images/swastik.png";
import {
  Link,
  useLocation,
  useParams,
  useSearchParams,
} from "react-router-dom";
import shape1 from "../../assets/images/shapes/about-1-shape-2.png";
import OwlCarousel from "react-owl-carousel3";
import shape2 from "../../assets/images/shapes/service-bg-shape-2-rtl.png";
import FAQSection from "../../Components/FAQSection/FAQ";
import TestimonialImg1 from "../../assets/images/resources/testi-1-1.jpg";
import TestimonialImg2 from "../../assets/images/resources/testi-1-2.jpg";
import TestimonialImg3 from "../../assets/images/resources/testi-1-3.jpg";
import TestimonialImg4 from "../../assets/images/resources/testi-1-4.jpg";
import DailyPooja from "../../assets/images/new/DailyPooja.jpg";
import Ecart from "../../assets/images/new/e-cart.jpg";
import Horoscope from "../../assets/images/new/Horoscope.jpg";
import panditBooking from "../../assets/images/new/panditBooking.jpg";
import poojaAtTemple from "../../assets/images/new/poojaAtTemple.jpg";
import templeBooking from "../../assets/images/new/templeBooking.jpg";
import batuk from "../../assets/images/new/fav-removebg-preview.png";
import ExitIntentModal from "./ExitIntentModal";
import { Helmet } from "react-helmet-async";
import PujaBookingBannerImg from "../../assets/images/new/PoojaBookingbannerImg.png";
import EstoreBannerImg from "../../assets/images/new/E-Storebanner.png";
import HoroscopeBannerImg from "../../assets/images/new/HoroscopeBanner.png";
import PujaAtTempleBannerImg from "../../assets/images/new/PujaAtTempleBannerImg.png";
import DailyPujaBookingBannerImg from "../../assets/images/new/DailyPoojaBookingBannerImg.png";
import DarshanBookingBannerImg from "../../assets/images/new/DarshanBookingBannerImg.png";

const data = [
  {
    headerName: "Puja Booking Service",
    Imgabout1: About1,
    Imgabout2: panditBooking,
    tagLine: "Embrace Divinity with Temple Pujas",
    route: "/services/book-puja",
    serviceBannerImg: PujaBookingBannerImg,
    sectionTitle: "Book an Experienced Pandit for Your Puja",
    serviceName: "puja-booking",
    howItWorksMainHeading: "Effortless Path to Divine Blessings",
    howItWorks: [
      {
        howItWorksTitle: "Select Your Ceremony",
        howItWorksDesc:
          "Choose the ritual you want to perform- a wedding, housewarming, naming ceremony, or any special puja. ",
      },
      {
        howItWorksTitle: "Book Your Puja",
        howItWorksDesc:
          "Our expert pandits ensure each ritual is conducted with care, devotion, and attention to sacred details. ",
      },
      {
        howItWorksTitle: "Embrace Sacred Vibes",
        howItWorksDesc:
          "Pandit leads your ceremony with care. Feel the divine energy, connect with your faith, and receive health, happiness, and prosperity blessings. ",
      },
    ],
    testimonials: [
      {
        Desc: "I booked a Satyanarayan Puja through My OmNamo, and everything was perfectly arranged. The pandit was knowledgeable, punctual, and performed the rituals with devotion. Highly recommended.",
        name: "Rajesh Singh.",
        location: "Delhi",
      },
      {
        Desc: " My Griha Pravesh Puja was conducted beautifully. The pandit arrived on time with all the required materials. The entire process was smooth and blessed. Thank you, My OmNamo.",
        name: "Priya Mane",
        location: "Nashik",
      },
      {
        Desc: "I needed a Navagraha Shanti puja, and My OmNamo made it so easy! The chanting, rituals, and arrangements were all done perfectly. Truly a divine experience.",
        name: "Amit Kapoor",
        location: "Mumbai",
      },
    ],
    FaqObj: [
      {
        question: "What types of puja’s can I book?",
        answer:
          "You can book a variety of rituals, including Ganpati puja, Navratri Aarti, Lakshmi puja, and more.",
        color: "#ffa415",
      },
      {
        question: "Can I book recurring pujas?",
        answer:
          "Absolutely! You can schedule daily, weekly, or multi-day rituals as per your needs.",
        color: "#fc5528",
      },
      {
        question: "Are the pandits experienced and qualified?",
        answer:
          "Yes, all our pandits are experienced, well-trained, and follow proper Vedic rituals.",
        color: "#8139e7",
      },
      {
        question: "Can I book a puja for a temple or home location?",
        answer:
          "Yes, you can book a puja for your home, temple, or any preferred location.",
        color: "#8139e7",
      },
      {
        question: "Do you offer online puja services?",
        answer:
          "Yes, we offer virtual pujas where pandits perform rituals online.",
        color: "#8139e7",
      },
      // {
      //   question: "Why is it important How Volunteer?",
      //   answer:
      //     "There are many variations of passages the majority have suffered alteration in some form injected humour, or randomised words.",
      //   color: "#399be7",
      // },
    ],
    metaTitle: "Book Puja Online - Experienced Pandits for All Ceremonies",
    metaDescription:
      "Book a pandit for weddings, housewarming, or any puja. Ensure sacred rituals are performed with care, devotion, and tradition. Start your booking now!",
    cannonicalLink: "https://www.myomnamo.com/services/puja-booking",
    breadcrumbSchema: {
      "@context": "https://schema.org/",
      "@type": "BreadcrumbList",
      itemListElement: [
        {
          "@type": "ListItem",
          position: 1,
          name: "Home",
          item: "https://www.myomnamo.com/",
        },
        {
          "@type": "ListItem",
          position: 2,
          name: "Puja Booking",
          item: "https://www.myomnamo.com/services/puja-booking",
        },
      ],
    },

    webpageSchema: {
      "@context": "http://schema.org",
      "@type": "WebPage",
      name: "Book Puja Online - Experienced Pandits for All Ceremonies",
      url: "https://www.myomnamo.com/services/puja-booking",
      description:
        "Book a pandit for weddings, housewarming, or any puja. Ensure sacred rituals are performed with care, devotion, and tradition. Start your booking now!",
      publisher: {
        "@type": "Organization",
        name: "MyOmNamo",
      },
    },

    faqSchema: {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      mainEntity: [
        {
          "@type": "Question",
          name: "What types of puja’s can I book?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "You can book a variety of rituals, including Ganpati puja, Navratri Aarti, Lakshmi puja, and more.",
          },
        },
        {
          "@type": "Question",
          name: "Can I book recurring pujas?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "Absolutely! You can schedule daily, weekly, or multi-day rituals as per your needs.",
          },
        },
        {
          "@type": "Question",
          name: "Are the pandits experienced and qualified?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "Yes, all our pandits are experienced, well-trained, and follow proper Vedic rituals.",
          },
        },
        {
          "@type": "Question",
          name: "Can I book a puja for a temple or home location?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "Yes, you can book a puja for your home, temple, or any preferred location.",
          },
        },
        {
          "@type": "Question",
          name: "Do you offer online puja services?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "Yes, we offer virtual pujas where pandits perform rituals online.",
          },
        },
      ],
    },
    metaData: {
      title: "Book Puja Online - Experienced Pandits for All Ceremonies",
      description:
        "Book a pandit for weddings, housewarming, or any puja. Ensure sacred rituals are performed with care, devotion, and tradition. Start your booking now!",
      image: "https://www.myomnamo.com/assets/images/new/logo.png",
      url: "https://www.myomnamo.com/services/puja-booking",
      siteName: "MyOmNamo",
      locale: "en_IN",
    },
    mainContent:
      "Make your special moments sacred with the guidance of an experienced pandit. Whether it’s a wedding, housewarming, naming ceremony, or any puja, a pandit ensures each ritual is done with care and devotion. Their knowledge of traditions brings peace and positivity to your home or event. From small home ceremonies to grand events, we bring positivity and tradition to your gathering. Performing rituals, helps you connect with your faith and look for blessings for health, happiness, and prosperity. Make every ceremony special and memorable with an experienced pandit who guides each step with care and kindness.",
  },
  {
    headerName: "Daily Puja Booking Service",
    Imgabout1: About1,
    Imgabout2: DailyPooja,
    tagLine: "Embrace Divinity with Temple Pujas",
    sectionTitle: "Book a Pandit for Daily Puja at Your Convenience",
    serviceBannerImg: DailyPujaBookingBannerImg,
    serviceName: "daily-puja-booking",
    howItWorksMainHeading: "Book a Pandit for Daily Puja at Your Convenience",
    howItWorks: [
      {
        howItWorksTitle: "Choose Your Ritual",
        howItWorksDesc:
          "Whether it's Ganpati Puja, Navratri Aarti, or a simple morning prayer, select the service you need. We’ll connect you with an experienced pandit. ",
      },
      {
        howItWorksTitle: "Schedule Your Time",
        howItWorksDesc:
          "Pick a date and time that fits your routine. Pandits are available every morning and evening, so you can schedule rituals at your convenience. ",
      },
      {
        howItWorksTitle: "Experience Divine Blessings",
        howItWorksDesc:
          "Your dedicated pandit arrives with all the necessary items for the Puja. Feel peace, positivity, and divine energy fill your space.",
      },
    ],
    testimonials: [
      {
        Desc: " I booked a daily Ganesh Puja, and it has brought so much positivity to my home. The pandit performs the rituals with devotion, and everything is well-organized. Truly grateful.",
        name: "Anjali Rajput",
        location: "Mumbai",
      },
      {
        Desc: " My family wanted a daily Shiv Abhishek, and My OmNamo made it effortless. The pandit is punctual, knowledgeable, and follows proper Vedic traditions. Highly recommended.",
        name: "Suresh Kale",
        location: "Nashik",
      },
      {
        Desc: "I opted for a monthly Lakshmi Puja service, and the experience has been wonderful. The prayers are performed with sincerity, bringing peace and prosperity to our home.",
        name: "Meera Savant",
        location: "Bangalore",
      },
    ],
    FaqObj: [
      {
        question: "What is a daily puja booking service?",
        answer:
          "It allows you to book a pandit for performing daily pujas at your home or temple.",
        color: "#ffa415",
      },
      {
        question: "How do I book a pandit for my ritual?",
        answer:
          "You can easily book a pandit online by selecting your ceremony, choosing a date, and confirming your booking.",
        color: "#fc5528",
      },
      {
        question: "Can I choose a specific pandit?",
        answer:
          "Yes, you can choose a pandit based on availability and your preferred language or tradition.",
        color: "#8139e7",
      },
      {
        question: "Can I schedule pujas at a fixed time daily?",
        answer:
          "Yes, you can set a fixed time for your daily puja at your convenience.",
        color: "#8139e7",
      },
      {
        question: "Can I book a virtual daily puja?",
        answer: "Yes, we offer online pujas where pandits perform rituals.",
        color: "#8139e7",
      },
      // {
      //   question: "Why is it important How Volunteer?",
      //   answer:
      //     "There are many variations of passages the majority have suffered alteration in some form injected humour, or randomised words.",
      //   color: "#399be7",
      // },
    ],
    route: "/services/daily-booking",
    metaTitle:
      "Daily Puja Booking Online - Trusted Pandits Morning & Evening Rituals",
    metaDescription:
      "Book an experienced pandit for your daily puja. Choose your ritual, pick a time, and welcome divine blessings to your home easily. Book now for morning or evening.",
    cannonicalLink: "https://www.myomnamo.com/services/daily-puja-booking",
    breadcrumbSchema: {
      "@context": "https://schema.org/",
      "@type": "BreadcrumbList",
      itemListElement: [
        {
          "@type": "ListItem",
          position: 1,
          name: "Home",
          item: "https://www.myomnamo.com/",
        },
        {
          "@type": "ListItem",
          position: 2,
          name: "Daily Puja Booking",
          item: "https://www.myomnamo.com/services/daily-puja-booking",
        },
      ],
    },
    webpageSchema: {
      "@context": "http://schema.org",
      "@type": "WebPage",
      name: "Daily Puja Booking Online - Trusted Pandits Morning & Evening Rituals",
      url: "https://www.myomnamo.com/services/daily-puja-booking",
      description:
        "Book an experienced pandit for your daily puja. Choose your ritual, pick a time, and welcome divine blessings to your home easily. Book now for morning or evening.",
      publisher: {
        "@type": "Organization",
        name: "MyOmNamo",
      },
    },
    faqSchema: {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      mainEntity: [
        {
          "@type": "Question",
          name: "What is a daily puja booking service?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "It allows you to book a pandit for performing daily pujas at your home or temple.",
          },
        },
        {
          "@type": "Question",
          name: "How do I book a pandit for my ritual?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "You can easily book a pandit online by selecting your ceremony, choosing a date, and confirming your booking.",
          },
        },
        {
          "@type": "Question",
          name: "Can I choose a specific pandit?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "Yes, you can choose a pandit based on availability and your preferred language or tradition.",
          },
        },
        {
          "@type": "Question",
          name: "Can I schedule pujas at a fixed time daily?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "Yes, you can set a fixed time for your daily puja at your convenience.",
          },
        },
        {
          "@type": "Question",
          name: "Can I book a virtual daily puja?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "Yes, we offer online pujas where pandits perform rituals.",
          },
        },
      ],
    },
    metaData: {
      title:
        "Daily Puja Booking Online - Trusted Pandits Morning & Evening Rituals",
      description:
        "Book an experienced pandit for your daily puja. Choose your ritual, pick a time, and welcome divine blessings to your home easily. Book now for morning or evening.",
      image: "https://www.myomnamo.com/assets/images/new/logo.png",
      url: "https://www.myomnamo.com/services/daily-puja-booking",
      siteName: "MyOmNamo",
      locale: "en_IN",
    },
    mainContent:
      "Looking for a pandit to perform daily pujas at home or at your event? Experienced pandits are available every morning and evening to guide you through your spiritual experience with care and devotion. Whether it's a 10-day Ganpati puja, a Navratri aarti, or any multi-day ceremony, we handle each step with accuracy and respect for tradition. From chanting mantras to performing aartis, we ensure your rituals are done properly. Bring peace, positivity, and divine blessings to your space every day. Experience divine blessings every day with a trusted pandit guiding your rituals.",
  },
  {
    headerName: "Online Pooja Samagri Store",
    Imgabout1: About1,
    Imgabout2: Ecart,
    tagLine: "Embrace Divinity with Temple Pujas",
    serviceBannerImg: EstoreBannerImg,
    route: "/my-store",
    serviceName: "e-store",
    howItWorksMainHeading: "Puja Shopping Made Simple With E-store",
    howItWorks: [
      {
        howItWorksTitle: "Browse & Select",
        howItWorksDesc:
          "Explore our wide range of puja samagri. Choose puja samgari or complete puja kits customised to your spiritual needs. ",
      },
      {
        howItWorksTitle: "Order & Pay",
        howItWorksDesc:
          "Add products to your cart, securely check out, and choose convenient payment options for a smooth shopping experience. ",
      },
      {
        howItWorksTitle: "Receive & Worship",
        howItWorksDesc:
          "Get your order delivered to your door. Perform your rituals peacefully with everything you need ready and sacred.",
      },
    ],
    testimonials: [
      {
        Desc: " I ordered a Lakshmi idol and Rudraksha mala, and the quality was amazing. The items were well-packed and delivered on time. Truly satisfied.",
        name: "Pooja More.",
        location: "Pune",
      },
      {
        Desc: " I purchased a complete puja kit, and it made my rituals so convenient. Everything was authentic and spiritually blessed. Highly recommend.",
        name: "Vikas Rathi",
        location: "Bangalore",
      },
      {
        Desc: "I bought incense sticks and a shankha from the E-Store. The delivery was quick, and the packaging was perfect. Will shop again.",
        name: "Meenal Shinde.",
        location: "Jaipur",
      },
    ],
    FaqObj: [
      {
        question: "What products do you offer?",
        answer:
          "We provide puja samagri, complete kits, idols, incense, flowers, and everything needed for daily rituals or special ceremonies.",
        color: "#ffa415",
      },
      {
        question: "Do you offer fast delivery?",
        answer:
          "Yes! We ensure quick and reliable delivery so you get your items on time for your puja or festival.",
        color: "#fc5528",
      },
      {
        question: "Are the products authentic and blessed?",
        answer:
          "Yes, our products are sourced from trusted vendors and many items are spiritually blessed.",
        color: "#8139e7",
      },
    ],
    sectionTitle: "Your One-Stop Online E-Store",
    metaTitle: "Online Pooja Samagri Store - Buy Pooja Items at MyOmNamo",
    metaDescription:
      "Online shopping for authentic Pooja samagri and pooja items at MyOmNamo. Explore a wide range of spiritual products for all your rituals and ceremonies. Fast delivery and trusted quality.",
    cannonicalLink: "https://www.myomnamo.com/services/e-store",
    breadcrumbSchema: {
      "@context": "https://schema.org/",
      "@type": "BreadcrumbList",
      itemListElement: [
        {
          "@type": "ListItem",
          position: 1,
          name: "Home",
          item: "https://www.myomnamo.com/",
        },
        {
          "@type": "ListItem",
          position: 2,
          name: "Pooja Samagri Store",
          item: "https://www.myomnamo.com/services/e-store",
        },
      ],
    },
    webpageSchema: {
      "@context": "http://schema.org",
      "@type": "WebPage",
      name: "Online Pooja Samagri Store - Buy Pooja Items at MyOmNamo",
      url: "https://www.myomnamo.com/services/e-store",
      description:
        "Online shopping for authentic Pooja samagri and pooja items at MyOmNamo. Explore a wide range of spiritual products for all your rituals and ceremonies. Fast delivery and trusted quality.",
      publisher: {
        "@type": "Organization",
        name: "MyOmNamo",
      },
    },
    faqSchema: {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      mainEntity: [
        {
          "@type": "Question",
          name: "What products do you offer?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "We provide puja samagri, complete kits, idols, incense, flowers, and everything needed for daily rituals or special ceremonies.",
          },
        },
        {
          "@type": "Question",
          name: "Do you offer fast delivery?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "Yes! We ensure quick and reliable delivery so you get your items on time for your puja or festival.",
          },
        },
        {
          "@type": "Question",
          name: "Are the products authentic and blessed?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "Yes, our products are sourced from trusted vendors and many items are spiritually blessed.",
          },
        },
      ],
    },
    metaData: {
      title: "Online Pooja Samagri Store - Buy Pooja Items at MyOmNamo",
      description:
        "Online shopping for authentic Pooja samagri and pooja items at MyOmNamo. Explore a wide range of spiritual products for all your rituals and ceremonies. Fast delivery and trusted quality.",
      image: "https://www.myomnamo.com/assets/images/new/logo.png",
      url: "https://www.myomnamo.com/services/e-store",
      siteName: "MyOmNamo",
      locale: "en_IN",
    },

    mainContent:
      "Shop for all your puja needs from home. Get puja samagri, complete puja kits, idols, incense, and more. Whether it's for daily pujas or special festivals, have everything ready for your rituals. No need to visit multiple shops, just browse and get items delivered to your door.  Make every ceremony meaningful with the right products, from sacred flowers to holy offerings. Celebrate traditions easily, knowing you have everything needed for a peaceful and complete puja experience. Keep your devotion strong with the right products for every spiritual occasion.",
  },
  {
    headerName: "Online Darshan Booking",
    Imgabout1: About1,
    Imgabout2: templeBooking,
    tagLine: "Embrace Divinity with Temple Pujas",
    sectionTitle: "Easy Temple Darshan Booking Online",
    serviceBannerImg: DarshanBookingBannerImg,
    serviceName: "darshan-booking",
    cannonicalLink: "https://www.myomnamo.com/services/darshan-booking",
    route: "/darshan-booking",
    howItWorksMainHeading: "Simple & Time-Saving Darshan Booking",
    howItWorks: [
      {
        howItWorksTitle: "Choose Your Temple",
        howItWorksDesc:
          "Select your temple and preferred date for darshan. Plan your visit effortlessly, even during peak times. ",
      },
      {
        howItWorksTitle: "Book Your Slot",
        howItWorksDesc:
          "Reserve your darshan slot online in minutes. Avoid long queues and secure a peaceful, hassle-free temple experience.",
      },
      {
        howItWorksTitle: "Get Confirmation",
        howItWorksDesc:
          "Receive instant booking confirmation via email or SMS. Show your details at the temple and enjoy your visit stress-free.",
      },
    ],
    testimonials: [
      {
        Desc: " Booking temple darshan online saved me so much time! No long queues and the process was smooth. Truly a divine experience.",
        name: "Rohit Pawar.",
        location: "Yeola",
      },
      {
        Desc: "I booked a VIP darshan for my family, and everything went perfectly. The confirmation was instant, and we had a peaceful visit.",
        name: "Neha Tile",
        location: "Nagpur",
      },
      {
        Desc: "This service made temple visits stress-free. I booked my darshan in advance and had a smooth entry without any hassle. Highly recommended.",
        name: "Amit Patil",
        location: "Jaipur",
      },
    ],
    FaqObj: [
      {
        question: "What is the Darshan Booking service?",
        answer:
          "Our service allows you to book temple darshan online for a hassle-free spiritual experience.",
        color: "#ffa415",
      },
      {
        question: "Can I book darshan for multiple people?",
        answer:
          "Yes, you can book darshan for yourself, your family, or your friends in one go. Just add their details during booking.",
        color: "#fc5528",
      },
      {
        question: "Is online booking available for special events?",
        answer:
          "Yes, you can book darshan for festivals, special pujas, or daily visits — even during peak times.",
        color: "#8139e7",
      },
      {
        question: "Are VIP or special darshan tickets available?",
        answer:
          "Yes, we offer VIP or express darshan bookings at select temples.",
        color: "#8139e7",
      },
    ],
    metaTitle:
      "Online Temple Darshan Booking - Plan Your Visit Now with MyOmNamo",
    metaDescription:
      "Book temple darshan online and avoid waiting. Secure your slot, receive instant confirmation, and focus on your prayers. Start your booking now!",
    canonicalLink: "https://www.myomnamo.com/services/darshan-booking",
    breadcrumbSchema: {
      "@context": "https://schema.org/",
      "@type": "BreadcrumbList",
      itemListElement: [
        {
          "@type": "ListItem",
          position: 1,
          name: "Home",
          item: "https://www.myomnamo.com/",
        },
        {
          "@type": "ListItem",
          position: 2,
          name: "Darshan Booking",
          item: "https://www.myomnamo.com/services/darshan-booking",
        },
      ],
    },
    webpageSchema: {
      "@context": "http://schema.org",
      "@type": "WebPage",
      name: "Online Temple Darshan Booking - Plan Your Visit Now with MyOmNamo",
      url: "https://www.myomnamo.com/services/darshan-booking",
      description:
        "Book temple darshan online and avoid waiting. Secure your slot, receive instant confirmation, and focus on your prayers. Start your booking now!",
      publisher: {
        "@type": "Organization",
        name: "MyOmNamo",
      },
    },
    faqSchema: {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      mainEntity: [
        {
          "@type": "Question",
          name: "What is the Darshan Booking service?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "Our service allows you to book temple darshan online for a hassle-free spiritual experience.",
          },
        },
        {
          "@type": "Question",
          name: "Can I book darshan for multiple people?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "Yes, you can book darshan for yourself, your family, or your friends in one go. Just add their details during booking.",
          },
        },
        {
          "@type": "Question",
          name: "Is online booking available for special events?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "Yes, you can book darshan for festivals, special pujas, or daily visits — even during peak times.",
          },
        },
        {
          "@type": "Question",
          name: "Are VIP or special darshan tickets available?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "Yes, we offer VIP or express darshan bookings at select temples.",
          },
        },
      ],
    },
    metaData: {
      title:
        "Online Temple Darshan Booking - Plan Your Visit Now with MyOmNamo",
      description:
        "Book temple darshan online and avoid waiting. Secure your slot, receive instant confirmation, and focus on your prayers. Start your booking now!",
      image: "https://www.myomnamo.com/assets/images/new/logo.png",
      url: "https://www.myomnamo.com/services/darshan-booking",
      siteName: "MyOmNamo",
      locale: "en_IN",
    },
    mainContent:
      "Plan your temple visit easily! Book your darshan in advance and skip the long queues. Whether you are visiting for a festival, special puja, or daily prayers, secure your spot in just a few steps. Enjoy a peaceful visit, offer your prayers, and look for blessings without the stress of waiting. Ideal for families, elderly devotees, or anyone wanting a smooth experience. Focus on your devotion and fully immerse yourself in the sacred atmosphere. Booking your darshan makes your visit more organized, giving you more time for prayer and reflection.",
  },
  {
    headerName: "Temple Puja Booking",
    Imgabout1: About1,
    Imgabout2: poojaAtTemple,
    tagLine: "Embrace Divinity with Temple Pujas",
    serviceBannerImg: PujaAtTempleBannerImg,
    route: "/pooja-at-temple",
    cannonicalLink: "https://www.myomnamo.com/services/temple-puja-booking",
    serviceName: "temple-puja",
    sectionTitle: "Perform a Sacred Puja at the Temple",
    howItWorksMainHeading: "Receive Sacred Blessings",
    howItWorks: [
      {
        howItWorksTitle: "Choose Your Puja",
        howItWorksDesc:
          "Choose from simple offerings or elaborate ceremonies, and personalize rituals for your spiritual intentions.  ",
      },
      {
        howItWorksTitle: "Book Effortlessly",
        howItWorksDesc:
          "From gathering offerings to preparing the sacred space, we ensure everything is ready so you can focus on your prayers without any worries.",
      },
      {
        howItWorksTitle: "Experience Divinity",
        howItWorksDesc:
          "Participate in sacred rituals, chant mantras, and receive blessings for positivity and well-being.",
      },
    ],
    testimonials: [
      {
        Desc: " I booked a puja at the temple, and everything was perfectly arranged. The rituals were performed beautifully, and I felt truly blessed.",
        name: "Anjali Raut",
        location: "Kolhapur",
      },
      {
        Desc: "Booking a temple puja online was so convenient. No long waits, everything was well-organized, and the prasad delivery was a lovely touch",
        name: "Vikas Mane",
        location: "Delhi",
      },
      {
        Desc: "The puja was conducted exactly as promised, with complete devotion and care. It was a peaceful and divine experience. Highly recommended.",
        name: "Priya Kadam",
        location: "Vashi",
      },
    ],
    FaqObj: [
      {
        question: "What is the Puja at Temple service?",
        answer:
          "Our service allows you to book a temple puja online, ensuring a smooth and hassle-free experience.",
        color: "#ffa415",
      },
      {
        question: "How do I book a puja?",
        answer:
          "You can book online or at the temple. Choose the puja type, date, and time.",
        color: "#fc5528",
      },
      {
        question: "What types of pujas are available?",
        answer:
          "We offer pujas for birthdays, anniversaries, festivals, personal prayers, and special ceremonies.",
        color: "#8139e7",
      },
      {
        question: "Are special or private pujas available?",
        answer:
          "Yes, some temples offer private pujas with personalized rituals.",
        color: "#8139e7",
      },
    ],
    metaTitle: "Book Temple Puja Online - Sacred Rituals for Every Occasion",
    metaDescription:
      "Book a temple puja for birthdays, anniversaries, or festivals. Experience sacred rituals with care and devotion. Focus on prayers while we handle the rest.",
    canonicalLink: "https://www.myomnamo.com/services/temple-puja-booking",
    breadcrumbSchema: {
      "@context": "https://schema.org/",
      "@type": "BreadcrumbList",
      itemListElement: [
        {
          "@type": "ListItem",
          position: 1,
          name: "Home",
          item: "https://www.myomnamo.com/",
        },
        {
          "@type": "ListItem",
          position: 2,
          name: "Temple Puja",
          item: "https://www.myomnamo.com/services/temple-puja-booking",
        },
      ],
    },
    webpageSchema: {
      "@context": "http://schema.org",
      "@type": "WebPage",
      name: "Book Temple Puja Online - Sacred Rituals for Every Occasion",
      url: "https://www.myomnamo.com/services/temple-puja-booking",
      description:
        "Book a temple puja for birthdays, anniversaries, or festivals. Experience sacred rituals with care and devotion. Focus on prayers while we handle the rest.",
      publisher: {
        "@type": "Organization",
        name: "MyOmNamo",
      },
    },
    faqSchema: {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      mainEntity: [
        {
          "@type": "Question",
          name: "What is the Puja at Temple service?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "Our service allows you to book a temple puja online, ensuring a smooth and hassle-free experience.",
          },
        },
        {
          "@type": "Question",
          name: "How do I book a puja?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "You can book online or at the temple. Choose the puja type, date, and time.",
          },
        },
        {
          "@type": "Question",
          name: "What types of pujas are available?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "We offer pujas for birthdays, anniversaries, festivals, personal prayers, and special ceremonies.",
          },
        },
        {
          "@type": "Question",
          name: "Are special or private pujas available?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "Yes, some temples offer private pujas with personalized rituals.",
          },
        },
      ],
    },
    metaData: {
      title: "Book Temple Puja Online - Sacred Rituals for Every Occasion",
      description:
        "Book a temple puja for birthdays, anniversaries, or festivals. Experience sacred rituals with care and devotion. Focus on prayers while we handle the rest.",
      image: "https://www.myomnamo.com/assets/images/new/logo.png",
      url: "https://www.myomnamo.com/services/temple-puja-booking",
      siteName: "MyOmNamo",
      locale: "en_IN",
    },
    mainContent:
      "Make your temple visit more special with a peaceful puja. Book a puja for any occasion, whether it be birthdays, anniversaries, festivals, or personal prayers. Let the rituals be performed with care and devotion, bringing positivity and blessings into your life. Whether it’s a small offering or a full ceremony, experience the divine atmosphere and connect deeply with your faith. Perfect for individuals, families, or groups looking for a sacred experience. Focus on your prayers while the arrangements are taken care of. Make every visit special and full of positive energy.",
  },
  {
    headerName: "Horoscope Reading",
    Imgabout1: About1,
    Imgabout2: Horoscope,
    tagLine: "Embrace Divinity with Temple Pujas",
    route: "/horoscope",
    serviceBannerImg: HoroscopeBannerImg,
    cannonicalLink: "https://www.myomnamo.com/services/horoscope",
    serviceName: "horoscope",
    sectionTitle: "Know What Your Stars Say",
    howItWorksMainHeading: "How Our Horoscope Reading Works",
    howItWorks: [
      {
        howItWorksTitle: "Choose Your Sign",
        howItWorksDesc:
          "Select your zodiac sign to receive a personalized reading about your life, emotions, and future possibilities.",
      },
      {
        howItWorksTitle: "Get Insights Instantly",
        howItWorksDesc:
          "Access daily, weekly, or yearly horoscopes with detailed guidance on love, career, and well-being for extra clarity.",
      },
      {
        howItWorksTitle: "Embrace Your Path",
        howItWorksDesc:
          "Use your horoscope’s advice to make decisions, discover lucky days, and invite more happiness and balance into your life.",
      },
    ],
    testimonials: [
      {
        Desc: " I booked a puja at the temple, and everything was perfectly arranged. The rituals were performed beautifully, and I felt truly blessed.",
        name: "Anjali Raut",
        location: "Kolhapur",
      },
      {
        Desc: "Booking a temple puja online was so convenient. No long waits, everything was well-organized, and the prasad delivery was a lovely touch",
        name: "Vikas Mane",
        location: "Delhi",
      },
      {
        Desc: "The puja was conducted exactly as promised, with complete devotion and care. It was a peaceful and divine experience. Highly recommended.",
        name: "Priya Kadam",
        location: "Vashi",
      },
    ],
    FaqObj: [
      {
        question: "What is the Puja at Temple service?",
        answer:
          "Our service allows you to book a temple puja online, ensuring a smooth and hassle-free experience.",
        color: "#ffa415",
      },
      {
        question: "How do I book a puja?",
        answer:
          "You can book online or at the temple. Choose the puja type, date, and time.",
        color: "#fc5528",
      },
      {
        question: "What types of pujas are available?",
        answer:
          "We offer pujas for birthdays, anniversaries, festivals, personal prayers, and special ceremonies.",
        color: "#8139e7",
      },
      {
        question: "Are special or private pujas available?",
        answer:
          "Yes, some temples offer private pujas with personalized rituals.",
        color: "#8139e7",
      },
    ],
    metaTitle: "Horoscope Readings by Zodiac Sign - Know Your Future",
    metaDescription:
      "Find guidance for love, career, and life decisions with a horoscope reading. Explore daily, weekly, or yearly insights tailored to your zodiac sign. Book a consultation.",
    canonicalLink: "https://www.myomnamo.com/services/horoscope",
    breadcrumbSchema: {
      "@context": "https://schema.org/",
      "@type": "BreadcrumbList",
      itemListElement: [
        {
          "@type": "ListItem",
          position: 1,
          name: "Home",
          item: "https://www.myomnamo.com/",
        },
        {
          "@type": "ListItem",
          position: 2,
          name: "Horoscope",
          item: "https://www.myomnamo.com/services/horoscope",
        },
      ],
    },
    webpageSchema: {
      "@context": "http://schema.org",
      "@type": "WebPage",
      name: "Horoscope Readings by Zodiac Sign - Know Your Future",
      url: "https://www.myomnamo.com/services/horoscope",
      description:
        "Find guidance for love, career, and life decisions with a horoscope reading. Explore daily, weekly, or yearly insights tailored to your zodiac sign. Book a consultation.",
      publisher: {
        "@type": "Organization",
        name: "MyOmNamo",
      },
    },
    metaData: {
      title: "Horoscope Readings by Zodiac Sign - Know Your Future",
      description:
        "Find guidance for love, career, and life decisions with a horoscope reading. Explore daily, weekly, or yearly insights tailored to your zodiac sign. Book a consultation.",
      image: "https://www.myomnamo.com/assets/images/new/logo.png",
      url: "https://www.myomnamo.com/services/horoscope",
      siteName: "MyOmNamo",
      locale: "en_IN",
    },
    mainContent:
      "Find out what your future holds with a simple horoscope reading. Learn about your day, week, or year efficiently. Get insights about love, work, health, and more. Whether you have a big decision to make or are just curious, a horoscope reading can guide you. Find out lucky days, helpful advice, and ways to bring more happiness into your life. Perfect for anyone looking for extra clarity and peace of mind. Let the stars guide you toward a brighter future.",
  },
];

const ServicesLandingPage = () => {
  const [slug, setSlug] = useState("");

  const [activeIndex, setActiveIndex] = useState(0);
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const service = searchParams.get("servicename");
  const { servicename } = useParams();
  const [showExitIntentModal, setShowExitIntentModal] = useState(false);

  console.log("servicename", servicename);

  const serviceData = data.find((item) => item.serviceName === servicename);

  console.log("serviceData", serviceData);

  const testimonials = [
    {
      name: "Aman Joshi",
      designation: "Pune",
      image: TestimonialImg1,
      content:
        "Temple Pujas made it so easy to book a Mahamrityunjaya Jaap for my family. The entire process was smooth, and the puja was performed with great devotion. I felt a sense of peace and protection afterward.",
    },
    {
      name: "Aman Joshi",
      designation: "Pune",
      image: TestimonialImg2,
      content:
        "Temple Pujas made it so easy to book a Mahamrityunjaya Jaap for my family. The entire process was smooth, and the puja was performed with great devotion. I felt a sense of peace and protection afterward.",
    },
    {
      name: "Aman Joshi",
      designation: "Pune",
      image: TestimonialImg3,
      content:
        "Temple Pujas made it so easy to book a Mahamrityunjaya Jaap for my family. The entire process was smooth, and the puja was performed with great devotion. I felt a sense of peace and protection afterward.",
    },
    {
      name: "Aman Joshi",
      designation: "Pune",
      image: TestimonialImg4,
      content:
        "Temple Pujas made it so easy to book a Mahamrityunjaya Jaap for my family. The entire process was smooth, and the puja was performed with great devotion. I felt a sense of peace and protection afterward.",
    },
  ];

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  useEffect(() => {
    const path = location.pathname;

    if (path.startsWith("/services/")) {
      const slugPart = path.replace("/services/", "");
      setSlug(slugPart || "default");

      if (slugPart === "horoscope") {
        setShowExitIntentModal(false);
        return; // Stop further execution
      }

      const modalClosed = sessionStorage.getItem("exit_modal_closed");

      // Scroll event listener
      const handleScroll = () => {
        if (!modalClosed && window.scrollY > 300) {
          setShowExitIntentModal(true);
          window.removeEventListener("scroll", handleScroll); // Remove listener after showing modal
        }
      };

      window.addEventListener("scroll", handleScroll);

      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    } else {
      setShowExitIntentModal(false);
    }
  }, [location]);
  const owlOptions = {
    items: 1,
    margin: 30,
    loop: false,
    smartSpeed: 700,
    nav: false,
    dots: true,
    autoplay: false,
    responsive: {
      0: { items: 1 },
      768: { items: 2 },
    },
  };
  const ExitIntenModalOpen = () => {
    setShowExitIntentModal(true);
  };
  const key = location?.state?.key;

  console.log(serviceData.serviceBannerImg);

  return (
    <div>
      <section className="page-header">
        <div
          className="page-header__bg_serviceBanner"
          style={{ backgroundImage: `url(${serviceData.serviceBannerImg})` }}
        ></div>
        <div className="container">
          <h1
            className="page-header__title bw-split-in-left"
            onClick={ExitIntenModalOpen}
          >
            {serviceData.headerName}
          </h1>
        </div>
      </section>

      <Helmet>
        <title>{serviceData.metaTitle}</title>
        <meta name="description" content={serviceData.metaDescription} />
        <link rel="canonical" href={serviceData.cannonicalLink} />

        {/* Open Graph tags */}
        <meta property="og:title" content={serviceData.metaData.title} />
        <meta
          property="og:description"
          content={serviceData.metaData.description}
        />
        <meta property="og:image" content={serviceData.metaData.image} />
        <meta property="og:url" content={serviceData.metaData.url} />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content={serviceData.metaData.siteName} />
        <meta
          property="og:locale"
          content={serviceData.metaData.locale || "en_IN"}
        />

        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={serviceData.metaData.title} />
        <meta
          name="twitter:description"
          content={serviceData.metaData.description}
        />
        <meta name="twitter:image" content={serviceData.metaData.image} />

        {/* Breadcrumb JSON-LD Structured Data */}
        <script type="application/ld+json">
          {JSON.stringify(serviceData.breadcrumbSchema)}
        </script>

        {/* WebPage JSON-LD Structured Data */}
        <script type="application/ld+json">
          {JSON.stringify(serviceData.webpageSchema)}
        </script>

        <script type="application/ld+json">
          {JSON.stringify(serviceData.faqSchema)}
        </script>
      </Helmet>

      <section>
        <div className="container">
          <div className="row" style={{ marginBottom: "20px" }}>
            {/* <div
              className="col-xl-9"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "20px",
              }}
            >
              <div
                className="about-one__image wow fadeInLeft"
                data-wow-delay="100ms"
              >
                <div className="about-one__image__one">
                  <img
                    src={data[key].Imgabout2}
                    alt="careox"
                    className="img-fluid" // Bootstrap className for responsiveness
                    style={{
                      width: "600px",
                      height: "300px",
                      objectFit: "cover",
                    }}
                  />
                </div>
              </div>
            </div> */}

            <div
              className="col-md-10 wow fadeInRight d-flex"
              data-wow-delay="200ms"
            >
              <div
                className="about-one__content"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <div className="sec-title text-left">
                  <h6 className="sec-title__tagline bw-split-in-right">
                    <img className=" tagline-img" src={batuk} alt="" />
                    {serviceData.tagLine}
                  </h6>

                  <h3 className="sec-title__title bw-split-in-left">
                    {serviceData.sectionTitle}
                  </h3>
                </div>
                <p
                  className="about-one__content__text"
                  style={{ textAlign: "justify" }}
                >
                  {serviceData.mainContent}
                </p>
                {serviceData.serviceName === "horoscope" ? (
                  <div className="col-2 wow about-one__content">
                    {" "}
                    <Link to={serviceData.route} className="careox-btn">
                      View Details
                    </Link>
                  </div>
                ) : (
                  <div className="col-2 wow about-one__content">
                    {" "}
                    <Link to={serviceData.route} className="careox-btn">
                      Book Now
                    </Link>
                  </div>
                )}
                {/* <div className="row gutter-y-30"></div>
                <div className="about-one__content__border"></div> */}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="service-one" style={{ padding: "30px 0" }}>
        <div
          className="service-one__shape-two"
          style={{ backgroundImage: `url(${shape2})` }}
        ></div>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="sec-title text-center">
                <h6 className="sec-title__tagline bw-split-in-right">
                  <img className=" tagline-img" src={batuk} alt="" />
                  How It Works{" "}
                </h6>

                <h3 className="sec-title__title bw-split-in-left">
                  {serviceData.howItWorksMainHeading}
                </h3>
              </div>
              <div className="row gutter-y-30 d-flex align-center justify-center">
                {serviceData.howItWorks?.map((item, index) => (
                  <div
                    className="col-lg-3 col-md-6 wow fadeInUp"
                    data-wow-delay="00ms"
                  >
                    <div
                      className="service-one__item text-center d-flex flex-column h-100"
                      style={{ color: "#a42642" }}
                    >
                      <div className="service-one__item__icon">
                        <img src={Icon} alt="careox" />
                      </div>
                      <h3 className="service-one__item__title">
                        {item?.howItWorksTitle}
                      </h3>
                      <p className="service-one__item__text flex-grow-1">
                        {item?.howItWorksDesc}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Testimonials */}

      <section className="testimonials-page">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="sec-title text-center">
                <h6 className="sec-title__tagline bw-split-in-right">
                  <img className=" tagline-img" src={batuk} alt="" />
                  Testimonials
                </h6>
                <h3 className="sec-title__title bw-split-in-left">
                  Devotee Testimonials
                </h3>
              </div>
              <OwlCarousel
                className="testimonials-page__carousel careox-owl__carousel careox-owl__carousel--with-shadow careox-owl__carousel--basic-nav owl-carousel owl-theme"
                {...owlOptions}
              >
                {serviceData.testimonials.map((testimonial, index) => (
                  <div className="item" key={index}>
                    <div
                      className="testimonials-page__card"
                      style={{ color: "#a42642", height: "298px" }}
                    >
                      {/* <div className="testimonials-page__card__image">
                        <img src={testimonial.image} alt={testimonial.name} />
                      </div> */}
                      <div className="testimonials-page__card__rating">
                        {[...Array(5)].map((_, i) => (
                          <i key={i} className="icofont-star"></i>
                        ))}
                      </div>
                      <div className="testimonials-page__card__content">
                        {testimonial.Desc}
                      </div>
                      <h3 className="testimonials-page__card__name">
                        {testimonial.name}
                      </h3>
                      <p className="testimonials-page__card__designation">
                        {testimonial.location}
                      </p>
                      <div className="testimonials-page__card__quote">
                        <i className="icofont-quote-left"></i>
                      </div>
                    </div>
                  </div>
                ))}
              </OwlCarousel>
            </div>
          </div>
        </div>
      </section>

      {/* <FAQSection /> */}

      <section className="faq-one faq-one--page">
        <div
          className="container"
          style={{ display: "flex", flexDirection: "column" }}
        >
          <div className="sec-title text-center">
            <h6
              className="sec-title__tagline bw-split-in-right"
              style={{ perspective: "400px" }}
            >
              {/* <img className=" tagline-img" src={batuk} alt="" /> FAQ */}
              <img className=" tagline-img" src={batuk} alt="" /> FAQ
              {/* <span className="sec-title__tagline__border"></span> FAQ */}
            </h6>
            <h3
              className="sec-title__title bw-split-in-left"
              style={{ perspective: "400px" }}
            >
              Frequently Asked Questions.
            </h3>
          </div>
          <div className="row">
            <div className="col-xl-2"></div>
            <div className="col-xl-8">
              <div className="faq-one__accordion careox-accordion">
                {serviceData.FaqObj.map((faq, index) => (
                  <div
                    key={index}
                    className={`accrodion ${
                      activeIndex === index ? "active" : ""
                    }`}
                    style={{ "--accent-color": faq.color }}
                  >
                    <div
                      className="accrodion-title"
                      onClick={() => toggleFAQ(index)}
                    >
                      <h4>
                        <span
                          className="accrodion-title__number"
                          style={{ marginRight: "10px", fontWeight: "500" }}
                        ></span>
                        {faq.question}
                        <span className="accrodion-title__icon"></span>
                      </h4>
                    </div>
                    <div
                      className="accrodion-content"
                      style={{
                        maxHeight: activeIndex === index ? "200px" : "0",
                        overflow: "hidden",
                        transition: "max-height 0.5s ease-in-out",
                      }}
                    >
                      <div className="inner">
                        <p>{faq.answer}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="col-xl-2"></div>

            <ExitIntentModal
              slug={slug}
              show={showExitIntentModal}
              onHide={() => {
                setShowExitIntentModal(false);
              }}
            />
          </div>
        </div>
      </section>
    </div>
  );
};

export default ServicesLandingPage;
