import React, { useEffect, useState } from "react";
import { DailyPanditBookingAPI } from "../../ApiServices/DailyPanditAPI/DailyPanditApi";
import { Link, useNavigate } from "react-router-dom";
import placeholderImg from "../../assets/images/new/Batuk2-removebg-preview.png";
import Batuk from "../../assets/images/new/Batuk2.png";
import { CurrentBlogData } from "../../ApiServices/BlogApi/BlogAPI";
import { Helmet } from "react-helmet-async";

const CommonBlogPage = () => {
  const user_id = localStorage.getItem("userID");
  const [searchKeyWord, setSearchKeyWord] = useState("");
  const [blogList, setblogList] = useState([]);
  const [dailyPanditPujaListData, setDailyPanditPujaListData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    GetCurrentBlog({
      START: 0,
      END: 100,
      WORD: "",
      EXTRA1: "",
      EXTRA2: "",
      EXTRA3: "",
      LANG_ID: "1",
      USER_ID: localStorage.getItem("userID"),
    });
  }, []);

  const GetCurrentBlog = async (params) => {
    // debugger;
    // setLoader(true);

    const response = await CurrentBlogData(params);

    if (response.ResponseCode === "0" && response.ResponseMessage === "list") {
      const blogs = response?.DATA;
      setblogList(blogs);
      //   if (blogs.length > 0) {
      //     if (location.state) {
      //       setCurrentBlog(location.state.blog);
      //     } else {
      //       setCurrentBlog(blogs[0]); // Set the first blog as the current blog
      //     }
      //   }
    }
  };

  const filteredBlogs = blogList.filter((data) =>
    data.BLOG_TITLE.toLowerCase().includes(searchKeyWord.toLowerCase())
  );

  const stripHtml = (html) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;
    return tempDiv.innerText || tempDiv.textContent || "";
  };

  const viewDetail = (puja) => {
    console.log("Navigating to details with puja:", puja);
    navigate("/services/daily-booking/puja-details", {
      state: { puja: puja },
    });
  };

  return (
    <>
      <section className="page-header" style={{ marginBottom: "2rem" }}>
        <div className="page-header__bg"></div>
        <div className="container">
          <h2 className="page-header__title bw-split-in-left">
            Spiritual Gyan - Facts & Beliefs
          </h2>
        </div>
      </section>
      <Helmet>
        {/* Meta Title & Description */}
        <title>Spiritual Gyan - Insights, Rituals & Divine Knowledge</title>
        <meta
          name="description"
          content="Explore spiritual wisdom, rituals, and divine insights on our blog. Enhance your spiritual journey with meaningful knowledge and guidance."
        />

        {/* Canonical URL */}
        <link rel="canonical" href="https://www.myomnamo.com/spiritual-gyan" />

        {/* Open Graph Meta Tags */}
        <meta
          property="og:title"
          content="Spiritual Gyan - Insights, Rituals & Divine Knowledge"
        />
        <meta
          property="og:description"
          content="Explore spiritual wisdom, rituals, and divine insights on our blog. Enhance your spiritual journey with meaningful knowledge and guidance."
        />
        <meta
          property="og:image"
          content="https://www.myomnamo.com/assets/images/new/logo.png"
        />
        <meta
          property="og:url"
          content="https://www.myomnamo.com/spiritual-gyan"
        />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="MyOmNamo" />
        <meta property="og:locale" content="en_IN" />

        {/* Twitter Card Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Spiritual Gyan - Insights, Rituals & Divine Knowledge"
        />
        <meta
          name="twitter:description"
          content="Explore spiritual wisdom, rituals, and divine insights on our blog. Enhance your spiritual journey with meaningful knowledge and guidance."
        />
        <meta
          name="twitter:image"
          content="https://www.myomnamo.com/assets/images/new/logo.png"
        />

        {/* Schema Markup - Breadcrumbs */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org/",
            "@type": "BreadcrumbList",
            itemListElement: [
              {
                "@type": "ListItem",
                position: 1,
                name: "Home",
                item: "https://www.myomnamo.com/",
              },
              {
                "@type": "ListItem",
                position: 2,
                name: "Spiritual Gyan",
                item: "https://www.myomnamo.com/spiritual-gyan",
              },
            ],
          })}
        </script>

        {/* WebPage Schema */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "http://schema.org",
            "@type": "WebPage",
            name: "Spiritual Gyan - Insights, Rituals & Divine Knowledge",
            url: "https://www.myomnamo.com/spiritual-gyan",
            description:
              "Explore spiritual wisdom, rituals, and divine insights on our blog. Enhance your spiritual journey with meaningful knowledge and guidance.",
            publisher: {
              "@type": "Organization",
              name: "MyOmNamo",
            },
          })}
        </script>
      </Helmet>
      <div
        className="container mt-4"
        style={{ display: "flex", flexDirection: "column" }}
      >
        {/* Search Bar */}
        <div className="row mb-4">
          <div className="col-md-6 mx-auto">
            <div className="input-group">
              <input
                type="text"
                className="form-control "
                placeholder="Search...."
                value={searchKeyWord}
                onChange={(e) => setSearchKeyWord(e.target.value)}
              />
              <span
                className="input-group-text text-white"
                style={{ backgroundColor: "#a52444" }}
              >
                <i className="fa fa-search"></i>
              </span>
            </div>
          </div>
        </div>

        {/* Puja List Heading */}
        {/* <h4 className="text-center mb-4" style={{ fontFamily: "Poppins" }}>
        Spiritual Gyan
      </h4> */}

        {/* Puja Cards */}
        <div className="row">
          {filteredBlogs?.length !== 0 ? (
            filteredBlogs
              .filter(
                (blog) =>
                  !searchKeyWord ||
                  blog?.DP_NAME?.toLowerCase().includes(
                    searchKeyWord.toLowerCase()
                  )
              )
              .map((blog, index) => (
                <div
                  className="col-lg-4 col-md-6 col-sm-6 mb-4"
                  key={index}
                // onClick={() => viewDetail(blog)}
                >
                  <Link to={`/spiritual-gyan/${blog.Blog_Slug}`}>
                    <div className="card card-shadow-sty shadow-sm border-0 h-100">
                      <img
                        src={blog.BLOG_IMAGE}
                        className="card-img-top"
                        alt={blog.DP_NAME}
                        onError={(e) => {
                          e.target.onerror = null; // Prevent infinite loop
                          e.target.src = placeholderImg; // Set fallback image
                        }}
                        style={{ height: "200px", objectFit: "fill" }}
                      />
                      <div
                        className="card-body"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                        }}
                      >
                        <h5 className="card-title" style={{ color: "#a52444" }}>
                          {blog.BLOG_TITLE}
                        </h5>
                        <p className="card-text">
                          {stripHtml(blog.BLOG).length > 80
                            ? `${stripHtml(blog.BLOG).substring(0, 80)}...`
                            : stripHtml(blog.BLOG)}
                        </p>
                        {/* <span className="badge bg-danger">{blog.DP_DISC}% Off</span> */}
                        <div className="mt-3 text-end">
                          <span
                            // className="btn btn-sm"
                            style={{
                              // backgroundColor: "#a52444",
                              color: "#a52444",
                              fontWeight: "500",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              gap: "5px",
                            }}
                          >
                            View Details <i className="icon-right-arrow"></i>
                          </span>
                          {/* <Link
                          to={`/spiritual-gyan/${blog.Blog_Slug}`}
                          className="btn btn-sm"
                          style={{
                            // backgroundColor: "#a52444",
                            color: "#a52444",
                            fontWeight: "500",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: "5px",
                          }}
                        >
                          View Details <i className="icon-right-arrow"></i>
                        </Link> */}
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              ))
          ) : (
            <div className="d-flex flex-column align-items-center justify-content-center vh-90 text-center mt-4">
              <img
                src={Batuk}
                alt="Empty Cart"
                className="img-fluid mb-3"
                style={{ maxWidth: "200px" }}
              />
              <h5 className="fw-bold">
                No services available for this catagory
              </h5>
              <p className="text-muted">Please explore our other categories.</p>
              {/* <Link to="/my-store">
                  <button
                    style={{ background: "#a52444" }}
                    className="text-white btn me-2"
                  >
                    Add Products
                  </button>
                </Link> */}
            </div>
          )}
        </div>
      </div>{" "}
    </>
  );
};

export default CommonBlogPage;
