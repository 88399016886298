import React, { useEffect, useState } from 'react'
import aries from '../../../assets/images/new/aries.jpg'
import Aquarius from '../../../assets/images/new/Aquarius.jpg'
import cancer from '../../../assets/images/new/cancer.jpg'
import capricorn from '../../../assets/images/new/capricorn.jpg'
import Leo from '../../../assets/images/new/Leo.jpg'
import libra from '../../../assets/images/new/libra.jpg'
import pisces from '../../../assets/images/new/pisces.jpg'
import sagittarius from '../../../assets/images/new/sagittarius.jpg'
import taurus from '../../../assets/images/new/taurus.jpg'
import virgo from '../../../assets/images/new/virgo.jpg'
import Zodiac from '../../../assets/images/new/Zodiac.png'
import { GetUserModel } from '../../../ApiServices/Profile/ProfileApi'
import { daily_nakshatra_prediction } from '../../../ApiServices/Horoscope/BirthDetailsApi'
import dayjs from 'dayjs'
import PredictionModal from './PredictionModal'

const Prediction = () => {
    const [dailyPredictionList, setDailyPredictionObj] = useState([])
    const [showModal, setShowModal] = useState(false)
    const [predictionTitle, setPredictionTitle] = useState(null)
    useEffect(() => {
        GetUserModelData()
    }, [])

    const GetUserModelData = async () => {
        // setLoader(true);

        const userID = JSON.parse(localStorage.getItem("userID"));
        try {
            const response = await GetUserModel(userID);
            if (response?.ResponseCode == 0) {
                // setLoader(false);
                const ModelData = response.DATA || []; // Assuming the data is an array and we need the first item

                const dob = new Date(ModelData[0]?.DOB);

                const year = dob.getFullYear();
                const month = dob.getMonth() + 1; // Months are zero-based (0 = January)
                const day = dob.getDate();

                const [hours, minutes] = ModelData[0]?.BIRTH_TIME.split(":");
                const apiParam = {
                    "day": day.toString(),
                    "month": month.toString(),
                    "year": year.toString(),
                    "hour": hours.toString(),
                    "min": minutes.toString(),
                    "lat": 25.7464,
                    "lon": 25.7464,
                    "tzone": 5.5
                }
                await GetDailyPredictionData(apiParam, dayjs(ModelData[0]?.DOB).format('DD/MM/YYYY'))

            } else {
                // setLoader(false);

                console.error('Error fetching data: ', response?.data?.data?.statusCode);
            }
        } catch (error) {
            // setLoader(false);

            console.error('Error', error);
        }
    };
    const GetDailyPredictionData = async (apiParam, birthDate) => {
        console.log('apiParam', apiParam)
        try {
            const response = await daily_nakshatra_prediction(apiParam);
            if (response !== null && response !== undefined) {
                // setLoader(false);
                setDailyPredictionObj((prev) => ({
                    ...prev,
                    Health: response?.prediction?.health,
                    Emotion: response?.prediction?.emotions,
                    Profession: response?.prediction?.profession,
                    Luck: response?.prediction?.luck,
                    Personal_life: response?.prediction?.personal_life,
                    Travel: response?.prediction?.travel,

                }));

            } else {
                // setLoader(false);

                console.error('Error fetching data: ', response);
            }
        } catch (error) {
            // setLoader(false);

            console.error('Error', error);
        }
    };

    const handleClickedOnSign = (value) => {
        setShowModal(true)
        setPredictionTitle(value)
    }
    return (
        <>

            <div class=" text-center m-5 ">
                <h2 class="mb-4">Horoscope</h2>
                <div class="row">
                    {/* <!-- Zodiac Sign Cards --> */}
                    <div class="col-md-3 col-sm-6 mb-4">
                        <div class="zodiac-card" onClick={() => handleClickedOnSign('Aries')}>
                            <div class="zodiac-icon">
                                <img width={50} height={50} src={aries} alt="Mesha" className="rounded-circle border" />

                            </div>
                            <div class="zodiac-name">Mesha</div>
                            <div class="zodiac-sign">Aries</div>
                        </div>
                    </div>
                    <div class="col-md-3 col-sm-6 mb-4">
                        <div class="zodiac-card" onClick={() => handleClickedOnSign('Taurus')}>
                            <div class="zodiac-icon" >
                                <img width={50} height={50} src={taurus} alt="Vrishabha" className="rounded-circle border" />
                            </div>
                            <div class="zodiac-name">Vrishabha</div>
                            <div class="zodiac-sign">Taurus</div>
                        </div>
                    </div>
                    <div class="col-md-3 col-sm-6 mb-4">
                        <div class="zodiac-card" onClick={() => handleClickedOnSign('Gemini')}>
                            <div class="zodiac-icon">
                                <img width={50} height={50} src={Zodiac} alt="Mithuna" className="rounded-circle border" />
                            </div>
                            <div class="zodiac-name">Mithuna</div>
                            <div class="zodiac-sign">Gemini</div>
                        </div>
                    </div>
                    <div class="col-md-3 col-sm-6 mb-4">
                        <div class="zodiac-card" onClick={() => handleClickedOnSign('Cancer')}>
                            <div class="zodiac-icon">
                                <img width={50} height={50} src={cancer} alt="Karka" className="rounded-circle border" />
                            </div>
                            <div class="zodiac-name">Karka</div>
                            <div class="zodiac-sign">Cancer</div>
                        </div>
                    </div>
                    <div class="col-md-3 col-sm-6 mb-4">
                        <div class="zodiac-card" onClick={() => handleClickedOnSign('Leo')} >
                            <div class="zodiac-icon">
                                <img width={50} height={50} src={Leo} alt="Simha" className="rounded-circle border" />
                            </div>
                            <div class="zodiac-name">Simha</div>
                            <div class="zodiac-sign">Leo</div>
                        </div>
                    </div>
                    <div class="col-md-3 col-sm-6 mb-4">
                        <div class="zodiac-card" onClick={() => handleClickedOnSign('Virgo')} >
                            <div class="zodiac-icon">
                                <img width={50} height={50} src={virgo} alt="Kanya" className="rounded-circle border" />
                            </div>
                            <div class="zodiac-name">Kanya</div>
                            <div class="zodiac-sign">Virgo</div>
                        </div>
                    </div>
                    <div class="col-md-3 col-sm-6 mb-4">
                        <div class="zodiac-card" onClick={() => handleClickedOnSign('Libra')}>
                            <div class="zodiac-icon">
                                <img width={50} height={50} src={libra} alt="Tula" className="rounded-circle border" />
                            </div>
                            <div class="zodiac-name">Tula</div>
                            <div class="zodiac-sign">Libra</div>
                        </div>
                    </div>
                    <div class="col-md-3 col-sm-6 mb-4">
                        <div class="zodiac-card" onClick={() => handleClickedOnSign('Scorpio')}>
                            <div class="zodiac-icon">
                                <img width={50} height={50} src={Zodiac} alt="Vrishchika" className="rounded-circle border" />
                            </div>
                            <div class="zodiac-name">Vrishchika</div>
                            <div class="zodiac-sign">Scorpio</div>
                        </div>
                    </div>
                    <div class="col-md-3 col-sm-6 mb-4">
                        <div class="zodiac-card" onClick={() => handleClickedOnSign('Sagittarius')}>
                            <div class="zodiac-icon">
                                <img width={50} height={50} src={sagittarius} alt="Dhanu" className="rounded-circle border" />
                            </div>
                            <div class="zodiac-name">Dhanu</div>
                            <div class="zodiac-sign">Sagittarius</div>
                        </div>
                    </div>
                    <div class="col-md-3 col-sm-6 mb-4">
                        <div class="zodiac-card" onClick={() => handleClickedOnSign('Capricorn')}>
                            <div class="zodiac-icon">
                                <img width={50} height={50} src={capricorn} alt="Makara" className="rounded-circle border" />
                            </div>
                            <div class="zodiac-name">Makara</div>
                            <div class="zodiac-sign">Capricorn</div>
                        </div>
                    </div>
                    <div class="col-md-3 col-sm-6 mb-4">
                        <div class="zodiac-card" onClick={() => handleClickedOnSign('Aquarius')}>
                            <div class="zodiac-icon">
                                <img width={50} height={50} src={Aquarius} alt="Kumbha" className="rounded-circle border" />
                            </div>
                            <div class="zodiac-name">Kumbha</div>
                            <div class="zodiac-sign">Aquarius</div>
                        </div>
                    </div>
                    <div class="col-md-3 col-sm-6 mb-4">
                        <div class="zodiac-card" onClick={() => handleClickedOnSign('Pisces')} >
                            <div class="zodiac-icon">
                                <img width={50} height={50} src={pisces} alt="Meena" className="rounded-circle border" />
                            </div>
                            <div class="zodiac-name">Meena</div>
                            <div class="zodiac-sign">Pisces</div>
                        </div>
                    </div>
                </div>
            </div>
            <PredictionModal show={showModal} onHide={(() => setShowModal(false))} dailyPredictionList={dailyPredictionList} predictionTitle={predictionTitle} />
        </>
    )
}

export default Prediction
