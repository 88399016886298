import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { PoojaAtTempleInvoice } from "../../ApiServices/Pooja At Temple/PoojaTempleApi";
import logo from '../../assets/images/logo.png'
const PujaAtTempleInvoice = () => {
  const user_id = localStorage.getItem("userID");
  const [invoiceData, setInvoiceData] = useState({
    name: null,
    address: null,
    PanditName: null,
    PujaName: null,
    PujaPrice: null,
    DiscountPercentage: null,
    DiscountAmount: null,
    SamagriPrice: null,
    ConvenienceFee: null,
    PB_Total: null,
    PB_TaxPercentage: null,
    TaxAmount: null,
    PB_GrandTotal: null,
  });

  const location = useLocation();
  console.log(location.state);

  useEffect(() => {
    GetPujaBookingInvoice();
  }, []);

  const GetPujaBookingInvoice = async () => {
    try {
      const response = await PoojaAtTempleInvoice(
        user_id,
        location.state.PATB_ID
      );
      console.log(response);
      if (
        response.ResponseMessage === "list" &&
        response.ResponseCode === "0"
      ) {
        const modelData = response.DATA[0];
        setInvoiceData((prev) => ({
          ...prev,
          name: modelData.FULL_NAME,
          address: modelData.ADDRESS,
          PanditName: modelData.PANDIT_NAME,
          PujaName: modelData.PAT_NAME,
          PAT_IMAGE: modelData?.PAT_IMAGE,
          PujaPrice: modelData.PB_PRICE,
          BramhanDakshina: modelData?.BramhanDakshina,
          SamagriPrice: modelData.PAT_SAMAGRIPRICE,
          ConvenienceFee: modelData?.CONVIENCE_FEE,
          PB_Total: modelData.BramhanDakshina,
          PB_TaxPercentage: modelData.PAT_TaxPercentage,
          TaxAmount: modelData.PAT_TaxAmount,
          PB_GrandTotal: modelData.TOTAL_PRICE,
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDownloadPDF = () => {
    const invoiceElement = document.getElementById("invoice-content");

    html2canvas(invoiceElement, { scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      const imgWidth = 210;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
      pdf.save(`PujaAtTemple_Invoice_${invoiceData.invoiceNumber}.pdf`);
    });
  };

  const calculateSubtotal = () => {
    return location.state.products.reduce(
      (total, item) => total + item.QTY * item.RATE,
      0
    );
  };

  return (
    <>
      <div className="container d-flex flex-column justify-content-center align-items-center mt-2" style={{ minHeight: "100vh" }}>
        <div id="invoice-content" style={{ width: "70vw" }}>
          <div style={{ margin: '10px' }}>
            <div className="card shadow-sm" style={{
              border: "2px solid black",
              padding: "20px",
              borderRadius: "10px",
              backgroundColor: 'white',

            }}>
              <div className="d-flex justify-content-between align-items-center border-bottom p-3">

                <div >
                  <h1 className="h3 fw-bold" style={{ color: "#A52444" }}>
                    INVOICE
                  </h1>
                </div>
                <div>
                  <img
                    src={logo}
                    alt="Buildous Logo"
                    className="img-fluid"
                    style={{ height: "50px", width: "120PX" }}
                  />
                </div>
              </div>

              <div className="card-body" >
                <div className="row d-flex justify-content-between">
                  <div className="col-md-6">
                    <h5 style={{ fontSize: '20px' }}>Invoice No.</h5>

                    <p >
                      {/* {invoiceData.address} */}
                      #INV1212
                    </p>
                  </div>
                  <div className="col-md-6 d-flex justify-content-end">
                    <div>
                      <h5 style={{ fontSize: '20px' }}>Invoice Date</h5>
                      {/* <p>{invoiceDetails.date}</p> */}
                      <p style={{ fontSize: '15px' }}>22/02/2016</p>
                    </div>
                  </div>
                </div>
                <div className="row d-flex justify-content-between">
                  <div className="col-md-12">
                    <h5 style={{ fontSize: '20px' }}>Customer Info</h5>
                    <p style={{ margin: 'auto', fontSize: '15px' }}>
                      <strong>Name:</strong> {invoiceData.name}
                      {/* <strong>Name:</strong> Test customer */}
                    </p>
                    <p >
                      {/* <strong>Address:</strong> {location.state.Address} */}
                      <strong>Address:</strong> {invoiceData.address}
                    </p>
                  </div>
                </div>
                <table className="table table-bordered">
                  <thead className="thead-light">
                    <tr>
                      <th style={{ fontSize: '16px' }} >Sr No.</th>
                      <th style={{ fontSize: '16px' }}>Temple Name</th>
                      <th style={{ fontSize: '16px' }}>Puja Name</th>
                      <th style={{ fontSize: '16px' }}>Pandit Name</th>
                      <th style={{ fontSize: '16px' }}>Pandit Fee</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style={{ fontSize: '15px' }}>1</td>
                      <td style={{ fontSize: '15px' }}>{invoiceData.PujaName}</td>
                      <td style={{ fontSize: '15px' }}>{invoiceData.PujaName}</td>

                      <td style={{ fontSize: '15px' }}>{invoiceData.PanditName}</td>
                      <td style={{ fontSize: '15px' }}>{invoiceData.BramhanDakshina}</td>
                    </tr>
                  </tbody>
                </table>

                <div className="row">
                  <div className="col-md-8">
                    <h5 style={{ fontSize: '20px' }}>Notes</h5>
                    <p>Thank you for shopping with us!</p>
                  </div>
                  <div className="col-md-4">
                    <table className="table">
                      <tbody>

                        <tr>
                          <td style={{ fontSize: '17px' }}>
                            <strong>Subtotal:</strong>
                          </td>
                          <td style={{ fontSize: '17px' }} className="text-right">
                            ₹{invoiceData?.BramhanDakshina}
                          </td>
                        </tr>
                        <tr>
                          <td style={{ fontSize: '17px' }}>
                            <strong>Convience Fee:</strong>
                          </td>
                          <td style={{ fontSize: '17px' }} className="text-right">
                            ₹{invoiceData?.ConvenienceFee}
                          </td>
                        </tr>
                        <tr>
                          <td style={{ fontSize: '17px' }}>
                            <strong>Tax (18%):</strong>
                          </td>
                          <td style={{ fontSize: '17px' }} className="text-right">
                            {/* ₹ {location.state.shippingCharges} */}₹{" "}
                            {invoiceData.TaxAmount}
                          </td>
                        </tr>
                        <tr>
                          <td style={{ fontSize: '17px' }}>
                            <strong>Total Amount:</strong>
                          </td>
                          <td style={{ fontSize: '17px' }} className="text-right">
                            {/* ₹ {formatToIndianCurrency(calculateTotal())} */}₹{" "}
                            {invoiceData?.PB_GrandTotal}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              {/* Card Footer with Download Button */}


            </div>
          </div>

        </div>
        <div className="mt-3">
          <button className="btn" style={{
            backgroundColor: "#a52444",
            color: "white",
            padding: "10px 20px",
            fontSize: "16px",
            borderRadius: "5px"
          }} onClick={handleDownloadPDF}>
            Download Invoice
          </button>
        </div>
      </div>
    </>
  );
};

export default PujaAtTempleInvoice;
