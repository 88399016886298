import { BaseUrl } from "../../BaseUrl/BaseUrl";
import {
  getApiWithAuthorization,
  postApiWithoutAuthorization,
} from "../ApiMethod/ApiMethod";

export const DarshanBook_TempleList = async (params,USER_ID) => {
  const url = `${BaseUrl}/DarshanBook_TempleList?TYPE=All&USER_ID=${USER_ID}`;
  const res = await postApiWithoutAuthorization(url, params);
  return res;
};
export const DarshanBook_TempleListGETTimeSlot = async (tr_ID,user_id) => {
  const url = `${BaseUrl}/DarshanBook_TempleList?TR_ID=${tr_ID}&USER_ID=${user_id}`;
  const res = await getApiWithAuthorization(url);

  return res;
};
export const DarshanBookingInvoice = async (tr_ID,user_id) => {
  const url = `${BaseUrl}/DarshanBookingInvoice?USER_ID=${user_id}&TRB_ID=${tr_ID}`;
  const res = await getApiWithAuthorization(url);

  return res;
};