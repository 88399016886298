import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { BaseUrl } from "../../BaseUrl/BaseUrl";
import { Tooltip } from "@mui/material";
import { ServiceDiscounts } from "../../Components/enum";
// import { Base_Url } from "../baseurl/Base_Url";

// import OrderDetails from "./OrderDetails";
const DailyBookingOrders = () => {
  const userID = JSON.parse(localStorage.getItem("userID"));
  const [orders, setOrders] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    axios
      .post(`${BaseUrl}/Get_AllMyOrder?USER_ID=${userID}&TYPE=DailyPuja`, {
        START: 1,
        END: 10,
        WORD: "None",
      })
      .then((response) => {
        console.log("Response...", response.data.DATA);
        setOrders(response.data.DATA);
      });
  }, []);

  // const needSamagri = "yes";

  const orderDetails = (myorders) => {
    navigate("/daily-Booking-order-details", { state: { order: myorders } });
  };

  const SGST = 9;
  const CGST = 9;

  //   const tax = Math.round(
  //     ((SGST + CGST) / 100) * parseFloat(puja.CONVENIENCE_FEE)
  //   );

  //   const totalDays =
  //     (new Date(productBookingData.endDate) -
  //       new Date(productBookingData.startDate)) /
  //     (1000 * 60 * 60 * 24);

  //   const totalPrice =
  //     parseFloat(puja?.DP_PRICE || 0) * totalDays +
  //     (productBookingData.needSamagri === "yes"
  //       ? parseFloat(puja?.DP_SAMAGRI_PRICE * totalDays || 0)
  //       : 0) +
  //     parseFloat(puja?.CONVENIENCE_FEE || 0) +
  //     tax; //Tax amount

  console.log("orders", orders);

  const numberOfDays = (startDate, endDate) => {
    const totalDays =
      (new Date(endDate) - new Date(startDate)) / (1000 * 60 * 60 * 24);

    return totalDays + 1;
  };

  //   console.log(
  //     Number(myOrders?.DPB_PRICE || 0) *
  //       numberOfDays(myOrders.DPB_FROMDATE, myOrders.DPB_TODATE) +
  //       (needSamagri === "yes"
  //         ? Number(myOrders?.DP_SAMAGRI_PRICE || 0) *
  //           numberOfDays(myOrders.DPB_FROMDATE, myOrders.DPB_TODATE)
  //         : 0) +
  //       Number(myOrders?.CONVENIENCE_FEE || 0) +
  //       Math.round(((SGST + CGST) / 100) * Number(myOrders?.CONVENIENCE_FEE || 0))
  //   );

  const CalculateGrandTotal = (
    price,
    fromDate,
    toDate,
    samagriPrice,
    needSamagri,
    convenienceFee,
    discount
  ) => {
    // debugger;
    return (
      totalPrice(
        price,
        fromDate,
        toDate,
        samagriPrice,
        needSamagri,
        convenienceFee,
        discount
      ) +
      Math.round(
        (ServiceDiscounts.panditBookingDiscount / 100) *
        Number(
          totalPrice(
            price,
            fromDate,
            toDate,
            samagriPrice,
            needSamagri,
            convenienceFee,
            discount
          )
        )
      )
    );
  };

  const totalPrice = (
    price,
    fromDate,
    toDate,
    samagriPrice,
    needSamagri,
    convenienceFee,
    discount
  ) => {
    return (
      Number(price) * numberOfDays(fromDate, toDate) +
      Number(convenienceFee) +
      (needSamagri === "yes"
        ? Number(samagriPrice || 0) * numberOfDays(fromDate, toDate)
        : 0) -
      discount
    );
  };

  // const totatAmount = (
  //   price,
  //   fromDate,
  //   toDate,
  //   samagriPrice,
  //   needSamagri,
  //   convenienceFee
  // ) => {
  //   const res =
  //     Number(price) * numberOfDays(fromDate, toDate) +
  //     (needSamagri === "yes"
  //       ? Number(samagriPrice || 0) * numberOfDays(fromDate, toDate)
  //       : 0) +
  //     Number(convenienceFee) +
  //     Math.round(((SGST + CGST) / 100) * Number(convenienceFee));

  //   return res;
  // };

  function formatToIndianCurrency(amount) {
    if (!amount) return "";

    const [integerPart, decimalPart] = amount.toString().split(".");

    // Format the integer part using Indian numbering system
    const formattedIntegerPart = integerPart.replace(
      // Match groups of 3 digits initially, then groups of 2 digits
      /(\d)(?=(\d\d)+\d$)/g,
      "$1,"
    );

    // Return the formatted value with the decimal part (if present)
    return decimalPart !== undefined
      ? `${formattedIntegerPart}.${decimalPart}`
      : formattedIntegerPart;
  }
  return (
    <div className="p-3 m-4 my-4">
      {orders?.map((order) => (
        <div
          key={order.id}
          className="card mb-3  border-0 shadow-lg p-3 hover-zoom-out"
          onClick={() => orderDetails(order)}
        >
          <div className="d-flex align-items-center justify-content-between">
            {/* Order ID */}
            <p className="fw-bold mb-0 me-3">#{order.DPB_ID}</p>

            {/* Product Name (Truncated if too long) */}
            <h6
              className=" mb-0 text-truncate flex-grow-1"
              style={{
                maxWidth: "250px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                fontWeight: "500",
              }}
              title={order.DP_NAME} // Shows full name on hover
            >
              {order.DP_NAME}
            </h6>

            {/* Product Price */}
            <p className=" mb-0 me-3" style={{ fontWeight: "500" }}>
              ₹
              {formatToIndianCurrency(
                order.DPB_GrandTotal
                // CalculateGrandTotal(
                //   order.DPB_PRICE,
                //   order.DPB_FROMDATE,
                //   order.DPB_TODATE,
                //   order.DPB_SAMAGRIPRICE,
                //   order.NEED_SAMAGRI,
                //   order.CONVENIENCE_FEE,
                //   order.DiscountAmount
                // )
              )}
            </p>
            <p className=" mb-0 me-3" style={{ fontWeight: "500" }}>
              {" "}
              {order?.PAYMENT_STATUS}
            </p>

            {/* Order Date */}
            <p
              className=" mb-0"
            // style={{ color: order.STATUS === "Pending" ? "red" : "green", minWidth: "100px", textAlign: "right", fontWeight: '500' }}
            >
              {order.REG_DATE}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default DailyBookingOrders;
