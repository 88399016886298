import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import React, { useEffect, useRef, useState } from 'react'
import logo from '../assets/images/logo.png'
import { useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
import { GetPanditBookingPaymentReceipt } from '../ApiServices/Receipt/ReceiptApi';
const ViewReceipt = () => {
    const receiptRef = useRef()
    const location = useLocation()
    const [receiptObj, setReceiptObj] = useState({
        receiptNumber: 'receipt123',
        Date: '23/04/2025',
        customerName: 'Aniket',
        receivedAmount: '2000',
        receivedFor: 'Puja At temple Amount',
        TotalAmount: '3000',
        NOTE: null
    })


    useEffect(() => {
        debugger
        if (location?.state?.moduleName !== null && location?.state?.moduleName !== undefined && location?.state?.moduleName !== '') {
            GetPanditBookingPaymentReceiptData()
        } else {
            setReceiptObj((prev) => ({
                ...prev,
                Date: dayjs(location?.state?.PaymentDate).format('DD/MM/YYYY'),
                customerName: location?.state?.UserName,
                receivedAmount: location?.state?.PaymentAmount,
                NOTE: location?.state?.Note,
            }))
        }

    }, [location])
    const GetPanditBookingPaymentReceiptData = async () => {

        try {
            const response = await GetPanditBookingPaymentReceipt(
                location?.state?.orderID,
                location?.state?.moduleName
            );
            console.log(response);
            if (
                response.ResponseMessage === "list" &&
                response.ResponseCode === "0"
            ) {
                const modelData = response.DATA;
                setReceiptObj((prev) => ({
                    ...prev,

                    Date: dayjs(modelData[0]?.PaymentDate).format('DD/MM/YYYY'),
                    customerName: modelData[0]?.UserName,
                    receivedAmount: modelData[0]?.PaymentAmount,
                    NOTE: modelData[0]?.Note,

                }))
            }
        } catch (error) {
            console.log(error);
        }
    };
    const handleDownloadPDF = () => {
        if (!receiptRef.current) return;
        html2canvas(receiptRef.current, { scale: 2 }).then((canvas) => {
            const imgData = canvas.toDataURL("image/png");
            const pdf = new jsPDF("p", "mm", "a4");

            const imgWidth = 210; // A4 width in mm
            const imgHeight = (canvas.height * imgWidth) / canvas.width;

            const marginTop = 10; // Add margin at the top

            pdf.addImage(imgData, "PNG", 0, marginTop, imgWidth, imgHeight);
            pdf.save(`Receipt_${receiptObj?.receiptNumber}.pdf`);
        });
    };
    return (
        <>
            <div ref={receiptRef}>
                <div
                    className="border border-dark p-4 m-4 w-75 mx-auto shadow bg-white mt-4"
                    style={{ borderWidth: '2px' }}
                >
                    <div style={{ padding: '10px' }}>
                        <div className="d-flex justify-content-between align-items-start">
                            <div>
                                <img src={logo} height={70} width={120} alt="Om Namo Logo" />
                            </div>
                            <div className="border border-2 border-dark px-4 py-2 fw-bold fs-5">Receipt</div>
                        </div>

                        <div className="mt-3 d-flex justify-content-between small">
                            <p>Receipt No.: #Receipt12165</p>
                            <p>Date: {receiptObj.Date}</p>
                        </div>

                        <div className="mt-3 small">
                            <p>
                                Amount Received From
                                <span className="border-bottom border-dark d-inline-block fw-bold pb-1 px-2 w-50">
                                    {receiptObj.customerName}
                                </span>
                                of
                                <span className="border-bottom border-dark d-inline-block fw-bold pb-1 px-2 w-25">
                                    (₹) {receiptObj.receivedAmount}
                                </span>
                            </p>
                            <p className="mt-2">
                                Note -
                                <span className="border-bottom border-dark d-inline-block fw-bold pb-1 px-2 w-75">
                                    {receiptObj.NOTE}
                                </span>
                            </p>
                        </div>

                        {/* <table className="table table-bordered mt-4 small">
                            <tbody>
                                <tr>
                                    <td className="fw-bold">Total Amount Due</td>
                                    <td>{receiptObj?.TotalAmount}</td>
                                </tr>
                                <tr>
                                    <td className="fw-bold">Amount Received</td>
                                    <td>{receiptObj?.receivedAmount}</td>
                                </tr>
                            </tbody>
                        </table> */}


                    </div>
                </div>
            </div>
            <div className="mt-4 pt-2 text-center fw-bold">
                <button onClick={handleDownloadPDF} className="btn" style={{ backgroundColor: '#A52444', color: 'white' }}>Download</button>
            </div>

        </>
    )
}

export default ViewReceipt
