import React from "react";
import { FaGooglePlay, FaApple } from "react-icons/fa";
import MobileBatuk from "../assets/images/new/Mobile_batuk-removebg-preview.png";

const MobileAppAd = () => {
  return (
    <div
      className="card text-center shadow-sm p-3 border-0"
      style={{
        background: "#fff",
        borderRadius: "12px",
        display: "flex",
        alignItems: "center",
      }}
    >
      <img
        src={MobileBatuk}
        alt="Mobile App"
        className=" rounded"
        style={{ maxHeight: "60%", objectFit: "cover", maxWidth: "60%" }}
      />
      <div className="card-body">
        <h5 className="card-title fw-bold">Download Our Mobile App</h5>
        <p className="card-text text-muted">
          Get the best experience on the go. Download our app now!
        </p>
        <div className="d-flex justify-content-center gap-2">
          <a
            href="https://play.google.com/store/apps/details?id=spiritualapp.myomnamo"
            target="_blank"
            rel="noopener noreferrer"
            className="btn btn-dark d-flex align-items-center gap-2"
          >
            <FaGooglePlay /> Play Store
          </a>
          <a
            href="your-appstore-link"
            target="_blank"
            rel="noopener noreferrer"
            className="btn btn-dark d-flex align-items-center gap-2"
          >
            <FaApple /> App Store
          </a>
        </div>
      </div>
    </div>
  );
};

export default MobileAppAd;
