import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./PujaDetails.css";
import ProductBookingModal from "./ProductBookingModal";
import dayjs from "dayjs";
import DatePicker from "react-date-picker";
import { ERROR_MESSAGES } from "../GlobalErrorMessage";
import { SelectLanguageOptions } from "../../middlewares/Utils";
import Select from "react-select";
import placeholderImg from "../../assets/images/new/Batuk2-removebg-preview.png";
import { AuthContextProvider } from "../../AuthContext/AuthContext";
import LoginPopUp from "../LoginPopup";

const PoojsDetails = () => {
  const [productBookingModal, setProductBookingModal] = useState(null);
  const [loginPopUp, setLoginPopUp] = useState(null);
  const [error, setError] = useState(null);
  const { isAuthenticated } = useContext(AuthContextProvider);
  // const userID = localStorage.getItem("userID");
  // console.log("userID", userID);
  const location = useLocation();
  const fromDate = dayjs();
  const navigate = useNavigate();
  const [pujaDetailsData, setPujaDetailsData] = useState({
    convenienceFee: null,
    pujaDetails: null,
    pujaDiscount: null,
    pujaId: null,
    pujaImage: null,
    pujaName: null,
    pujaPrice: null,
    pujaSamagri: null,
    pujaSamagriPrice: null,
  });

  const [productBookingData, setProductBookingData] = useState({
    date: null,
    time: null,
    languageID: null,
    landmark: null,
    address: null,
    pincode: null,
    city: null,
    state: null,
    needSamagri: null,
  });

  const product = location.state.product;

  useEffect(() => {
    // debugger;
    setPujaDetailsData((prev) => ({
      ...prev,
      convenienceFee: location.state.product.CONVENIENCE_FEE,
      pujaDetails: location.state.product.PUJA_DETAILS,
      pujaDiscount: location.state.product.PUJA_DISC,
      pujaId: location.state.product.PUJA_ID,
      pujaImage: location.state.product.PUJA_IMAGE,
      pujaName: location.state.product.PUJA_NAME,
      pujaPrice: location.state.product.PUJA_PRICE,
      pujaSamagri: location.state.product.PUJA_SAMAGRI,
      pujaSamagriPrice: location.state.product.SAMAGRI_PRICE,
      DiscountAmount: location.state.product.DiscountAmount,
    }));
  }, [location.state]);

  const createMarkup = (html) => {
    return { __html: html };
  };

  const handleOpenProductBookingModal = () => {
    // setProductBookingModal(true);
    // debugger;
    if (isAuthenticated) {
      navigate("/services/book-puja/add-service", {
        state: {
          product: location.state.product,
          pujaDetailsData: pujaDetailsData,
        },
      });
    } else {
      setLoginPopUp(true);
    }
  };

  const HandleProductBookingBtnClicked = () => {
    let isValid = false;

    if (
      productBookingData.date === null ||
      productBookingData.date === undefined ||
      productBookingData.date === "" ||
      productBookingData.time === null ||
      productBookingData.time === undefined ||
      productBookingData.time === "" ||
      productBookingData.landmark === null ||
      productBookingData.landmark === undefined ||
      productBookingData.landmark === "" ||
      productBookingData.languageID === null ||
      productBookingData.languageID === undefined ||
      productBookingData.languageID === "" ||
      productBookingData.address === null ||
      productBookingData.address === undefined ||
      productBookingData.address === "" ||
      productBookingData.state === null ||
      productBookingData.state === undefined ||
      productBookingData.state === "" ||
      productBookingData.city === null ||
      productBookingData.city === undefined ||
      productBookingData.city === "" ||
      productBookingData.pincode === null ||
      productBookingData.pincode === undefined ||
      productBookingData.pincode === "" ||
      productBookingData.needSamagri === null ||
      productBookingData.needSamagri === undefined ||
      productBookingData.needSamagri === ""
    ) {
      isValid = true;
      setError(true);
    }

    // const api_Params = {
    //   date: productBookingData.date,
    //   time: productBookingData.time,
    //   languageID: productBookingData.languageID,
    //   landmark: productBookingData.landmark,
    //   address: productBookingData.address,
    //   pincode: productBookingData.pincode,
    //   city: productBookingData.city,
    //   state: productBookingData.state,
    //   needSamagri: productBookingData.needSamagri,
    // };

    if (!isValid) {
      navigate("/services/book-puja/puja-details/pandit-list", {
        state: { productBookingData: productBookingData, product: product },
      });
    }
  };

  const handleToDateChange = (newValue) => {
    if (dayjs(newValue).isValid()) {
      const newToDate = dayjs(newValue).format("YYYY-MM-DD");
      setProductBookingData((prev) => ({ ...prev, date: newToDate }));
    } else {
      setProductBookingData((prev) => ({ ...prev, date: null }));
    }
  };

  const handleLanguageChange = (selectedValue) => {
    setProductBookingData((prev) => ({
      ...prev,
      languageID: selectedValue.value,
    }));
  };

  function formatToIndianCurrency(amount) {
    if (!amount) return "";

    const [integerPart, decimalPart] = amount.toString().split(".");

    // Format the integer part using Indian numbering system
    const formattedIntegerPart = integerPart.replace(
      // Match groups of 3 digits initially, then groups of 2 digits
      /(\d)(?=(\d\d)+\d$)/g,
      "$1,"
    );

    // Return the formatted value with the decimal part (if present)
    return decimalPart !== undefined
      ? `${formattedIntegerPart}.${decimalPart}`
      : formattedIntegerPart;
  }

  console.log(location.state.product, "product");
  return (
    <div className="PujaDetails">
      <div
        className="PujaDetailsContainer"
        style={{
          display: "flex",
          gap: "20px",
          maxWidth: "1200px",
          margin: "auto",
          padding: "20px",
          height: "calc(100vh - 80px)", // Adjust height minus header
          overflow: "hidden",
        }}
      >
        {/* <div className="pujaDetails" style={{ display: "flex", gap: "20px" }}> */}
        <div
          className="left-puja-details text-center"
          style={{ width: "400px", margin: "auto" }}
        >
          <img
            src={pujaDetailsData.pujaImage}
            alt="Puja-Img"
            onError={(e) => {
              e.target.onerror = null; // Prevent infinite loop
              e.target.src = placeholderImg; // Set fallback image
            }}
            className="img-fluid rounded" // Bootstrap classes for responsiveness and rounded corners
            style={{ maxWidth: "100%", height: "auto" }}
          />
          {/* {pujaDetailsData.pujaDiscount && (
            <div
              className="position-absolute top-0 start-0 bg-danger text-white px-2 py-1 rounded"
              style={{ fontSize: "14px", fontWeight: "bold", margin: "10px" }}
            >
              {pujaDetailsData.pujaDiscount}% OFF
            </div>
          )} */}
        </div>

        <div
          className="right"
          style={{
            flex: 1,
            overflowY: "scroll",
            height: "100%",
            paddingRight: "10px",
            scrollbarWidth: "none", // Hides scrollbar in Firefox
            msOverflowStyle: "none", // Hides scrollbar in IE/Edge
          }}
        >
          <div className="d-flex align-items-center justify-content-between">
            <h3 className="mb-0">{pujaDetailsData.pujaName}</h3>
            <div className="button-container">
              <button
                className="careox-btn d-flex align-items-center justify-content-center"
                style={{
                  backgroundColor: "#A52444",
                  color: "white",
                }}
                onClick={handleOpenProductBookingModal}
              >
                Proceed to Book
              </button>
            </div>
          </div>

          <div className="price-tag position-relative d-inline-block p-2 d-flex  align-items-center">
            <div
              className="text-dark px-3 py-1 rounded"
              style={{ background: "#efb423", marginRight: "10px" }}
            >
              <strong>
                ₹{" "}
                {formatToIndianCurrency(
                  product.PUJA_PRICE - product.DiscountAmount
                )}
              </strong>
            </div>
            <span
              style={{
                textDecoration: "line-through",
                color: "#777",
                marginLeft: "10px",
              }}
            >
              ₹ {product.PUJA_PRICE}
            </span>
            <span style={{ color: "black", marginLeft: "10px" }}>
              ({Math.round((product.DiscountAmount / product.PUJA_PRICE) * 100)}
              % OFF)
            </span>
            {/* <div
                className="text-muted"
                style={{ textDecoration: "line-through" }}
              >
                {pujaDetailsData?.DiscountAmount}
              </div> */}
          </div>

          {/* <h5>
              <span>
                <s>Rs {pujaDetailsData.pujaPrice}</s>
              </span>
              <span> ({pujaDetailsData.pujaDiscount}% Off)</span>
            </h5> */}

          <div className="mb-3 ">
            {/* <h5>Puja Details:</h5> */}
            <p
              dangerouslySetInnerHTML={{
                __html: pujaDetailsData.pujaDetails,
              }}
            />
          </div>

          <div className="mb-3">
            <h5>Samagri:</h5>
            {pujaDetailsData.pujaSamagri ? (
              <div
                dangerouslySetInnerHTML={createMarkup(
                  pujaDetailsData.pujaSamagri
                )}
              />
            ) : (
              <p>No samagri details available.</p>
            )}
          </div>

          {/* <h5>
              Samagri Price: Rs{" "}
              {formatToIndianCurrency(pujaDetailsData.pujaSamagriPrice)}
            </h5> */}

          {/* <div className="button-container">
              <button
                className="btn btn-primary"
                style={{ backgroundColor: "#A52444", color: "white" }}
                onClick={handleOpenProductBookingModal}
              >
                Proceed to Book
              </button>
            </div> */}
        </div>
        {/* </div> */}
      </div>
      <ProductBookingModal
        show={productBookingModal}
        onHide={() => setProductBookingModal(false)}
        product={pujaDetailsData}
      />
      {loginPopUp && (
        <LoginPopUp show={loginPopUp} onHide={() => setLoginPopUp(false)} />
      )}
    </div>
  );
};

export default PoojsDetails;
