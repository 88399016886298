import React, { useContext, useState } from "react";
import "./Register.css";
import Logo from "../../../assets/images/new/logo.png";
import Select from "react-select";
import { countrySelect } from "../../../middlewares/Utils";
import { RegisterUserAPI } from "../../../ApiServices/Register/RegisterAPI";
import { AuthContextProvider } from "../../../AuthContext/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import { ERROR_MESSAGES } from "../../../Components/GlobalErrorMessage";

const Register = () => {
  const [requireMessage, setRequireMessage] = useState(false);
  const [apiError, setApiError] = useState(false);
  const [error, setError] = useState(false);
  const [registerObj, setRegisterObj] = useState({
    username: null,
    email: null,
    countryID: null,
    mobileNo: null,
  });
  const [otpLoader, setOtpLoader] = useState(null);
  const { setLoader, setIsAuthenticated } = useContext(AuthContextProvider);
  const navigate = useNavigate();

  const HandleCountryChange = (selectedValue) => {
    setRegisterObj((prev) => ({
      ...prev,
      countryID: selectedValue.value,
    }));
  };

  const handleGetOTPClicked = () => {
    let isValid = false;
    if (
      registerObj.username === "" ||
      registerObj.username === null ||
      registerObj.username === undefined ||
      registerObj.email === "" ||
      registerObj.email === null ||
      registerObj.email === undefined ||
      registerObj.mobileNo === "" ||
      registerObj.mobileNo === null ||
      registerObj.mobileNo === undefined ||
      registerObj?.mobileNo?.length < 10 ||
      registerObj.countryID === "" ||
      registerObj.countryID === null ||
      registerObj.countryID === undefined
    ) {
      isValid = true;
      setError(true);
    } else if (
      registerObj.email !== null &&
      !validateEmail(registerObj.email)
    ) {
      isValid = true;
      setError(true);
    }

    const apiParams = {
      // userName: loginObj.username,
      // mobileNo: loginObj.email,
      UserName: registerObj.username,
      MobileNo: registerObj.mobileNo,
      Email: registerObj.email,
      CuntryID: registerObj.countryID === "India" ? "1" : "2",
      Type: "SignUP",
    };

    if (!isValid) {
      RegisterUserData(apiParams);
    }
  };

  const RegisterUserData = async (apiParams) => {
    setLoader(true);
    setOtpLoader(true);
    const RegisterUserRes = await RegisterUserAPI(apiParams);

    try {
      if (
        RegisterUserRes.ResponseMessage.trim() ===
        "OTP has been sent to your email."
      ) {
        setLoader(false);
        setOtpLoader(false);
        navigate("/registerotp", { state: { email: registerObj.email } });
      } else {
        setApiError(RegisterUserRes.ResponseMessage); // Set API error if not successful
        console.log("error");
        setOtpLoader(false);
        setLoader(false);
      }
    } catch (error) {
      console.log(error);
      setOtpLoader(false);
      setLoader(false);
    }

    // setUserId(userId);
    // setShowPasswordModal(true);
  };

  const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  return (
    <div className="e-store main">
      <div className="signinn mb-5" style={{ marginTop: "3rem" }}>
        <div className="container" id="container" style={{ padding: "0px" }}>
          <div
            className="form-container sign-in-container"
            style={{ padding: "10px 40px" }}
          >
            <div>
              <div className="my-img">
                <img src={Logo} alt="img" className="p-2 img-responsive" />
              </div>

              {/* <h1 className="mb-4">Sign Up</h1> */}

              <input
                type="text"
                className="form-control"
                name="name"
                placeholder="User Name"
                onChange={(e) => {
                  let inputVal = e.target.value;
                  if (inputVal.startsWith(" ")) {
                    inputVal = inputVal.trimStart();
                  }
                  setRegisterObj((prev) => ({
                    ...prev,
                    username: inputVal,
                  }));
                }}
                value={registerObj.username}
                style={{ margin: "5px" }}
                maxLength={50}
              />
              {error &&
              (registerObj.username === "" ||
                registerObj.username === undefined ||
                registerObj.username === null) ? (
                <span
                  style={{
                    color: "red",
                    fontFamily: "Ubuntu",
                    fontSize: "15px",
                    marginRight: "160px",
                    marginLeft: "10px",
                  }}
                >
                  {ERROR_MESSAGES}
                </span>
              ) : (
                ""
              )}

              <input
                type="text"
                name="email"
                className="form-control"
                placeholder="Email"
                onChange={(e) => {
                  let inputVal = e.target.value;
                  if (inputVal.startsWith(" ")) {
                    inputVal = inputVal.trimStart();
                  }
                  setRegisterObj((prev) => ({
                    ...prev,
                    email: inputVal,
                  }));
                }}
                value={registerObj.email}
                style={{ margin: "5px" }}
                maxLength={50}
              />
              {error &&
                (!registerObj.email || registerObj.email.trim() === "" ? (
                  <span
                    style={{
                      color: "red",
                      fontFamily: "Ubuntu",
                      fontSize: "15px",
                      marginLeft: "10px",
                      marginRight: "160px",
                    }}
                  >
                    {ERROR_MESSAGES}
                  </span>
                ) : registerObj.email !== null &&
                  !validateEmail(registerObj.email) ? (
                  <span style={{ color: "red" }}>Invalid Email ID</span>
                ) : (
                  ""
                ))}

              <Select
                options={countrySelect}
                value={countrySelect.find(
                  (val) => val.value === registerObj.countryID
                )}
                placeholder="Select country"
                onChange={HandleCountryChange}
              />

              {error &&
              (registerObj.countryID === "" ||
                registerObj.countryID === undefined ||
                registerObj.countryID === null) ? (
                <span
                  style={{
                    color: "red",
                    fontFamily: "Ubuntu",
                    fontSize: "15px",
                    marginRight: "160px",
                    marginLeft: "10px",
                  }}
                >
                  {ERROR_MESSAGES}
                </span>
              ) : (
                ""
              )}

              <input
                type="text"
                name="mobile"
                className="form-control"
                placeholder="Mobile Number"
                onChange={(e) => {
                  const numericValue = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
                  setRegisterObj((prev) => ({
                    ...prev,
                    mobileNo: numericValue,
                  }));
                }}
                value={registerObj.mobileNo}
                style={{ margin: "5px" }}
                maxLength={10} // Set maxLength based on selected country
              />
              {error &&
                (!registerObj.mobileNo || registerObj.mobileNo.trim() === "" ? (
                  <span
                    style={{
                      color: "red",
                      fontFamily: "Ubuntu",
                      fontSize: "15px",
                      marginRight: "160px",
                      marginLeft: "10px",
                    }}
                  >
                    {ERROR_MESSAGES}
                  </span>
                ) : registerObj.mobileNo !== null &&
                  registerObj?.mobileNo?.length !== 10 ? (
                  <span style={{ color: "red" }}>Invalid Mobile Number</span>
                ) : (
                  ""
                ))}

              <div>
                {apiError && (
                  <span
                    style={{
                      color: "red",
                      fontFamily: "Ubuntu",
                      fontSize: "18px",
                      marginRight: "21px",
                    }}
                  >
                    {apiError}
                  </span>
                )}
              </div>

              <button
                className="mt-4 mb-3 signin-btn otp-btn"
                disabled={otpLoader}
                onClick={handleGetOTPClicked}
              >
                {otpLoader ? <div className="small-loader"></div> : "Get OTP"}
              </button>
              {/* <div
                className="note"
                style={{ marginTop: "20px", textAlign: "center" }}
              >
                <p
                  style={{
                    color: "gray",
                    fontFamily: "Ubuntu",
                    fontSize: "14px",
                  }}
                >
                  Already have an account?{" "}
                  <Link to="/login" style={{ color: "blue" }}>
                    Login here
                  </Link>
                </p>
              </div> */}
            </div>
          </div>
          <div className="overlay-container">
            <div className="overlay">
              <div className="overlay-panel overlay-right">
                <h1>Hello, Friend!</h1>
                <p>Create New Account and Explore Products</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
