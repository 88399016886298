import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
// import { LoginUser, SendOTP } from "../../../ApiServices/Login/Login";
// import { AuthContextProvider } from "../../../AuthContext/AuthContext";
// import "./Login.css";
// import { ERROR_MESSAGES } from "../../../Components/GlobalErrorMessage";
import Logo from "../assets/images/new/logo.png";
import Select from "react-select";
// import { countrySelect } from "../../../middlewares/Utils";
// import LoginImg from "../../../assets/images/new/AbuotUs.png";
import { SendOTP, VerifyOTP } from "../ApiServices/Login/Login";
import { AuthContextProvider } from "../AuthContext/AuthContext";
import { ERROR_MESSAGES } from "./GlobalErrorMessage";
import { countrySelect } from "../middlewares/Utils";
import OTPInput from "otp-input-react";
import LoginOTPPopUp from "./LoginOTPPopup";

function LoginPopUp({ show, onHide, onConfirm, setOnGetOTP }) {
  const navigate = useNavigate();
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [showLoginOTPPopUp, setShowLoginOTPPopUp] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isResendDisabled, setIsResendDisabled] = useState(false);
  const [error, setError] = useState(false);
  const [userID, setUserId] = useState();
  const [counter, setCounter] = useState(60);
  const [resendVisible, setResendVisible] = useState(false);
  const [timer, setTimer] = useState(60);
  const [otpLoader, setOtpLoader] = useState(null);
  const [loginObj, setLoginObj] = useState({
    username: "",
    email: "",
    countryID: 1,
    mobileNo: null,
  });
  const [loginOTPData, setLoginOTPData] = useState({
    Email: null,
    otp: null,
    MobileNo: null,
    UserName: null,
    CuntryID: null,
    Type: "SignIn",
  });
  const { setLoader, setIsAuthenticated } = useContext(AuthContextProvider);
  const [incorrectCred, setIncorrectCred] = useState(null);
  const [apiError, setApiError] = useState(null);

  // useEffect(() => {
  //   const registerKey = sessionStorage.getItem("registerKey");
  //   if (!registerKey) {
  //     navigate("/login");
  //   }
  // }, [navigate]);

  // useEffect(() => {
  //   if (isResendDisabled && timer > 0) {
  //     const interval = setInterval(() => {
  //       setTimer((prev) => prev - 1);
  //     }, 1000);
  //     return () => clearInterval(interval);
  //   } else if (timer === 0) {
  //     setIsResendDisabled(false);
  //   }
  // }, [isResendDisabled, timer]);

  const LoginData = () => {
    // debugger;
    let isValid = false;
    if (
      loginObj.username === "" ||
      loginObj.username === null ||
      loginObj.username === undefined ||
      loginObj.email === "" ||
      loginObj.email === null ||
      loginObj.email === undefined ||
      loginObj.mobileNo === "" ||
      loginObj.mobileNo === null ||
      loginObj.mobileNo === undefined ||
      loginObj.countryID === "" ||
      loginObj.countryID === null ||
      loginObj.countryID === undefined
    ) {
      isValid = true;
      setError(true);
    } else if (loginObj.email !== null && !validateEmail(loginObj.email)) {
      isValid = true;
      setError(true);
    } else if (loginObj?.mobileNo?.length !== 10) {
      isValid = true;
      setError(true);
    }

    const apiParams = {
      UserName: loginObj.username,
      MobileNo: loginObj.mobileNo,
      Email: loginObj.email,
      CuntryID: loginObj.countryID,
      Type: "SignIn",
    };

    if (!isValid) {
      RegisterUserData(apiParams);
      setOtpLoader(false);
      // setOnGetOTP(false);
      setShowLoginOTPPopUp(true);
      setCounter(60); // Restart timer
      // navigate("/loginotp", {
      //   state: {
      //     mobileNumber: loginObj?.mobileNo,
      //     username: loginObj.username,
      //     email: loginObj.email,
      //     countryID: loginObj.countryID,
      //   },
      // });
    }
  };

  const RegisterUserData = async (apiParams) => {
    // debugger;
    // setLoader(true);
    setOtpLoader(true);
    const RegisterUserRes = await SendOTP(apiParams);

    try {
      if (RegisterUserRes?.ResponseMessage == "Success...") {
        // setLoader(false);
        setOtpLoader(false);
        // setTimeout(() => {
        //   navigate("/loginotp", {
        //     state: { mobileNumber: loginObj?.mobileNo },
        //   });
        // }, 100);
        // navigate("/loginotp", { state: { email: loginObj?.email } });
      } else {
        // setLoader(false);
        setOtpLoader(false);
        setApiError(RegisterUserRes.ResponseMessage); // Set API error if not successful
      }
    } catch (error) {
      // setLoader(false);
      setOtpLoader(false);
      console.log(error);
    }
  };

  const HandleCountryChange = (selectedValue) => {
    setLoginObj((prev) => ({
      ...prev,
      countryID: selectedValue.value,
    }));
  };

  const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  useEffect(() => {
    let timer;
    if (counter > 0) {
      timer = setInterval(() => {
        setCounter((prevCounter) => prevCounter - 1);
      }, 1000);
    } else {
      setResendVisible(true);
    }

    return () => clearInterval(timer); // Cleanup timer on unmount
  }, [counter]);

  const handleOTPSubmit = async () => {
    // debugger;
    let isValid = false;

    if (!loginObj.mobileNo) {
      setErrorMessage("Email not found. Please try signing up again.");
      return;
    }

    if (!loginOTPData.otp) {
      isValid = true;
      setError(true);
    }

    if (!isValid) {
      try {
        const response = await VerifyOTP(loginOTPData?.otp, loginObj.mobileNo);

        if (
          response.ResponseCode === "0" &&
          response.ResponseMessage === "Login successful!"
        ) {
          localStorage.setItem("userID", response.DATA[0].UserID);
          setIsAuthenticated(true);
          // navigate("/");
        } else {
          setError(true);
          setErrorMessage(
            response.ResponseMessage || "Invalid OTP. Please try again."
          );
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleResendOTP = async () => {
    // debugger;
    console.log("hello"); // Log "hello" on clicking resend

    const RegisterUserRes = await SendOTP(loginOTPData);

    try {
      if (RegisterUserRes?.ResponseMessage == "Success...") {
        // setLoader(false);

        setCounter(60); // Restart timer
        setResendVisible(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const setInitialData = () => {
    setLoginObj(() => ({
      username: null,
      email: null,
      countryID: 1,
      mobileNo: null,
    }));
  };
  return (
    <Modal
      show={show}
      // onHide={onHide}
      onHide={() => {
        onHide();
        setError(false);
        setShowLoginOTPPopUp(false);
        setInitialData();
      }}
      backdrop="static"
      keyboard={false}
      centered
      dialogClassName="custom-modal-width"
    >
      <Modal.Header
        closeButton
        // style={{
        //   backgroundColor: "#A52444",
        //   color: "white",
        // }}
      >
        <Modal.Title className="w-100 text-center">
          <span style={{ fontSize: "25px", fontWeight: "400" }}>Login</span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{
          //   backgroundColor: "#f2eeee",
          color: "Black",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          minHeight: "80px", // Ensures proper vertical centering
        }}
      >
        {!showLoginOTPPopUp ? (
          <div
            className="form-container sign-in-container"
            style={{ width: "60%" }}
          >
            <div>
              <div className="my-img">
                <img
                  src={Logo}
                  alt="img"
                  className="img-responsive"
                  style={{ paddingBottom: "15px", width: "200px" }}
                />
              </div>

              <input
                type="text"
                name="name"
                className="form-control"
                placeholder="Full Name"
                style={{ marginBottom: "10px" }}
                onChange={(e) => {
                  setError(false);
                  let inputVal = e.target.value;

                  // Prevent leading spaces
                  if (inputVal.startsWith(" ")) {
                    inputVal = inputVal.trimStart();
                  }

                  // Allow only alphabets and spaces
                  inputVal = inputVal.replace(/[^A-Za-z\s]/g, "");

                  setLoginObj((prev) => ({
                    ...prev,
                    username: inputVal,
                  }));
                }}
                value={loginObj.username}
                //   style={{ margin: "5px" }}
                maxLength={30}
              />
              {error &&
              (loginObj.username === "" ||
                loginObj.username === undefined ||
                loginObj.username === null) ? (
                <span
                  className="error-msg"
                  style={{ display: "flex", marginBottom: "8px" }}
                >
                  {ERROR_MESSAGES}
                </span>
              ) : (
                ""
              )}

              <input
                type="text"
                name="email"
                className="form-control"
                placeholder="Email"
                style={{ marginBottom: "10px" }}
                onChange={(e) => {
                  setError(false);
                  let inputVal = e.target.value;
                  if (inputVal.startsWith(" ")) {
                    inputVal = inputVal.trimStart();
                  }
                  setLoginObj((prev) => ({
                    ...prev,
                    email: inputVal,
                  }));
                }}
                value={loginObj.email}
                //   style={{ margin: "5px" }}
                maxLength={50}
              />
              {error &&
                (!loginObj.email || loginObj.email.trim() === "" ? (
                  <span
                    className="error-msg"
                    style={{ display: "flex", marginBottom: "8px" }}
                  >
                    {ERROR_MESSAGES}
                  </span>
                ) : loginObj.email !== null &&
                  !validateEmail(loginObj.email) ? (
                  <span
                    className="error-msg"
                    style={{ display: "flex", marginBottom: "8px" }}
                  >
                    Invalid Email ID
                  </span>
                ) : (
                  ""
                ))}

              {/* <Select
                options={countrySelect}
                value={countrySelect.find(
                  (val) => val.value === loginObj.countryID
                )}
                placeholder="Select country"
                onChange={HandleCountryChange}
              /> */}

              {/* {error &&
              (loginObj.countryID === "" ||
                loginObj.countryID === undefined ||
                loginObj.countryID === null) ? (
                <span
                  style={{
                    color: "red",
                    fontFamily: "Ubuntu",
                    fontSize: "15px",
                    marginRight: "160px",
                    marginLeft: "10px",
                  }}
                >
                  {ERROR_MESSAGES}
                </span>
              ) : (
                ""
              )} */}

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "5px",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Select
                    options={countrySelect}
                    value={countrySelect.find(
                      (val) => val.value === loginObj.countryID
                    )}
                    placeholder="Select country code"
                    onChange={HandleCountryChange}
                    styles={{
                      control: (base) => ({
                        ...base,
                        width: "max-content", // Adjust width as needed
                        // marginLeft: "6px",
                        marginRight: "10px",
                        fontSize: "15px",
                        height: "45px",
                      }),
                    }}
                  />

                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <input
                      type="text"
                      name="mobile"
                      className="form-control"
                      placeholder="Mobile Number"
                      onChange={(e) => {
                        const numericValue = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
                        setLoginObj((prev) => ({
                          ...prev,
                          mobileNo: numericValue,
                        }));
                      }}
                      value={loginObj.mobileNo}
                      // style={{ margin: "5px" }}
                      maxLength={10} // Set maxLength based on selected country
                    />
                  </div>
                </div>
                <div style={{ alignSelf: "flex-start", width: "100%" }}>
                  {error &&
                    (!loginObj.mobileNo || loginObj.mobileNo.trim() === "" ? (
                      <span
                        className="error-msg text-left"
                        style={{ display: "flex", marginBottom: "8px" }}
                      >
                        {ERROR_MESSAGES}
                      </span>
                    ) : loginObj.mobileNo !== null &&
                      loginObj?.mobileNo?.length !== 10 ? (
                      <span
                        className="error-msg"
                        style={{ display: "flex", marginBottom: "8px" }}
                      >
                        Invalid Mobile Number
                      </span>
                    ) : (
                      ""
                    ))}
                </div>
              </div>

              {apiError && (
                <span
                  style={{
                    color: "red",
                    fontFamily: "Poppins",
                    fontSize: "16px",
                    display: "block",
                  }}
                >
                  {apiError}
                </span>
              )}
            </div>
          </div>
        ) : (
          <div
            className="form-container sign-in-container"
            style={{ width: "60%" }}
          >
            <div
              className="otpp Otp"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                width: "100%",
              }}
            >
              <div className="my-img">
                <img
                  src={Logo}
                  alt="img"
                  className=" pt-0 img-responsive"
                  style={{ paddingBottom: "15px", width: "200px" }}
                />
              </div>

              <h4 className="mb-1">Enter OTP</h4>
              <div
                className="d-flex"
                style={{ flexDirection: "column", alignItems: "center" }}
              >
                <span
                  style={{
                    fontSize: "16px",
                    lineHeight: "20px",
                    marginTop: "5px",
                    marginBottom: "18px",
                  }}
                >
                  Please enter the OTP sent to your mobile number{" "}
                  {loginObj.countryID === 1 ? "+91 " : "+44 "}
                  {loginObj.mobileNo}
                  {/* <span
                    onClick={() => navigate("/login")}
                    style={{
                      marginLeft: "5px",
                      background: "none",
                      border: "none",
                      color: "#a52444",
                      cursor: "pointer",
                      textDecoration: "underline",
                    }}
                  >
                    Change
                  </span> */}
                </span>
              </div>

              <OTPInput
                value={loginOTPData.otp}
                className="mt-3  otp-input"
                onChange={(otp) => {
                  setError(false);
                  setLoginOTPData((prev) => ({
                    ...prev,
                    otp,
                  }));
                }}
                autoFocus
                OTPLength={4}
                otpType="number"
                disabled={false}
              />

              {/* {error &&
              (loginOTPData.otp === "" ||
                loginOTPData.otp === null ||
                loginOTPData.otp === undefined) ? (
                <span className="error-msg">{ERROR_MESSAGES}</span>
              ) : (
                ""
              )} */}

              {error && errorMessage && (
                <span style={{ color: "red", fontSize: "16px" }}>
                  {errorMessage}
                </span>
              )}

              {/* Countdown Timer / Resend OTP */}
              <div className="mt-3">
                {resendVisible ? (
                  <button
                    className="resend-btn"
                    onClick={handleResendOTP}
                    style={{
                      background: "none",
                      border: "none",
                      color: "#a52444",
                      cursor: "pointer",
                      textDecoration: "none",
                    }}
                  >
                    Resend OTP
                  </button>
                ) : (
                  <span style={{ fontSize: "14px", color: "#666" }}>
                    Resend OTP in {counter}s
                  </span>
                )}
              </div>
            </div>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        {!showLoginOTPPopUp ? (
          <button
            type="submit"
            className="signin-btn"
            disabled={otpLoader}
            onClick={LoginData}
          >
            {otpLoader ? <div className="small-loader"></div> : "Get OTP"}
          </button>
        ) : (
          <button
            className=" signin-btn"
            type="submit"
            onClick={handleOTPSubmit}
          >
            Verify OTP
          </button>
        )}
        {/* <Button
          variant="secondary"
          onClick={() => {
            onHide();
            setError(false);
          }}
          style={{ backgroundColor: "#A52444", border: "none" }}
        >
          NO
        </Button>
        <Button
          variant="primary"
          onClick={onConfirm}
          style={{
            backgroundColor: "#EFB423",
            color: "#2B1B10",
            border: "none",
          }}
        >
          Yes
        </Button> */}
      </Modal.Footer>
      {/* <LoginOTPPopUp
        show={showLoginOTPPopUp}
        onHide={() => setShowLoginOTPPopUp(false)}
      /> */}
    </Modal>
  );
}

export default LoginPopUp;
