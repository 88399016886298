import { isValidElement, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { ERROR_MESSAGES } from "../../Components/GlobalErrorMessage";

function TempleCodModal({
  show,
  onHide,
  onConfirm,
  modelRequestData,
  setModelRequestData,
}) {

  console.log(modelRequestData,'ssssssss43');
  
  const [error, setError] = useState(null);
  const handleCODSubmit = () => {
    let isValid = false;
    if (
      modelRequestData.amountEntered === null ||
      modelRequestData.amountEntered === "" ||
      modelRequestData.amountEntered === undefined
    ) {
      isValid = true;
      setError(true);
    } else if (
      modelRequestData.amountEntered < twentyPercentAmount ||
      modelRequestData.amountEntered > modelRequestData.totalPrice1
    ) {
      isValid = true;
      setError(true);
    }

    if (!isValid) {
      onConfirm();
    }
  };
  const twentyPercentAmount = modelRequestData.totalPrice1 * 0.2;

  const acceptDecimal = (inputValue) => {
    // debugger;
    const sanitizedInput = inputValue
      .replace(/[^0-9.]/g, "") // Remove all non-numeric and non-dot characters
      .replace(/(\..*?)\./g, "$1"); // Allow only a single dot

    // Split into integer and decimal parts
    const [integerPart, decimalPart] = sanitizedInput.split(".");

    // Format the integer and decimal parts
    const formattedIntegerPart = integerPart?.slice(0, 8) || ""; // Limit integer to 8 digits
    const formattedDecimalPart = decimalPart?.slice(0, 2) || ""; // Limit decimal to 2 digits

    // Combine formatted parts
    const formattedInput =
      decimalPart !== undefined
        ? `${formattedIntegerPart}.${formattedDecimalPart}`
        : formattedIntegerPart;

    return formattedInput;
  };

  function formatToIndianCurrency(amount) {
    if (!amount) return "";

    const [integerPart, decimalPart] = amount.toString().split(".");

    // Format the integer part using Indian numbering system
    const formattedIntegerPart = integerPart.replace(
      // Match groups of 3 digits initially, then groups of 2 digits
      /(\d)(?=(\d\d)+\d$)/g,
      "$1,"
    );

    // Return the formatted value with the decimal part (if present)
    return decimalPart !== undefined
      ? `${formattedIntegerPart}.${decimalPart}`
      : formattedIntegerPart;
  }
  return (
    <Modal
      show={show}
      onHide={() => {
        onHide();
        setModelRequestData((prev) => ({
          ...prev,
          amountEntered: 0,
        }));
      }}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header
        closeButton
        style={{
          backgroundColor: "#A52444",
          color: "white",
        }}
      >
        <Modal.Title style={{ fontSize: "25px", fontWeight: "bold" }}>
          COD Confirmation
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{
          backgroundColor: "#f2eeee",
          color: "Black",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          minHeight: "80px", // Ensures proper vertical centering
          flexDirection: "column",
        }}
      >
        <p style={{ fontSize: "20px" }}>
          
          You need to pay minimum {formatToIndianCurrency(modelRequestData.convenienceFeeAddedWithTax)} amount in order to proceed with COD option
        </p>

        <div className="amounts d-flex align-center flex-column">
          <span>
            <strong>Total Amount: </strong>
            {modelRequestData.totalPrice1}
          </span>
            {/* <span>
              <strong>Payment Amount (20%): </strong>
              {formatToIndianCurrency(twentyPercentAmount)}
            </span> */}
        </div>

        <div
          className="d-flex flex-column"
          style={{ width: "100%", marginTop: "10px" }}
        >
          <label
            htmlFor=""
            style={{ display: "flex", justifyContent: "flex-start" }}
          >
            Enter Amount Here
            <span style={{ color: "red" }}>*</span>
          </label>
          <input
            type="text"
            value={formatToIndianCurrency(modelRequestData?.amountEntered)}
            onChange={(e) => {
              let inputVal = acceptDecimal(e.target.value);

              if (inputVal.startsWith(" ")) {
                inputVal = inputVal.trimStart();
              }
              setModelRequestData((prev) => ({
                ...prev,
                amountEntered: inputVal,
              }));
            }}
            placeholder="Enter Amount"
            maxLength={9}
            className="form-control"
          />
          {error &&
          (modelRequestData.amountEntered === null ||
            modelRequestData.amountEntered === undefined ||
            modelRequestData.amountEntered === "") ? (
            <span style={{ color: "red" }}>{ERROR_MESSAGES}</span>
          ) : error && modelRequestData.amountEntered < modelRequestData.convenienceFeeAddedWithTax ? (
            <span style={{ color: "red" }}>
              Amount should not be less than {modelRequestData.convenienceFeeAddedWithTax}
            </span>
          ) : error &&
            modelRequestData.amountEntered > modelRequestData.totalPrice1 ? (
            <span style={{ color: "red" }}>
              Amount should not be greater than total price 
            </span>
          ) : (
            ""
          )}
        </div>
      </Modal.Body>
      <Modal.Footer style={{ backgroundColor: "#f2eeee" }}>
        <Button
          variant="secondary"
          onClick={() => {
            onHide();
            setModelRequestData((prev) => ({
              ...prev,
              amountEntered: 0,
            }));
          }}
          style={{ backgroundColor: "#A52444", border: "none" }}
        >
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={handleCODSubmit}
          style={{
            backgroundColor: "#EFB423",
            color: "#2B1B10",
            border: "none",
          }}
        >
          Pay now
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default TempleCodModal;
