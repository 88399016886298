import React, { useEffect, useState } from "react";
import { DarshanBookingInvoice } from "../../ApiServices/Darshan Booking/DarshanBookingApi";
import { useLocation, useNavigate } from "react-router-dom";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import logo from '../../assets/images/logo.png'
import dayjs from "dayjs";
const TempleDarshanInvoice = () => {
  const navigate = useNavigate();
  const location = useLocation();
  console.log(location.state?.TRB_ID, "dsdsdsdss2323");

  const [invoiceData, setInvoiceData] = useState({
    FULL_NAME: null,
    ADDRESS: null,
    TRB_PRICE: null,
    Tax_Amount: null,
    Tax_Perc: null,
    Convinience_Fee: null,
    TRB_TOTALPRICE: null,
    Booking_Amt: null,
    TR_NAME: null,
  });

  const userID = JSON.parse(localStorage.getItem("userID"));
  useEffect(() => {
    GetTempleBookingInvoice();
  }, []);

  const GetTempleBookingInvoice = async () => {
    try {
      const response = await DarshanBookingInvoice(
        location?.state?.TRB_ID,
        userID
      );
      console.log(response);
      if (
        response.ResponseMessage === "list" &&
        response.ResponseCode === "0"
      ) {
        const modelData = response.DATA[0];
        setInvoiceData((prev) => ({
          ...prev,

          FULL_NAME: modelData?.FULL_NAME,
          ADDRESS: modelData?.ADDRESS,
          TRB_PRICE: modelData?.TRB_PRICE,
          Tax_Amount: modelData?.Tax_Amount,
          Tax_Perc: modelData?.Tax_Perc,
          Convinience_Fee: modelData?.Convinience_Fee,
          TRB_TOTALPRICE: modelData?.TRB_TOTALPRICE,
          Booking_Amt: modelData?.Booking_Amt,
          TR_NAME: modelData?.TR_NAME,
          BookingDate: dayjs(modelData?.BookingDate).format('DD/MM/YYYY'),
          bookingTime: modelData?.TS_NAME,
          seatsNo: modelData?.NO_SEATS,
          invoiceDate: modelData?.invoiceDate === null || modelData?.invoiceData === undefined ? dayjs().format('DD/MM/YYYY') : dayjs(modelData?.invoiceDate).format('DD/MM/YYYY')
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };
  console.log(invoiceData, "sdad3223");

  const handleDownloadPDF = () => {
    const invoiceElement = document.getElementById("invoice-content");

    html2canvas(invoiceElement, { scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      const imgWidth = 210;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
      pdf.save(`Invoice_2.pdf`);
    });
  };

  return (
    <div className="container d-flex flex-column justify-content-center align-items-center mt-2" style={{ minHeight: "100vh" }}>
      <div id="invoice-content" style={{ width: "70vw" }}>
        <div style={{ margin: '10px' }}>
          <div className="card shadow-sm" style={{
            border: "2px solid black",
            padding: "20px",
            borderRadius: "10px",
            backgroundColor: 'white',

          }}>
            <div className="d-flex justify-content-between align-items-center border-bottom p-3">

              <div >
                <h1 className="h3 fw-bold" style={{ color: "#A52444" }}>
                  INVOICE
                </h1>
              </div>
              <div>
                <img
                  src={logo}
                  alt="Buildous Logo"
                  className="img-fluid"
                  style={{ height: "50px", width: "120PX" }}
                />
              </div>
            </div>

            <div className="card-body" >
              <div className="row d-flex justify-content-between">
                <div className="col-md-6">
                  <h5 style={{ fontSize: '20px' }}>Invoice No.</h5>

                  <p >
                    {/* {invoiceData.address} */}
                    #INV1212
                  </p>
                </div>
                <div className="col-md-6 d-flex justify-content-end">
                  <div>
                    <h5 style={{ fontSize: '20px' }}>Invoice Date</h5>
                    {/* <p>{invoiceDetails.date}</p> */}
                    <p style={{ fontSize: '15px' }}>{invoiceData?.invoiceDate}</p>
                  </div>
                </div>
              </div>
              <div className="row d-flex justify-content-between">
                <div className="col-md-12">
                  <h5 style={{ fontSize: '20px' }}>Customer Info</h5>
                  <p style={{ margin: 'auto', fontSize: '15px' }}>
                    <strong>Name:</strong> {invoiceData.FULL_NAME}
                    {/* <strong>Name:</strong> Test customer */}
                  </p>
                  <p >
                    {/* <strong>Address:</strong> {location.state.Address} */}
                    <strong>Address:</strong> {invoiceData.ADDRESS}
                  </p>
                </div>
              </div>
              <table className="table table-bordered">
                <thead className="thead-light">
                  <tr>
                    <th style={{ fontSize: '15px' }}>Sr No.</th>
                    <th style={{ fontSize: '15px' }}>Temple Name </th>
                    <th style={{ fontSize: '15px' }}>Booking Date</th>
                    <th style={{ fontSize: '15px' }}>Booking Time</th>
                    <th style={{ fontSize: '15px' }}>Number of seats</th>
                    <th style={{ fontSize: '15px' }}>Darshan Fee (per person)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr style={{ fontSize: '15px' }}>
                    <td >1</td>
                    <td >{invoiceData.TR_NAME}</td>
                    <td >{invoiceData.BookingDate}</td>
                    <td >{invoiceData.bookingTime}</td>
                    <td >{invoiceData.seatsNo}</td>
                    <td >{invoiceData.TRB_PRICE}</td>
                  </tr>
                </tbody>
              </table>

              <div className="row">
                <div className="col-md-8">
                  <h5 style={{ fontSize: '20px' }}>Notes</h5>
                  <p>Thank you for shopping with us!</p>
                </div>
                <div className="col-md-4">
                  <table className="table">
                    <tbody>

                      <tr>
                        <td style={{ fontSize: '15px' }}>
                          <strong>Subtotal:</strong>
                        </td>
                        <td className="text-right" style={{ fontSize: '15px' }}>
                          ₹{" "}
                          {Number(invoiceData?.TRB_PRICE) * Number(invoiceData.seatsNo)}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ fontSize: '15px' }}>
                          <strong>Convinience Fee:</strong>
                        </td>
                        <td className="text-right" style={{ fontSize: '15px' }}>
                          ₹{" "}
                          {invoiceData?.Convinience_Fee}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ fontSize: '15px' }}>
                          <strong>Tax ({invoiceData?.Tax_Perc}%):</strong>
                        </td>
                        <td className="text-right" style={{ fontSize: '15px' }}>
                          {/* ₹ {location.state.shippingCharges} */}₹{" "}
                          {invoiceData.Tax_Amount}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ fontSize: '15px' }}>
                          <strong>Total Amount:</strong>
                        </td>
                        <td className="text-right" style={{ fontSize: '15px' }}>
                          {invoiceData.TRB_TOTALPRICE}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            {/* Card Footer with Download Button */}


          </div>
        </div>

      </div>
      <div className="mt-3">
        <button className="btn" style={{
          backgroundColor: "#a52444",
          color: "white",
          padding: "10px 20px",
          fontSize: "16px",
          borderRadius: "5px"
        }} onClick={handleDownloadPDF}>
          Download Invoice
        </button>
      </div>
    </div>
  );
};

export default TempleDarshanInvoice;
